import React from "react";
import { List } from "antd";
import moment from "moment";
import Spinner from "../../components/Spinner/spinner";

function TicketQueue({
  myTickets,
  unreadMessages,
  loading,
  handleRoomSelection,
  activeRoomID,
}) {
  const stripHtmlTags = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };
  return (
    <div
      style={{
        cursor: "pointer",
        flex: 1,
        marginLeft: "5px",
        marginRight: "5px",
        maxHeight: "86vh",
        overflowY: "auto",
        scrollbarWidth: "none" /* For Firefox */,
        msOverflowStyle: "none" /* For Internet Explorer and Edge */,
      }}
    >
      <style>
        {`
            div::-webkit-scrollbar {
              display: none;
            }
            div {
              -ms-overflow-style: none; 
              scrollbar-width: none; 
            }
          `}
      </style>
      {!loading ? (
        <>
          <List
            itemLayout="horizontal"
            dataSource={myTickets}
            renderItem={(item) => {
              const lastMessage =
                item?.messages && item?.messages.length > 0
                  ? item.messages[item.messages.length - 1]?.message
                  : "No messages available";
              const formattedDate =
                item?.messages && item?.messages.length > 0
                  ? moment(item.messages[item.messages.length - 1]?.timestamp)
                      .local()
                      .fromNow()
                  : null;

              const isUnread = unreadMessages[item.roomId] > 0;

              return item.ticketId.status !== "closed" ? (
                <List.Item
                  onClick={() => handleRoomSelection(item?.roomId, item)}
                  className={
                    item?.roomId === activeRoomID
                      ? "dexkor-help-desk-conversation-list"
                      : ""
                  }
                  style={{
                    borderBottom: "2px solid #f0f0f0", // Increased the width of the bottom border
                    cursor: "pointer",
                    paddingLeft: "15px",
                  }}
                >
                  <List.Item.Meta
                    title={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "roboto",
                            fontWeight: "600",
                            color: "#191B1D",
                            display: "inline-block", // Ensure ellipsis works correctly
                            maxWidth: "200px", // Set a max-width to define where truncation happens
                            whiteSpace: "nowrap", // Prevent the text from wrapping
                            overflow: "hidden", // Hide any overflowing text
                            textOverflow: "ellipsis", // Show "..." for overflowing text
                          }}
                        >
                          {item?.ticketId?.ticketNumber || "Ticket Number"}
                          {",   "}
                          {item?.ticketId?.subject}
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            color: "#A0A7AF",
                            fontWeight: "400",
                            fontFamily: "roboto",
                            marginRight: "0.5rem",
                          }}
                        >
                          {formattedDate}
                        </span>
                      </div>
                    }
                    description={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            color: "#A0A7AF",
                            fontFamily: "roboto",
                            fontWeight: "400",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "space-between",
                            marginRight: "5px",
                            display: "inline-block", // Ensure ellipsis works correctly
                            maxWidth: "200px", // Set a max-width to define where truncation happens
                            whiteSpace: "nowrap", // Prevent the text from wrapping
                            overflow: "hidden", // Hide any overflowing text
                            textOverflow: "ellipsis", // Show "..." for overflowing text
                          }}
                        >
                          {stripHtmlTags(lastMessage)}
                          {isUnread && (
                            <span
                              style={{
                                backgroundColor: "blue",
                                color: "white",
                                borderRadius: "12px",
                                padding: "2px 6px",
                                marginLeft: "8px",
                                fontSize: "12px",
                              }}
                            >
                              {unreadMessages[item.roomId]}
                            </span>
                          )}
                        </div>
                        <div
                          style={{
                            border: "solid",
                            borderRadius: "5px",
                            fontSize: "10px",
                            borderColor:
                              item.ticketId.priority === "Low"
                                ? "#A3D9A5" // soft green border
                                : item.ticketId.priority === "Medium"
                                ? "#B3C0DD" // soft blue (same as original)
                                : item.ticketId.priority === "High"
                                ? "#E3D8A5" // soft yellow
                                : item.ticketId.priority === "Critical"
                                ? "#E3A5A5" // soft red
                                : "#B3C0DD", // default border color if no match
                            color:
                              item.ticketId.priority === "Low"
                                ? "#1E6B1E" // dark green
                                : item.ticketId.priority === "Medium"
                                ? "#2C5EC6" // darker blue
                                : item.ticketId.priority === "High"
                                ? "#C6A21E" // darker yellow (golden tone)
                                : item.ticketId.priority === "Critical"
                                ? "#C62C2C" // dark red
                                : "#000000", // default text color if no match
                            backgroundColor:
                              item.ticketId.priority === "Low"
                                ? "#CCF9CC" // light green
                                : item.ticketId.priority === "Medium"
                                ? "#CCDAF9" // light blue
                                : item.ticketId.priority === "High"
                                ? "#FAF4B7" // light yellow
                                : item.ticketId.priority === "Critical"
                                ? "#F9CCCC" // light red
                                : "#FFFFFF", // default background if no match
                            marginRight: "1.5rem",
                          }}
                        >
                          <p style={{ padding: "0px 2px" }}>
                            {item.ticketId.priority}
                          </p>
                        </div>
                      </div>
                    }
                  />
                </List.Item>
              ) : null;
            }}
          />
        </>
      ) : (
        <>
          {" "}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <Spinner size="large" />
            <span style={{ marginTop: "10px", fontFamily: "roboto" }}>
              Loading ...
            </span>
          </div>
        </>
      )}
    </div>
  );
}

export default TicketQueue;
