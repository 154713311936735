import React from 'react'
import Frame from '../Images/Frame.jpg'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'

const Excellence = () => {
    const navig = useNavigate();
  return (
   <div style={{height: 'calc(100vh - 10%)'}}>
    <div style={{paddingTop: '10%', display: 'flex', justifyContent: 'center'}}>
        <img src={Frame} />
    </div>
    <div>
    <p style={{ fontSize: '23px', fontWeight: 'bold', display: 'flex', justifyContent: 'center', paddingTop: '30px', fontFamily: 'roboto' }}>
  Introducing Your DexKor Excellence: Coming Soon...
</p>
    </div>
    <div style={{ textAlign: 'center' }}>
  <p style={{ 
    textAlign: 'center', 
    paddingTop: '10px',
    marginLeft: 'auto', 
    fontFamily: 'roboto',
    marginRight: 'auto', 
    width: '60%', // Adjust this value to control the width of the paragraph
  }}>
  An intelligent analytics module that provides insights and generates tasks aimed at enhancing customer experience and driving continuous improvement.
  </p>
</div>
{/* <div style={{display: 'flex', justifyContent: 'center', paddingTop: '30px'}}>
    <Button style={{fontFamily: 'roboto'}} type='primary' size='large' onClick={() => navig('/help-desk/inbox')}>Get Started</Button>
</div> */}

   </div>
  )
}

export default Excellence