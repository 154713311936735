import React, { useState } from "react";
import DOMPurify from "dompurify";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import RenderAttachment from "./RenderAttachment";
import ReplyForm from "./ReplyForm/ReplyForm";

function EmailChat({
  selectedConversation,
  setSelectedConversation,
  setMyTicket,
  scrollToBottom,
  handleFileChange,
  setMessage,
  message,
  sendMessage,
}) {
  const [expandedId, setExpandedId] = useState(null);
  const [showDetails, setShowDetails] = useState(null);

  const toggleExpand = (id) => {
    setExpandedId((prev) => (prev === id ? null : id));
  };

  const toggleDetails = (id) => {
    setShowDetails((prev) => (prev === id ? null : id));
  };

  const extractQuotedText = (message) => {
    const quotedTextPattern =
      /(?:^On .+ wrote:|^From: .+|^\s*>.*(?:\n|$)|^>.*(?:\n|$))/gms;
    const match = message.match(quotedTextPattern);

    if (match) {
      const firstMatchIndex = message.indexOf(match[0]);
      return message.substring(firstMatchIndex);
    }
    return "";
  };

  const removeQuotedText = (message) => {
    const quotedTextPattern =
      /(?:^On .+ wrote:|^From: .+|^\s*>.*(?:\n|$)|^>.*(?:\n|$))/gms;
    return message.replace(quotedTextPattern, "").trim();
  };

  const formatQuotedText = (text) => {
    const lines = text.split("\n");

    return lines
      .map((line) => {
        const indentLevel = (line.match(/^>+/) || [""])[0].length;
        const cleanLine = line.replace(/^>+ */, "");
        return `<div style="margin-left: ${
          indentLevel * 5
        }px;" class="border-l border-gray-300 pl-2 italic text-xs text-red-700">${cleanLine}</div>`;
      })
      .join("");
  };

  function formatDate(timestamp) {
    const messageDate = new Date(timestamp);
    const today = new Date();
    const startOfToday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    const startOfMessageDate = new Date(
      messageDate.getFullYear(),
      messageDate.getMonth(),
      messageDate.getDate()
    );

    const currentYear = today.getFullYear();
    const messageYear = messageDate.getFullYear();

    if (startOfMessageDate.getTime() === startOfToday.getTime()) {
      // If the message is from today, show only the time
      return messageDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    } else if (messageYear === currentYear) {
      // If the message is from the current year, show the date without the year
      return messageDate.toLocaleDateString([], {
        month: "short",
        day: "2-digit",
      });
    } else {
      // Otherwise, show the date with the year
      return messageDate.toLocaleDateString([], {
        year: "numeric",
        month: "short",
        day: "2-digit",
      });
    }
  }

  // Helper function to extract emails from cc or bcc fields
  const extractEmails = (field) => {
    if (Array.isArray(field)) {
      return field
        .map((item) => (typeof item === "object" ? item.address : item))
        .join(", ");
    }
    return "";
  };

  return (
    <div className="flex flex-row overflow-hidden">
      <div className="flex-1 bg-transparent overflow-y-scroll">
        {selectedConversation ? (
          <div className="p-0">
            <h1 className="text-xl font-bold mb-4 mt-4 ml-4">
              {selectedConversation.ticketId.ticketNumber}{" - "}{selectedConversation.ticketId.name}
            </h1>
            <div className="email-thread">
              {selectedConversation.messages.length > 0 &&
                selectedConversation.messages.map((message, index) => (
                  <div key={index} className="flex flex-col w-full">
                    <div
                      className={`w-full p-4 ${
                        message.sender === "Customer"
                          ? "rounded-e-lg rounded-t-lg"
                          : "rounded-s-lg rounded-t-lg"
                      }  ${
                        message.sender === "Agent"
                          ? "bg-blue-100"
                          : "bg-gray-200"
                      } mb-2`}
                    >
                      <div className="flex flex-row items-center mb-2">
                        <div className="w-10 h-10">
                          <img
                            src={
                              message.sender === "Customer"
                                ? "https://lh3.googleusercontent.com/a/default-user=s40-p"
                                : "https://lh3.googleusercontent.com/a/default-user=s40-p"
                            }
                            alt="Sender Avatar"
                            className="rounded-full object-cover w-10 h-10"
                          />
                        </div>
                        <div className="ml-3">
                          <p className="font-medium">
                            {message.sender === "Agent"
                              ? "me"
                              : selectedConversation?.customer?.name}
                          </p>
                          <p className="text-xs text-gray-500">
                            {formatDate(message.timestamp)}
                          </p>
                          <p
                            className="text-gray-500 mt-1 cursor-pointer flex items-center"
                            onClick={() => toggleDetails(message._id)}
                          >
                            to{" "}
                            {message.sender === "Customer"
                              ? "me"
                              : selectedConversation?.customer?.name}
                            <GoChevronDown
                              className={`ml-1 h-4 w-4 dexkor-chevron-transition ${
                                showDetails === message._id
                                  ? "dexkor-chevron-rotate-180"
                                  : ""
                              }`}
                            />
                          </p>
                        </div>
                      </div>
                      <div className="text-sm text-gray-900">
                        {showDetails === message._id && (
                          <div className="mt-2 mb-2 p-2 space-y-1 border border-gray-300 rounded-lg">
                            <p className="font-semibold flex">
                              <span className="text-gray-500 mr-3">From</span>{" "}
                              {message.sender === "Customer"
                                ? selectedConversation?.customer?.name
                                : selectedConversation?.user?.name}
                              {", "}
                              {message.sender === "Customer"
                                ? selectedConversation?.customer?.email
                                : "help@dexkor.com"}
                            </p>
                            <p className="font-semibold">
                              {" "}
                              <span className="text-gray-500 mr-7">
                                To
                              </span>{" "}
                              {message.sender !== "Customer"
                                ? selectedConversation?.customer?.name
                                : selectedConversation?.user?.name}
                              {", "}
                              {message.sender !== "Customer"
                                ? selectedConversation?.customer?.email
                                : "help@dexkor.com"}
                            </p>
                            {selectedConversation.ticketId.cc &&
                              selectedConversation.ticketId.cc.length > 0 && (
                                <p className="font-semibold">
                                  {" "}
                                  <span className="text-gray-500 mr-7">
                                    Cc
                                  </span>{" "}
                                  {extractEmails(
                                    selectedConversation.ticketId.cc
                                  )}
                                </p>
                              )}
                            {selectedConversation.ticketId.bcc &&
                              selectedConversation.ticketId.bcc.length > 0 && (
                                <p className="font-semibold">
                                  {" "}
                                  <span className="text-gray-500 mr-7">
                                    Bcc
                                  </span>{" "}
                                  {extractEmails(
                                    selectedConversation.ticketId.bcc
                                  )}
                                </p>
                              )}
                            <p className="font-semibold">
                              <span className="text-gray-500 mr-3">Date</span>{" "}
                              {new Date(message.timestamp).toLocaleString([], {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              })}
                            </p>
                          </div>
                        )}
                        <p
                          className={`whitespace-pre-line mb-4 ${
                            expandedId === message._id ? "" : "line-clamp-3"
                          }`}
                          onClick={() => toggleExpand(message._id)}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              removeQuotedText(message.message)
                            ),
                          }}
                        ></p>

                        {/* Check if the message has an attachment and render it */}
                        {message.attachment && (
                          <RenderAttachment
                            attachment={message.attachment}
                            attachmentType={message.attachmentType}
                          />
                        )}

                        {extractQuotedText(message.message) && (
                          <span
                            className="text-blue-500 cursor-pointer"
                            onClick={() => toggleExpand(message._id)}
                          >
                            {expandedId === message._id
                              ? "Hide quoted text"
                              : "Show quoted text"}
                          </span>
                        )}
                        {expandedId === message._id && (
                          <div
                            className="text-sm mt-2"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                formatQuotedText(
                                  extractQuotedText(message.message)
                                )
                              ),
                            }}
                          ></div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <ReplyForm
              selectedConversation={selectedConversation}
              setSelectedConversation={setSelectedConversation}
              setMyTicket={setMyTicket}
              scrollToBottom={scrollToBottom}
              handleFileChange={handleFileChange}
              setMessage={setMessage}
              message={message}
              sendMessage={sendMessage}
              extractEmails={extractEmails}
            />
          </div>
        ) : (
          <div className="p-8">
            <p>Welcome to GmailKit!</p>
            <i className="italic">Select an email to get started.</i>
          </div>
        )}
      </div>
    </div>
  );
}

export default EmailChat;