import React, { useEffect, useRef, useState } from "react";
import { List, Collapse, theme } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import useNotification from "../../components/snackbars/SnackBar";
import axios from "axios";
import io from "socket.io-client";

const socket = io(`${process.env.REACT_APP_CHAT_SERVER}`);

function TicketDetails({
  selectedConversation,
  setSelectedConversation,
  setMyTickets,
  onTabEdit,
}) {
  const [showOwnerDropdown, setShowOwnerDropDown] = useState(false);
  const [showAssigneeDropdown, setShowAssigneeDropDown] = useState(false);
  const [loggedInUserData, setLoggedInUserData] = useState(null);
  const [conf, setConf] = useNotification();
  const [searchQuery, setSearchQuery] = useState("");

  console.log({ searchQuery });

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1); // Track the current page
  const [limit, setLimit] = useState(4); // Number of users per page
  const [hasMore, setHasMore] = useState(true); // Whether more users are available
  const [loading, setLoading] = useState(false); // Loading state
  const dropdownRef = useRef(null); // Create a ref for the dropdown

  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 20,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  const data = [selectedConversation];
  console.log({ users });

  // Fetch users based on page and limit
  const fetchUsers = async (reset = false) => {
    if (loading || !hasMore || !loggedInUserData) return;

    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/chunks/${loggedInUserData.uniquePartnerId}?page=${page}&limit=${limit}`
      );
      const fetchedUsers = response.data.users;

      if (response.status === 204) {
        setHasMore(false); // Stop fetching when no more users are available
      } else {
        setUsers((prevUsers) =>
          reset ? fetchedUsers : [...prevUsers, ...fetchedUsers]
        );
        setPage((prevPage) => prevPage + 1); // Increment the page
      }
    } catch (error) {
      setConf({ msg: "Error fetching users!", variant: "error" });
      console.error("Error fetching users: ", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch logged-in user data
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
    if (userData) setLoggedInUserData(userData);
  }, []);

  // Fetch users when the dropdown is shown for the first time
  useEffect(() => {
    if (showOwnerDropdown || showAssigneeDropdown) {
      fetchUsers(true); // Fetch users for the first time or reset if dropdown is reopened
    }
  }, [showOwnerDropdown, showAssigneeDropdown]);

  // Infinite scroll logic for dropdown
  useEffect(() => {
    const dropdownElement = dropdownRef.current; // Reference the dropdown element
    const handleScroll = () => {
      if (
        dropdownElement &&
        dropdownElement.scrollTop + dropdownElement.clientHeight >=
          dropdownElement.scrollHeight &&
        !loading
      ) {
        fetchUsers(); // Fetch more users on scroll
      }
    };

    if (dropdownElement) {
      dropdownElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (dropdownElement) {
        dropdownElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, hasMore, page]);

  useEffect(() => {
    // Define an async function to filter all users
    const searchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/filter/${loggedInUserData.uniquePartnerId}`,
          {
            params: { query: searchQuery }, // Pass the query as a query parameter
          }
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    // Call the fetch function
    if (searchQuery !== "") {
      searchUsers();
    }
  }, [searchQuery]);

  const updateAssigneeOrOwner = (
    assignee,
    ownerId,
    ownerName,
    assigneeName
  ) => {
    socket.emit(
      "updateConversationDetails",
      {
        conversationId: selectedConversation._id,
        assignee,
        ownerId,
      },
      (response) => {
        if (response.success) {
          // Check if the logged-in user is the owner of the conversation
          const isOwner = loggedInUserData._id === ownerId;
          setMyTickets((prevConversations) => {
            if (isOwner) {
              // If the logged-in user is the owner, update the conversation in the list
              const updatedConversations = prevConversations.map(
                (conversation) =>
                  conversation._id === response.conversation._id
                    ? response.conversation // Replace with the updated conversation
                    : conversation // Keep the existing conversation
              );

              // Set the selected conversation to the updated conversation
              setSelectedConversation(response.conversation);
              return updatedConversations; // Return the updated list
            } else {
              // If the logged-in user is not the owner, remove the conversation
              const updatedConversations = prevConversations.filter(
                (conversation) => conversation._id !== response.conversation._id
              );

              // Clear the selected conversation
              setSelectedConversation(null);
              onTabEdit(selectedConversation.roomId, "remove");
              return updatedConversations; // Return the filtered list
            }
          });
          setShowAssigneeDropDown(false);
          setShowOwnerDropDown(false);
          if (!isOwner) {
            setConf({
              msg: `Ticket ownership transfered to ${ownerName}`,
              variant: "success",
            });
          } else {
            setConf({
              msg: `Ticket assigned to ${assigneeName}`,
              variant: "success",
            });
          }
        } else {
          setConf({
            msg: "error occurred while changing the ticket details",
            variant: "error",
          });
          console.error("Error assigning ticket:", response.message);
        }
      }
    );
  };

  return (
    <div>
      <Collapse
        bordered={false}
        defaultActiveKey={["1"]}
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{
          backgroundColor: "white",
        }}
        items={[
          {
            key: "1",
            label: (
              <div
                style={{
                  fontWeight: "600",
                  fontFamily: "roboto",
                }}
              >
                {" "}
                Ticket Details{" "}
              </div>
            ),
            children: (
              <List
                size="small"
                dataSource={data}
                renderItem={(item) => (
                  <List.Item key={item.roomId}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Ticket Type: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId.type}
                      </span>
                    </div>{" "}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Priority: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId.priority}
                      </span>
                    </div>{" "}
                    <br />
                    {item.owner && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontFamily: "roboto",
                            fontWeight: "400",
                          }}
                        >
                          <span style={{ color: "#A0A7AF" }}>owner: </span>

                          {item?.owner?._id === loggedInUserData?._id ||
                          loggedInUserData?.role === "Admin" ? (
                            <span style={{ color: "#2A4DAD" }}>
                              <div>
                                <div
                                  className="cursor-pointer font-medium rounded-lg text-sm text-center inline-flex items-center"
                                  onClick={() => {
                                    setShowOwnerDropDown(!showOwnerDropdown);
                                    setPage(1);
                                    setShowAssigneeDropDown(false);
                                  }}
                                >
                                  {item?.owner?.name}{" "}
                                  <svg
                                    className="w-2.5 h-2.5 ms-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 10 6"
                                  >
                                    <path
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="m1 1 4 4 4-4"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </span>
                          ) : (
                            <span style={{ color: "#2A4DAD" }}>
                              {item?.owner?.name}
                            </span>
                          )}
                        </div>{" "}
                        {/* <!-- Owner Dropdown menu --> */}
                        {showOwnerDropdown && !showAssigneeDropdown && (
                          <div className="flex justify-end">
                            <div className="absolute z-10 bg-white rounded-lg shadow w-60">
                              <ul
                                id="dropdownUsers"
                                className="max-h-48 py-2 overflow-y-auto text-gray-700"
                                aria-labelledby="dropdownUsersButton"
                                ref={dropdownRef}
                              >
                                {users.length > 0 &&
                                  users.map((user, index) => (
                                    <li
                                      key={index}
                                      onClick={() =>
                                        updateAssigneeOrOwner(
                                          item.assignee._id,
                                          user._id,
                                          user.name,
                                          item.assignee.name
                                        )
                                      }
                                    >
                                      <a
                                        href="#"
                                        className="flex items-center px-4 py-2 hover:bg-gray-100 text-wrapper"
                                      >
                                        {user?.image ? (
                                          <img
                                            className="w-6 h-6 me-2 mb-3 rounded-full"
                                            src={user.image}
                                            alt="User image"
                                          />
                                        ) : (
                                          <div className="w-6 h-6 me-2 mb-3 rounded-full bg-gray-300 flex items-center justify-center text-white">
                                            {user?.name?.charAt(0)}
                                          </div>
                                        )}
                                        <span className="flex-1 overflow-hidden">
                                          <p className="text-sm font-medium text-gray-800 truncate">
                                            {user.name}
                                          </p>
                                          <span className="text-xs text-gray-600 truncate">
                                            {user.email}
                                          </span>
                                        </span>
                                      </a>
                                    </li>
                                  ))}
                                {loading && (
                                  <li className="text-center">Loading...</li>
                                )}
                              </ul>
                              <div className="flex items-center p-2 text-xs font-medium text-blue-600 border-t border-gray-200 rounded-b-lg bg-gray-50 hover:bg-gray-100">
                                <input
                                  value={searchQuery}
                                  onChange={(e) =>
                                    setSearchQuery(e.target.value)
                                  }
                                  type="text"
                                  placeholder="Search user..."
                                  className="w-full px-1 py-0.5 text-xs bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <br />
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Assigned To: </span>

                      {item?.assignee?._id === loggedInUserData?._id ||
                      loggedInUserData?.role === "Admin" ? (
                        <span style={{ color: "#2A4DAD" }}>
                          <div>
                            <div
                              className="cursor-pointer font-medium rounded-lg text-sm text-center inline-flex items-center"
                              onClick={() => {
                                setShowAssigneeDropDown(!showAssigneeDropdown);
                                setPage(1);
                                setShowOwnerDropDown(false);
                              }}
                            >
                              {item?.assignee?.name}{" "}
                              <svg
                                className="w-2.5 h-2.5 ms-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 10 6"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="m1 1 4 4 4-4"
                                />
                              </svg>
                            </div>
                          </div>
                        </span>
                      ) : (
                        <span style={{ color: "#2A4DAD" }}>
                          {item?.assignee?.name}
                        </span>
                      )}
                    </div>{" "}
                    {/* <!-- Dropdown menu --> */}
                    {showAssigneeDropdown && !showOwnerDropdown && (
                      <div className="flex justify-end">
                        <div className="absolute z-10 bg-white rounded-lg shadow w-60">
                          <ul
                            className="max-h-48 py-2 overflow-y-auto overflow-x-auto text-gray-700"
                            aria-labelledby="dropdownUsersButton"
                            ref={dropdownRef}
                          >
                            {users.length > 0 &&
                              users.map((user, index) => (
                                <li
                                  key={index}
                                  onClick={() =>
                                    updateAssigneeOrOwner(
                                      user._id,
                                      item.owner._id,
                                      item.owner.name,
                                      user.name
                                    )
                                  }
                                >
                                  <a
                                    href="#"
                                    className="flex items-center px-4 py-2 hover:bg-gray-100 text-wrapper"
                                  >
                                    {user?.image ? (
                                      <img
                                        className="w-6 h-6 me-2 mb-3 rounded-full"
                                        src={user.image}
                                        alt="User image"
                                      />
                                    ) : (
                                      <div className="w-6 h-6 me-2 mb-3 rounded-full bg-gray-300 flex items-center justify-center text-white">
                                        {user?.name?.charAt(0)}
                                      </div>
                                    )}
                                    <span className="flex-1 overflow-hidden">
                                      <p className="text-sm font-medium text-gray-800 truncate">
                                        {user.name}
                                      </p>
                                      <span className="text-xs text-gray-600 truncate">
                                        {user.email}
                                      </span>
                                    </span>
                                  </a>
                                </li>
                              ))}
                            {loading && (
                              <li className="text-center">Loading...</li>
                            )}
                          </ul>
                          <div className="flex items-center p-2 text-xs font-medium text-blue-600 border-t border-gray-200 rounded-b-lg bg-gray-50 hover:bg-gray-100">
                            <input
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                              type="text"
                              placeholder="Search user..."
                              className="w-full px-1 py-0.5 text-xs bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Tags: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId.tags}
                      </span>
                    </div>
                  </List.Item>
                )}
              />
            ),
            style: panelStyle,
          },
        ]}
      />
    </div>
  );
}

export default TicketDetails;
