import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "../../Private Route/PrivateRoute";
import PageNotFound from "../../components/PageNotFound";
import Emails from "../pages/inbox/Emails/Emails";
import Tickets from "../pages/inbox/Tickets/Tickets";
import Index from "../Index";
import SnoozedTickets from "../pages/snoozed/Tickets/SnoozedTickets";
import SnoozedEmails from "../pages/snoozed/Emails/SnoozedEmails";
import SLABreached from "../pages/MyTeamQueue/SLABreached/SLABreached";
import ApproachingSLABreach from "../pages/MyTeamQueue/ApproachingSLABreach/ApproachingSLABreach";
import AssignedTickets from "../pages/Assigned/Tickets/AssignedTickets";
import AssignedEmails from "../pages/Assigned/Emails/AssignedEmails";
import AllPendings from "../pages/MyTeamQueue/AllPendings/Allpendings";
import TicketExplorer from "../pages/TicketExplorer/TicketExplorer";
import { useSelector } from "react-redux";

const HelpDeskRoutes = () => {

  const ticketExplorerViews = useSelector(
    (state) => state.ticketExplorer.views
  );

  return (
    <>
      <Route
        path="/help-desk"
        element={
          <PrivateRoute>
            <Index />
          </PrivateRoute>
        }
      >
        <Route path="inbox">
          <Route
            path="tickets"
            element={
              <PrivateRoute>
                <Tickets />
              </PrivateRoute>
            }
          />
          <Route
            path="emails"
            element={
              <PrivateRoute>
                <Emails />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="snoozed">
          <Route
            path="tickets"
            element={
              <PrivateRoute>
                <SnoozedTickets />
              </PrivateRoute>
            }
          />
          <Route
            path="emails"
            element={
              <PrivateRoute>
                <SnoozedEmails />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="assigned">
          <Route
            path="tickets"
            element={
              <PrivateRoute>
                <AssignedTickets />
              </PrivateRoute>
            }
          />
          <Route
            path="emails"
            element={
              <PrivateRoute>
                <AssignedEmails />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="my-team-queue">
          <Route
            path="sla-breached"
            element={
              <PrivateRoute>
                <SLABreached />
              </PrivateRoute>
            }
          />
          <Route
            path="approaching-sla-breach"
            element={
              <PrivateRoute>
                <ApproachingSLABreach />
              </PrivateRoute>
            }
          />
          <Route
            path="all-pendings"
            element={
              <PrivateRoute>
                <AllPendings />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="ticket-explorer">
          {ticketExplorerViews.map((view) => {
            return (
              <Route
                path={view.name.replace(/\s+/g, "-").toLowerCase()}
                element={
                  <PrivateRoute>
                    <TicketExplorer viewData={view} />
                  </PrivateRoute>
                }
              />
            );
          })}
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </>
  );
};

export default HelpDeskRoutes;
