import React, { useState } from "react";
import logo from "../../../Images/Logo icon.png";
import "./AllTicket.css"
import { IoArrowBackCircleSharp } from "react-icons/io5";
import moment from "moment";
import { Row, Col, Button, Card, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { CiFilter } from "react-icons/ci";
import { BiMessageSquareEdit } from "react-icons/bi";

const AllTickets = ({ ticketData }) => {
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) =>
    setSearchQuery(e.target.value.toLowerCase());

  const filteredTickets =
    searchQuery.trim() === ""
      ? ticketData
      : ticketData.filter((ticket) => {
          const searchQueryLower = searchQuery.toLowerCase();
          return (
            ticket.ticketId.ticketNumber &&
            ticket.ticketId.ticketNumber
              .toLowerCase()
              .includes(searchQueryLower)
          );
        });

  const renderAttachment = (attachment, attachmentType) => {
    const handlePreview = () => {
      window.open(attachment, "_blank");
    };

    if (attachmentType.includes("image")) {
      return (
        <img
          src={attachment}
          alt="attachment"
          className="w-22 max-w-xs rounded-lg"
        />
      );
    } else if (attachmentType.includes("video")) {
      return (
        <video src={attachment} controls className="w-22 max-w-xs rounded-lg" />
      );
    } else if (attachmentType.includes("audio")) {
      return <audio src={attachment} controls />;
    } else {
      return (
        <button
          onClick={handlePreview}
          className="bg-transparent hover:bg-transparent hover:text-black px-3 py-1 rounded"
        >
          Attachment preview
        </button>
      );
    }
  };

  return (
    <Row className='dexkor-sales-hub-all-ticket-body'>
      <Col span={12}>
          <div
            style={{
              backgroundColor: "#EFF3F5",
              width: "100%",
              height: "100vh",
              marginTop: "-15px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "15px",
              }}
            >
              <Input
                style={{
                  width: "90%",
                  backgroundColor: "#F6F7FA",
                  height: "45px",
                }}
                placeholder="Search Tickets"
                prefix={<SearchOutlined style={{ color: "#5F5F5F" }} />}
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <span style={{ display: "flex" }}>
                <Button
                  style={{
                    backgroundColor: "#ECECEC",
                    color: "#5F5F5F",
                    height: "45px",
                  }}
                >
                  <CiFilter style={{ color: "#5F5F5F", fontSize: "26px" }} />{" "}
                  Filter
                </Button>
                <Button
                  style={{
                    backgroundColor: "#2A4DAD",
                    color: "white",
                    height: "45px",
                  }}
                >
                  <BiMessageSquareEdit
                    style={{ color: "white", fontSize: "26px" }}
                  />{" "}
                  New Ticket
                </Button>
              </span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                height: "100%",
                overflowY: "auto",
              }}
            >
              {filteredTickets.length > 0 ? (
                filteredTickets.map((ticket) => (
                  <Card
                    key={ticket.ticketId.ticketNumber}
                    style={{
                      width: "90%",
                      margin: "10px 0",
                      cursor: "pointer",
                    }}
                    onClick={() => setSelectedTicket(ticket)}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                      }}
                    >
                      <span style={{ color: "#202020", fontSize: "20px" }}>
                        {ticket.ticketId.ticketNumber} - {ticket.ticketId.name}
                      </span>
                      <span>
                        {moment(ticket.createdAt).format("DD-MM-YYYY hh:mm A")}
                      </span>
                    </div>
                    <div
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "normal",
                        marginTop: "10px",
                      }}
                    >
                      <p
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          WebkitLineClamp: 4,
                          textOverflow: "ellipsis",
                          lineHeight: "1.5em",
                          maxHeight: "6em",
                          whiteSpace: "normal",
                        }}
                      >
                        {ticket.ticketId.description}
                      </p>
                    </div>
                    <p>{ticket.ticketId.status}</p>
                  </Card>
                ))
              ) : (
                <p>No tickets available</p>
              )}
            </div>
          </div>
        
      </Col>
      <Col span={12}>
      <div className="h-full overflow-y-auto z-0 p-4 pb-56 bg-gray-100 mt-3 ml-3 mr-3">
              {selectedTicket ? (
                selectedTicket?.messages.length > 0 &&
                selectedTicket?.messages.map((msg, index) =>
                  msg.sender === "Agent" ? (
                    <div
                      key={index}
                      className="flex justify-end mb-4 cursor-pointer"
                    >
                      {msg.message && (
                        <div className="block max-w-96 bg-gray-200 text-black rounded-lg p-3 gap-3">
                          <p className="flex">{msg.message}</p>
                          <p className="text-gray-600 text-xs pl-5 pb-0 pt-1 mr-0 right-0">
                            {new Date(msg.timestamp).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            })}
                          </p>
                        </div>
                      )}
                      {msg.attachment && (
                        <div
                          className={`flex max-w-96 bg-blue-500 text-white ${
                            msg.attachmentType.includes("audio")
                              ? "rounded-full"
                              : "rounded-lg"
                          } p-1 gap-3`}
                        >
                          {renderAttachment(msg.attachment, msg.attachmentType)}
                        </div>
                      )}
                      <div className="w-9 h-9 rounded-full flex items-center justify-center ml-2 mr-2">
                        <img
                          src="https://i.pinimg.com/474x/5c/90/91/5c90918460c19210ac39858555a46af6.jpg"
                          alt="My Avatar"
                          className="w-8 h-8 rounded-full"
                        />
                      </div>
                    </div>
                  ) : (
                    <div key={index} className="flex mb-4 cursor-pointer">
                      <div className="w-9 h-9 rounded-full flex items-center justify-center mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1707101905111990272/Z66vixO-_normal.jpg"
                          alt="User Avatar"
                          className="w-8 h-8 rounded-full"
                        />
                      </div>
                      {msg.message && (
                        <div className="block max-w-96 bg-blue-200 rounded-lg p-3 gap-3">
                          <p className="text-gray-900">{msg.message}</p>
                          <p className="text-gray-600 text-xs pl-5 mr-0 right-0 ml-auto">
                            {new Date(msg.timestamp).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            })}
                          </p>
                        </div>
                      )}
                      {msg.attachment && (
                        <div
                          className={`flex max-w-96 bg-green-500 ${
                            msg.attachmentType.includes("audio")
                              ? "rounded-full"
                              : "rounded-lg"
                          } p-1 gap-3`}
                        >
                          {renderAttachment(msg.attachment, msg.attachmentType)}
                        </div>
                      )}
                    </div>
                  )
                )
              ) : (
                <p>Pick a ticket to start chatting</p>
              )}
            </div></Col>
    </Row>
  );
};

export default AllTickets;
