import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import "./ImportExport.css"
import { Button, Card, Space, Modal, Row, Col, Menu } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'

const UserSetup = () => {
  const [selectedKey, setSelectedKey] = useState('0');
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const navig = useNavigate();

  const items = [
    { key: '1', label: 'Import Customer', path: '/setting/import-export-setup/import-customer'},
    { key: '2', label: 'Import Users', path: '/setting/import-export-setup/import-user'},
    // { key: '3', label: 'Export Customer'},
    // { key: '4', label: 'Export Users' },
    { key: '5', label: 'Import Subscription', path: '/setting/import-export-setup/import-subscription' },
    { key: '6', label: 'Update Subscription', path: '/setting/import-export-setup/update-subscription' },
    { key: '7', label: 'Import Task', path: '/setting/import-export-setup/import-task' },
    { key: '8', label: 'Update Customer', path: '/setting/import-export-setup/update-customer' },
  ];

  useEffect(() => {
    const currentPath = location.pathname;

    // Find the item whose path is a prefix of the current URL
    const matchedItem = items.find((item) => currentPath.startsWith(item.path));

    if (matchedItem) {
      setSelectedKey(matchedItem.key); // Update selected key based on the matching URL
    } else {
      setSelectedKey(''); // Clear the selection if no match is found
    }
  }, [location.pathname, items]);

  const getMenuItems = (menuItems) =>
    menuItems.map(item => ({
      key: item.key,
      label: (
        <div
          onClick={(e) => {
            if (item.path && !e.target.classList.contains('ant-menu-submenu-arrow')) {
              navig(item.path);
            }
          }}
          className='dexkor-sales-hub-menu-item'
        >
          {item.label}
          {item.number !== undefined && <span>{item.number}</span>}
        </div>
      ),
      children: item.children ? getMenuItems(item.children) : null,
    }));

  const handleMenuClick = (e) => {
    const selectedItem = items.flatMap(item => item.children || [item]).find(item => item.key === e.key);
    if (selectedItem && selectedItem.path) {
      navig(selectedItem.path);
    }
    if (e.key === '2') {
      setOpenKeys([e.key]);
      setSelectedKey('21');  // Select first child
    } else {
      setSelectedKey(e.key);
    }
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  
    if (keys.includes('2')) {
      const firstChild = items.find(item => item.key === '2').children[0];
      if (firstChild && firstChild.path) {
        setSelectedKey(firstChild.key);  
        navig(firstChild.path);      
      }
    }
  };

  return (
   <div>
    <Row>
      <Col span={4}> <div className="dexkor-sales-hub-main-screen-left-nav-bar-header">
            <span className='cursor-pointer' >Import/Export</span>
          </div>
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
            onOpenChange={onOpenChange}
            className='dexkor-sales-hub-nav-bar-menu-list'
            items={getMenuItems(items)}
            openKeys={openKeys}
          />
          </Col>
      <Col span={20}>
      <Outlet /></Col>
    </Row>
    
   </div>
  )
}

export default UserSetup