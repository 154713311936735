import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeComment,
  removeReply,
  updateComment,
  updateReply,
} from "../../../Redux/Slice/starboardBeerDonationPostsSlice";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import moment from "moment";
import "./CommentSection.css";

function CommentSection({ beerDonationPostId }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [editingText, setEditingText] = useState("");
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(null);
  const [message, setMessage] = useState("");
  const [replyMessage, setReplyMessage] = useState("");
  const [replyToReplyMessage, SetReplyToReplyMessage] = useState("");
  const [replyToCommentId, setReplyToCommentId] = useState(null);
  const [showReplies, setShowReplies] = useState(false);
  const [showReplyInputField, setShowReplyInputField] = useState(null);
  const comments = useSelector(
    (state) =>
      state.starboardBeerDonationPosts.starboardBeerDonationPostsData.find(
        (post) => post._id === beerDonationPostId
      )?.comments || []
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUserData = JSON.parse(
          localStorage.getItem("dexkorUserData")
        );
        setUserData(storedUserData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message.trim()) return;

    try {
      const newComment = {
        message,
        userId: userData._id,
        beerDonationPostId: beerDonationPostId,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_CONVERSATION}/starboard/comments`,
        newComment
      );
      dispatch(
        updateComment({
          postId: beerDonationPostId,
          newComment: response.data.comment,
        })
      );
      setMessage("");
    } catch (error) {
      console.error("Error posting comment:", error);
    }
  };

  const toggleComentSettingDropdown = (Id) => {
    if (!isDropdownOpen) setIsDropdownOpen(Id);
    else {
      if (isDropdownOpen === Id) setIsDropdownOpen(null);
      else setIsDropdownOpen(Id);
    }
  };

  const handleReplySubmit = async (e, commentId, replyFlag) => {
    e.preventDefault();
    if (replyFlag && !replyMessage.trim()) return;
    if (!replyFlag && !replyToReplyMessage.trim()) return;

    try {
      const newReply = {
        message: replyFlag ? replyMessage : replyToReplyMessage,
        userId: userData._id,
        commentId: commentId,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_CONVERSATION}/starboard/comments/${commentId}/replies`,
        newReply
      );
      // Assuming response.data.replies contains the updated list of replies
      const updatedReplies = response.data.replies;

      dispatch(
        updateReply({
          postId: beerDonationPostId,
          commentId: commentId,
          updatedReplies: updatedReplies,
        })
      );
      setReplyMessage("");
      SetReplyToReplyMessage("");
      setReplyToCommentId(null);
      setShowReplyInputField(null);
    } catch (error) {
      console.error("Error posting reply:", error);
    }
  };

  const toggleComments = (replyId) => {
    setShowReplyInputField((prevOpenOpenField) =>
      prevOpenOpenField === replyId ? null : replyId
    );
  };

  const deleteComment = async (commentId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_CONVERSATION}/starboard/comments/${commentId}`
      );
      if (response.status === 200) {
        dispatch(
          removeComment({
            postId: beerDonationPostId,
            commentId: commentId,
          })
        );
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };
  const deleteReply = async (replyId, commentId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_CONVERSATION}/starboard/comments/${commentId}/replies/${replyId}`
      );
      if (response.status === 200) {
        dispatch(
          removeReply({
            postId: beerDonationPostId,
            commentId: commentId,
            replyId: replyId,
          })
        );
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleEditComment = async (e, commentId) => {
    e.preventDefault();
    try {
      // Create the payload with the updated message
      const payload = {
        message: editingText, // editingText holds the updated comment text
      };

      // Make the API request to update the comment
      const response = await axios.put(
        `${process.env.REACT_APP_CONVERSATION}/starboard/comments/${commentId}`,
        payload
      );

      if (response.status === 200) {
        dispatch(
          updateComment({
            postId: beerDonationPostId,
            newComment: response.data.comment,
          })
        );
        setEditingId(null);
        setEditingText("");
      }
    } catch (error) {
      console.error(
        "Failed to update comment:",
        error.response?.data?.message || error.message
      );
    }
  };

  const handleEditReply = async (e, commentId, replyId) => {
    e.preventDefault();
    try {
      // Create the payload with the updated message
      const payload = {
        message: editingText, // editingText holds the updated comment text
      };

      // Make the API request to update the comment
      const response = await axios.put(
        `${process.env.REACT_APP_CONVERSATION}/starboard/comments/${commentId}/replies/${replyId}`,
        payload
      );

      if (response.status === 200) {
        const updatedReplies = response.data.replies;
        dispatch(
          updateReply({
            postId: beerDonationPostId,
            commentId: commentId,
            updatedReplies: updatedReplies,
          })
        );
        setEditingId(null);
        setEditingText("");
      }
    } catch (error) {
      console.error(
        "Failed to update comment:",
        error.response?.data?.message || error.message
      );
    }
  };

  return (
    <section className="bg-white border-t rounded-b-lg py-8 antialiased">
      <div className="max-w-2xl mx-auto px-1">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-md font-bold text-gray-900">
            Comments ({comments.length})
          </h2>
        </div>
        <form onSubmit={handleSubmit} className="mb-6">
          <div className="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200">
            <label htmlFor="comment" className="sr-only">
              Your comment
            </label>
            <textarea
              id="comment"
              rows="2"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none"
              placeholder="Write a comment..."
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="dexkor-comment-button inline-flex ml-auto py-2.5 px-4 text-xs font-medium"
          >
            Comment
          </button>
        </form>
        {comments.length > 0 &&
          comments.map((comment, index) => (
            <div key={index}>
              <article
                className={`p-6 text-base bg-white ${
                  index !== 0 && "border-t border-gray-200"
                }`}
              >
                <footer className="flex justify-between items-center mb-2">
                  <div className="flex items-center">
                    <p className="inline-flex items-center mr-3 text-sm text-gray-900d">
                      <img
                        className="mr-2 w-6 h-6 rounded-full"
                        src="https://flowbite.com/docs/images/people/profile-picture-2.jpg"
                        alt="Michael Gough"
                      />
                      {comment?.user.name}
                    </p>
                    <p className="text-sm text-gray-600">
                      <time
                        pubdate
                        datetime="2022-02-08"
                        title="February 8th, 2022"
                      >
                        {moment(comment.createdAt).local().fromNow()}{" "}
                        {comment.createdAt !== comment.updatedAt && "(Edited)"}
                      </time>
                    </p>
                  </div>
                  {userData && comment.userId === userData._id && (
                    <div className="relative">
                      <button
                        className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500  bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
                        type="button"
                        onClick={() => toggleComentSettingDropdown(comment._id)}
                      >
                        <svg
                          className="w-4 h-4"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 16 3"
                        >
                          <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                        </svg>
                        <span className="sr-only">Comment settings</span>
                      </button>
                      {/* <!-- Dropdown menu --> */}
                      {isDropdownOpen === comment._id && (
                        <div
                          id="dropdownComment2"
                          className="absolute right-0 z-10 w-36 bg-white rounded divide-y divide-gray-100 shadow-lg"
                          style={{ overflow: "auto" }} // Ensure dropdown content can scroll if needed
                        >
                          <ul
                            className="py-1 text-sm text-gray-700"
                            aria-labelledby="dropdownMenuIconHorizontalButton"
                          >
                            <li>
                              <a
                                onClick={() => {
                                  setEditingId(comment._id);
                                  setEditingText(comment.message);
                                  toggleComentSettingDropdown(comment._id);
                                }}
                                href="#"
                                className="block py-2 px-4 hover:bg-gray-100"
                              >
                                Edit
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={() => deleteComment(comment._id)}
                                href="#"
                                className="block py-2 px-4 hover:bg-gray-100"
                              >
                                Delete
                              </a>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </footer>
                {editingId === comment._id ? (
                  <form
                    onSubmit={(e) => handleEditComment(e, comment._id)}
                    className="mb-6"
                  >
                    <div className="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200">
                      <label htmlFor="comment" className="sr-only">
                        Your comment
                      </label>
                      <textarea
                        id="comment"
                        rows="2"
                        value={editingText}
                        onChange={(e) => setEditingText(e.target.value)}
                        className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none"
                        placeholder="Write a comment..."
                        required
                      ></textarea>
                    </div>
                    <div className="flex">
                      <button
                        type="button"
                        onClick={() => setEditingId(null)}
                        className="dexkor-comment-cancel-button ml-auto items-center p-0 text-xs font-medium bg-transparent hover:bg-transparent"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="dexkor-comment-button py-1.5 px-4 text-xs font-medium"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                ) : (
                  <p className="text-gray-500 ">{comment?.message}</p>
                )}
                <div className="flex items-center mt-2 space-x-1">
                  {comment?.replies?.length > 0 && (
                    <a
                      onClick={() => setShowReplies(!showReplies)}
                      className="mr-16 flex items-center text-sm text-gray-500 p-0 bg-transparent hover:bg-transparent font-medium"
                    >
                      {comment?.replies?.length > 0 &&
                        (showReplies ? (
                          <GoChevronUp className="h-5 w-5 mr-2" />
                        ) : (
                          <GoChevronDown className="h-5 w-5 mr-2" />
                        ))}
                      {comment?.replies?.length}{" "}
                      {comment?.replies?.length === 1 ? "Reply" : "Replies"}
                    </a>
                  )}
                  <button
                    type="button"
                    className="flex items-center text-sm text-gray-500 p-0 bg-transparent hover:bg-transparent font-medium"
                    onClick={() => setReplyToCommentId(comment._id)}
                  >
                    <svg
                      className="mr-1.5 w-3.5 h-3.5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 18"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 5h5M5 8h2m6-3h2m-5 3h6m2-7H2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3v5l5-5h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"
                      />
                    </svg>
                    Reply
                  </button>
                </div>
                {replyToCommentId === comment._id && (
                  <form
                    onSubmit={(e) => handleReplySubmit(e, comment._id, true)}
                    className="mt-4"
                  >
                    <div>
                      <label
                        htmlFor={`reply-${comment._id}`}
                        className="sr-only"
                      >
                        Your reply
                      </label>
                      <input
                        id={`reply-${comment._id}`}
                        type="text"
                        rows="1"
                        value={replyMessage}
                        onChange={(e) => setReplyMessage(e.target.value)}
                        className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none "
                        placeholder="Write a reply..."
                        required
                      ></input>
                    </div>
                    <div className="flex">
                      <button
                        type="button"
                        onClick={() => setReplyToCommentId(null)}
                        className="dexkor-comment-cancel-button ml-auto items-center p-0 text-xs font-medium bg-transparent hover:bg-transparent"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="dexkor-comment-button py-1.5 px-4 text-xs font-medium"
                      >
                        Reply
                      </button>
                    </div>
                  </form>
                )}
              </article>
              {showReplies &&
                comment.replies.length > 0 &&
                comment.replies.map((reply, replyIndex) => (
                  <article
                    key={replyIndex}
                    className="pl-6 pr-3 pt-3 pb-3 ml-6 lg:ml-12 text-base bg-white rounded-lg"
                  >
                    <footer className="flex justify-between items-center mb-2">
                      <div className="flex items-center">
                        <p className="inline-flex items-center mr-3 text-sm text-gray-900">
                          <img
                            className="mr-2 w-6 h-6 rounded-full"
                            src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                            alt="Jese Leos"
                          />
                          {reply.user.name}
                        </p>
                        <p className="text-sm text-gray-600">
                          <time
                            pubdate
                            datetime="2022-02-12"
                            title="February 12th, 2022"
                          >
                            {moment(reply.createdAt).local().fromNow()}{" "}
                            {reply.createdAt !== reply.updatedAt && "(Edited)"}
                          </time>
                        </p>
                      </div>
                      {userData && reply.userId === userData._id && (
                        <div className="relative">
                          <button
                            className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500  bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
                            type="button"
                            onClick={() =>
                              toggleComentSettingDropdown(reply._id)
                            }
                          >
                            <svg
                              className="w-4 h-4"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 16 3"
                            >
                              <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                            </svg>
                            <span className="sr-only">Comment settings</span>
                          </button>
                          {/* <!-- Dropdown menu --> */}
                          {isDropdownOpen === reply._id && (
                            <div
                              id="dropdownComment2"
                              className="absolute right-0 z-10 w-36 bg-white rounded divide-y divide-gray-100 shadow-lg"
                              style={{ overflow: "auto" }} // Ensure dropdown content can scroll if needed
                            >
                              <ul
                                className="py-1 text-sm text-gray-700"
                                aria-labelledby="dropdownMenuIconHorizontalButton"
                              >
                                <li>
                                  <a
                                    onClick={() => {
                                      setEditingId(reply._id);
                                      toggleComentSettingDropdown(reply._id);
                                      setEditingText(reply.message);
                                    }}
                                    href="#"
                                    className="block py-2 px-4 hover:bg-gray-100"
                                  >
                                    Edit
                                  </a>
                                </li>
                                <li>
                                  <a
                                    onClick={() =>
                                      deleteReply(reply._id, comment._id)
                                    }
                                    href="#"
                                    className="block py-2 px-4 hover:bg-gray-100"
                                  >
                                    Delete
                                  </a>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      )}
                    </footer>
                    {editingId === reply._id ? (
                      <form
                        onSubmit={(e) =>
                          handleEditReply(e, comment._id, reply._id)
                        }
                        className="mt-4"
                      >
                        <div>
                          <label
                            htmlFor={`reply-${comment._id}`}
                            className="sr-only"
                          >
                            Your reply
                          </label>
                          <input
                            id={`reply-${comment._id}`}
                            type="text"
                            rows="1"
                            value={editingText}
                            onChange={(e) => setEditingText(e.target.value)}
                            className="w-full text-sm text-gray-900 focus:ring-0 focus:outline-none "
                            placeholder="Write a reply..."
                            required
                          ></input>
                        </div>
                        <div className="flex">
                          <button
                            type="button"
                            onClick={() => setEditingId(null)}
                            className="dexkor-comment-cancel-button ml-auto items-center p-0 text-xs font-medium bg-transparent hover:bg-transparent"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="dexkor-comment-button py-1.5 px-4 text-xs font-medium"
                          >
                            submit
                          </button>
                        </div>
                      </form>
                    ) : (
                      <p className="text-gray-500">{reply.message}</p>
                    )}
                    <div className="flex ml-auto items-center mt-2 space-x-1">
                      <button
                        type="button"
                        className="flex items-center text-sm text-gray-500 p-0 bg-transparent hover:bg-transparent font-medium"
                        onClick={() => {
                          toggleComments(reply._id);
                          SetReplyToReplyMessage(`@${reply.user.name} `);
                        }}
                      >
                        <svg
                          className="mr-1.5 w-3.5 h-3.5"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 18"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5 5h5M5 8h2m6-3h2m-5 3h6m2-7H2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3v5l5-5h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"
                          />
                        </svg>
                        Reply
                      </button>
                    </div>
                    {showReplyInputField === reply._id && (
                      <form
                        onSubmit={(e) =>
                          handleReplySubmit(e, comment._id, false)
                        }
                        className="mt-4"
                      >
                        <div>
                          <label
                            htmlFor={`reply-${comment._id}`}
                            className="sr-only"
                          >
                            Your reply
                          </label>
                          <input
                            id={`reply-${comment._id}`}
                            type="text"
                            rows="1"
                            value={replyToReplyMessage}
                            onChange={(e) =>
                              SetReplyToReplyMessage(e.target.value)
                            }
                            className="w-full text-sm text-gray-900 focus:ring-0 focus:outline-none "
                            placeholder="Write a reply..."
                            required
                          ></input>
                        </div>
                        <div className="flex">
                          <button
                            type="button"
                            onClick={() => setShowReplyInputField(null)}
                            className="dexkor-comment-cancel-button ml-auto items-center p-0 text-xs font-medium bg-transparent hover:bg-transparent"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="dexkor-comment-button py-1.5 px-4 text-xs font-medium"
                          >
                            Reply
                          </button>
                        </div>
                      </form>
                    )}
                  </article>
                ))}
            </div>
          ))}
      </div>
    </section>
  );
}

export default CommentSection;
