import React from "react";
import Frame from "../../Images/Frame.jpg"; // Assuming you want to use the same image

const MessageDisplay = ({ messageTitle, messageDescription }) => {
  return (
    <div>
      <div
        style={{ paddingTop: "10%", display: "flex", justifyContent: "center" }}
      >
        <img src={Frame} alt="Frame" />
      </div>
      <div>
        <p
          style={{
            fontSize: "23px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            paddingTop: "30px",
            fontFamily: "roboto",
          }}
        >
          {messageTitle}
        </p>
      </div>
      <div style={{ textAlign: "center" }}>
        <p
          style={{
            textAlign: "center",
            paddingTop: "10px",
            marginLeft: "auto",
            fontFamily: "roboto",
            marginRight: "auto",
            width: "60%", // Control the width of the description
          }}
        >
          {messageDescription}
        </p>
      </div>
    </div>
  );
};

export default MessageDisplay;
