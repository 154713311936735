import React from "react";
import { Route } from "react-router-dom";
import HelpDesk from "../EngineeringDesk";
import MainPage from "../Inbox/MainPage";
import Inbox from "../MainScreen";
import LiveChat from "../Inbox/LiveChat"
import AllPendingView from "../../View/AllPendingView";
import PrivateRoute from "../../Private Route/PrivateRoute";
import PageNotFound from "../../components/PageNotFound";

const EngineeringRoutes = () => {
  return (
    <>
     <Route path="/dev-core" element={<PrivateRoute><HelpDesk /></PrivateRoute>}>
        <Route path="" element={<PrivateRoute><MainPage /></PrivateRoute>} />
          <Route path="assigned-conversation" element={<PrivateRoute><Inbox /></PrivateRoute>} />
          <Route path="live-chat" element={<PrivateRoute><LiveChat /></PrivateRoute>} />
          <Route path="internal-queue/all-pending-conversation" element={<PrivateRoute><AllPendingView /></PrivateRoute>} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
    </>
  );
};

export default EngineeringRoutes;
