import React from 'react';

function FinalPage({ email }) {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8">
        <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">Welcome to DexKor!</h2>
        <p className="text-lg mb-6 text-gray-700 text-center">
          A welcome email containing your admin credentials has been sent to your company's email.
        </p>
        <p className="text-md text-gray-600 text-center">
          Please check the following email address for your credentials:
        </p>
        <p className="text-lg font-semibold text-gray-900 text-center mt-2">{email}</p>
        <p className="text-sm text-gray-500 mt-6 text-center">
          If you haven't received the email, please check your spam folder or contact us for further assistance.
        </p>
      </div>
    </div>
  );
}

export default FinalPage;