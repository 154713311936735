import React, { useEffect, useState } from 'react'
import "./Task.css"
import logo from "../../../Images/Logo icon.png"
import axios from 'axios'
import dayjs from 'dayjs';
import { Row, Col, Flex, message, Upload, Button, Card, Space, Table, DatePicker, TimePicker, Tag, Input,  Select, Badge, Progress, Modal } from 'antd'
import { LoadingOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { CiLocationOn, CiEdit   } from "react-icons/ci";
import { FaPhoneAlt, FaPlus } from "react-icons/fa";

const { TextArea } = Input;

const Task = ({ selectedCompany }) => {
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState([])
    const [activities, setActivities] = useState([])
    const [newActivity, setNewActivity] = useState({
        date: "",
        time: "",
        title: "",
        description: "",
      });
    const [taskModal, setTaskModal] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [data, setData] = useState([])

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewActivity((prev) => ({
          ...prev,
          [name]: value,
        }));
      };

      const handleDateChange = (date, dateString) => {
        setNewActivity((prev) => ({
          ...prev,
          date: dateString, // Storing formatted date
        }));
      };
      
      const handleTimeChange = (time, timeString) => {
        setNewActivity((prev) => ({
          ...prev,
          time: timeString, // Storing formatted time
        }));
      };

    useEffect(() => {
        setUserData(JSON.parse(localStorage.getItem("dexkorUserData")));
      }, []);

    useEffect(() => {
        const fetchActivities = async () => {
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_CONVERSATION}/activities/${selectedCompany.companyID}`
            );
            setActivities(
              response.data.map((activity, index) => ({
                ...activity,
                key: index, // Ensuring each row has a unique key
              }))
            );
          } catch (error) {
            console.error('Error fetching activities:', error);
          }
        };
    
        fetchActivities(); // Call the async function
      }, [selectedCompany.companyID]);

      const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
      };

      
//   const filteredactvities = activities.filter(
//     (activity) =>
//       activity.title.toLowerCase().includes(searchActivityQuery) ||
//       activity.scheduledBy.toLowerCase().includes(searchActivityQuery)
//   );

      const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
      };

    
  
    const columns = [
        {
          title: 'Task',
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
        },
        {
            title: 'Progress',
            key: 'status',
            render: (text,record) => (
                <Progress percent={50} status="active" />
            )
          },
          {
            title: 'Action',
            key: 'action',
            
            render: (text, record) => (
              <Button
                type="primary"
                style={{
                  backgroundColor: '#2A4DAD',
                  borderRadius: '15px',
                }}
              >
                Manage
              </Button>
            ),
          }
    ]

    const handleOk = () => {
        setTaskModal(false)
      };
      const handleCancel = () => {
        setTaskModal(false);
      };

    const handleAddActivity = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_CONVERSATION}/activities/create`,
            {
              companyID: selectedCompany.companyID,
              date: newActivity.date,
              time: newActivity.time,
              title: newActivity.title,
              description: newActivity.description,
              scheduledBy: userData.email,
            }
          );
          setActivities([...activities, response.data]);
          setNewActivity({ date: "", time: "", title: "", description: "" });
          setTaskModal(false);
        } catch (error) {
          console.error("Error adding activity:", error);
          setTaskModal(false);
        }
      };
   
    return (
        <div className='dexkor-sales-hub-task-body' >
   <div className='dexkor-sales-hub-task-search-bar'>
    <span> <h2>Search For Task</h2> <Input prefix={<SearchOutlined />} className="dexkor-sales-hub-task-search-bar-input" placeholder="Search..." /></span>
    <span> <h2>Status</h2>
    <Select
    className='dexkor-sales-hub-task-search-bar-input'
      defaultValue="lucy"
    //   onChange={handleChange}
      options={[
        {
          value: 'jack',
          label: (
            <span style={{gap: '5px', display: 'flex'}}>
              <Badge color="green" />  <p>Active</p>
            </span>
          ),
        },
        {
          value: 'lucy',
          label: (
            <span>
              <Badge color="green" /> Completed
            </span>
          ),
        },
        {
          value: 'Yiminghe',
          label: (
            <span>
              <Badge color="#D03545" /> Scheduled
            </span>
          ),
        },
      ]}
    />
    </span>
    <span> <h2>Progress</h2>
    <Select
    className='dexkor-sales-hub-task-search-bar-input'
      defaultValue="lucy"
    //   onChange={handleChange}
      options={[
        {
          value: 'jack',
          label: (
            <span style={{gap: '5px', display: 'flex'}}>
              <Badge color="green" />  <p>Active</p>
            </span>
          ),
        },
        {
          value: 'lucy',
          label: (
            <span>
              <Badge color="green" /> Completed
            </span>
          ),
        },
        {
          value: 'Yiminghe',
          label: (
            <span>
              <Badge color="#D03545" /> Scheduled
            </span>
          ),
        },
      ]}
    />
    </span>
    <span style={{padding: '20px'}}>
        <Button type='primary' size='large' onClick={() => setTaskModal(true)} style={{backgroundColor: '#2A4DAD'}}>Add Task</Button>
    </span>
   </div>
   <div className='dexkor-sales-hub-task-table'>
   <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={activities}
          loading={loading}
        />
   </div>
   <Modal
        open={taskModal}
        width={600}
        title="Add Task"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => handleAddActivity()}>
            Submit
          </Button>,
         
        ]}
      >
       <>
       <span style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
  <h2>Select Date:</h2>
  <DatePicker
    style={{ width: '80%' }}
    onChange={handleDateChange} // Pass date directly to handler
  />
</span>

<span style={{ display: 'flex', gap: '20px', alignItems: 'center', paddingTop: '20px' }}>
  <h2>Select Time:</h2>
  <TimePicker
    style={{ width: '80%' }}
    defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
    onChange={(time, timeString) => handleTimeChange(time, timeString)} // Correct parameter
  />
</span>

<span style={{ display: 'flex', gap: '20px', alignItems: 'center', paddingTop: '20px' }}>
  <h2>Title:</h2>
  <Input
    style={{ marginLeft: '45px', marginRight: '17px' }}
    name="title" // Important: Name must match the state property
    onChange={handleInputChange} // Use generic input handler
  />
</span>

<span style={{ display: 'flex', gap: '20px', alignItems: 'center', paddingTop: '20px' }}>
  <h2>Description:</h2>
  <TextArea
    style={{
      height: 120,
      marginRight: '17px',
      resize: 'none',
    }}
    name="description" // Important: Name must match the state property
    onChange={handleInputChange} // Use generic input handler
  />
</span>
       </>
      </Modal>
</div>
    )
}

export default Task;
