// StatusTable.js
import React from 'react';

const StatusTable = ({ statuses }) => {
  return (
    <div className="pt-5">
      {statuses && statuses.length > 0 ? (  // Check if statuses exist and have items
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">Name</th>
              <th scope="col" className="px-6 py-3">Type</th>
              <th scope="col" className="px-6 py-3">Status</th>
              <th scope="col" className="px-6 py-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {statuses.map((status) => (
              <tr key={status.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {status.statusName}
                </th>
                <td className="px-6 py-4">{status.statusMode}</td>
                <td className="px-6 py-4">{status.enabled ? "Active" : "Inactive"}</td>
                <td className="px-6 py-4">
                  <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No Data</p>  // Display "No Data" if statuses is empty
      )}
    </div>
  );
};

export default StatusTable;
