import { createSlice } from "@reduxjs/toolkit";

const starboardBeerDonationPostsSlice = createSlice({
  name: "starboardBeerDonationPosts",
  initialState: {
    starboardBeerDonationPostsData: [],
  },
  reducers: {
    setStarboardBeerDonationPosts: (state, action) => {
      // Ensure the payload is an array and sort it by createdAt
      state.starboardBeerDonationPostsData = action.payload
        .slice() // Create a shallow copy of the array to avoid mutating the original payload
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort posts in descending order
        .map((post) => ({
          ...post,
          comments: post.comments
            ? post.comments
                .slice() // Create a shallow copy of the comments array
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort comments in descending order
                .map((comment) => ({
                  ...comment,
                  replies: comment.replies
                    ? comment.replies
                        .slice() // Create a shallow copy of the replies array
                        .sort(
                          (a, b) =>
                            new Date(b.createdAt) - new Date(a.createdAt)
                        ) // Sort replies in descending order
                    : [], // If there are no replies, return an empty array
                }))
            : [], // If there are no comments, return an empty array
        }));
    },
    updateStarboardBeerDonationPosts: (state, action) => {
      const updatedPost = action.payload;
      // Check if the post already exists
      const index = state.starboardBeerDonationPostsData.findIndex(
        (post) => post._id === updatedPost._id
      );

      if (index !== -1) {
        // If it exists, replace the old post with the new one
        state.starboardBeerDonationPostsData[index] = updatedPost;
      } else {
        // If it doesn't exist, add the new post
        state.starboardBeerDonationPostsData = [
          ...state.starboardBeerDonationPostsData,
          updatedPost,
        ];
      }

      // Sort posts after updating
      state.starboardBeerDonationPostsData.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    },
    clearStarboardBeerDonationPosts: (state) => {
      state.starboardBeerDonationPostsData = [];
    },
    updateComment: (state, action) => {
      const { postId, newComment } = action.payload;
      // Find the post
      const postIndex = state.starboardBeerDonationPostsData.findIndex(
        (post) => post._id === postId
      );

      if (postIndex !== -1) {
        // Update the comment with newComment, replacing the old comment
        state.starboardBeerDonationPostsData[postIndex] = {
          ...state.starboardBeerDonationPostsData[postIndex],
          comments: state.starboardBeerDonationPostsData[postIndex].comments
            .filter((comment) => comment._id !== newComment._id) // Remove the old comment if it exists
            .concat(newComment) // Add the new comment
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)), // Sort comments by createdAt
        };
      }
    },
    updateReply: (state, action) => {
      const { postId, commentId, updatedReplies } = action.payload;

      // Find the index of the post to update
      const postIndex = state.starboardBeerDonationPostsData.findIndex(
        (post) => post._id === postId
      );

      if (postIndex !== -1) {
        // Update the comments array for the specified post
        state.starboardBeerDonationPostsData[postIndex] = {
          ...state.starboardBeerDonationPostsData[postIndex],
          comments: state.starboardBeerDonationPostsData[
            postIndex
          ].comments.map((comment) =>
            comment._id === commentId
              ? {
                  ...comment,
                  replies: updatedReplies // Set the updatedReplies as the replies
                    .sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    ), // Sort replies by createdAt
                }
              : comment
          ),
        };
      } else {
        console.error(`Post with ID ${postId} not found.`);
      }
    },
    updateReactions: (state, action) => {
      const { postId, reactions } = action.payload;
      const postIndex = state.starboardBeerDonationPostsData.findIndex(
        (post) => post._id === postId
      );

      if (postIndex !== -1) {
        state.starboardBeerDonationPostsData[postIndex] = {
          ...state.starboardBeerDonationPostsData[postIndex],
          reactions, // Update reactions
        };
      }
    },
    removeComment: (state, action) => {
      const { postId, commentId } = action.payload;

      // Find the index of the post that contains the comment
      const postIndex = state.starboardBeerDonationPostsData.findIndex(
        (post) => post._id === postId
      );

      if (postIndex !== -1) {
        // Filter out the comment to be removed and update the post's comments array
        state.starboardBeerDonationPostsData[postIndex] = {
          ...state.starboardBeerDonationPostsData[postIndex],
          comments: state.starboardBeerDonationPostsData[postIndex].comments
            .filter((comment) => comment._id !== commentId)
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)), // Ensure comments are sorted
        };
      } else {
        console.error(`Post with ID ${postId} not found.`);
      }
    },
    removeReply: (state, action) => {
      const { postId, commentId, replyId } = action.payload;

      // Find the index of the post that contains the comment
      const postIndex = state.starboardBeerDonationPostsData.findIndex(
        (post) => post._id === postId
      );

      if (postIndex !== -1) {
        // Update the replies array for the specific comment within the post
        state.starboardBeerDonationPostsData[postIndex] = {
          ...state.starboardBeerDonationPostsData[postIndex],
          comments: state.starboardBeerDonationPostsData[
            postIndex
          ].comments.map((comment) =>
            comment._id === commentId
              ? {
                  ...comment,
                  replies: comment.replies
                    .filter((reply) => reply._id !== replyId) // Filter out the reply to be removed
                    .sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    ), // Ensure replies are sorted
                }
              : comment
          ),
        };
      } else {
        console.error(`Post with ID ${postId} not found.`);
      }
    },
  },
});

export const {
  setStarboardBeerDonationPosts,
  clearStarboardBeerDonationPosts,
  updateStarboardBeerDonationPosts,
  updateComment,
  updateReply,
  updateReactions,
  removeComment,
  removeReply,
} = starboardBeerDonationPostsSlice.actions;

export default starboardBeerDonationPostsSlice.reducer;
