import React, { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import ReactQuill from "react-quill";
import moment from "moment";
import logo from "../../../Images/Logo icon.png";
import "./Comment.css";
import {
  Row,
  Tabs,
  Col,
  Flex,
  message,
  Upload,
  Button,
  Tooltip,
  Card,
  Modal,
  Form,
  Input,
  Avatar,
} from "antd";
import { LoadingOutlined, PlusOutlined, SendOutlined } from "@ant-design/icons";
import {
  PaperClipIcon,
  PaperAirplaneIcon,
  MicrophoneIcon,
} from "@heroicons/react/24/outline";
import { CiLocationOn, CiEdit } from "react-icons/ci";
import { FaPhoneAlt, FaPlus } from "react-icons/fa";
import Spinner from "../../../components/Spinner/spinner";
import Sales from "../../../SalesHub1/All Pages/Comment/AllCommentOption/Sales/Sales";
const { TextArea } = Input;

const Comment = ({ selectedCompany }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [commentFlag, setCommentFlag] = useState(false)
  const [recording, setRecording] = useState(false);
  const [transcribing, setTranscribing] = useState(false);
  const [error, setError] = useState("");
  const [allComments, setAllComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [attachmentType, setAttachmentType] = useState("");
  const [allDocuments, setAllDocuments] = useState([]);
  const [userData, setUserData] = useState([]);
  const editorRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const recognitionRef = useRef(null);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
    setUserData(userInfo);
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAttachment(reader.result);
        setAttachmentType(file.type); // Set attachment type based on the file type
      };
      reader.readAsDataURL(file);
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(audioChunksRef.current, { type: "audio/webm" });
        const reader = new FileReader();
        reader.onloadend = () => {
          setAttachment(reader.result);
          setAttachmentType("audio/webm");
        };
        reader.readAsDataURL(blob);
        audioChunksRef.current = [];
      };

      mediaRecorderRef.current.start();
      setRecording(true);
    } catch (error) {
      console.error("Error starting audio recording:", error);
    }
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  const startTranscription = () => {
    if (!("webkitSpeechRecognition" in window)) {
      console.error("Speech recognition is not supported in this browser.");
      return;
    }

    recognitionRef.current = new window.webkitSpeechRecognition();
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = true;
    recognitionRef.current.lang = "en-US";

    let finalTranscript = "";

    recognitionRef.current.onresult = (event) => {
      let interimTranscript = "";
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          finalTranscript += event.results[i][0].transcript;
        } else {
          interimTranscript += event.results[i][0].transcript;
        }
      }
      setNewComment(finalTranscript + interimTranscript);
    };

    recognitionRef.current.start();
    setTranscribing(true);
  };

  const stopTranscription = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setTranscribing(false);
    }
  };

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchComments = async () => {
      setLoading(true);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CONVERSATION}/comments/${selectedCompany.companyID}`
        );
        const fetchedComments = response.data.comments || [];
        const sortedComments = fetchedComments.sort(
          (a, b) => new Date(a.time) - new Date(b.time)
        );
        setAllComments(sortedComments);
        setLoading(false);
        setCommentFlag(false)
      } catch (error) {
        console.error("Error fetching comments:", error);
        setLoading(false);
      }
    };
    fetchComments();
  }, [selectedCompany, commentFlag]);

  const handleCommentSubmit = async () => {
    if (!userData) {
      setError("User not logged in.");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONVERSATION}/comments/${selectedCompany.companyID}`,
        {
          userId: userData.name,
          comment: newComment,
          time: new Date().toLocaleString(),
          department: userData.resources,
        }
      );
      setAllComments([response.data, ...allComments]);
      setNewComment("");
      setCommentFlag(true)
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const options = [
    "bold",
    "italic",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "|",
    "align",
    "|",
    "hr",
    "|",
    "table",
    "link",
  ];

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "",
      defaultActionOnPaste: "insert_as_html",
      defaultLineHeight: 1.5,
      enter: "div",
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      statusbar: false,
      sizeLG: 900,
      sizeMD: 700,
      sizeSM: 400,
      toolbarAdaptive: false,
    }),
    []
  );

  return (
    <>
      <div style={{ lineHeight: "5px", marginTop: "-15px" }}>
        <Tabs
          defaultActiveKey="1"
          tabBarGutter={0}
          items={[
            {
              label: "Sales  ",
              key: "1",
              children: (
                <div style={{ marginTop: "-16px" }}>
                  <Sales allComments={allComments} selectedCompany={selectedCompany} setCommentFlag={setCommentFlag}/>{" "}
                </div>
              ),
            },
            // {
            //   label: "Pre-Sales",
            //   key: "2",
            //   // children: 'Tab 2',
            //   // disabled: true,
            // },
            // {
            //   label: "Customer Success",
            //   key: "3",
            //   // children: 'Tab 2',
            //   // disabled: true,
            // },
            // {
            //   label: "OnBoarding",
            //   key: "4",
            //   // children: 'Tab 2',
            //   // disabled: true,
            // },
          ]}
        />
      </div>
    </>
  );
};

export default Comment;
