import React from "react";
import { Route } from "react-router-dom";
import Finance from "../Finance";
import PrivateRoute from "../../Private Route/PrivateRoute";

const FinanceRoutes = () => {
  return (
    <>
  <Route path="/finance" element={<PrivateRoute><Finance /></PrivateRoute>}> 
  </Route>
    </>
  );
};

export default FinanceRoutes;
