import React, { useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import { Button, Tooltip } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { PaperClipIcon, MicrophoneIcon } from '@heroicons/react/24/outline';
import './ReactQuill.css';

const ReactQuillBox = ({ handleCommentSubmit, comment, onCommentChange, attachment, setAttachment, attachmentType, setAttachmentType, onAttachmentSelect }) => {
    const [recording, setRecording] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevents new line on Enter
            handleSubmit(); // Calls the submit handler
        }
    };

    const handleSubmit = () => {
        handleCommentSubmit(); // Submit the comment
        onCommentChange(''); // Reset comment in parent after submission
        setAttachment(''); // Clear the attachment after submission
        setAttachmentType(''); // Clear the attachment type after submission
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setAttachment(reader.result);
                setAttachmentType(file.type);
                onAttachmentSelect(reader.result); // Notify parent to send comment immediately
            };
            reader.readAsDataURL(file);
        }
    };

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);
            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };
            mediaRecorderRef.current.onstop = () => {
                const blob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
                const reader = new FileReader();
                reader.onloadend = () => {
                    setAttachment(reader.result);
                    setAttachmentType('audio/webm');
                    onAttachmentSelect(reader.result); // Notify parent to send comment immediately
                };
                reader.readAsDataURL(blob);
                audioChunksRef.current = [];
            };
            mediaRecorderRef.current.start();
            setRecording(true);
        } catch (error) {
            console.error('Error starting audio recording:', error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
            mediaRecorderRef.current.stop();
            setRecording(false);
        }
    };

    return (
        <div>
            <div onKeyDown={handleKeyDown} tabIndex={0} style={{ width: '100%' }}>
                <ReactQuill
                    value={comment} // Use the comment prop for the value
                    onChange={onCommentChange} // Call the onCommentChange prop to update the parent
                    required
                    theme="snow"
                    className="custom-Reactquill"
                    modules={{
                        toolbar: [
                            { size: [] },
                            'bold', 'italic', 'underline', 'strike',
                            { list: 'ordered' }, { list: 'bullet' },
                            { indent: '-1' }, { indent: '+1' },
                            'link', 'video', 'blockquote', 'code-block',
                            { align: [] }, { color: [] }, { background: [] },
                            'clean',
                        ],
                    }}
                    formats={[
                        'header', 'font', 'size', 'bold', 'italic', 'underline', 'strike',
                        'blockquote', 'list', 'bullet', 'indent', 'link', 'video',
                        'align', 'color', 'background', 'code-block',
                    ]}
                />
            </div>

            <div className="dexkor-email-reply-send-button-container flex items-center">
                <span style={{ width: '100%' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            height: '40px',
                            padding: '0 10px',
                            width: '100%',
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip title="Attach File">
                                <label>
                                    <PaperClipIcon
                                        style={{ marginRight: '15px' }}
                                        className="h-5 w-5 text-gray-500"
                                    />
                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                        className="hidden"
                                    />
                                </label>
                            </Tooltip>

                            <Tooltip title="Microphone">
                                <div
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginLeft: '15px',
                                    }}
                                >
                                    <button
                                        className={`bg-transparent hover:bg-transparent p-2 text-gray-500 ${
                                            recording ? 'hover:text-green-500' : 'hover:text-gray-700'
                                        } ml-1 ${recording ? 'text-green-500' : ''}`}
                                        onClick={recording ? stopRecording : startRecording}
                                    >
                                        <MicrophoneIcon className={`h-5 w-5 ${recording ? 'animate-pulse' : 'text-gray-500'}`} />
                                    </button>
                                </div>
                            </Tooltip>
                        </div>
                        <Tooltip title="Send">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '15px',
                                }}
                            >
                                <Button 
                                    type="primary" 
                                    icon={<SendOutlined />} 
                                    onClick={handleSubmit} 
                                    disabled={!comment && !attachment} // Disable if there's no comment or attachment
                                />
                            </div>
                        </Tooltip>
                    </div>
                </span>
            </div>
        </div>
    );
};

export default ReactQuillBox;
