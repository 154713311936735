import React from "react";
import { Route } from "react-router-dom";
import ProductPro from "../ProductPro";
import PrivateRoute from "../../Private Route/PrivateRoute";

const ProductProRoutes = () => {
  return (
    <>
     <Route path="/product-pro" element={<PrivateRoute><ProductPro /></PrivateRoute>}> 
     </Route>
    </>
  );
};

export default ProductProRoutes;
