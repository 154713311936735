import React, { useState, useEffect } from "react";
import "./HelpDesk.css";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { Menu, Row, Col } from "antd";
import { useSelector } from "react-redux";

const EngineeringDesk = () => {
  const [selectedKey, setSelectedKey] = useState("0");
  const [closedTicketCount, setClosedTicketCount] = useState("0");
  const [unassignedTicketCount, setUnassignedTicketCount] = useState("0");
  const [myOpenTickets, setMyOpenTickets] = useState("0");
  const [openKeys, setOpenKeys] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const ticketData = useSelector((state) => state.tickets.ticketData);

  const items = [
    {
      key: "1",
      label: "Assigned Chat",
      number: myOpenTickets,
      children: [
        {
          key: "11",
          label: "Conversation",
          path: "/dev-core/assigned-conversation",
        },
        { key: "12", label: "Tickets" },
        { key: "13", label: "Email" },
        { key: "14", label: "Custom" },
      ],
    },
    {
      key: "2",
      label: "Snoozed",
      number: myOpenTickets,
      children: [
        { key: "21", label: "Conversation" },
        { key: "22", label: "Tickets" },
        { key: "23", label: "Email" },
        { key: "24", label: "Custom" },
      ],
    },
    { key: "3", label: "Closed Chats", number: closedTicketCount },
    { key: "4", label: "Unassigned Chats", number: unassignedTicketCount },
    { key: "5", label: "Transferred Tickets", number: myOpenTickets },
    {
      key: "6",
      label: "Internal Team Queue",
      children: [
        { key: "61", label: "All Pending" },
        { key: "62", label: "SLA Breached" },
        { key: "63", label: "Approaching SLA Breach" },
      ],
    },
    {
      key: "7",
      label: "Pending Queue",
      children: [
        { key: "71", label: "Pending Support" },
        { key: "72", label: "Pending Product" },
        { key: "73", label: "Pending Engineering" },
        { key: "74", label: "Pending Finance" },
      ],
    },
    {
      key: "8",
      label: "Custom View",
    },
  ];

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
    if (ticketData) {
      const closedTickets = ticketData.filter(
        (item) => item.ticketId?.status === "closed"
      );
      setClosedTicketCount(closedTickets.length);

      const unassignedTicket = ticketData.filter((item) => item.userId == null);
      setUnassignedTicketCount(unassignedTicket.length);

      const openTickets = ticketData.filter(
        (item) =>
          item.ticketId.status !== "closed" && item.userId === userData?._id
      );
      setMyOpenTickets(openTickets.length);
    }
  }, [ticketData]);

  const getMenuItems = (menuItems) =>
    menuItems.map((item) => ({
      key: item.key,
      label: (
        <div
          onClick={(e) => {
            if (
              item.path &&
              !e.target.classList.contains("ant-menu-submenu-arrow")
            ) {
              navigate(item.path);
            }
          }}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          {item.label}
          {item.number !== undefined && (
            <span style={{ marginLeft: "auto" }}>{item.number}</span>
          )}
        </div>
      ),
      children: item.children ? getMenuItems(item.children) : null,
    }));

  const handleMenuClick = (e) => {
    const selectedItem = items
      .flatMap((item) => item.children || [item])
      .find((item) => item.key === e.key);
    if (selectedItem && selectedItem.path) {
      navigate(selectedItem.path);
    }
    setSelectedKey(e.key);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const currentItem = items
      .flatMap((item) => item.children || item)
      .find((item) => item.path === currentPath);
    if (currentItem) {
      setSelectedKey(currentItem.key);
      const parentKey = getParentKey(currentItem.key, items);
      if (parentKey) {
        setOpenKeys([parentKey]);
      }
    }
  }, [location]);

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const getParentKey = (childKey, menuItems) => {
    for (let item of menuItems) {
      if (item.children) {
        for (let child of item.children) {
          if (child.key === childKey) {
            return item.key;
          }
        }
      }
    }
    return null;
  };

  return (
    <>
      <Row style={{ height: "100vh" }}>
        <Col xs={24} sm={6} md={5} lg={4} xl={3}>
          <div
            style={{ width: "100%", padding: "0px", boxSizing: "border-box" }}
          >
            <div style={{ height: "40px", borderBottom: "2px solid #f0f0f0" }}>
              <p
                style={{
                  fontSize: "20px",
                  marginLeft: "16px",
                  marginTop: "16px",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                }}
              >
                DevCore
              </p>
            </div>

            <Menu
              mode="inline"
              selectedKeys={[selectedKey]}
              onClick={handleMenuClick}
              onOpenChange={onOpenChange}
              style={{
                height: "calc(100vh - 118px)",
                overflowY: "auto",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: "600",
              }}
              className="dexkor-help-desk-left-bar"
              items={getMenuItems(items)}
              openKeys={openKeys}
            />
          </div>
        </Col>
        <Col
          xs={24}
          sm={18}
          md={19}
          lg={20}
          xl={21}
          style={{ backgroundColor: "#ffffff" }}
        >
          <Outlet />
        </Col>
      </Row>
    </>
  );
};

export default EngineeringDesk;
