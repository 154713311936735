import React, { useEffect, useRef, useState } from "react";
import RenderAttachment from "../../HelpDesk/components/EmailChat/RenderAttachment";
import DOMPurify from "dompurify";
import logo from "../../Images/Logo icon.png";
import googleLogo from "../../Images/google-logo.png";
import { Avatar, Divider } from "antd";
import { RiCheckboxBlankCircleFill } from "react-icons/ri";

const Conversation = ({ conversation }) => {
  const [userData, setUserData] = useState("");
  const chatContainerRef = useRef(null);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
    setUserData(userInfo);
  }, [conversation]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const removeQuotedText = (message) => {
    const quotedTextPattern =
      /(?:^On .+ wrote:|^From: .+|^\s*>.*(?:\n|$)|^>.*(?:\n|$))/gms;
    return message.replace(quotedTextPattern, "").trim();
  };

  useEffect(() => {
    // Trigger scroll to bottom when messages change
    scrollToBottom();
  }, [conversation?.messages]);

  const findAgentDetails = (senderId) => {
    return conversation?.members?.find((member) => member._id === senderId);
  };

  return (
    <div ref={chatContainerRef}>
      {conversation ? (
        conversation?.messages?.length > 0 &&
        conversation?.messages.map((msg, index) => {
          const currentMessageDate = new Date(msg.timestamp).toLocaleDateString(
            "en-US",
            {
              year: "numeric",
              month: "long",
              day: "numeric",
            }
          );

          const previousMessageDate =
            index > 0
              ? new Date(
                  conversation.messages[index - 1].timestamp
                ).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
              : null;

          const agentDetails =
            msg.sender === "Agent" ? findAgentDetails(msg.senderId) : null;

          return (
            <div key={msg.id}>
              {currentMessageDate !== previousMessageDate && (
                <div
                  style={{
                    textAlign: "center",
                    margin: "1rem 0",
                    fontWeight: "bold",
                  }}
                >
                  <Divider>
                    <p
                      style={{
                        fontFamily: "roboto",
                        fontSize: "12px",
                        color: "#A0A7AF",
                      }}
                    >
                      {currentMessageDate}
                    </p>
                  </Divider>
                </div>
              )}

              <div>
                {msg.sender === "Agent" ? (
                  msg?.senderId === userData._id ? (
                    <div
                      key={index}
                      className="flex justify-end mb-4 items-center"
                    >
                      <div>
                        <div>
                          <div className="pt-1 flex items-center gap-2 ">
                            <span
                              style={{
                                fontWeight: "700",
                                fontFamily: "roboto",
                              }}
                            >
                              {agentDetails?.name || "Unknown Agent"}
                            </span>
                            <span>
                              <RiCheckboxBlankCircleFill
                                style={{
                                  color: "#D9D9D9",
                                  height: "7px",
                                  width: "7px",
                                }}
                              />
                            </span>
                            <span
                              style={{
                                color: "#2A4DAD",
                                fontFamily: "roboto",
                              }}
                            >
                              {new Date(msg?.timestamp).toLocaleString(
                                "en-US",
                                {
                                  hour: "numeric",
                                  minute: "2-digit",
                                  hour12: true,
                                }
                              )}
                            </span>
                            <span>
                              <RiCheckboxBlankCircleFill
                                style={{
                                  color: "#D9D9D9",
                                  height: "7px",
                                  width: "7px",
                                }}
                              />
                            </span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "2px",
                              }}
                            >
                              <p
                                style={{
                                  color: "#A0A7AF",
                                  fontFamily: "roboto",
                                }}
                              >
                                Via
                              </p>
                              {conversation.ticketId?.source === "email" ? (
                                <img
                                  style={{
                                    height: "15px",
                                    width: "15px",
                                  }}
                                  src={googleLogo}
                                  alt="Google Logo"
                                />
                              ) : (
                                <img
                                  style={{
                                    height: "15px",
                                    width: "15px",
                                  }}
                                  src={logo}
                                  alt="DexKor Logo"
                                />
                              )}
                              <p
                                style={{
                                  color: "#A0A7AF",
                                  fontFamily: "roboto",
                                }}
                              >
                                {conversation.ticketId?.source === "email"
                                  ? "Email"
                                  : "Widget"}
                              </p>
                            </span>
                            <div className="w-9 h-9 rounded-full flex items-center justify-center ml-2 mr-2">
                              <Avatar>
                                {agentDetails?.image ||
                                  agentDetails?.name?.charAt(0)}
                              </Avatar>
                            </div>
                          </div>
                        </div>
                        <div className="pt-3">
                          {msg.message && (
                            <div className="block max-w-96 bg-gray-200 text-black rounded-lg p-3 gap-3">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(msg.message),
                                }}
                              />
                            </div>
                          )}
                          {msg.attachment && (
                            <div
                              className={`flex max-w-96 bg-gray-500 text-white ${
                                msg.attachmentType.includes("audio")
                                  ? "rounded-full"
                                  : "rounded-lg"
                              } p-1 gap-3`}
                            >
                              <RenderAttachment
                                attachment={msg.attachment}
                                attachmentType={msg.attachmentType}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      key={index}
                      className="flex mb-4 items-center cursor-pointer"
                    >
                      <div className="w-9 h-9 rounded-full flex items-center justify-center mr-2">
                        <Avatar>
                          {agentDetails?.image || agentDetails?.name?.charAt(0)}
                        </Avatar>
                      </div>
                      <div>
                        <div className="pt-1 flex items-center gap-2 ">
                          <span
                            style={{
                              fontWeight: "700",
                              fontFamily: "roboto",
                            }}
                          >
                            {agentDetails?.name || "Unknown Agent"}
                          </span>
                          <span>
                            <RiCheckboxBlankCircleFill
                              style={{
                                color: "#D9D9D9",
                                height: "7px",
                                width: "7px",
                              }}
                            />
                          </span>
                          <span
                            style={{
                              color: "#2A4DAD",
                              fontFamily: "roboto",
                            }}
                          >
                            {new Date(msg?.timestamp).toLocaleString("en-US", {
                              hour: "numeric",
                              minute: "2-digit",
                              hour12: true,
                            })}
                          </span>
                          <span>
                            <RiCheckboxBlankCircleFill
                              style={{
                                color: "#D9D9D9",
                                height: "7px",
                                width: "7px",
                              }}
                            />
                          </span>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "2px",
                            }}
                          >
                            <p
                              style={{
                                color: "#A0A7AF",
                                fontFamily: "roboto",
                              }}
                            >
                              Via
                            </p>
                            {conversation.ticketId?.source === "email" ? (
                              <img
                                style={{
                                  height: "15px",
                                  width: "15px",
                                }}
                                src={googleLogo}
                                alt="Google Logo"
                              />
                            ) : (
                              <img
                                style={{
                                  height: "15px",
                                  width: "15px",
                                }}
                                src={logo}
                                alt="DexKor Logo"
                              />
                            )}
                            <p
                              style={{
                                color: "#A0A7AF",
                                fontFamily: "roboto",
                              }}
                            >
                              {conversation.ticketId?.source === "email"
                                ? "Email"
                                : "Widget"}
                            </p>
                          </span>
                        </div>
                        <div className="pt-3">
                          {msg.message && (
                            <div className="block max-w-96 bg-blue-200 text-black rounded-lg p-3 gap-3">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(msg.message),
                                }}
                              />
                            </div>
                          )}
                          {msg.attachment && (
                            <div
                              className={`flex max-w-96 bg-gray-500 text-white ${
                                msg.attachmentType.includes("audio")
                                  ? "rounded-full"
                                  : "rounded-lg"
                              } p-1 gap-3`}
                            >
                              <RenderAttachment
                                attachment={msg.attachment}
                                attachmentType={msg.attachmentType}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  <div key={index} className="flex mb-4 cursor-pointer">
                    <div className="w-9 h-9 rounded-full flex items-center justify-center mr-2">
                      <Avatar>{conversation.customer.name?.charAt(0)}</Avatar>
                    </div>
                    <div>
                      <div className="pt-1 flex items-center gap-2">
                        <span
                          style={{
                            fontWeight: "700",
                            fontFamily: "roboto",
                          }}
                        >
                          {conversation?.customer?.name}
                        </span>
                        <span>
                          <RiCheckboxBlankCircleFill
                            style={{
                              color: "#D9D9D9",
                              height: "7px",
                              width: "7px",
                            }}
                          />
                        </span>
                        <span
                          style={{
                            color: "#2A4DAD",
                            fontFamily: "roboto",
                          }}
                        >
                          {new Date(msg?.timestamp).toLocaleString("en-US", {
                            hour: "numeric",
                            minute: "2-digit",
                            hour12: true,
                          })}
                        </span>
                        <span>
                          <RiCheckboxBlankCircleFill
                            style={{
                              color: "#D9D9D9",
                              height: "7px",
                              width: "7px",
                            }}
                          />
                        </span>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "2px",
                          }}
                        >
                          <p
                            style={{
                              color: "#A0A7AF",
                              fontFamily: "roboto",
                            }}
                          >
                            Via
                          </p>
                          {conversation.ticketId?.source === "email" ? (
                            <img
                              style={{
                                height: "15px",
                                width: "15px",
                              }}
                              src={googleLogo}
                              alt="Google Logo"
                            />
                          ) : (
                            <img
                              style={{
                                height: "15px",
                                width: "15px",
                              }}
                              src={logo}
                              alt="DexKor Logo"
                            />
                          )}
                          <p
                            style={{
                              color: "#A0A7AF",
                              fontFamily: "roboto",
                            }}
                          >
                            {conversation.ticketId?.source === "email"
                              ? "Email"
                              : "Widget"}
                          </p>
                        </span>
                      </div>
                      <div className="pt-3">
                        {msg.message && (
                          <div className="block max-w-96 bg-blue-200 rounded-lg p-3 gap-3">
                            <div
                              className="text-gray-900"
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  removeQuotedText(msg.message)
                                ),
                              }}
                            />
                          </div>
                        )}
                        {msg.attachment && (
                          <div
                            className={`flex max-w-96 bg-blue-500 ${
                              msg.attachmentType.includes("audio")
                                ? "rounded-full"
                                : "rounded-lg"
                            } p-1 gap-3`}
                          >
                            <RenderAttachment
                              attachment={msg.attachment}
                              attachmentType={msg.attachmentType}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })
      ) : (
        <p>Pick a ticket to start chatting</p>
      )}
    </div>
  );
};

export default Conversation;
