import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "./MainContent.css";
import Spinner from "../../../components/Spinner/spinner";
import io from "socket.io-client";
import CustomerList from "../../../AccountCare/Components/Customer List/customerList";
import logo from "../../../Images/Logo icon.png";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Modal,
  Select,
  Input,
  List,
  Avatar,
  Spin,
  Tabs,
  theme,
  Divider,
  Button,
  Collapse,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";

import CompanyDetails from "../../../AccountCare/All Pages/Company/CompanyDetails";
import Comment from "../../All Pages/Comment/Comment";
import AllTickets from "../../All Pages/All Ticket/AllTicket";
import Documents from "../../All Pages/Documents/Documents";
// import Contacts from "../../../AccountCare/All Pages/Contact/Contacts";
import Task from "../../All Pages/Task/Task";
import Company from "../../../AccountCare/All Pages/Company copy/CompanyDetails";
import useNotification from "../../../components/snackbars/SnackBar";
import StatusChange from "../../../AccountCare/Components/Modal/Status Change/statusChange";
import LazyLoad from "react-lazy-load";

const { TabPane } = Tabs;

const socket = io(`${process.env.REACT_APP_CHAT_SERVER}`);

const AssignedSales = ({ item }) => {
  const [activeKey, setActiveKey] = useState(null);
  const [allCompanies, setAllCompanies] = useState([]);
  const [statusChange, setStatusChange] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusChangeFlag, setStatusChangeFlag] = useState(false);
  const [ticketData, setTicketData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeCompanyID, setActiveCompanyID] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [userData, setUserData] = useState([]);
  const [companyTabState, setCompanyTabState] = useState({});
  const [selectedValue, setSelectedValue] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [companyModalVisible, setCompanyModalVisible] = useState(false);
  const [conf, setConf] = useNotification();
  const [companyDetails, setCompanyDetails] = useState({
    companyName: "",
    companyEmail: "",
    companyPhone: "",
    parentCategory: "",
    secondaryCategory: "",
    revenueCategory: "",
    dmDetails: "",
    goLiveDate: "",
    hqCity: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    createdBy: "",
    // status: "WIP",
  });
  const [pocDetails, setPocDetails] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      location: "",
      designation: "",
      userType: "",
    },
  ]);
  const navigate = useNavigate();
  const { Panel } = Collapse;
  const { token } = theme.useToken();
  const { companyID, tab } = useParams();
  const tabContainerRef = useRef(null);

  const scrollLeft = () => {
    if (tabContainerRef.current) {
      tabContainerRef.current.scrollBy({
        left: -100, // Adjust the scroll amount as needed
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (tabContainerRef.current) {
      tabContainerRef.current.scrollBy({
        left: 100, // Adjust the scroll amount as needed
        behavior: "smooth",
      });
    }
  };

  const handleStatusChange = (company) => {
    setStatusChange(true);
    // setStatusChangeFlag(true);
  };

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("dexkorUserData")));
  }, []);

  const deletePoc = (index) => {
    const updatedPocDetails = pocDetails.filter((_, i) => i !== index);
    setPocDetails(updatedPocDetails);
  };

  const addPoc = () => {
    setPocDetails([
      ...pocDetails,
      {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        location: "",
        designation: "",
        userType: "",
      },
    ]);
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
    const getAllConversation = async () => {
      try {
        setLoading(true);
        const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
        const response = await axios.get(
          `${process.env.REACT_APP_CONVERSATION}/conversations/all/${userData.uniquePartnerId}`
        );
        setLoading(false);
        setTicketData(response.data);
      } catch (error) {
        setLoading(false); // Handle errors properly
        console.error(error); // Log error for debugging
      }
    };

    getAllConversation();
  }, []);

  const onCompanyTabChange = (key) => {     
    if (selectedCompany) {
      setCompanyTabState((prevState) => ({
        ...prevState,
        [selectedCompany.companyID]: key,
      }));
    }
  };
  useEffect(() => {
    if (companyID) {
      const company = allCompanies.find((tab) => tab._id === companyID);
      if (company) {
        setActiveKey(company.companyID);
        setSelectedCompany(company);
        // Add the tab if it doesn't exist yet
        const existingTab = tabs.find((tab) => tab.key === company.companyID);
        if (!existingTab) {
          setTabs((prevTabs) => [
            ...prevTabs,
            { key: company.companyID, title: company.companyID },
          ]);
        }
      }
    }
  }, [companyID, allCompanies]);

  useEffect(() => {
    if (companyID && Array.isArray(allCompanies) && allCompanies.length > 0) {
      const company = allCompanies.find((tab) => tab._id === companyID);
      
      if (company) {
        setActiveKey(company.companyID);
        setSelectedCompany(company);
        // Add the tab if it doesn't exist yet
        // const existingTab = tabs.find((tab) => tab.key === company.companyID);     
        // if (!existingTab) {
        //   setTabs((prevTabs) => [
        //     ...prevTabs,
        //     { key: company.companyID, title: company.companyID },
        //   ]);
        // }
      }
    }
  }, [companyID, allCompanies]);
  

  const companyTabitems = [
    {
      key: "1",
      label: "Notes",
      children: <Comment selectedCompany={selectedCompany} />,
    },
    {
      key: "2",
      label: "Tickets",
      children: (
        <AllTickets
          ticketData={ticketData}
          selectedCompany={selectedCompany}
          setTicketData={setTicketData}
        />
      ),
    },

    {
      key: "3",
      label: "Tasks",
      children: <Task selectedCompany={selectedCompany} />,
    },
    // {
    //   key: "4",
    //   label: "Documents",
    //   children: <Documents selectedCompany={selectedCompany} />,
    // },
    // {
    //   key: "5",
    //   label: "Contacts",
    //   children: <Contacts selectedCompany={selectedCompany}/>,
    // },
    {
      key: "5",
      label: "Contact",
      children:   <div  className="override-height">
      <CompanyDetails selectedCompany={selectedCompany} />
    </div>,
    },
    {
      key: "",
      label: "Customer Details",

      children: <Company selectedCompany={selectedCompany} />,
    },
  ];

  const onTabEdit = (targetKey, action) => {
    if (action === "remove") {
      removeTab(targetKey);
    }
  };

  const removeTab = (targetKey) => {
    const newTabs = tabs.filter((tab) => tab.key !== targetKey);
    const company = allCompanies.find(
      (company) => company.companyID === targetKey
    );
    const targetIndex = tabs.findIndex((tab) => tab.key === targetKey);
    if (newTabs.length > 0) {
      let newActiveKey;
      let newActiveCompany;
      if (targetIndex > 0) {
        newActiveKey = newTabs[targetIndex - 1].key;
        newActiveCompany = allCompanies.find(
          (company) => company.companyID === newActiveKey
        );
      } else {
        newActiveKey = newTabs[0].key;
        newActiveCompany = allCompanies.find(
          (company) => company.companyID === newActiveKey
        );
      }
      setActiveKey(newActiveKey);
      if (newActiveCompany) {
        navigate(`/sales-hub/assigned-sales/${newActiveCompany._id}`);
      }
    } else {
      setActiveKey(null);
      navigate(`/sales-hub/assigned-sales`);
    }
    setTabs(newTabs);
  };

  const onTabChange = (key) => {   
    setActiveKey(key);
    const company = allCompanies.find((tab) => tab.companyID === key);
    
    if (company) {
      setSelectedCompany(company);
      setActiveCompanyID(key);
      navigate(`/sales-hub/assigned-sales/${company._id}`);
    }
  };

  const onTicketClick = (company) => {
    setActiveCompanyID(company.companyID);
    const existingTab = tabs.find((tab) => tab.key === company.companyID);

    if (existingTab) {
      setActiveKey(existingTab.key);
    } else {
      const newTab = {
        key: company.companyID,
        title: company.companyID,
      };
      const newTabs = [...tabs, newTab];
      setTabs(newTabs);
      setActiveKey(newTab.key);
    }

    setSelectedCompany(company);
    navigate(`/sales-hub/assigned-sales/${company._id}`);
  };

  useEffect(() => {
    if (companyID) {
      const company = allCompanies.find((tab) => tab._id === companyID);
      if (company) {
        setActiveKey(company.companyID);
        setSelectedCompany(company);
        setActiveCompanyID(company.companyID);

        // Add the tab if it doesn't exist yet
        const existingTab = tabs.find((tab) => tab.key === company.companyID);
        if (!existingTab) {
          const newTab = {
            key: company.companyID,
            title: company.companyID,
          };
          setTabs((prevTabs) => [...prevTabs, newTab]);
        }
      }
    }
  }, [companyID, allCompanies, tabs]);

  const handleSearchChange = (e) =>
    setSearchQuery(e.target.value.toLowerCase());

  const showModal = () => setCompanyModalVisible(true);
  const handleModalOk = () => {
    clearFormDetails();
    setCompanyModalVisible(false);
  };
  const handleModalCancel = () => {
    clearFormDetails();
    setCompanyModalVisible(false);
  };

  const clearFormDetails = () => {
    setCompanyDetails({
      companyName: "",
      parentCategory: "",
      secondaryCategory: "",
      revenueCategory: "",
      dmDetails: "",
      goLiveDate: "",
      hqCity: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
      createdBy: "",
      // status: "WIP",
    });
    setPocDetails([
      {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        location: "",
        designation: "",
        userType: "",
      },
    ]);
  };

  const handleInputChange = (field) => (e) => {
    let value = e.target.value;

    if (field === "customerCode" || field === "parentCustomerCode") {
      value = value.replace(/\s/g, ""); // Remove spaces for both 'customerCode' and 'parentCode'
    }
    setCompanyDetails({ ...companyDetails, [field]: value });
  };

  const handleInputPOCChange = (index, field) => (e) => {
    const updatedPocDetails = [...pocDetails];
    updatedPocDetails[index][field] = e.target.value;
    setPocDetails(updatedPocDetails);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONVERSATION}/company/register`,
        {
          uniquePartnerId: userData.uniquePartnerId,
          companyData: companyDetails,
          pocDetails,
        }
      );
      clearFormDetails();
      setCompanyModalVisible(false);
      setConf({ msg: "Customer Added Successfully", variant: "success" });
    } catch (error) {
      setCompanyModalVisible(false);
      setConf({ msg: "Failed to add Customer", variant: "error" });
    }
  };

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));

    if (userInfo) {
      setCompanyDetails((prevData) => ({
        ...prevData,
        createdBy: userInfo.email || "",
      }));
    }
  }, []);

  return (
    <>
      <Row className="dexkor-sales-hub-main-screen-body">
        <Col span={5} className="dexkor-sales-hub-main-screen-left-nav-bar">
          <div className="dexkor-sales-hub-main-screen-left-nav-bar-header">
            <span>
              <Button onClick={showModal}>Add Customer</Button>
            </span>
          </div>

          <div className="dexkor-sales-hub-search-bar">
            <Input
              className="dexkor-sales-hub-search-bar-input"
              placeholder="Search For Customers"
              prefix={<SearchOutlined />}
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>

          <div className="dexkor-sales-hub-company-list-container">
            {userData && (
              <LazyLoad height={200} offset={100}>
                <CustomerList
                  statusFlag={statusChangeFlag}
                  setStatusFlag={setStatusChangeFlag}
                  activeCompanyID={activeCompanyID}
                  onTicketClick={onTicketClick}
                  handleStatusChange={handleStatusChange}
                  moduleId={"saleshub001"}
                  assigned={userData._id}
                  filterType={"assigned"}
                  searchQuery={searchQuery}
                  setAllCompanies={setAllCompanies}
                />
              </LazyLoad>
            )}
          </div>
        </Col>

        <Col span={19} className="dexkor-sales-hub-main-page-right-section">
          <div className="dexkor-sales-hub-main-page-right-section-tab-container">
          <Tabs
              className="dexkor-sales-hub-main-page-right-section-tabs"
              type="editable-card"
              hideAdd
              activeKey={activeKey}
              onChange={onTabChange}
              onEdit={onTabEdit}
              tabBarGutter={0}
            >
              {tabs.map((tab) => (
                <TabPane
                  tab={
                    <div
                      className={`dexkor-sales-hub-main-page-right-section-title-tab ${
                        activeKey === tab.key ? "active" : ""
                      }`}
                    >
                      <span>{tab.title}</span>
                    </div>
                  }
                  key={tab.key}
                  closeIcon={
                    <CloseOutlined
                      className={`dexkor-sales-hub-main-page-right-section-title-tab ${
                        activeKey === tab.key ? "active" : ""
                      }`}
                    />
                  }
                />
              ))}
            </Tabs>
          </div>

          <div
            className="dexkor-sales-hub-main-page-right-section-company-details"
            style={{ position: "relative" }}
          >
            {activeKey === null ? null : (
              <span>
                <Tabs
                  className="tabs-with-divider"
                  tabBarGutter={0}
                  defaultActiveKey={
                    companyTabState[selectedCompany.companyID] || "1"
                  }
                  onChange={onCompanyTabChange}
                  style={{
                    fontFamily: "roboto",
                    fontSize: "12px",
                    height: "calc(100% - 56px)",
                    width: "100%", // Ensures the Tabs take full width
                  }}
                  items={companyTabitems}
                />
                {/* <Select
      style={{
        position: "absolute",
        right: "120px",
        top: "50%",  // Vertically centers the button relative to the tabs
        transform: "translateY(-50%)",  // Adjusts centering
        fontSize: "12px",
        width: 120
      }}
      defaultValue="lucy"
      // onChange={handleChange}
      options={[
        {
          value: 'jack',
          label: 'Sales',
        },
        {
          value: 'lucy',
          label: 'Lucy',
        },
        {
          value: 'Yiminghe',
          label: 'yiminghe',
        },
        {
          value: 'disabled',
          label: 'Disabled',
          disabled: true,
        },
      ]}
    /> */}
                {/* <button
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "50%", // Vertically centers the button relative to the tabs
                    transform: "translateY(-50%)", // Adjusts centering
                    padding: "8px 16px",
                    fontSize: "12px",
                    backgroundColor: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  // onClick={handleButtonClick}
                >
                  Action
                </button> */}
              </span>
            )}
          </div>
        </Col>
      </Row>
      <Modal
        visible={companyModalVisible}
        title="Add New Customer"
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        width={800}
        footer={[
          <Button key="cancel" onClick={handleModalCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Add
          </Button>,
        ]}
      >
        {/* <Collapse accordion items={modalItems} /> */}

        <Row
          style={{
            alignItems: "center",
            marginBottom: "15px",
            marginTop: "30px",
          }}
        >
          <Col span={8}>
            <p>Customer Code:</p>
          </Col>
          <Col span={16}>
            <Input
              style={{ height: "50px" }}
              type="text"
              placeholder="Enter the Customer Code"
              onChange={handleInputChange("customerCode")}
            />
          </Col>
        </Row>
        <Row style={{ alignItems: "center", marginBottom: "15px" }}>
          <Col span={8}>
            <p>Customer Name:</p>
          </Col>
          <Col span={16}>
            <Input
              style={{ height: "50px" }}
              type="text"
              placeholder="Customer Name"
              onChange={handleInputChange("companyName")}
            />
          </Col>
        </Row>
        <Row style={{ alignItems: "center", marginBottom: "15px" }}>
          <Col span={8}>
            <p>Customer Email:</p>
          </Col>
          <Col span={16}>
            <Input
              style={{ height: "50px" }}
              type="email"
              placeholder="Customer Email"
              onChange={handleInputChange("companyEmail")}
            />
          </Col>
        </Row>
        <Row style={{ alignItems: "center", marginBottom: "15px" }}>
          <Col span={8}>
            <p>Customer Contact:</p>
          </Col>
          <Col span={16}>
            <Input
              style={{ height: "50px" }}
              type="Number"
              maxLength={10}
              placeholder="Customer Contact"
              onChange={handleInputChange("companyPhone")}
            />
          </Col>
        </Row>
        <Row
          style={{
            alignItems: "center",
            marginBottom: "15px",
            marginTop: "30px",
          }}
        >
          <Col span={8}>
            <p>Parent Customer Code:</p>
          </Col>
          <Col span={16}>
            <Input
              style={{ height: "50px" }}
              type="text"
              placeholder="Enter the Parent Code"
              onChange={handleInputChange("parentCustomerCode")}
            />
          </Col>
        </Row>
      </Modal>
      <StatusChange
        setStatusChange={setStatusChange}
        statusChange={statusChange}
        moduleId={"saleshub001"}
        selectedCompany={selectedCompany}
        setStatusChangeFlag={setStatusChangeFlag}
      />
    </>
  );
};

export default AssignedSales;
