import React, { useEffect, useState } from "react";
import { Select } from "antd";
import io from "socket.io-client";

const socket = io(`${process.env.REACT_APP_CHAT_SERVER}`);

function TicketNavBar({
  selectedConversation,
  setSelectedConversation,
  setMyTickets,
  setUnassignedTicket,
  onTabEdit,
  setTabs,
}) {
  const [ticketPriority, setTicketPriority] = useState(null);
  const [formData, setFormData] = useState({
    impact: "Select impact",
    urgency: "Select urgency",
  });
  useEffect(() => {
    setFormData({
      impact: "Select impact",
      urgency: "Select urgency",
    });
  }, [selectedConversation]);

  useEffect(() => {
    if (
      formData.impact !== "Select impact" &&
      formData.urgency !== "Select urgency"
    ) {
      const newPriority = calculatePriority(formData.impact, formData.urgency);
      setTicketPriority(newPriority);
    }
  }, [formData.impact, formData.urgency]);

  const calculatePriority = (impact, urgency) => {
    if (impact === "Low" && urgency === "Low") return "Low";
    if (impact === "Low" && urgency === "Medium") return "Low";
    if (impact === "Low" && urgency === "High") return "Medium";
    if (impact === "Medium" && urgency === "Low") return "Medium";
    if (impact === "Medium" && urgency === "Medium") return "Medium";
    if (impact === "Medium" && urgency === "High") return "High";
    if (impact === "High" && urgency === "Low") return "Medium";
    if (impact === "High" && urgency === "Medium") return "High";
    if (impact === "High" && urgency === "High") return "Critical";
    return ""; // Default fallback
  };

  const handleStatusChange = (event) => {
    if (selectedConversation === null) return;

    const newStatus = event?.target?.value || event;
    const ticketId = selectedConversation.ticketId._id;
    const roomId = selectedConversation.roomId;

    if (newStatus === "Unassigned") {
      socket.emit(
        "unassignUserFromConversation",
        {
          userId: selectedConversation?.owner?._id,
          conversationId: selectedConversation._id,
        },
        (response) => {
          if (response.success) {
            // If the logged-in user is not the owner, remove the conversation
            setMyTickets((prevConversations) => {
              // If the logged-in user is not the owner, remove the conversation
              const updatedConversations = prevConversations.filter(
                (conversation) => conversation._id !== response.conversation._id
              );

              // Clear the selected conversation
              onTabEdit(selectedConversation.roomId, "remove");
              if (updatedConversations.length > 0) {
                setSelectedConversation(updatedConversations[0]);
              } else {
                setSelectedConversation(null);
              }
              return updatedConversations; // Return the filtered list
            });
            setTabs((prevTabs) => {
              const updatedTabs = prevTabs.filter(
                (tab) => tab.key !== response.conversation.roomId
              );
              return updatedTabs;
            });
            setUnassignedTicket((prevTickets) => [
              ...prevTickets,
              selectedConversation,
            ]);
          } else {
            console.error("Error updating ticket status:", response.message);
          }
        }
      );
    } else {
      socket.emit(
        "updateTicketStatus",
        {
          ticketId,
          status: newStatus,
          roomId: selectedConversation.roomId,
        },
        (response) => {
          if (response.success) {
            console.log("Ticket status updated successfully.", response);
          } else {
            console.error("Error updating ticket status:", response.message);
          }
        }
      );
    }
  };

  useEffect(() => {
    if (ticketPriority) {
      console.log({ ticketPriority });
      socket.emit(
        "updateTicketPriority",
        {
          ticketId: selectedConversation.ticketId._id,
          priority: ticketPriority, // Assuming userData contains the current user ID
          roomId: selectedConversation.roomId,
        },
        (response) => {
          if (response.success) {
            setMyTickets((prevConversations) => {
              return prevConversations.map((conversation) => {
                if (conversation.roomId === response.roomId) {
                  // Update the status of the ticketId in the selected conversation
                  return {
                    ...conversation,
                    ticketId: response.ticket,
                  };
                }
                return conversation;
              });
            });
            setSelectedConversation((prevSelectedConversation) => ({
              ...prevSelectedConversation,
              ticketId: response.ticket,
            }));
          } else {
            console.error("Error assigning ticket:", response.message);
          }
        }
      );
    }
  }, [ticketPriority]);

  const handlePriorityChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  return (
    <div className="mb-4 flex gap-4">
      <Select
        showSearch
        placeholder="Ticket Status"
        style={{ width: 250, marginTop: "12px", padding: "0" }}
        allowClear
        value={selectedConversation.ticketId.status}
        onChange={(value) => handleStatusChange(value)}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        options={[
          {
            key: 1,
            value: "Unassigned",
            label: "Unassigned",
          },
          {
            key: 2,
            value: "Assigned",
            label: "Assigned",
          },
          {
            key: 3,
            value: "Work In Progress",
            label: "Work In Progress",
          },
          {
            key: 4,
            value: "Awaiting Customer Response",
            label: "Awaiting Customer Response",
          },
          {
            key: 5,
            value: "Pending To Tech",
            label: "Pending To Tech",
          },
          { key: 6, value: "resolved", label: "Resolved" },
        ]}
      />
      <Select
        showSearch
        placeholder="Impact"
        value={formData.impact}
        style={{ width: 150, marginTop: "12px", cursor: "pointer" }}
        allowClear
        onChange={(value) => handlePriorityChange("impact", value)}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        options={[
          { key: 1, value: "Low", label: "Low" },
          { key: 2, value: "Medium", label: "Medium" },
          { key: 3, value: "High", label: "High" },
        ]}
      />
      <Select
        showSearch
        placeholder="Urgency"
        value={formData.urgency}
        style={{ width: 150, marginTop: "12px", cursor: "pointer" }}
        allowClear
        onChange={(value) => handlePriorityChange("urgency", value)}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        options={[
          { key: 1, value: "Low", label: "Low" },
          { key: 2, value: "Medium", label: "Medium" },
          { key: 3, value: "High", label: "High" },
        ]}
      />
    </div>
  );
}

export default TicketNavBar;
