import React, { useEffect, useState } from "react";
import axios from "axios";
import "./TicketForm.css";
import IssueLoaderButton from "./IssueLoaderButton";
import { IoTrashOutline } from "react-icons/io5";
import io from "socket.io-client";

const socket = io(`${process.env.REACT_APP_CHAT_SERVER}`);

const TicketForm = ({ setShowForm, setUnassignedTicket }) => {
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    subject: "",
    category: "",
    type: "",
    impact: "Low",
    urgency: "Low",
    priority: "",
    description: "",
    attachments: [],
  });
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("dexkorUserData"));
    setUserData(data);
  }, []);

  const typeOptions = {
    Accounting: [
      {
        value: "Data Value MisMatch/Missing",
        label: "Data Value MisMatch/Missing",
      },
      { value: "Unmapped SKU Not Shown", label: "Unmapped SKU Not Shown" },
      { value: "Offline Unmapped", label: "Offline Unmapped" },
      { value: "Tax Rate Set up", label: "Tax Rate Set up" },
      {
        value: "Tally Sale/Returns Value mismatch",
        label: "Tally Sale/Returns Value mismatch",
      },
      { value: "GSTR1 Error", label: "GSTR1 Error" },
    ],
    Reconciliation: [
      { value: "Data misMatch/missing", label: "Data misMatch/missing" },
      { value: "P & L Related", label: "P & L Related" },
      { value: "Claims Related", label: "Claims Related" },
      { value: "Reports Related", label: "Reports Related" },
      { value: "Unable to Scan Returns", label: "Unable to Scan Returns" },
    ],
  };

  useEffect(() => {
    if (formData.impact && formData.urgency) {
      const newPriority = calculatePriority(formData.impact, formData.urgency);
      setFormData((prevData) => ({
        ...prevData,
        priority: newPriority,
      }));
    }
  }, [formData.impact, formData.urgency]);

  const calculatePriority = (impact, urgency) => {
    if (impact === "Low" && urgency === "Low") return "Low";
    if (impact === "Low" && urgency === "Medium") return "Low";
    if (impact === "Low" && urgency === "High") return "Medium";
    if (impact === "Medium" && urgency === "Low") return "Medium";
    if (impact === "Medium" && urgency === "Medium") return "Medium";
    if (impact === "Medium" && urgency === "High") return "High";
    if (impact === "High" && urgency === "Low") return "Medium";
    if (impact === "High" && urgency === "Medium") return "High";
    if (impact === "High" && urgency === "High") return "Critical";
    return ""; // Default fallback
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "attachments") {
      setFormData((prevData) => ({
        ...prevData,
        attachments: [...prevData.attachments, ...Array.from(files)],
      }));
    } else if (name === "number") {
      // Remove non-numeric characters
      const numericValue = value.replace(/\D/g, "");
      setFormData({
        ...formData,
        [name]: numericValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleDeleteAttachment = (index) => {
    setFormData((prevData) => {
      const newAttachments = prevData.attachments.filter((_, i) => i !== index);
      return { ...prevData, attachments: newAttachments };
    });
  };

 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const userToken = localStorage.getItem("dexkorUserToken");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONVERSATION}/tickets/create`,
        {
          uniquePartnerId: userData.uniquePartnerId,
          name: formData.name,
          number: formData.number,
          email: formData.email,
          subject: formData.subject,
          category: formData.category,
          source: "widget",
          type: formData.type,
          priority: formData.priority,
          description: formData.description,
          cc: [{ name: formData.name, address: formData.email }],
        }, // Send form data directly
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            Role: `${userData.role}`,
            "Content-Type": "application/json",
          },
        }
      );

      try {
        const roomId = `Room-${new Date().getTime()}`;
        const customerId = null;
        const uniquePartnerId = userData.uniquePartnerId;
        socket.emit(
          "createRoom",
          uniquePartnerId,
          roomId,
          response.data._id,
          customerId,
          (response) => {
            if (response.success) {
              setUnassignedTicket((prevConversations) => [
                ...prevConversations,
                response.conversation,
              ]);
            }
          }
        );

        setShowForm();
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      setLoading(false);
    }
  };


  

  return (
    <div className="p-4 h-full mt-0 dexkor-scrollable-form">
      <h2 className="dexkor-issue-header">Create New Ticket</h2>
      <form className="space-y-5 mt-4" onSubmit={handleSubmit}>
        <div className="dexkor-form-grid">
          {/* Full Name */}
          <div className="mb-4">
            <input
              id="fullName"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Full Name"
              className="dexkor-form-input"
              required
            />
          </div>

          {/* Callback Number */}
          <div className="mb-4">
            <input
              id="callbackNumber"
              name="number"
              type="text"
              value={formData.number}
              onChange={handleChange}
              placeholder="Callback Number"
              className="dexkor-form-input"
              required
              maxLength={10}
              minLength={10}
              pattern="\d{10}" // Allow only 10 digits
            />
          </div>

          {/* Primary Email */}
          <div className="mb-4">
            <input
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Primary Email"
              className="dexkor-form-input"
              required
            />
          </div>

          {/* Subject */}
          <div className="mb-4">
            <input
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              placeholder="Subject"
              className="dexkor-form-input"
              required
            />
          </div>

          {/* Category */}
          <div className="mb-4">
            <select
              id="category"
              name="category"
              value={formData.category}
              onChange={handleChange}
              className="dexkor-form-input py-1"
              required
            >
              <option value="" disabled>
                Select Category
              </option>
              <option value="Accounting">Accounting</option>
              <option value="Reconciliation">Reconciliation</option>
            </select>
          </div>

          {/* Type */}
          <div className="mb-4">
            <select
              id="type"
              name="type"
              value={formData.type}
              onChange={handleChange}
              className="dexkor-form-input py-1"
              required
              disabled={!formData.category} // Disable if no category is selected
            >
              <option value="" disabled>
                Select type
              </option>
              {formData.category &&
                typeOptions[formData.category]?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
          </div>

          {/* Impact */}
          <div className="mb-4">
            <select
              id="impact"
              name="impact"
              value={formData.impact}
              onChange={handleChange}
              className="dexkor-form-input py-1"
              required
            >
              <option value="" disabled>
                Select Impact
              </option>
              <option value="Low">Low</option>
              <option value="Medium">Medium</option>
              <option value="High">High</option>
            </select>
          </div>

          {/* Urgency */}
          <div className="mb-4">
            <select
              id="urgency"
              name="urgency"
              value={formData.urgency}
              onChange={handleChange}
              className="dexkor-form-input py-1"
              required
            >
              <option value="" disabled>
                Select Urgency
              </option>
              <option value="Low">Low</option>
              <option value="Medium">Medium</option>
              <option value="High">High</option>
            </select>
          </div>

          {/* Priority (Full-width) */}
          <div className="mb-4 dexkor-form-full-width">
            <input
              id="priority"
              name="priority"
              value={formData.priority}
              placeholder="Priority"
              className="dexkor-form-input"
              required
              readOnly
            />
          </div>

          {/* Description (Full-width) */}
          <div className="mb-4 dexkor-form-full-width">
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Write description about the issue."
              className="dexkor-form-input-textarea"
              rows={2}
              required
            />
          </div>

          {/* Attachments (Full-width) */}
          <div className="mb-4 dexkor-form-full-width">
            <div className="flex items-center justify-center w-full">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
              >
                <div className="flex flex-col items-center justify-center pt-3 pb-4">
                  <div className="flex items-center justify-center space-x-2">
                    <svg
                      className="w-5 h-5 mb-2 text-gray-500"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500">
                      <span className="font-semibold">Click to upload</span>
                    </p>
                  </div>
                  <p className="text-xs text-gray-500">
                    SVG, PNG, JPG or PDF (MAX. 5mb)
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  name="attachments"
                  onChange={handleChange}
                  className="hidden"
                  multiple // Allow multiple file uploads
                />
              </label>
            </div>
            {/* Display selected attachments */}
            <div className="mt-2">
              {formData.attachments.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between mb-1"
                >
                  <span className="text-sm text-gray-700">{file.name}</span>
                  <button
                    type="button"
                    onClick={() => handleDeleteAttachment(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <IoTrashOutline className="h-5 w-5" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-6">
          {loading ? (
            <IssueLoaderButton />
          ) : (
            <>
              <button
                onClick={() => setShowForm()}
                className="dexkor-issue-cancel-button flex items-center justify-center"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="dexkor-issue-submit-button flex items-center justify-center"
              >
                Submit
              </button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default TicketForm;
