import React, { useEffect, useState } from "react";
import axios from "axios";
import "./UnassingedCustomers.css";
import Spinner from "../../components/Spinner/spinner";
import { SearchOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Modal,
  Input,
  Button,
  theme
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import CustomerList from "../../AccountCare/Components/Customer List/customerList";
import CompanyDetails from "../../AccountCare/All Pages/Company/CompanyDetails";
import useNotification from "../../components/snackbars/SnackBar";
import ReassignCustomer from "../../AccountCare/Components/Modal/Reassign Customer/ReassignCustomer";
import LazyLoad from "react-lazy-load";

const UnassignedCustomer = ({ item }) => {
  const [activeKey, setActiveKey] = useState(null);
  const [allCompanies, setAllCompanies] = useState([]);
  const [ticketUpdateFlag, setTicketUpdateFlag] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ticketData, setTicketData] = useState([]);
  const [conf, setConf] = useNotification();
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [customerTransferModal, setCustomerTransferModal] = useState(false);
  const [activeCompanyID, setActiveCompanyID] = useState(null);
  const [userData, setUserData] = useState([]);
  const [companyModalVisible, setCompanyModalVisible] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({
    companyName: "",
    companyEmail: "",
    companyPhone: "",
    parentCategory: "",
    secondaryCategory: "",
    revenueCategory: "",
    dmDetails: "",
    goLiveDate: "",
    hqCity: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    createdBy: "",
    // status: "WIP",
  });
  const [pocDetails, setPocDetails] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      location: "",
      designation: "",
      userType: "",
    },
  ]);
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const { companyID } = useParams();

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("dexkorUserData")));
  }, []);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
    const userToken = localStorage.getItem("dexkorUserToken");

    const getAllUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/all/${userData.uniquePartnerId}`
        );
        const filteredUsers = response.data.filter((user) =>
          user.resources.some(
            (resource) =>
              resource.name === "SalesHub" && resource.enabled === true
          )
        );

        setAllUsers(filteredUsers);
      } catch (error) {
        console.log(error);
      }
    };
    getAllUsers();
  }, []);

  const deletePoc = (index) => {
    const updatedPocDetails = pocDetails.filter((_, i) => i !== index);
    setPocDetails(updatedPocDetails);
  };

  const addPoc = () => {
    setPocDetails([
      ...pocDetails,
      {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        location: "",
        designation: "",
        userType: "",
      },
    ]);
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
    const getAllConversation = async () => {
      try {
        setLoading(true);
        const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
        const response = await axios.get(
          `${process.env.REACT_APP_CONVERSATION}/conversations/all/${userData.uniquePartnerId}`
        );
        setLoading(false);
        setTicketData(response.data);
      } catch (error) {
        setLoading(false); // Handle errors properly
        console.error(error); // Log error for debugging
      }
    };

    getAllConversation();
  }, []);

  const onTicketClick = (company) => {
    setActiveCompanyID(company.companyID);
    setSelectedCompany(company);
    navigate(`/sales-hub/unassigned-customer/${company._id}`);
  };

  const handleSearchChange = (e) =>
    setSearchQuery(e.target.value.toLowerCase());

  const showModal = () => setCompanyModalVisible(true);

  const handleCustomerTransferModal = () => {
    setCustomerTransferModal(true);
    setSelectedUserId(null);
    setSelectedUserName(null);
  };
  const handleModalOk = () => {
    clearFormDetails();
    setCompanyModalVisible(false);
    setCustomerTransferModal(false);
  };
  const handleModalCancel = () => {
    clearFormDetails();
    setCompanyModalVisible(false);
    setCustomerTransferModal(false);
  };

  const clearFormDetails = () => {
    setCompanyDetails({
      companyName: "",
      parentCategory: "",
      secondaryCategory: "",
      revenueCategory: "",
      dmDetails: "",
      goLiveDate: "",
      hqCity: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
      createdBy: "",
      // status: "WIP",
    });
    setPocDetails([
      {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        location: "",
        designation: "",
        userType: "",
      },
    ]);
  };

  const handleInputChange = (field) => (e) => {
    let value = e.target.value;

    if (field === "customerCode" || field === "parentCustomerCode") {
      value = value.replace(/\s/g, ""); // Remove spaces for both 'customerCode' and 'parentCode'
    }
    setCompanyDetails({ ...companyDetails, [field]: value });
  };

  const handleInputPOCChange = (index, field) => (e) => {
    const updatedPocDetails = [...pocDetails];
    updatedPocDetails[index][field] = e.target.value;
    setPocDetails(updatedPocDetails);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONVERSATION}/company/register`,
        {
          uniquePartnerId: userData.uniquePartnerId,
          companyData: companyDetails,
          pocDetails,
        }
      );
      clearFormDetails();
      setCompanyModalVisible(false);
    } catch (error) {
      console.error("Error registering company:", error);
      setCompanyModalVisible(false);
    }
  };

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));

    if (userInfo) {
      setCompanyDetails((prevData) => ({
        ...prevData,
        createdBy: userInfo.email || "",
      }));
    }
  }, []);

  return (
    <>
      <Row className="dexkor-sales-hub-main-screen-body">
        <Col span={5} className="dexkor-sales-hub-main-screen-left-nav-bar">
          <div className="dexkor-sales-hub-main-screen-left-nav-bar-header">
            <span>
              <Button onClick={showModal}>Add Customer</Button>
            </span>
          </div>

          <div className="dexkor-sales-hub-search-bar">
            <Input
              className="dexkor-sales-hub-search-bar-input"
              placeholder="Search For Customers"
              prefix={<SearchOutlined />}
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>

          <div className="dexkor-sales-hub-company-list-container">
            {!loading ? (
              <LazyLoad height={200} offset={100}>
              <CustomerList
                moduleId={"saleshub001"}
                assigned={null}
                filterType={"unassigned"}
                activeCompanyID={activeCompanyID}
                onTicketClick={onTicketClick}
                searchQuery={searchQuery}
                setStatusFlag={false}
                setAllCompanies={setAllCompanies}
              />
              </LazyLoad>
            ) : (
              <div className="dexkor-sales-hub-company-list-spinner">
                <Spinner />
                <span>Loading ...</span>
              </div>
            )}
          </div>
        </Col>

        <Col span={19} className="dexkor-sales-hub-main-page-right-section">
          {!selectedCompany ? null : (
            <div className="dexkor-sales-hub-main-page-unassigne-customer-company-details">
              <div>
                <div className="dexkor-sales-hub-unassigned-customer-company-page-header">
                  <div>Customer Details</div>
                  <div className="pr-10">
                    <Button onClick={handleCustomerTransferModal}>
                      Assign to Sales Team
                    </Button>
                  </div>
                </div>
                {selectedCompany ? (
                   <div className="dexkor-all-customer-company-details">
                    <CompanyDetails selectedCompany={selectedCompany} />
                  </div>
                ) : (
                  <p>Please select a company to view the details</p>
                )}
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Modal
        visible={companyModalVisible}
        title="Add New Customer"
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        width={800}
        footer={[
          <Button key="cancel" onClick={handleModalCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Add
          </Button>,
        ]}
      >

        <Row
          style={{
            alignItems: "center",
            marginBottom: "15px",
            marginTop: "30px",
          }}
        >
          <Col span={8}>
            <p>Customer Code:</p>
          </Col>
          <Col span={16}>
            <Input
              style={{ height: "50px" }}
              type="text"
              placeholder="Enter the Customer Code"
              onChange={handleInputChange("customerCode")}
            />
          </Col>
        </Row>
        <Row style={{ alignItems: "center", marginBottom: "15px" }}>
          <Col span={8}>
            <p>Customer Name:</p>
          </Col>
          <Col span={16}>
            <Input
              style={{ height: "50px" }}
              type="text"
              placeholder="Customer Name"
              onChange={handleInputChange("companyName")}
            />
          </Col>
        </Row>
        <Row style={{ alignItems: "center", marginBottom: "15px" }}>
          <Col span={8}>
            <p>Customer Email:</p>
          </Col>
          <Col span={16}>
            <Input
              style={{ height: "50px" }}
              type="email"
              placeholder="Customer Email"
              onChange={handleInputChange("companyEmail")}
            />
          </Col>
        </Row>
        <Row style={{ alignItems: "center", marginBottom: "15px" }}>
          <Col span={8}>
            <p>Customer Contact:</p>
          </Col>
          <Col span={16}>
            <Input
              style={{ height: "50px" }}
              type="Number"
              maxLength={10}
              placeholder="Customer Contact"
              onChange={handleInputChange("companyPhone")}
            />
          </Col>
        </Row>
        <Row
          style={{
            alignItems: "center",
            marginBottom: "15px",
            marginTop: "30px",
          }}
        >
          <Col span={8}>
            <p>Parent Customer Code:</p>
          </Col>
          <Col span={16}>
            <Input
              style={{ height: "50px" }}
              type="text"
              placeholder="Enter the Parent Code"
              onChange={handleInputChange("parentCustomerCode")}
            />
          </Col>
        </Row>
      </Modal>
      <ReassignCustomer
        setCustomerTransferModal={setCustomerTransferModal}
        setSelectedUserId={setSelectedUserId}
        customerTransferModal={customerTransferModal}
        module={"SalesHub"}
        moduleID={"saleshub001"}
        selectedCompany={selectedCompany}
        setStatusFlag={false}
      />
    </>
  );
};

export default UnassignedCustomer;
