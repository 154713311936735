import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Spinner from "../components/Spinner/spinner";
import "./MainScreen.css";
import { useDispatch } from "react-redux";
import io from "socket.io-client";
import logo from "../Images/Logo icon.png";
import googleLogo from "../Images/google-logo.png";
import { RiChatNewFill, RiAlarmLine, RiFilter3Fill } from "react-icons/ri";
import { PaperClipIcon, MicrophoneIcon } from "@heroicons/react/24/outline";
import {
  Menu,
  Input,
  Avatar,
  List,
  Form,
  Tabs,
  Modal,
  Row,
  Cascader,
  Col,
  Divider,
  Select,
  Button,
  Tooltip,
  Collapse,
  theme,
} from "antd";
import {
  SearchOutlined,
  CaretRightOutlined,
  SendOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles for the editor
import { setTickets } from "../Redux/Slice/userSlice";
import EmailChat from "./Inbox/EmailChat/EmailChat";
import Conversation from "../components/Chat Conversation/Conversation";

const socket = io(`${process.env.REACT_APP_CHAT_SERVER}`);

const Inbox = ({ item }) => {
  const [activeKey, setActiveKey] = useState(null); // Track the active tab key
  const [data, setData] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeRoomID, setActiveRoomID] = useState(null);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [message, setMessage] = useState("");
  const [allowInteraction, setAllowInteraction] = useState(true);
  const [attachment, setAttachment] = useState(null);
  const [attachmentType, setAttachmentType] = useState("");
  const [transcribing, setTranscribing] = useState(false);
  const recognitionRef = useRef(null);
  const [conversations, setConversations] = useState([]);
  const [myConversations, setMyConversations] = useState([]);
  const [myTicket, setMyTicket] = useState([]);
  const [users, setUsers] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [assignee, setAssignee] = useState(null);
  const [ticketPriority, setTicketPriority] = useState(null);
  const [ticketSeverity, setTicketSeverity] = useState(null);
  const [unassignedTicket, setUnassignedTicket] = useState([]);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [recording, setRecording] = useState(false);
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [sortFlag, setSortFlag] = useState(false);
  const [userData, setUserData] = useState(null);
  const [open, setOpen] = useState(false);
  const chatContainerRef = useRef(null);
  const [unreadMessages, setUnreadMessages] = useState({});
  const { register } = useForm();

  const [value, setValue] = useState("simple text");
  const dispatch = useDispatch();

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("dexkorUserData"));
    setUserData(data);
  }, []);

  function onTextChange(e) {
    setValue(e.target.value);
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleNewTicketCreate = async (data) => {
    try {
      const token = localStorage.getItem("dexkorUserToken");
      const response = await axios.post(
        `${process.env.REACT_APP_CONVERSATION}/tickets/create`,
        {
          uniquePartnerId: userData.uniquePartnerId,
          name: data.name,
          description: data.description,
          type: data.type,
          priority: data.priority,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the headers
            Role: `${userData.role}`, // Add the Role header
            "Content-Type": "application/json", // Optional: set content type
          },
        }
      );

      const roomId = `Room-${new Date().getTime()}`;
      const customerId = null;
      const uniquePartnerId = userData.uniquePartnerId;
      socket.emit(
        "createRoom",
        uniquePartnerId,
        roomId,
        response.data._id,
        customerId,
        (response) => {
          if (response.success) {
            setUnassignedTicket((prevConversations) => [
              ...prevConversations,
              response.conversation,
            ]);
          }
        }
      );

      setOpen(false);
    } catch (error) {
      console.log(error);
      setOpen(false);
    }
  };

  const showModal = () => {
    setOpen(true);
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      ["link"],
      ["clean"],
    ],
  };

  useEffect(() => {
    if (tabs.length > 0) {
      setActiveKey(tabs[0].key); // Set the first tab as the active one
    }
  }, [tabs]);

  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    // background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  const onChange = (value) => {
    console.log(value);
  };

  const InboxFilter = [
    {
      value: "Sort By",
      label: "Sort By",
      children: [
        {
          value: "SLA",
          label: "SLA",
        },
        {
          value: "Modified Date",
          label: "Modified Date",
        },
        {
          value: "Created Date",
          label: "Created Date",
        },
      ],
    },
    {
      value: "Divider",
      label: <Divider style={{ margin: "8px 0" }} />, // Customize divider style if needed
    },
    {
      value: "Ascending Order",
      label: "Ascending Order",
    },
    {
      value: "Descending Order",
      label: "Descending Order",
    },
  ];

  const stripHtmlTags = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    // Trigger scroll to bottom when messages change
    scrollToBottom();
  }, [selectedConversation?.messages]);

  const handleMenuClick = (e) => {
    console.log("Click:", e);
    // Handle menu item click if needed
  };

  useEffect(() => {
    if (!selectedConversation && myTicket.length > 0) {
      setSelectedConversation(myTicket[0]);
      setActiveKey(myTicket[0]?.roomId);
    }
  }, [myTicket]);

  useEffect(() => {
    if (!sortFlag) return;
    // Sorting myConversations whenever it changes
    let sortedConversations = [...myTicket].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    setMyTicket(sortedConversations);
    sortedConversations = conversations.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    setConversations(sortedConversations);

    setSortFlag(false);
  }, [sortFlag]);

  useEffect(() => {
    // Listen for the 'message' event
    socket.on("message", (message) => {
      const { room } = message;

      // Check if room is undefined or null and log the message if it is
      if (!room) {
        console.error("Received message with undefined room:", message);
        return; // Skip updating if room is undefined
      }
      setUnreadMessages((prev) => {
        // If the user is not currently viewing this room, increment the unread count
        if (activeKey !== room) {
          return {
            ...prev,
            [room]: (prev[room] || 0) + 1,
          };
        }
        return prev;
      });
      setMyTicket((prevConversations) => {
        return prevConversations.map((conversation) => {
          if (conversation.roomId === message.room) {
            // Update the messages in the selected conversation
            return {
              ...conversation,
              messages: [...conversation.messages, message],
            };
          }
          return conversation;
        });
      });

      if (
        selectedConversation &&
        message.room === selectedConversation.roomId
      ) {
        setSelectedConversation((prevSelectedConversation) => ({
          ...prevSelectedConversation,
          messages: [...prevSelectedConversation.messages, message],
        }));
      }
    });

    socket.on("ticketStatusUpdated", (response) => {
      setMyTicket((prevConversations) => {
        return prevConversations.map((conversation) => {
          if (conversation.roomId === response.roomId) {
            // Update the status of the ticketId in the selected conversation
            return {
              ...conversation,
              ticketId: {
                ...conversation.ticketId,
                status: response.status,
              },
            };
          }
          return conversation;
        });
      });

      if (
        selectedConversation &&
        response.roomId === selectedConversation.roomId
      ) {
        setSelectedConversation((prevSelectedConversation) => ({
          ...prevSelectedConversation,
          ticketId: {
            ...prevSelectedConversation.ticketId,
            status: response.status,
          },
        }));
      }
    });

    socket.on("ticketClosed", (response) => {
      setMyTicket((prevConversations) => {
        return prevConversations.map((conversation) => {
          if (conversation.roomId === response.roomId) {
            // Update the status of the ticketId in the selected conversation
            return {
              ...conversation,
              ticketId: {
                ...conversation.ticketId,
                status: response.status,
              },
            };
          }
          return conversation;
        });
      });

      if (
        selectedConversation &&
        response.roomId === selectedConversation.roomId
      ) {
        setSelectedConversation((prevSelectedConversation) => ({
          ...prevSelectedConversation,
          ticketId: {
            ...prevSelectedConversation.ticketId,
            status: response.status,
          },
        }));
      }
    });

    // Clean up the socket listener on unmount
    return () => {
      socket.off("message");
      socket.off("ticketClosed");
    };
  }, [selectedConversation, activeKey]);

  useEffect(() => {
    socket.on("connect", () => {
      socket.emit("joinAgent");
    });

    // Emit all roomIds to join
    const roomIds = myTicket.map((conversation) => conversation.roomId);
    socket.emit("join", roomIds);

    socket.on("newConversation", (newConversation) => {
      console.log("New room available:", newConversation);
      setData((prevConversations) => [
        ...new Set([...prevConversations, newConversation]),
      ]);
      setSortFlag(true);
    });

    return () => {
      socket.off("newRoom");
      // socket.off("roomRemoved");
    };
  }, [selectedConversation]);

  console.log({ selectedConversation });

  const switchConversation = (key) => {
    if (key === "1") {
      setAllowInteraction(true);
    } else if (key === "2") {
      setAllowInteraction(false);
    }
  };

  // Function to filter messages
  const filterMessagesForCustomerinteraction = (messages) => {
    return messages?.filter((message) => message?.type !== "Internal");
  };

  // Assuming selectedConversation has a messages array
  const filteredMessagesForCustomerInteraction =
    filterMessagesForCustomerinteraction(selectedConversation?.messages);

  // Function to filter messages
  const filterMessagesForInternal = (messages) => {
    return messages?.filter((message) => message?.type === "Internal");
  };

  // Assuming selectedConversation has a messages array
  const filteredMessagesForInternal = filterMessagesForInternal(
    selectedConversation?.messages
  );

  useEffect(() => {
    if (attachment) {
      sendMessage(); // Automatically send message when attachment is set
    }
  }, [attachment]);

  const sendMessage = () => {
    // Check if the selectedConversation is available and that the message or attachment is not empty
    const inputMessage = message.trim();
    if (inputMessage || (attachment && attachmentType)) {
      // Construct the message object
      const msg = {
        room: selectedConversation.roomId,
        sender: "Agent",
        senderId: userData._id,
        message: inputMessage === "" ? null : inputMessage,
        type: "Internal",
        attachment,
        attachmentType,
        source: selectedConversation.ticketId?.source || null,
        user: selectedConversation?.user || null,
        customer: selectedConversation?.customer || null,
        ticket: selectedConversation.ticketId,
      };
      socket.emit("message", msg);
      setMessage("");
      setAttachment(null); // Reset attachment after sending
      setAttachmentType(""); // Reset attachment type after sending
    } else {
      console.warn("No message or attachment to send");
    }
  };
  const statusCheck = () => {
    if (selectedConversation) {
      const status = {
        room: selectedConversation.ticketId.ticketNumber,
        status: "resolved",
      };
      socket.on("ticketStatusUpdate", (response) => {
        setMyTicket((prevConversations) => {
          return prevConversations.map((conversation) => {
            if (conversation.roomId === response.roomId) {
              // Update the status of the ticketId in the selected conversation
              return {
                ...conversation,
                ticketId: {
                  ...conversation.ticketId,
                  status: response.status,
                },
              };
            }
            return conversation;
          });
        });

        if (
          selectedConversation &&
          response.roomId === selectedConversation.roomId
        ) {
          setSelectedConversation((prevSelectedConversation) => ({
            ...prevSelectedConversation,
            ticketId: {
              ...prevSelectedConversation.ticketId,
              status: response.status,
            },
          }));
        }
      });
    }
  };

  const handleRoomSelection = (room, myConversation) => {
    if (!room) {
      console.error(
        "Trying to select a room with undefined room ID:",
        myConversation
      );
      return; // Skip the function if room ID is undefined
    }
    setSelectedConversation(myConversation);
    setActiveRoomID(room);
    onTicketClick(myConversation);
    setUnreadMessages((prev) => ({
      ...prev,
      [room]: 0,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAttachment(reader.result);
        setAttachmentType(file.type); // Set attachment type based on the file type
      };
      reader.readAsDataURL(file);
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(audioChunksRef.current, { type: "audio/webm" });
        const reader = new FileReader();
        reader.onloadend = () => {
          setAttachment(reader.result);
          setAttachmentType("audio/webm");
        };
        reader.readAsDataURL(blob);
        audioChunksRef.current = [];
      };

      mediaRecorderRef.current.start();
      setRecording(true);
    } catch (error) {
      console.error("Error starting audio recording:", error);
    }
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  const startTranscription = () => {
    if (!("webkitSpeechRecognition" in window)) {
      console.error("Speech recognition is not supported in this browser.");
      return;
    }

    recognitionRef.current = new window.webkitSpeechRecognition();
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = true;
    recognitionRef.current.lang = "en-US";

    let finalTranscript = "";

    recognitionRef.current.onresult = (event) => {
      let interimTranscript = "";
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          finalTranscript += event.results[i][0].transcript;
        } else {
          interimTranscript += event.results[i][0].transcript;
        }
      }
      setMessage(finalTranscript + interimTranscript);
    };

    recognitionRef.current.start();
    setTranscribing(true);
  };

  const stopTranscription = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setTranscribing(false);
    }
  };

  const renderAttachment = (attachment, attachmentType) => {
    const handlePreview = () => {
      window.open(attachment, "_blank");
    };

    if (attachmentType.includes("image")) {
      return (
        <img
          src={attachment}
          alt="attachment"
          className="w-22 max-w-xs rounded-lg"
        />
      );
    } else if (attachmentType.includes("video")) {
      return (
        <video src={attachment} controls className="w-22 max-w-xs rounded-lg" />
      );
    } else if (attachmentType.includes("audio")) {
      return <audio src={attachment} controls />;
    } else {
      return (
        <div className="flex items-center space-x-2">
          <button
            onClick={handlePreview}
            className="bg-transparent hover:bg-transparent hover:text-black px-3 py-1 rounded"
          >
            Attachment preview
          </button>
        </div>
      );
    }
  };

  const handleStatusChange = (event) => {
    if (selectedConversation === null) return;

    const newStatus = event?.target?.value || event;
    const ticketId = selectedConversation.ticketId._id;
    const roomId = selectedConversation.roomId;

    socket.emit(
      "updateTicketStatus",
      {
        ticketId,
        status: newStatus,
        roomId: selectedConversation.roomId,
      },
      (response) => {
        if (response.success) {
          console.log("Ticket status updated successfully.", response);
        } else {
          console.error("Error updating ticket status:", response.message);
        }
      }
    );
  };

  const handleUnassignment = (userData, selectedConversation) => {
    const userId = userData?._id;
    const conversationId = selectedConversation._id; // Corrected this line

    socket.emit(
      "unassignUserFromConversation",
      {
        userId,
        conversationId,
      },
      (response) => {
        if (response.success) {
          // Filter out conversations whose conversation ID is equal to the unassigned conversation's ID
          const updatedConversations = myConversations.filter(
            (conversation) => conversation._id !== response.conversation._id
          );
          setMyTicket(updatedConversations);
          setConversations((prevConversations) => [
            ...prevConversations,
            response.conversation,
          ]);
          setSelectedConversation(null);
          setSortFlag(true);
        } else {
          console.error(
            "Error occurred while unassigning ticket:",
            response.message
          );
        }
      }
    );
  };

  const handleassignment = (userData, selectedConversation) => {
    const memberId = userData._id;
    const conversationId = selectedConversation._id;
    socket.emit(
      "addMemberToConversation",
      {
        memberId,
        conversationId,
      },
      (response) => {
        if (response.success) {
          // Filter out conversations whose userId is equal to userData._id
          const updatedConversations = unassignedTicket.filter(
            (conversation) => conversation._id !== response.conversation._id
          );
          setUnassignedTicket(updatedConversations);
          setMyTicket((prevConversations) => [
            ...prevConversations,
            response.conversation,
          ]);
          setSelectedConversation(null);
          setSortFlag(true);
        } else {
          console.error(
            "Error occured while assigning ticket:",
            response.message
          );
        }
      }
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      weekday: "long", // e.g., Monday
      year: "numeric", // e.g., 2024
      month: "long", // e.g., August
      day: "numeric", // e.g., 8
      hour: "2-digit", // e.g., 09
      minute: "2-digit", // e.g., 02
      hour12: true, // e.g., PM/AM
    });
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/all`
      ); // Adjust the API endpoint accordingly
      const allUsers = response.data;
      const filteredUsers = allUsers.filter(
        (user) => user._id !== userData._id
      );
      setUsers(filteredUsers);
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  useEffect(() => {
    if (!assignee) return;
    socket.emit(
      "assignUserToConversation",
      {
        conversationId: selectedConversation._id,
        userId: assignee, // Assuming userData contains the current user ID
      },
      (response) => {
        if (response.success) {
          setMyTicket((prevConversations) => [
            ...prevConversations,
            response.conversation,
          ]);
          const remainingConversations = data.filter(
            (con) => con._id !== response.conversation._id
          );
          setUnassignedTicket(remainingConversations);
          setSortFlag(true);
          // setDropdownVisible(!isDropdownVisible);
          // setSelectedConversation(null);
        } else {
          console.error("Error assigning ticket:", response.message);
        }
      }
    );
  }, [assignee]);

  useEffect(() => {
    if (!ticketPriority) return;
    socket.emit(
      "updateTicketPriority",
      {
        ticketId: selectedConversation.ticketId._id,
        priority: ticketPriority, // Assuming userData contains the current user ID
        roomId: selectedConversation.roomId,
      },
      (response) => {
        if (response.success) {
          setMyTicket((prevConversations) => {
            return prevConversations.map((conversation) => {
              if (conversation.roomId === response.roomId) {
                // Update the status of the ticketId in the selected conversation
                return {
                  ...conversation,
                  ticketId: response.ticket,
                };
              }
              return conversation;
            });
          });
          setSelectedConversation((prevSelectedConversation) => ({
            ...prevSelectedConversation,
            ticketId: response.ticket,
          }));
        } else {
          console.error("Error assigning ticket:", response.message);
        }
      }
    );
  }, [ticketPriority]);

  const handleAssigneeChange = (e) => {
    setAssignee(e.target.value);
  };

  const handlePriorityChange = (e) => {
    setTicketPriority(e);
  };

  const handleSeverityChange = (e) => {
    setTicketSeverity(e);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
    if (!isDropdownVisible) {
      fetchUsers(); // Fetch users only when dropdown is shown
    }
  };

  const onTabChange = (key) => {
    setActiveRoomID(key);
    setActiveKey(key);
    const conversation = data.find((tab) => tab.roomId === key);
    setSelectedConversation(conversation);
  };

  const onTabEdit = (key, action) => {
    if (action === "remove") {
      const newTabs = tabs.filter((tab) => tab.key !== key);
      setTabs(newTabs);
      if (newTabs.length > 0) {
        setActiveKey(newTabs[0].key); // Set the first tab as active if any remain
      } else {
        setActiveKey(null); // Clear activeKey if no tabs remain
      }
    }
  };

  console.log({ selectedConversation });

  useEffect(() => {
    const getAllConversation = async () => {
      try {
        setLoading(true);
        const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
        const response = await axios.get(
          `${process.env.REACT_APP_CONVERSATION}/conversations/all/${userInfo.uniquePartnerId}`
        );
        setLoading(false);
        const conversations = response.data;
        // Filter conversations where the status inside ticketId is "Pending To Tech"
        const engineeringTickets = conversations.filter(
          (convo) => convo.ticketId?.status === "Pending To Tech"
        );
        setData(engineeringTickets);
        dispatch(setTickets(response.data));
      } catch (error) {
        setLoading(false); // Handle errors properly
        console.error(error); // Log error for debugging
      }
    };

    getAllConversation();
  }, [dispatch, sortFlag]);

  useEffect(() => {
    const filteredMyTickets = () => {
      console.log("New tickets", data);

      // Filter conversations where userData._id matches any member's _id
      const filteredConversations = data.filter((conversation) =>
        conversation?.members?.some((member) => member._id === userData?._id)
      );
      setMyTicket(filteredConversations);

      // Filter conversations where userData._id does NOT match any member's _id
      const unassignedConversations = data.filter(
        (conversation) =>
          !conversation.members.some((member) => member._id === userData?._id)
      );
      setUnassignedTicket(unassignedConversations);
    };

    if (data.length > 0 && userData) {
      filteredMyTickets();
    }
  }, [data]);

  const onTicketClick = (item) => {
    // Use roomId as the unique key for the tab
    const existingTab = tabs.find((tab) => tab.key === item.roomId);

    if (existingTab) {
      setActiveKey(existingTab.key); // Set the active tab to the existing tab
    } else {
      // Create a new tab with roomId as the key
      const newTab = {
        key: item.roomId,
        title: item.ticketId.ticketNumber,
        conversation: item.messages, // Use roomId as the title or adjust as needed
      };
      const newTabs = [...tabs, newTab];
      setTabs(newTabs); // Add the new tab to the tabs array
      setActiveKey(newTab.key); // Set the newly created tab as active
    }
  };

  const height =
    selectedConversation?.ticketId?.source === "email" ? "83vh" : "60vh";

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        <label className="ml-3 cursor-pointer">
          <PaperClipIcon className="h-5 w-5 text-gray-500" />
          <input type="file" onChange={handleFileChange} className="hidden" />
        </label>
      </Menu.Item>
      <Menu.Item key="3">
        <button
          className={`bg-transparent hover:bg-transparent p-2 text-gray-500 ${
            recording ? "hover:text-green-500" : "hover:text-gray-700"
          } ml-1 ${recording ? "text-green-500" : ""}`}
          onClick={recording ? stopRecording : startRecording}
        >
          <MicrophoneIcon
            className={`h-5 w-5 ${
              recording ? "animate-pulse" : "text-gray-500"
            }`}
          />
        </button>
      </Menu.Item>
    </Menu>
  );

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();

      const trimmedMessage = message.replace(/(<([^>]+)>)/gi, "").trim();

      if (!trimmedMessage) {
        return;
      }
      sendMessage();
    }
  };

  return (
    <>
      <Row style={{ height: "100vh" }}>
        <Col xs={4} sm={3} md={2} lg={5}>
          <div
            style={{
              height: "56px",
              display: "flex",
              backgroundColor: "#2A4DAD",
              width: "100%",
              color: "white",
              alignItems: "center", // Center content vertically
              fontSize: "20px",
              justifyContent: "space-between", // Space between Inbox and Filter icon
              padding: "0 30px", // Padding to align content
              boxSizing: "border-box", // Ensures padding is included in height/width
            }}
          >
            <span
              style={{
                fontFamily: "roboto",
                fontWeight: "400",
              }}
            >
              Inbox
            </span>
            <span>
              <Cascader
                options={InboxFilter}
                onChange={onChange}
                placeholder="Please select"
              >
                <RiFilter3Fill />
              </Cascader>
            </span>
          </div>

          <div
            style={{
              height: "56px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
            }}
          >
            <Input
              style={{ width: "90%", backgroundColor: "#F6F7FA" }}
              placeholder="Search For Ticket"
              prefix={<SearchOutlined />}
            />
          </div>
          <div
            style={{
              cursor: "pointer",
              flex: 1,
              marginLeft: "5px",
              marginRight: "5px",
              maxHeight: "86vh",
              overflowY: "auto",
              scrollbarWidth: "none" /* For Firefox */,
              msOverflowStyle: "none" /* For Internet Explorer and Edge */,
            }}
          >
            <style>
              {`
            div::-webkit-scrollbar {
              display: none;
            }
            div {
              -ms-overflow-style: none; 
              scrollbar-width: none; 
            }
          `}
            </style>
            {!loading ? (
              <>
                <List
                  itemLayout="horizontal"
                  dataSource={myTicket}
                  renderItem={(item) => {
                    const lastMessage =
                      item?.messages && item?.messages.length > 0
                        ? item.messages[item.messages.length - 1]?.message
                        : "No messages available";
                    const formattedDate =
                      item?.messages && item?.messages.length > 0
                        ? moment(
                            item.messages[item.messages.length - 1]?.timestamp
                          )
                            .local()
                            .fromNow()
                        : null;

                    const isUnread = unreadMessages[item.roomId] > 0;

                    return item.ticketId.status !== "closed" ? (
                      <List.Item
                        onClick={() => handleRoomSelection(item?.roomId, item)}
                        className={
                          item?.roomId === activeRoomID
                            ? "dexkor-help-desk-conversation-list"
                            : ""
                        }
                        style={{
                          borderBottom: "2px solid #f0f0f0", // Increased the width of the bottom border
                          cursor: "pointer",
                          paddingLeft: "15px",
                        }}
                      >
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              style={{
                                backgroundColor: "#F7F7F7",
                                border: "1px solid #2A4DAD",
                              }}
                            >
                              {item?.ticketId?.source === "email" ? (
                                <img src={googleLogo} />
                              ) : (
                                <img src={logo} />
                              )}
                            </Avatar>
                          }
                          title={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "roboto",
                                  fontWeight: "600",
                                  color: "#191B1D",
                                }}
                              >
                                {item?.ticketId?.ticketNumber ||
                                  "Ticket Number"}
                              </span>
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "#A0A7AF",
                                  fontWeight: "400",
                                  fontFamily: "roboto",
                                }}
                              >
                                {formattedDate}
                              </span>
                            </div>
                          }
                          description={
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                color: "#A0A7AF",
                                fontFamily: "roboto",
                                fontWeight: "400",
                                fontSize: "14px",
                                display: "flex",
                                justifyContent: "space-between",
                                marginRight: "5px",
                              }}
                            >
                              {stripHtmlTags(lastMessage)}
                              {isUnread && (
                                <span
                                  style={{
                                    backgroundColor: "blue",
                                    color: "white",
                                    borderRadius: "12px",
                                    padding: "2px 6px",
                                    marginLeft: "8px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {unreadMessages[item.roomId]}
                                </span>
                              )}
                            </div>
                          }
                        />
                      </List.Item>
                    ) : null;
                  }}
                />
              </>
            ) : (
              <>
                {" "}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh",
                  }}
                >
                  <Spinner size="large" />
                  <span style={{ marginTop: "10px", fontFamily: "roboto" }}>
                    Loading ...
                  </span>
                </div>
              </>
            )}
          </div>
        </Col>
        <Col
          xs={20}
          sm={21}
          md={22}
          lg={19}
          style={{ backgroundColor: "#ffffff" }}
        >
          <Row style={{ height: "100vh" }}>
            <Col xs={4} sm={3} md={2} lg={17}>
              <div
                style={{
                  height: "56px",
                  backgroundColor: "#2A4DAD",
                  width: "100%",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "25px",
                  margin: "0",
                  padding: "0",
                  boxSizing: "border-box", // Ensure box-sizing is applied to the parent div
                }}
              >
                <Tabs
                  tabBarStyle={{
                    height: "100% !important", // Ensure Tabs height is 100% of its parent
                    backgroundColor: "#2A4DAD",
                    color: "white",
                    fontSize: "20px",
                    borderBottom: "none",
                    boxSizing: "border-box",
                    lineHeight: "35px",
                    display: "flex",
                    alignItems: "center", // Ensure tabs are vertically centered
                  }}
                  type="editable-card"
                  hideAdd
                  onChange={onTabChange}
                  activeKey={activeKey}
                  onEdit={onTabEdit}
                  tabBarGutter={0}
                >
                  {tabs.map((tab, index) => (
                    <Tabs.TabPane
                      tab={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between", // Ensure space between title and icon
                            // marginTop: "10px",
                            color: activeKey === tab.key ? "blue" : "white",
                          }}
                        >
                          <span>{tab.title}</span>
                        </div>
                      }
                      key={tab.key}
                      closeIcon={
                        <CloseOutlined
                          style={{
                            color: activeKey === tab.key ? "blue" : "white",
                            // marginTop: '22px'
                          }}
                        />
                      }
                    />
                  ))}
                </Tabs>
              </div>

              <div
                style={{
                  height: "56px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {selectedConversation && (
                  <div
                    style={{
                      display: "",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%", // Ensure full width for proper spacing
                    }}
                  >
                    {/* Container for select elements */}
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Select
                        showSearch
                        placeholder="Ticket Status"
                        style={{ width: 150, marginTop: "12px" }}
                        allowClear
                        value={selectedConversation.ticketId.status}
                        onChange={(value) => handleStatusChange(value)}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={[
                          { value: "Unassigned", label: "Unassigned" },
                          { value: "open", label: "Open" },
                          { value: "Pending Client", label: "Pending Client" },
                          { value: "Assigned", label: "Assigned" },
                          {
                            value: "Pending Customer",
                            label: "Pending Customer",
                          },
                          {
                            value: "Pending Product",
                            label: "Pending Product",
                          },
                          { value: "Pending Tech", label: "Pending Tech" },
                          {
                            value: "Reassign To Support",
                            label: "Reassign To Support",
                          },
                          { value: "resolved", label: "Resolved" },
                          { value: "resolved", label: "Resolved" },
                          { value: "closed", label: "Closed" },
                          {
                            value: "Tech RCA Psnding",
                            label: "Tech RCA Pending",
                          },
                        ]}
                      />
                      <Select
                        showSearch
                        placeholder="Priority"
                        value={selectedConversation.ticketId.priority}
                        style={{ width: 150, marginTop: "12px" }}
                        allowClear
                        onChange={(value) => handlePriorityChange(value)}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={[
                          { value: "Lowest", label: "Lowest" },
                          { value: "Low", label: "Low" },
                          { value: "Medium", label: "Medium" },
                          { value: "High", label: "High" },
                          { value: "Highest", label: "Highest" },
                        ]}
                      />
                      <Select
                        showSearch
                        placeholder="Severity"
                        style={{ width: 150, marginTop: "12px" }}
                        allowClear
                        onChange={(value) => handleSeverityChange(value)}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={[
                          { value: "Severity 1", label: "Severity 1" },
                          { value: "Severity 2", label: "Severity 2" },
                          { value: "Severity 3", label: "Severity 3" },
                          { value: "Normal", label: "Normal" },
                        ]}
                      />

                      <div style={{ marginLeft: "auto", marginRight: "10px" }}>
                        <RiAlarmLine
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "blue",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <div
                  style={{
                    justifyContent: "center",
                    backgroundColor: "#F6F7F9",
                    marginTop: "-15px",
                  }}
                >
                  <div
                    ref={chatContainerRef}
                    style={{
                      width: "95%",
                      height: "60vh",
                      overflow: "auto",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                      marginTop: "1%",
                      marginBottom: "1%",
                    }}
                  >
                    <Tabs
                      defaultActiveKey="1"
                      style={{ paddingLeft: "20px" }}
                      onChange={switchConversation}
                      items={[
                        {
                          label: "Internal Communication ",
                          key: "1",
                          children: (
                            <Conversation
                              conversation={{
                                ...selectedConversation,
                                messages: filteredMessagesForInternal,
                              }}
                            />
                          ),
                        },
                        {
                          label: "Customer Communication",
                          key: "2",
                          children: (
                            <Conversation
                              conversation={{
                                ...selectedConversation,
                                messages:
                                  filteredMessagesForCustomerInteraction,
                              }}
                            />
                          ),
                        },
                      ]}
                    />
                    {selectedConversation &&
                      selectedConversation.ticketId?.source === "email" && (
                        <EmailChat
                          selectedConversation={selectedConversation}
                          setSelectedConversation={setSelectedConversation}
                          setMyTicket={setMyTicket}
                          scrollToBottom={scrollToBottom}
                          handleFileChange={handleFileChange}
                          setMessage={setMessage}
                          message={message}
                          sendMessage={sendMessage}
                        />
                      )}
                  </div>
                </div>

                {allowInteraction &&
                selectedConversation &&
                selectedConversation?.ticketId?.source !== "email" ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div>
                        <span>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "100%%",
                                position: "relative",
                              }}
                            >
                              <div>
                                <ReactQuill
                                  value={message}
                                  onChange={setMessage}
                                  required
                                  theme="snow"
                                  className="dexkore-sales-hub-custom-quill1"
                                  modules={{
                                    toolbar: [
                                      { size: [] },
                                      "bold",
                                      "italic",
                                      "underline",
                                      "strike",

                                      { list: "ordered" },
                                      { list: "bullet" },
                                      { indent: "-1" },
                                      { indent: "+1" },

                                      "link",
                                      "video",
                                      "blockquote",
                                      "code-block",
                                      { align: [] },
                                      { color: [] },
                                      { background: [] },
                                      "clean",
                                    ],
                                  }}
                                  formats={[
                                    "header",
                                    "font",
                                    "size",
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strike",
                                    "blockquote",
                                    "list",
                                    "bullet",
                                    "indent",
                                    "link",
                                    "video",
                                    "align",
                                    "color",
                                    "background",
                                    "code-block",
                                  ]}
                                />
                              </div>
                              <div className="dexkor-email-reply-send-button-container flex items-center">
                                <span style={{ width: "100%" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      height: "40px",
                                      padding: "0 10px",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Tooltip title="Attach File">
                                        <label>
                                          <PaperClipIcon
                                            style={{ marginRight: "15px" }}
                                            className="h-5 w-5 text-gray-500"
                                          />
                                          <input
                                            type="file"
                                            onChange={handleFileChange}
                                            className="hidden"
                                          />
                                        </label>
                                      </Tooltip>

                                      <Tooltip title="Microphone">
                                        <div
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginLeft: "15px",
                                          }}
                                        >
                                          <button
                                            className={`bg-transparent hover:bg-transparent p-2 text-gray-500 ${
                                              recording
                                                ? "hover:text-green-500"
                                                : "hover:text-gray-700"
                                            } ml-1 ${
                                              recording ? "text-green-500" : ""
                                            }`}
                                            onClick={
                                              recording
                                                ? stopRecording
                                                : startRecording
                                            }
                                          >
                                            <MicrophoneIcon
                                              className={`h-5 w-5 ${
                                                recording
                                                  ? "animate-pulse"
                                                  : "text-gray-500"
                                              }`}
                                            />
                                          </button>
                                        </div>
                                      </Tooltip>
                                    </div>
                                    <Tooltip title="Send">
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "flex-end",
                                          width: "auto",
                                        }}
                                      >
                                        <Button
                                          onClick={sendMessage}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            backgroundColor: "#2A4DAD",
                                            color: "white",
                                            padding: "8px 16px",
                                            marginLeft: "auto",
                                          }}
                                        >
                                          Send
                                          <SendOutlined
                                            style={{ marginLeft: "8px" }}
                                          />
                                        </Button>
                                      </div>
                                    </Tooltip>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                        </span>
                        <span>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",

                              height: "40px",
                              padding: "0 10px",
                            }}
                          />
                        </span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col xs={4} sm={3} md={2} lg={7}>
              <div
                style={{
                  height: "56px",
                  backgroundColor: "#2A4DAD",
                  width: "100%",
                  color: "white",
                  alignContent: "center",
                  fontSize: "25px",
                }}
              ></div>
              <div
                style={{
                  height: "56px",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "12px",
                }}
              >
                <Button
                  type="primary"
                  style={{ fontFamily: "roboto", fontWeight: "400" }}
                  onClick={showModal}
                >
                  <RiChatNewFill /> Create New Ticket
                </Button>
              </div>
              <div
                style={{
                  overflowY: "auto",
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                  height: "83vh",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    marginLeft: "13px",
                    marginTop: "9px",
                    color: "#2A4DAD",
                    fontFamily: "roboto",
                    fontWeight: "600",
                  }}
                >
                  Unassigned
                </p>
                <div
                  style={{
                    cursor: "pointer",
                    flex: 1,
                    marginLeft: "5px",
                    marginRight: "5px",
                    maxHeight: "50vh",
                    // minHeight: "50vh",
                    overflowY: "auto",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <style>
                    {`
                      div::-webkit-scrollbar {
                        display: none;
                      }
                      div {
                        -ms-overflow-style: none; 
                        scrollbar-width: none; 
                      }
                    `}
                  </style>
                  {!loading ? (
                    <>
                      <List
                        itemLayout="horizontal"
                        size="small"
                        dataSource={unassignedTicket}
                        renderItem={(item) => {
                          const nameInitial = item?.customer?.name
                            ? item?.customer?.name.charAt(0).toUpperCase()
                            : "?";

                          const formattedDate = item?.ticketId?.updatedAt
                            ? moment(item?.ticketId?.updatedAt)
                                .local()
                                .fromNow()
                            : null;

                          return (
                            <List.Item
                              onClick={() => handleassignment(userData, item)}
                            >
                              <List.Item.Meta
                                avatar={
                                  <Avatar
                                    style={{
                                      backgroundColor: "#F7F7F7",
                                      border: "1px solid #2A4DAD",
                                    }}
                                  >
                                    {/* <img src={logo} /> */}
                                    {item?.ticketId?.source === "email" ? (
                                      <img src={googleLogo} />
                                    ) : (
                                      <img src={logo} />
                                    )}
                                  </Avatar>
                                }
                                title={
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      fontFamily: "roboto",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <span>
                                      {item?.ticketId?.ticketNumber ||
                                        "Ticket Number"}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: "gray",
                                        fontFamily: "roboto",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {formattedDate}
                                    </span>
                                  </div>
                                }
                              />
                            </List.Item>
                          );
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "80vh",
                        }}
                      >
                        <Spinner size="large" />
                        <span style={{ marginTop: "10px" }}>Loading ...</span>
                      </div>
                    </>
                  )}
                </div>
                <Divider />
                <div>
                  <Collapse
                    bordered={false}
                    defaultActiveKey={["1"]}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                    style={{
                      backgroundColor: "white",
                    }}
                    items={[
                      {
                        key: "1",
                        label: (
                          <div
                            style={{ fontWeight: "600", fontFamily: "roboto" }}
                          >
                            {" "}
                            Ticket Details{" "}
                          </div>
                        ),
                        children: (
                          <List
                            size="small"
                            dataSource={myTicket}
                            renderItem={(item) =>
                              item?.roomId === selectedConversation?.roomId ? (
                                <List.Item key={item.roomId}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      fontFamily: "roboto",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <span style={{ color: "#A0A7AF" }}>
                                      Ticket Type:{" "}
                                    </span>
                                    <span style={{ color: "#2A4DAD" }}>
                                      {item.ticketId.type}
                                    </span>
                                  </div>{" "}
                                  <br />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      fontFamily: "roboto",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <span style={{ color: "#A0A7AF" }}>
                                      Priority:{" "}
                                    </span>
                                    <span style={{ color: "#2A4DAD" }}>
                                      {item.ticketId.priority}
                                    </span>
                                  </div>{" "}
                                  <br />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      fontFamily: "roboto",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <span style={{ color: "#A0A7AF" }}>
                                      Assigned To:{" "}
                                    </span>
                                    <span style={{ color: "#2A4DAD" }}>
                                      {item?.user?.name}
                                    </span>
                                  </div>{" "}
                                  <br />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      fontFamily: "roboto",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <span style={{ color: "#A0A7AF" }}>
                                      Tags:{" "}
                                    </span>
                                    <span style={{ color: "#2A4DAD" }}>
                                      {item.ticketId.tags}
                                    </span>
                                  </div>{" "}
                                  <br />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      fontFamily: "roboto",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <span style={{ color: "#A0A7AF" }}>
                                      Status:{" "}
                                    </span>
                                    <span style={{ color: "#2A4DAD" }}>
                                      {item.ticketId.status}
                                    </span>
                                  </div>
                                </List.Item>
                              ) : null
                            }
                          />
                        ),
                        style: panelStyle,
                      },
                    ]}
                  />
                </div>
                <Divider />
                <div>
                  <div
                    style={{
                      fontWeight: "600",
                      fontFamily: "roboto",
                      marginLeft: "5%",
                    }}
                  >
                    {" "}
                    SLA
                  </div>
                  {myTicket.map((item) =>
                    item.roomId === selectedConversation?.roomId ? (
                      <>
                        {/* <div
                        style={{
                          border: "solid",
                          borderColor: "#4C73DE",
                          borderRadius: "5px",
                          margin: "5%",
                        }}
                      > */}
                        {/* <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                            margin: "2%",
                          }}
                        >
                          <RiUser3Fill /> {item.customer.name}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                            color: "#2A4DAD",
                            margin: "2%",
                          }}
                        >
                          <RiMailLine />
                          <span
                            style={{
                              border: "solid 2px",
                              borderColor: "#9AA3AB",
                              borderRadius: "0.5rem",
                              marginBottom: "2px",
                            }}
                          >
                            
                            {item.customer.email}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                            color: "#2A4DAD",
                            margin: "2%",
                          }}
                        >
                          <RiPhoneLine />
                          <span
                            style={{
                              border: "solid 2px",
                              borderColor: "#9AA3AB",
                              borderRadius: "0.5rem",
                            }}
                          >
                            
                            {item.customer.phone}
                          </span>
                        </div> */}
                        {/* </div> */}

                        <div
                          style={{
                            border: "solid",
                            borderColor: "#4C73DE",
                            borderRadius: "5px",
                            margin: "5%",
                          }}
                        >
                          {myTicket.map((item) =>
                            item.roomId === selectedConversation?.roomId ? (
                              <div style={{ margin: "2%" }}>
                                <div style={{ display: "flex", gap: "10px" }}>
                                  {" "}
                                  <span style={{ fontFamily: "roboto" }}>
                                    First Response Due
                                  </span>
                                  <span>
                                    {item.ticketId.slaBreached !== true ? (
                                      <div
                                        style={{
                                          border: "solid",
                                          borderRadius: "5px",
                                          fontSize: "10px",
                                          borderColor: "#B3C0DD",
                                          color: "#2C5EC6",
                                          backgroundColor: "#CCDAF9",
                                        }}
                                      >
                                        <p style={{ margin: "0px 2px" }}>
                                          Respond in 2h
                                        </p>
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          border: "solid",
                                          borderRadius: "5px",
                                          fontSize: "10px",
                                          borderColor: "#B3C0DD",
                                          color: "#C82024",
                                          backgroundColor: "#FED5DB",
                                        }}
                                      >
                                        <p style={{ margin: "0px 2px" }}>
                                          OverDue by 2d
                                        </p>
                                      </div>
                                    )}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    color: "#2A4DAD",
                                    fontFamily: "roboto",
                                  }}
                                >
                                  {new Date(
                                    item?.ticketId?.slaFirstResponseDue
                                  ).toLocaleString("en-US", {
                                    weekday: "short",
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "2-digit",
                                    hour12: true,
                                  })}{" "}
                                </div>{" "}
                              </div>
                            ) : null
                          )}
                        </div>
                        <div
                          style={{
                            border: "solid",
                            borderColor: "#4C73DE",
                            borderRadius: "5px",
                            margin: "5%",
                          }}
                        >
                          {myTicket.map((item) =>
                            item.roomId === selectedConversation?.roomId ? (
                              <div style={{ margin: "2%" }}>
                                <div>
                                  {" "}
                                  <span style={{ fontFamily: "roboto" }}>
                                    Resolution Due
                                  </span>{" "}
                                </div>
                                <div
                                  style={{
                                    color: "#2A4DAD",
                                    fontFamily: "roboto",
                                  }}
                                >
                                  {" "}
                                  {new Date(
                                    item?.ticketId?.slaResolutionDue
                                  ).toLocaleString("en-US", {
                                    weekday: "short",
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "2-digit",
                                    hour12: true,
                                  })}
                                </div>{" "}
                              </div>
                            ) : null
                          )}
                        </div>
                      </>
                    ) : null
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        open={open}
        title="Create New Ticket"
        footer={null}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={handleNewTicketCreate}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="Name" name="name" {...register("name")}>
            <Input />
          </Form.Item>

          <Form.Item label="Description" name="description">
            <Input {...register("description")} />
          </Form.Item>

          <Form.Item label="Type" name="type">
            <Input {...register("type")} />
          </Form.Item>

          <Form.Item label="Priority" name="priority">
            <Input {...register("priority")} />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Inbox;
