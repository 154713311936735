import React, { useEffect, useState } from 'react'
import "./Roles.css"
import axios from 'axios'
import {
    PlusOutlined
  } from "@ant-design/icons";
import { SearchOutlined } from '@ant-design/icons'
import { Button,  Form, Input, InputNumber, Popconfirm, Table, Typography  } from 'antd'
import { useScrollTrigger } from '@mui/material'

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

const RolesManagement = () => {
    const [form] = Form.useForm();
    const [searchQuery, setSearchQuery] = useState("");
    const [userData, setUserData] = useState(null)
    const [allUsers, setAllUsers] = useState([])
    const [editingKey, setEditingKey] = useState('');
    const [data, setData] = useState(allUsers);
    const isEditing = (record) => record.key === editingKey;
    const edit = (record) => { 
      form.setFieldsValue({
        name: '',
        age: '',
        address: '',
        ...record,
      });
      setEditingKey(record.key);
    };
    const cancel = () => {
      setEditingKey('');
    };

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("dexkorUserData")))
  }, []);

  const handleSearchChange = (e) =>
    setSearchQuery(e.target.value.toLowerCase());
  
  const filteredUsers =
  searchQuery.trim() === ""
    ? allUsers
    : allUsers.filter((users) => {
        const searchQueryLower = searchQuery.toLowerCase();
        return (
          users.name &&
          users.name
            .toLowerCase()
            .includes(searchQueryLower) ||
            users.email && users.email.toLowerCase().includes(searchQueryLower)
        );
      });

    const save = async (key) => {
      try {
        const row = await form.validateFields();
        const newData = [...data];
        const index = newData.findIndex((item) => key === item.key);
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...row,
          });
          setData(newData);
          setEditingKey('');
        } else {
          newData.push(row);
          setData(newData);
          setEditingKey('');
        }
      } catch (errInfo) {
        console.log('Validate Failed:', errInfo);
      }
    };

    const columns = [
        {
          title: 'Full name',
          dataIndex: 'name',
          width: '20%',
          editable: true,
        },
        {
          title: 'Email',
          dataIndex: 'email',
          width: '20%',
          editable: true,
        },
        {
          title: 'Role',
          dataIndex: 'role',
          width: '40%',
          editable: true,
        },
        {
            title: 'Action',
            dataIndex: 'operation',
            with: "20%",
            render: (_, record) => {            
              const editable = isEditing(record);
              return (
                <div>
                  <span>
                    {editable ? (
                      <span>
                        <Typography.Link
                          onClick={() => save(record.key)}
                          style={{
                            marginInlineEnd: 8,
                          }}
                        >
                          Update
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                          <a>Cancel</a>
                        </Popconfirm>
                      </span>
                    ) : (
                      <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                        Edit
                      </Typography.Link>
                    )}
                  </span>
                  <span style={{ marginLeft: 16 }}>
                    {!editable ?  <Typography.Link>Delete</Typography.Link> : null}
                   
                  </span>
                </div>
              );
            },
          }
          
      ];
      const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            inputType: col.dataIndex === 'age' ? 'number' : 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });

    useEffect(() => {
        const fetchAllUsers = async () => {
          const userData =  JSON.parse(localStorage.getItem("dexkorUserData"));
          try {
            const response = await axios.get(`${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/all/${userData.uniquePartnerId}`);
          setAllUsers(response.data)
          } catch (error) {
            console.error("Error fetching users:", error);
            
          }
        };
    
        fetchAllUsers();
      }, []); 

  return (
    <div>
      <div className="dexkor-sales-hub-main-screen-left-nav-bar-header">
            <span className='cursor-pointer' >Roles Management</span>
          </div>
        <div className='flex justify-between items-center py-5 px-5' style={{width: '100%'}}>
            <div> 
            <Input
              style={{
                width: "200%",
                backgroundColor: "#F6F7FA",
                height: "45px",
              }}
              placeholder="Search Roles"
              prefix={<SearchOutlined style={{ color: "#5F5F5F" }} />}
              value={searchQuery}
              onChange={handleSearchChange}
            />
            </div>
            <div><Button size='large' style={{backgroundColor: "#2A4DAD", color: '#fff'}}><PlusOutlined /> Create New Role</Button></div>
        </div>
        <div className='px-5'>
        <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={filteredUsers}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
        </div>
    </div>
  )
}

export default RolesManagement