import React from "react";
import { Route } from "react-router-dom";
import AccountCare from "../Left Nav Bar/NavBar";
import AssignedCustomer from "../Right Side Main Screen/MainContent";
import PrivateRoute from "../../Private Route/PrivateRoute";
import Task from "../All Pages/Task/Task";
import CompanyDetails from "../All Pages/Company/CompanyDetails"
import RenewalTask from "../All Pages/Renewal Task/Task";
import FeedbackCalling from "../All Pages/Feedback Calling Task/Task";
import UnassignedCustomer from "../../SalesHub1/Unassigned Customer/UnassignedCustomers"
import MyTask from "../All Pages/My Task/MyTask";

const AccountCareRoutes = () => {
  return (
    <>
      <Route path="/account-care" element={<PrivateRoute><AccountCare /></PrivateRoute>}> 
      <Route path="assigned-customer" element={<PrivateRoute><AssignedCustomer /></PrivateRoute>} /> 
      <Route path="assigned-customer/:companyID" element={<PrivateRoute><AssignedCustomer /></PrivateRoute>} />
      <Route path="unassigned-customer" element={<PrivateRoute><UnassignedCustomer /></PrivateRoute>} />
      <Route path="unassigned-customer/:customerID" element={<PrivateRoute><UnassignedCustomer /></PrivateRoute>} />
      <Route path="assigned-task" element={<PrivateRoute><MyTask /></PrivateRoute>} /> 
      <Route path="all-task" element={<PrivateRoute><Task /></PrivateRoute>} /> 
      <Route path="renewal-pending" element={<PrivateRoute>< RenewalTask/></PrivateRoute>} /> 
      <Route path="feedback-calling" element={<PrivateRoute>< FeedbackCalling/></PrivateRoute>} /> 
      </Route>

    </>
  );
};

export default AccountCareRoutes;
