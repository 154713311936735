import React, { useState, useRef, useEffect } from "react";
import { ArrowDownTrayIcon, XMarkIcon } from "@heroicons/react/24/outline";

const RenderAttachment = ({ attachment, attachmentType }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);

  const handlePreview = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [isModalOpen]);

  const renderAttachment = (attachment, attachmentType) => {
    if (attachmentType.includes("image")) {
      return (
        <img
          onClick={handlePreview}
          src={attachment}
          alt="attachment"
          className="w-40 rounded-lg cursor-pointer"
        />
      );
    } else if (attachmentType.includes("video")) {
      return (
        <video src={attachment} controls className="w-40 max-w-xs rounded-lg" />
      );
    } else if (attachmentType.includes("audio")) {
      return (
        <audio src={attachment} controls className="w-48 rounded-lg text-sm" />
      );
    } else if (attachmentType.includes("pdf")) {
      return (
        <div className="flex items-center w-44 space-x-2 p-0 border rounded-lg bg-gray-100 cursor-pointer hover:bg-gray-200">
          <a href={attachment} download className="flex items-center">
            <ArrowDownTrayIcon className="w-8 h-8 p-1 border border-red-600 bg-red-600 text-white rounded-s-lg" />
          </a>
          <div className="pl-6" onClick={handlePreview}>
            <p className="text-xs font-medium">PDF Document</p>
            <p className="text-xs text-gray-500">Click to view</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex items-center w-44 space-x-2 p-0 border rounded-lg bg-gray-100 cursor-pointer hover:bg-gray-200">
          <a href={attachment} download className="flex items-center">
            <ArrowDownTrayIcon className="w-8 h-8 p-1 border border-red-600 bg-red-600 text-white rounded-s-lg" />
          </a>
          <div className="pl-6" onClick={handlePreview}>
            <p className="text-xs font-medium">Document</p>
            <p className="text-xs text-gray-500">Click to view</p>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      {renderAttachment(attachment, attachmentType)}

      {isModalOpen && (
  <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
    <div
      ref={modalRef}
      className="relative bg-white bg-opacity-60 rounded-lg shadow-lg p-1"
    >
      {attachmentType.includes("image") ? (
        <img
          src={attachment}
          alt="attachment"
          className="max-w-full max-h-[90vh] rounded-lg object-contain"
        />
      ) : attachmentType.includes("pdf") ? (
        <iframe
          src={attachment}
          className="w-full h-96 rounded-lg"
          title="Document Preview"
        />
      ) : attachmentType.includes("msword") ||
        attachmentType.includes(
          "vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) ||
        attachmentType.includes("vnd.ms-powerpoint") ||
        attachmentType.includes(
          "vnd.openxmlformats-officedocument.presentationml.presentation"
        ) ? (
        <iframe
          src={`https://docs.google.com/gview?url=${attachment}&embedded=true`}
          className="w-full h-96 rounded-lg"
          title="Document Preview"
        />
      ) : (
        <div className="text-center p-4">
          <p className="text-lg">
            Preview not available for this file type.
          </p>
        </div>
      )}
    </div>
  </div>
)}

    </div>
  );
};

export default RenderAttachment;