import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Tabs } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import useNotification from "../../../components/snackbars/SnackBar";
import Spinner from "../../../components/Full Page Spinner/fullpagespinner";
import StatusTable from "./Table";
import AddStatus from "./AddStatus";

const { TabPane } = Tabs;

const Status = () => {
  const [loading, setLoading] = useState(false);
  const [addStatusModal, setAddStatusModal] = useState(false)
  const [moduleId, setModuleId] = useState("SalesHub"); // Default to SalesHub
  const [conf, setConf] = useNotification();
  const [statuses, setStatuses] = useState([]);
  const navig = useNavigate();
  const location = useLocation(); // Get the current URL location

  const openModal = () => setAddStatusModal(true);
  const closeModal = () => setAddStatusModal(false);

  const tabMap = {
    SalesHub: "saleshub001",
    Onboarding: "onboarding001",
    AccountCare: "accountcare001",
  };

  const getStatus = async (key) => {
    setLoading(true); // Set loading state
    try {
      const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
      if (userInfo) {
        const response = await axios.get(
          `${process.env.REACT_APP_CONVERSATION}/status/${key}/${userInfo.uniquePartnerId}/get-status`
        );
        setStatuses(response.data.data || []); // Set statuses or empty array if null
      }
    } catch (error) {
      console.error("Error fetching status:", error);
      setConf({ message: "Failed to fetch status", type: "error" });
      setStatuses([]); // Clear statuses on error
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const handleTabChange = (key) => {    
    const tabName = Object.keys(tabMap).find(name => tabMap[name] === key);
    setModuleId(tabName); // Update moduleId to tab name
    navig(`/setting/general-setup/status-setup/${tabName}`); // Change the path using tab name
    getStatus(key); // Fetch the status for the selected tab
  };

  useEffect(() => {
    const path = location.pathname.split('/').pop(); // Get the last segment of the path
    const activeTab = path || "SalesHub"; // Default to SalesHub if no path
    const activeKey = tabMap[activeTab] || tabMap["SalesHub"]; // Get the corresponding key
    setModuleId(activeTab); // Set the active tab
    getStatus(activeKey); // Fetch the status for the active tab
  }, [location.pathname]); // Run effect when the location changes

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="dexkor-sales-hub-main-screen-left-nav-bar-header">
            <span className="cursor-pointer">Status</span>
          </div>
          <Tabs activeKey={tabMap[moduleId]} onChange={handleTabChange} type="card" size="large">
            <TabPane tab="SalesHub" key={tabMap.SalesHub}>
              <div className="px-5 py-5">
                <div className="flex justify-between">
                  <div>
                    <p>SalesHub Status</p>
                  </div>
                  <div>
                    <Button onClick={openModal}>Add New Status</Button>
                  </div>
                </div>
                <StatusTable statuses={statuses} /> 
              </div>
            </TabPane>
            <TabPane tab="Onboarding" key={tabMap.Onboarding}>
              <StatusTable statuses={statuses} /> 
            </TabPane>
            <TabPane tab="Account Care" key={tabMap.AccountCare}>
              <StatusTable statuses={statuses} /> 
            </TabPane>
          </Tabs>
          <AddStatus isOpen={addStatusModal} onClose={closeModal} />
        </>
      )}
    </div>
  );
};

export default Status;
