import React, { useState, useEffect } from "react";
import "./resetPassword.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import useNotification from "../../components/snackbars/SnackBar";
import Spinner from "../../components/Full Page Spinner/fullpagespinner";

const ResetPassword = () => {
  const { token } = useParams();
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState();
  const [conf, setConf] = useNotification();
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isVerificationComplete, setIsVerificationComplete] = useState(false); // New state for verification completion
  const navigate = useNavigate();

  useEffect(() => {
    const VerifyUser = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/verify-reset-token/${token}`
        );
        setUserDetails(response.data.data);     
        setIsTokenValid(true);
      } catch (error) {
        console.log(error.response.data);
        setIsTokenValid(false);
      } finally {
        setLoading(false);
        setIsVerificationComplete(true); // Set verification completion to true
      }
    };

    VerifyUser();
  }, [token]);

  const ResetPassword = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      if (userDetails.password === confirmPassword) {
        const res = await axios.put(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/update-user/${userDetails.uniquePartnerId}/${userDetails.email}`,
          { password: confirmPassword}
        );
       setConf({msg: "Password Updated Successfully", variant: "success"})
       navigate("/login")
      } else {
        setConf({
          msg: "Password and confirm password must be the same!",
          variant: "warning",
        });
      }
    } catch (error) {
      setConf({ msg: error.response.data.msg, variant: "warning" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="dexkor-sales-hub-main-screen-left-nav-bar-header mb-5">
            <span className="cursor-pointer">DexKor Password Recovery Portal</span>
          </div>
          {isVerificationComplete ? ( // Check for verification completion
            isTokenValid ? (
              <form
                className="max-w-sm mx-auto p-6 bg-white shadow-md rounded-lg"
                onSubmit={(e) => ResetPassword(e)}
              >
                <h2 className="text-xl font-semibold mb-6 text-center text-gray-900 dark:text-white">
                 Password Recover
                </h2>
                <div className="mb-5">
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    required
                    onChange={(e) =>
                      setUserDetails((prev) => ({
                        ...prev,
                        password: e.target.value,
                      }))
                    }
                  />
                </div>

                <div className="mb-5">
                  <label
                    htmlFor="repeat-password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    id="repeat-password"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    required
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <button
                  type="submit"
                  className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Update Password
                </button>
              </form>
            ) : (
              <div className="flex flex-col items-center justify-center">
                <h3 className="text-lg font-semibold mb-4">Token is Invalid</h3>
                <p className="text-sm text-gray-600">
                  Please check your invitation link or contact support.
                </p>
              </div>
            )
          ) : (
            <div className="flex flex-col items-center justify-center">
              <h3 className="text-lg font-semibold mb-4">Verifying...</h3>
              <Spinner />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ResetPassword;
