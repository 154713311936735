import React from "react";
import { Route } from "react-router-dom";
import OnboardHub from "../OnboardHub";
import OnboardHubDesk from "../MainPage/Left Nav Bar/NavBar"
import OnboardHubMain from "../MainPage/Right Side Main Screen/MainContent"
import PrivateRoute from "../../Private Route/PrivateRoute";

const AdSuiteRoute = () => {
  return (
    <>
      <Route path="/onboard-hub" element={<PrivateRoute><OnboardHubDesk /></PrivateRoute>}> 
        <Route path="" element={<PrivateRoute><OnboardHub /></PrivateRoute>} />
        <Route path="assigned-onboard" element={<PrivateRoute><OnboardHubMain /></PrivateRoute>} />
        </Route>
    </>
  );
};

export default AdSuiteRoute;
