import React from "react";
import { Route } from "react-router-dom";
import TalentPulse from "../TalendPulse";
import PrivateRoute from "../../Private Route/PrivateRoute";

const TalentPulseRoutes = () => {
  return (
    <>
    <Route path="/talent-pulse" element={<PrivateRoute><TalentPulse /></PrivateRoute>}> 
    </Route>
    </>
  );
};

export default TalentPulseRoutes;
