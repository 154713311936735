import React, { useState, useEffect } from 'react';
import "./NavBar.css"
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { Menu, Row, Col } from 'antd';
import { useSelector } from "react-redux";
import { patch } from '@mui/material';

const SalesDesk = () => {
  const [selectedKey, setSelectedKey] = useState('0');
  const [closedTicketCount, setClosedTicketCount] = useState("0");
  const [unassignedTicketCount, setUnassignedTicketCount] = useState("0");
  const [myOpenTickets, setMyOpenTickets] = useState("0");
  const [openKeys, setOpenKeys] = useState([]);
  const navigate = useNavigate(); 
  const location = useLocation();
  const ticketData = useSelector((state) => state.tickets.ticketData);

  const items = [
    { key: '1', label: 'MQL Unassigned', path: '/sales-hub/mql-unassigned'},
    { key: '2', label: 'Assigned Companies', path: '/onboard-hub/assigned-onboard',
      children: [
        { key: '21', label: 'All Companies',},
        { key: '22', label: 'My Companies' },
        { key: '23', label: 'Recorded Calls' },
        { key: '24', label: 'All Calls' }
      ]
     },
    { key: '3', label: 'All Details',
      children: [
        { key: '31', label: 'Tasks', },
        { key: '32', label: 'Call Scheduler'},
        { key: '33', label: 'Email'},
        { key: '34', label: 'Document Sent' },
        { key: '35', label: 'Customer Email' },
        { key: '36', label: 'Follow up' },
        { key: '37', label: 'WhatsApp' },
        { key: '38', label: 'Calling' }
      ]
    },
    { key: '3', label: 'Closed Chats',  number: closedTicketCount },
    { key: '4', label: 'Unassigned Chats', number: unassignedTicketCount },
    { key: '5', label: 'Transfered Tickets', number: myOpenTickets},
    { 
      key: '6', 
      label: 'Internal Team Queue',  
      children: [
        { key: '61', label: 'All Pending' },
        { key: '62', label: 'SLA Breached'},
        { key: '63', label: "Approaching SLA Breach" },
      ]
    },
    { 
      key: '7', 
      label: 'Pending Queue',  
      children: [
        { key: '71', label: 'Pending Support' },
        { key: '72', label: 'Pending Product' },
        { key: '73', label: "Pending Engineering"},
        { key: '73', label: "Pending Finance" },
      ]
    },
    {
      key: '8',
      label: 'Custom View'
      
    }
  ];

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
    if (ticketData) {
      const closedTickets = ticketData.filter((item) => item.ticketId?.status === "closed");
      setClosedTicketCount(closedTickets.length);
     
      const unassignedTicket = ticketData.filter((item) => item.userId == null);
      setUnassignedTicketCount(unassignedTicket.length);
      
      const openTickets = ticketData.filter((item) => (item.ticketId.status !== 'closed' && item.userId === userData?._id));
      setMyOpenTickets(openTickets.length);
    }
  }, [ticketData]);

  const getMenuItems = (menuItems) =>
    menuItems.map((item) => ({
      key: item.key,
      label: (
        <div
          onClick={(e) => {
            if (item.path && !e.target.classList.contains('ant-menu-submenu-arrow')) {
              navigate(item.path);
            }
          }}
          className='dexkor-sales-hub-menu-item'
        >
          {item.label}
          {item.number !== undefined && <span>{item.number}</span>}
        </div>
      ),
      children: item.children ? getMenuItems(item.children) : null,
    }));

  const handleMenuClick = (e) => {
    const selectedItem = items.flatMap(item => item.children || [item]).find(item => item.key === e.key);
    if (selectedItem && selectedItem.path) {
      navigate(selectedItem.path); 
    }
    setSelectedKey(e.key);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const currentItem = items.flatMap(item => item.children || item).find(item => item.path === currentPath);
    if (currentItem) {
      setSelectedKey(currentItem.key);
      const parentKey = getParentKey(currentItem.key, items);
      if (parentKey) {
        setOpenKeys([parentKey]);
      }
    }
  }, [location]);

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const getParentKey = (childKey, menuItems) => {
    for (let item of menuItems) {
      if (item.children) {
        for (let child of item.children) {
          if (child.key === childKey) {
            return item.key;
          }
        }
      }
    }
    return null;
  };

  return (
    <>
      <Row className='dexkor-sales-hub-body'>
        <Col span={3}>
          <div className='dexkor-sales-hub-left-nav-bar-header'>
              <p className='dexkor-sales-hub-left-nav-bar-header-text'>
                OnBoard Hub
              </p>
            </div>
            
            <Menu
              mode="inline"
              selectedKeys={[selectedKey]}
              onClick={handleMenuClick}
              onOpenChange={onOpenChange}
              className='dexkor-sales-hub-nav-bar-menu-list'
              items={getMenuItems(items)}
              openKeys={openKeys}
            />
        </Col>
        <Col span={21} className="dexkor-sales-hub-main-screen">
          <Outlet />
        </Col>
      </Row>
    </>
  );
};

export default SalesDesk;
