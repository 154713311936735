import React, { useState, useRef } from "react";
import axios from "axios";
import "./ImportCustomer.css";
import { saveAs } from "file-saver";
import { Button } from "antd";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import useNotification from "../../../components/snackbars/SnackBar";
import Spinner from "../../../components/Full Page Spinner/fullpagespinner";

const ImportCustomer = () => {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [file, setFile] = useState(null);
  const [conf, setConf] = useNotification();
  const [importModal, setImportModal] = useState(false);
  const fileInputRef = useRef(null);
  const navig = useNavigate();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    setSelectedFile(file);

    // Reset the file input field so that the user can select the same file again
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setFile(file);
    setSelectedFile(file);

    // Reset the file input field
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setFile(null);

    // Reset the file input field
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleSubmit = async () => {
    if (!file) {
      setConf({ msg: "Please upload a CSV File", variant: "error" });
      return;
    }

    setLoading(true);

    Papa.parse(file, {
      header: true,
      complete: async (results) => {
        const records = results.data;
        const successRecords = [];
        const errorRecords = [];

        const processRecord = async (record) => {
          const userData = JSON.parse(localStorage.getItem("dexkorUserData"));

          const companyDetailsData = {
            parentCustomerCode: record.parentCustomerCode,
            customerCode: record.customerCode,
            companyName: record.customerName,
            companyEmail: record.customerEmail,
            companyPhone: record.customerPhone,
            addressLine1: record.addressLine1,
            addressLine2: record.addressLine2,
            city: record.city,
            state: record.state,
            country: record.country,
            pincode: record.pinCode,
            createdBy: userData.email,
          };

          const pocDetailsData = {
            firstName: record.pocFirstName,
            lastName: record.pocLastName,
            email: record.pocEmail,
            phone: record.pocPhone,
            location: record.pocLocation,
            designation: record.pocDesignation,
            userType: record.pocUserType,
          };

          try {
            await axios.post(
              `${process.env.REACT_APP_CONVERSATION}/company/register`,
              {
                uniquePartnerId: userData?.uniquePartnerId,
                companyData: companyDetailsData,
                pocDetails: pocDetailsData,
              }
            );
            // Add to success records
            successRecords.push(record);
          } catch (apiError) {
            console.error("Error importing record:", apiError);
            // Append the error message to the record
            record.errorMessage =
              apiError.response && apiError.response.data
                ? apiError.response.data.message
                : apiError.message || "Unknown Error";
            // Add the modified record to error records
            errorRecords.push(record);
          }
        };

        // Process each record sequentially
        for (const record of records) {
          await processRecord(record);
        }

        // Check if there are any error records and set the final message
        if (errorRecords.length > 0) {
          setConf({
            msg: `Bulk import completed with ${errorRecords.length} error(s).`,
            variant: "error",
          });

          // Prepare CSV for errored records, including error message column
          const csvData = Papa.unparse(errorRecords, {
            header: true,
          });

          // Create a Blob from the CSV string and trigger a download
          const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
          saveAs(blob, "errored_records.csv");
        } else {
          setConf({
            msg: "Bulk Customer Import Completed Successfully",
            variant: "success",
          });
        }

        setLoading(false);
      },
      error: (parseError) => {
        setConf({ msg: "Error Parsing CSV File", variant: "error" });
        setLoading(false);
      },
    });
  };

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="dexkor-sales-hub-main-screen-left-nav-bar-header">
            <span className="cursor-pointer">Import Customer</span>
          </div>
          <div className="px-10 py-10">
            <div
              className="flex items-center justify-center w-full"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            >
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    Please upload only CSV file
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  accept=".csv"
                />
              </label>
            </div>

            <div className="py-5">
            <span style={{ fontWeight: "bold" }}>Note:</span>
              <p>
                 1. Please make
                sure to use the{" "}
                <span>
                  {" "}
                  <a
                    href="https://drive.google.com/uc?export=download&id=1YJE8yDIX7nxHVxe70WIj-ZZ_IOrDZCyg"
                    download="default.csv"
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Default
                  </a>
                </span>{" "}
                
                CSV structure
              </p>
              <p>2. Below are the Mandatory Field needs to be included in the CSV file</p>
              <div className="pl-4">
              <p>(a): Customer Code should be Unique and Mandatory</p>
              <p>(b): Customer Name </p>
              <p>(c): Customer Email </p>
              <p>(d): Customer Phone </p>
              <p>(e): Format of all date should be DD-MM-YYYY </p>
              </div>
            </div>

            {/* Conditionally show the uploaded file name */}
            {selectedFile && (
              <div className="mt-4">
                <p>
                  <strong>Attached File:</strong> {selectedFile.name}
                </p>
              </div>
            )}
          </div>
          <div className="justify-center flex">
            <Button
              size="large"
              style={{ backgroundColor: "#2A4DAD", color: "#fff" }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              size="large"
              style={{ backgroundColor: "#2A4DAD", color: "#fff" }}
              onClick={() => handleSubmit()}
            >
              Import
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ImportCustomer;
