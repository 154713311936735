import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Form, Select, Button } from 'antd';
import axios from 'axios';
import useNotification from '../../../../components/snackbars/SnackBar';

const { Option } = Select;

const ReassignCustomer = ({ setStatusFlag, setCustomerTransferModal, setSelectedUserId, customerTransferModal, module, moduleID, selectedCompany }) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectUserId, setSelectUserId] = useState(null)
  const [conf, setConf] = useNotification();

  useEffect(() => {
    const getUsers = async () => {
      const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
      if (userInfo) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/all/${userInfo.uniquePartnerId}`
          );   
          const filteredUsers = response.data
          .filter((user) => user.status === 'Active') // Check if status is Active
          .map((user) => ({
            ...user,
            resources: user.resources.filter(
              (resource) => resource.name === module && resource.enabled === true
            ),
          }));
          setTeamMembers(filteredUsers);
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      }
    };
    getUsers();
  }, []);

  useEffect(() => {
    const getStatus = async () => {
      const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
      if (userInfo) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_CONVERSATION}/status/${moduleID}/${userInfo.uniquePartnerId}/get-status`
          );
          const filteredStatus = response.data.data.filter((status) => status.enabled === true);
          setAllStatus(filteredStatus);
        } catch (error) {
          console.error("Error fetching status:", error);
        }
      }
    };
    getStatus();
  }, []);

  // Handle modal actions
  const handleOk = () => {
    setCustomerTransferModal(false); // Close modal on OK
    setSelectedStatus(null);
     setSelectedTeamMember(null); // Reset selected team member
  };

  const handleCancel = () => {
    setCustomerTransferModal(false); // Close modal on Cancel
     setSelectedStatus(null);
     setSelectedTeamMember(null); // Reset selected team member
  };

  const handleStatusChange = (statusId) => {
    const selected = allStatus.find(status => status._id === statusId);
    setSelectedStatus(selected);
  };

  const handleTeamMemberChange = (userId) => {
    setSelectUserId(userId)
    const selectedUser = teamMembers.find(user => user._id === userId);
    setSelectedTeamMember(selectedUser ? selectedUser.name : null); // Store the user's name for display
  };

  const handleAssign = async (e) => {
    e.preventDefault();

    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
    if (userInfo) {
      let statusArray = []; // You can fetch it from your current data if available
      if (!statusArray || statusArray.length === 0) {
        // If status array doesn't exist or is empty, create a new array
        statusArray = [
          {
            moduleId: moduleID,
            statusName: selectedStatus?.statusName,
            statusType: selectedStatus?.statusType,
            statusAlias: selectedStatus?.statusAlias,
          },
        ];
      } else {
        // If it exists, update each status object
        statusArray = statusArray.map((status) => ({
          ...status,
          moduleId: moduleID,
          statusName: selectedStatus?.statusName,
          statusType: selectedStatus?.statusType,
          statusAlias: selectedStatus?.statusAlias,
        }));
      }


      try {
        const response = await axios.put(
          `${process.env.REACT_APP_CONVERSATION}/company/update/${selectedCompany.customerCode}/${userInfo.uniquePartnerId}`,
          { customerAssignedTo: selectUserId, status: statusArray }
        );
        setCustomerTransferModal(false);
        setConf({
          msg: `Customer Assigned To Sales successfully`,
          variant: "success",
        });
        setStatusFlag(true)
      } catch (error) {
        console.log(error);
      }
    }
  }
  return (
    <Modal
      title="Customer Re-assign"
      visible={customerTransferModal}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null} // Remove default footer to add custom buttons
    >
      <Form layout="vertical">
        <div className='pt-10 pb-5'>
          <Row>
            <Col span={8}>
              <Form.Item> <p>Select Team Member</p></Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item>
                <Select
                  showSearch
                  placeholder="Select Team Member"
                  optionFilterProp="filter"
                  value={selectedTeamMember} // Display selected team member's name
                  onChange={handleTeamMemberChange}
                >
                  {teamMembers.map((user) => (
                    <Option key={user._id} value={user._id} filter={user.name}>
                      {user.name} ({user.email})
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row className='flex items-center'>
            <Col span={8}>
              <Form.Item> <p>Status</p></Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item>
                <Select
                  placeholder="Select Status"
                  onChange={handleStatusChange}
                  value={selectedStatus?.statusName}
                >
                  {allStatus.map((status) => (
                    <Option key={status._id} value={status._id} filter={status.statusName}>
                      {status.statusName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="flex items-center justify-center">
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            type="primary"
            onClick={(e) =>  handleAssign(e)}
          >
            Assign
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ReassignCustomer;
