import React, { useState, useEffect } from "react";
import "./CompanyDetails.css";
import axios from "axios";
import moment from "moment";
import dayjs from "dayjs";
import logo from "../../../Images/Logo icon.png";
import {
  Row,
  Col,
  Flex,
  message,
  Upload,
  DatePicker,
  Button,
  Card,
  InputNumber,
  Table,
  Form,
  Typography,
  Input,
  Modal,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { TbEdit } from "react-icons/tb";
import { SlLocationPin } from "react-icons/sl";
import { MdOutlineEmail } from "react-icons/md";
import { FaRegSquarePlus } from "react-icons/fa6";
import { FiPhone } from "react-icons/fi";
import useNotification from "../../../components/snackbars/SnackBar";

const { TextArea } = Input;
const { Title } = Typography;

const CompanyDetails = ({ selectedCompany }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [conf, setConf] = useNotification();
  const [subscrtionModal, setSubscriptionModal] = useState(false);
  const [contactDetails, setContactDetails] = useState({});
  const [isSubscriptionEdit, setIsSubscriptionEdit] = useState(false);
  const [subscriptionDetails, setSusbscriptionDetails] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState({
    subscriptionStartDate: "",
    lastRenewalDate: "",
    subscriptionDuration: 0,
    planName: "",
    planType: "",
    totalConsumption: "",
    allowedConsumption: "",
  });

  const handleSubscriptionChange = (name) => (event) => {
    let value;

    if (name === "subscriptionDuration") {
      // Convert the duration input to a number
      value = Number(event.target.value) || 0; // Default to 0 if NaN
    } else if (name === "subscriptionStartDate") {
      // For the DatePicker, handle the date object
      value = event; // event is the date object when using DatePicker
    } else {
      value = event.target.value; // Default case for other inputs
    }

    setSubscriptionData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddSubscription = async () => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));

    if (userInfo) {
      try {
        const dataToSubmit = {
          ...subscriptionData,
          subscriptionStartDate: subscriptionData.subscriptionStartDate
            ? moment(subscriptionData.subscriptionStartDate).format("DD-MM-YYYY")
            : null,
          lastRenewalDate: subscriptionData.subscriptionStartDate
            ? moment(subscriptionData.subscriptionStartDate).format("DD-MM-YYYY")
            : null,
          customerCode: selectedCompany.customerCode,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_CONVERSATION}/company/${selectedCompany.companyID}/${userInfo.uniquePartnerId}/addSubscription`,
          dataToSubmit
        );
      } catch (error) {
        if(error.response.status === 400){
          setConf({msg: "A Subscription already exist for this customer", variant: "warning"})
        }
      }
     
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleClickSubscriptionUpdate = async () => {
    alert("working");
  };
  const handleClickUpdate = async () => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
    if (userInfo) {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_CONVERSATION}/company/update/${selectedCompany.customerCode}/${userInfo.uniquePartnerId}`,
          { contactDetails }
        );
        setIsEdit(false);
      } catch (error) {
        console.log(error);
        setIsEdit(false);
      }
    }
  };

  useEffect(() => {
    const getSubscription = async () => {
      const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));

      if (userInfo) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_CONVERSATION}/company/${selectedCompany.companyID}`
          );
          if (response.data.subscriptionDetails && response.data.subscriptionDetails.length > 0) {
            setSusbscriptionDetails(response.data.subscriptionDetails[0]);
          } else {
            setSusbscriptionDetails(null);
          }
          setContactDetails(response.data.contactDetails);    
        } catch (error) {
          console.log(error);
        }
      }
    };

    getSubscription();
  }, [selectedCompany]);
  

  const handleOk = () => {
    setSubscriptionModal(false);
  };
  const handleCancel = () => {
    setSubscriptionModal(false);
  };

  return (
    <div className="dexkor-account-care-company-body">
      {/* <div className="dexkor-account-care-poc-header">
      <div>
          <h2> Customer Assignment Status</h2>
        </div>
      </div>
      <div>
        Hello
      </div> */}
      <div className="dexkor-account-care-poc-header">
        <div>
          <h2> Owner Details</h2>
        </div>
        <div className="pr-10">
          {!isEdit ? (
            <div>
              <Button onClick={() => setIsEdit(true)}>Edit</Button>{" "}
            </div>
          ) : (
            <div>
              <Button onClick={() => setIsEdit(false)}>Cancel</Button>
              <Button onClick={(e) => handleClickUpdate(e)}>Update</Button>
            </div>
          )}
        </div>
      </div>
      <div style={{ padding: "20px" }}>
        <Form layout="vertical">
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "16px", fontFamily: "roboto" }}>
                    Customer Success Manager
                  </span>
                }
              >
                <Input
                  name="customerSuccessManager"
                  disabled={!isEdit}
                  value={contactDetails?.customerSuccessManager || ""}
                  onChange={handleInputChange}
                  className={!isEdit ? "custom-disabled-input" : ""} 
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "16px", fontFamily: "roboto" }}>
                    Technical Account Manager
                  </span>
                }
              >
                <Input
                  name="technicalAccountManager"
                  disabled={!isEdit}
                  value={contactDetails?.technicalAccountManager || ""}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "16px", fontFamily: "roboto" }}>
                    Account Manager
                  </span>
                }
              >
                <Input
                  name="accountManager"
                  disabled={!isEdit}
                  value={contactDetails?.accountManager || ""}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "16px", fontFamily: "roboto" }}>
                    Sales Advisor
                  </span>
                }
              >
                <Input
                  name="salesPersonName"
                  disabled={!isEdit}
                  value={contactDetails?.salesPersonName || ""}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "16px", fontFamily: "roboto" }}>
                    Pre-Sales Advisor
                  </span>
                }
              >
                <Input
                  name="preSalesPersonName"
                  disabled={!isEdit}
                  value={contactDetails?.preSalesPersonName || ""}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "16px", fontFamily: "roboto" }}>
                    On Boarding Advisor
                  </span>
                }
              >
                <Input
                  name="onboardingPersonName"
                  disabled={!isEdit}
                  value={contactDetails?.onboardingPersonName || ""}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "16px", fontFamily: "roboto" }}>
                    Support Advisor
                  </span>
                }
              >
                <Input
                  name="supportPersonName"
                  disabled={!isEdit}
                  value={contactDetails?.supportPersonName || ""}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="dexkor-account-care-poc-header">
        <div>
          <h2> Subscription Details</h2>
        </div>
        <div className="pr-10">
          {/* {!isSubscriptionEdit ? (
            subscriptionDetails ? (
              <div>
                <Button onClick={() => setIsSubscriptionEdit(true)}>
                  Edit
                </Button>{" "}
              </div>
            ) : (
              <div>
                <Button onClick={() => setSubscriptionModal(true)}>
                  Add new Subscription
                </Button>{" "}
              </div>
            )
          ) : (
            <div>
              <Button onClick={() => setIsSubscriptionEdit(false)}>
                Cancel
              </Button>
              <Button onClick={(e) => handleClickSubscriptionUpdate(e)}>
                Update
              </Button>
            </div>
          )} */}
        </div>
      </div>
      <div style={{ padding: "20px" }}>
  {subscriptionDetails ? (
    <Form layout="vertical">
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item
            label={
              <span style={{ fontSize: "16px", fontFamily: "roboto" }}>
                Subscription Start Date
              </span>
            }
          >
            <DatePicker
            style={{width: '100%', height: '50px'}}
              disabled={!isSubscriptionEdit}
              value={
                subscriptionDetails?.subscriptionStartDate
                  ? dayjs(subscriptionDetails.subscriptionStartDate)
                  : null
              }
              format="DD-MM-YYYY"
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label={
              <span style={{ fontSize: "16px", fontFamily: "roboto" }}>
                Subscription End Date
              </span>
            }
          >
            <DatePicker
             style={{width: '100%', height: '50px'}}
              disabled={!isSubscriptionEdit}
              value={
                subscriptionDetails?.subscriptionEndDate
                  ? dayjs(subscriptionDetails.subscriptionEndDate)
                  : null
              }
              format="DD-MM-YYYY"
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label={
              <span style={{ fontSize: "16px", fontFamily: "roboto" }}>
                Subscription Duration (In Months)
              </span>
            }
          >
            <InputNumber
             style={{width: '100%', height: '50px'}}
          disabled={!isSubscriptionEdit}
          value={subscriptionData.subscriptionDuration || 0} 
          suffix="months"
          min={0}
        />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label={
              <span style={{ fontSize: "16px", fontFamily: "roboto" }}>
                Next Renewal Date
              </span>
            }
          >
            <DatePicker
             style={{width: '100%', height: '50px'}}
              disabled={!isSubscriptionEdit}
              value={
                subscriptionDetails?.nextRenewalDate
                  ? dayjs(subscriptionDetails.nextRenewalDate)
                  : null
              }
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ) : (
    <div className="flex justify-center items-center mt-10">
      <Title level={4}>
        Customer doesn't have any Active Subscription. Please create a new one.
      </Title>
    </div>
  )}
</div>;
      <Modal
        title="Basic Modal"
        open={subscrtionModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form layout="vertical" onFinish={handleAddSubscription}>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "16px", fontFamily: "roboto" }}>
                    Start Date
                  </span>
                }
              >
                <DatePicker
                  style={{ height: "50px", width: "100%" }}
                  name="subscriptionStartDate"
                  value={subscriptionData.subscriptionStartDate || ""} // moment conversion for DatePicker
                  onChange={handleSubscriptionChange("subscriptionStartDate")}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "16px", fontFamily: "roboto" }}>
                    Duration (In Months):
                  </span>
                }
              >
                <Input
                  name="subscriptionDuration"
                  value={subscriptionData.subscriptionDuration || 0}
                  onChange={handleSubscriptionChange("subscriptionDuration")}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "16px", fontFamily: "roboto" }}>
                    Plan Name
                  </span>
                }
              >
                <Input
                  name="planName"
                  value={subscriptionData.planName}
                  onChange={handleSubscriptionChange("planName")}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "16px", fontFamily: "roboto" }}>
                    Plan Type
                  </span>
                }
              >
                <Input
                  name="planType"
                  value={subscriptionData.planType}
                  onChange={handleSubscriptionChange("planType")}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "16px", fontFamily: "roboto" }}>
                    Total Consumption
                  </span>
                }
              >
                <Input
                  name="totalConsumption"
                  value={subscriptionData.totalConsumption}
                  onChange={handleSubscriptionChange("totalConsumption")}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "16px", fontFamily: "roboto" }}>
                    Allowed Consumption
                  </span>
                }
              >
                <Input
                  name="allowedConsumption"
                  value={subscriptionData.allowedConsumption}
                  onChange={handleSubscriptionChange("allowedConsumption")}
                />
              </Form.Item>
            </Col>
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default CompanyDetails;
