import React, { useEffect, useState } from "react";
import "./Task.css";
import axios from "axios";
import dayjs from "dayjs";
import { Button, Input, Table, DatePicker, TimePicker, Modal, message, Select, Form, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FaPlus } from "react-icons/fa";
import useNotification from "../../../components/snackbars/SnackBar";
import ActivityComment from "../../../AccountCare/Components/Drawer/Activity Comment/ActivityComment"

const { TextArea } = Input;
const { Option } = Select;

const Task = ({ selectedCompany }) => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [activities, setActivities] = useState([]);
  const [taskModal, setTaskModal] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [activityDrawerOpen, setActivityDrawerOpen] = useState(false)
  const [conf, setConf] = useNotification();
  const [form] = Form.useForm();
  
  const statusOptions = ["Pending", "In Progress", "Completed", "On Hold", "Cancelled"];

  const typeOptions = ["Activity", "Renewal", "Feedback"];

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("dexkorUserData")));
  }, []);
  

  // useEffect(() => {
  //   const fetchActivities = async () => {
  //     const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
  //     if (userInfo) {
  //       try {
  //         const response = await axios.get(
  //           `${process.env.REACT_APP_CONVERSATION}/activities/${selectedCompany.customerCode}/${userInfo.uniquePartnerId}`
  //         );
  //         setActivities(response.data.map((activity, index) => ({
  //           ...activity,
  //           key: index,
  //         })));
  //       } catch (error) {
  //         console.error("Error fetching activities:", error);
  //       }
  //     }
  //   };
  //   fetchActivities();
  // }, [selectedCompany.customerCode]);

  useEffect(() => {
    const fetchTask = async () => {
      const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CONVERSATION}/task/getTaskByCustomerCode/${selectedCompany.customerCode}/${userInfo.uniquePartnerId}`
        );

        const filteredTasks = response.data.data

        setActivities(
          filteredTasks.map((activity, index) => ({
            ...activity,
            key: index, // Ensuring each row has a unique key
          }))
        );
     
      } catch (error) {
        console.error("Error fetching activities:", error);
       
      }
    };

    fetchTask(); 

  }, [selectedCompany]);

  const handleComments = async(record) => {
    setActivityDrawerOpen(true)
    setSelectedActivity(record)
  }

  const columns = [
    {
      title: "Task ID",
      dataIndex: "taskID",
      key: "taskID",
    },
    {
      title: "Customer Code",
      dataIndex: "customerCode",
      key: "taskID",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "taskID",
    },
    {
      title: "Status",
      dataIndex: "taskStatus",
      key: "status",
    },
    {
      title: "Type",
      dataIndex: "taskType",
      key: "status",
    },
    {
      title: "Owner",
      dataIndex: "taskOwner",
      key: "status",
    },
    {
      title: "Action",
      key: "action",

      render: (text, record) => (
        <>        <Button
          type="primary"
          style={{
            backgroundColor: "#2A4DAD",
            borderRadius: "15px",
          }}
          onClick={() => {
            setSelectedActivity(record); // Set the selected task
            setTaskModal(true); // Show the modal
          }}
        >
          Update
        </Button>

        <Button
        type="primary"
        style={{
          backgroundColor: "#2A4DAD",
          borderRadius: "15px",
        }}
        onClick={() => {
         handleComments(record)
        }}
      >
        Comments
      </Button>
      </>
      ),
    },
  ];


  const handleCancel = () => {
    setTaskModal(false);
    form.resetFields(); // Reset the form fields when closing the modal
    setUpdateMode(false);
    setSelectedActivity(null);
  };

  const handleAddActivity = async () => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
    if (!userInfo) {
      message.error("User not found. Please log in.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONVERSATION}/task/task-create/${selectedCompany.customerCode}/${userInfo.uniquePartnerId}`,
        {
          uniquePartnerId: userInfo.uniquePartnerId,
          customerCode: selectedCompany.customerCode,
          customerName: selectedCompany.companyName,
          taskOwner: userInfo.email,
          taskType: "Activity",
          ...form.getFieldsValue(), // Use form values directly
          scheduledBy: userData.email,
        }
      );
      setActivities((prevActivities) => [...prevActivities, response.data]);
      setConf({msg: "Activity Added Successfully", variant: 'success'})
      handleCancel();
    } catch (error) {
      console.error("Error adding activity:", error);
      setConf({msg: "Failed to Add Activity", variant: 'error'})
    }
  };

  const handleUpdateActivity = async () => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
    if (!userInfo) {
      message.error("User not found. Please log in.");
      return;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_CONVERSATION}/activities/${selectedActivity.activityID}/${userInfo.uniquePartnerId}/update-activity`,
        form.getFieldsValue() // Use form values directly
      );

      const updatedActivities = activities.map((activity) =>
        activity.activityID === selectedActivity.activityID ? response.data.data : activity
      );
      setActivities(updatedActivities);
      setConf({msg: "Activity Updated Successfully", variant: 'success'})
      handleCancel();
    } catch (error) {
      console.error("Failed to update activity:", error);
      setConf({msg: "Failed to update activity", variant: 'error'})
    }
  };

  const handleUpdateClick = (record) => {
    setUpdateMode(true);
    setSelectedActivity(record);
    form.setFieldsValue({
      date: dayjs(record.date), // Ensure this is in the right format
      time: dayjs(record.time, "HH:mm:ss"), // Ensure this is in the right format
      title: record.title,
      description: record.description,
      status: record.status || "Pending",
    });
    setTaskModal(true);
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const handleSearchChange = (e) =>
    setSearchQuery(e.target.value.toLowerCase());

  const filteredTask =
  searchQuery.trim() === ""
    ? activities
    : activities.filter((activity) => {
        const searchQueryLower = searchQuery.toLowerCase();
        return (
          (activity.name &&
            activity.title.toLowerCase().includes(searchQueryLower)) ||
          (activity.email &&
            activity.description.toLowerCase().includes(searchQueryLower)) || 
            (activity.email &&
              activity.status.toLowerCase().includes(searchQueryLower))
        );
      });
  return (
    <div className="dexkor-sales-hub-task-body">
      <div className="flex justify-between items-center">
        <div className="dexkor-sales-hub-task-search-bar">
          <h2>Search For Task</h2>
          <Input
            prefix={<SearchOutlined />}
            className="dexkor-sales-hub-task-search-bar-input"
            placeholder="Search..."
            value={searchQuery}
                  onChange={handleSearchChange}
          />
        </div>
        <Button
          type="primary"
          size="large"
          onClick={() => setTaskModal(true)}
          style={{ backgroundColor: "#2A4DAD", marginRight: '20px' }}
        >
          <FaPlus /> Add New Task
        </Button>
      </div>
      <Table style={{ padding: '20px' }} columns={columns} dataSource={activities} loading={loading} />
      <Modal
        open={taskModal}
        width={600}
        title={updateMode ? "Update Task" : "Add Task"}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
          >
            {updateMode ? "Update" : "Submit"}
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={updateMode ? handleUpdateActivity : handleAddActivity}
          validateMessages={validateMessages}
        >
          {/* Date Row */}
          <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
            <Col span={8}>
              <Form.Item> Select Date </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="date"
                rules={[{ required: true, message: "Date is required!" }]}
                style={{ marginBottom: '0px' }}
              >
                <DatePicker
                  style={{ width: "100%", height: "40px", border: "1px solid #d9d9d9", padding: "5px 11px", borderRadius: "4px" }}
                  format="YYYY-MM-DD"
                  onChange={(date) => form.setFieldsValue({ date })}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Time Row */}
          <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
            <Col span={8}>
              <Form.Item> Select Time </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="time"
                rules={[{ required: true, message: "Time is required!" }]}
                style={{ marginBottom: '0px' }}
              >
                <TimePicker
                  style={{ width: "100%", height: "40px", border: "1px solid #d9d9d9", padding: "5px 11px", borderRadius: "4px" }}
                  format="HH:mm:ss"
                  onChange={(time) => form.setFieldsValue({ time })}
                  defaultOpenValue={dayjs("00:00:00", "HH:mm:ss")}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Status Row */}
          <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
            <Col span={8}>
              <Form.Item> Status </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="taskStatus"
                rules={[{ required: true, message: "Status is required!" }]}
                style={{ marginBottom: '0px' }}
              >
                <Select style={{ width: "100%", marginTop: '0px' }}>
                  {statusOptions.map((status) => (
                    <Option key={status} value={status}>
                      {status}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
            <Col span={8}>
              <Form.Item> Type </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="taskType"
                rules={[{ required: true, message: "Type is required!" }]}
                style={{ marginBottom: '0px' }}
              >
                <Select style={{ width: "100%", marginTop: '0px' }}>
                  {typeOptions.map((type) => (
                    <Option key={type} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* Title Row */}
          <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
            <Col span={8}>
              <Form.Item> Title </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="taskName"
                rules={[{ required: true, message: "Title is required!" }]}
                style={{ marginBottom: '0px' }}
              >
                <Input
                  style={{ width: "100%", height: "40px", border: "1px solid #d9d9d9", padding: "5px 11px", borderRadius: "4px" }}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Description Row */}
          <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
            <Col span={8}>
              <Form.Item> Description </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="taskDescription"
                rules={[{ required: true, message: "Description is required!" }]}
                style={{ marginBottom: '0px' }}
              >
                <TextArea
                  rows={4}
                  style={{ width: "100%", border: "1px solid #d9d9d9", padding: "5px 11px", borderRadius: "4px" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <ActivityComment activityDrawerOpen={activityDrawerOpen} setActivityDrawerOpen={setActivityDrawerOpen} selectedActivity={selectedActivity}/>
    </div>
  );
};

export default Task;
