import React, { useState, useRef, useEffect } from 'react';
import { Drawer, Card, Avatar } from 'antd';
import ReactQuillBox from '../../ReactQuill/ReactQuill'; // Ensure the correct import
import { CloseOutlined } from '@ant-design/icons';
import DOMPurify from 'dompurify'; // Import DOMPurify
import './ActivityComment.css'; // Custom CSS
import axios from 'axios';

const ActivityComment = ({ activityDrawerOpen, setActivityDrawerOpen, selectedActivity }) => {
    const [comment, setComment] = useState('');
    const [attachment, setAttachment] = useState('');
    const [attachmentType, setAttachmentType] = useState('');
    const [comments, setComments] = useState([]);
    const commentsEndRef = useRef(null);

    // Update comments state when selectedActivity changes
    useEffect(() => {
        if (selectedActivity && selectedActivity.comments) {
            console.log(selectedActivity);
            
            setComments(selectedActivity.comments);
        } else {
            setComments([]);
        }
    }, [selectedActivity, activityDrawerOpen]);

    const handleClose = () => {
        setActivityDrawerOpen(false);
        setComments([]); // Reset comments on close
        setComment('');
        setAttachment('');
        setAttachmentType('');
    };

    const handleAddComment = async () => {
        const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));

        if (userInfo) {
            try {
                const data = {
                    comment: comment,
                    commentType: attachment ? attachmentType : "Text",
                    createdBy: userInfo.email,
                };

                if (attachment) {
                    data.attachment = attachment; 
                }

                const addComment = await axios.post(`${process.env.REACT_APP_CONVERSATION}/task/${userInfo.uniquePartnerId}/${selectedActivity.taskID}/${selectedActivity.customerCode}/add-comment`, data);

                setComments(prevComments => [
                    ...prevComments,
                    { ...data, createdAt: new Date().toISOString() } 
                ]);

                setComment('');
                setAttachment('');
                setAttachmentType('');
            } catch (error) {
                console.error("Error adding comment:", error);
            }
        }
    };

    const handleCommentChange = (newComment) => {
        setComment(newComment); 
    };

    useEffect(() => {
        if (commentsEndRef.current) {
            commentsEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [comments]);

    return (
        <Drawer
            destroyOnClose
            width={800}
            placement="right"
            open={activityDrawerOpen}
            onClose={handleClose}
            closable={false}
            title={
                <div className="drawer-header">
                    <span>All Comments</span>
                    <CloseOutlined className="close-icon" onClick={handleClose} />
                </div>
            }
        >
            <div className="drawer-content">
                <div className="comments-container">
                    {comments.length > 0 ? (
                        comments.map((comment, index) => (
                            <Card key={index} className="comment-card">
                                <div className='flex justify-between'>
                                    <div className='flex items-center gap-3'>
                                    <Avatar  />
                                    {comment.createdBy}
                                    </div>
                                    <div>
                                    <p>{new Date(comment.createdAt).toLocaleString()}</p>
                                    </div>
                                </div>
                                <div className='pl-10 pt-2'>
                                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(comment.comment) }} />
                                </div>
                                {comment.attachment && (
                                    <div className="attachment-container">
                                        <p>Attachment: {comment.attachment}</p>
                                        {comment.commentType === 'Text' && (
                                            <a href={comment.attachment} target="_blank" rel="noopener noreferrer">
                                                Download Attachment
                                            </a>
                                        )}
                                        {comment.commentType === 'Image' && (
                                            <img 
                                                src={comment.attachment} 
                                                alt="Attachment" 
                                                style={{ maxWidth: '100%', height: 'auto' }} 
                                            />
                                        )}
                                        {comment.commentType === 'File' && (
                                            <a href={comment.attachment} download>
                                                Download File
                                            </a>
                                        )}
                                    </div>
                                )}
                            </Card>
                        ))
                    ) : (
                        <p>No comments available.</p>
                    )}
                    <div ref={commentsEndRef} />
                </div>
                <div className="react-quill-container">
                    <ReactQuillBox 
                        handleCommentSubmit={handleAddComment} 
                        comment={comment} 
                        onCommentChange={handleCommentChange} 
                        attachment={attachment} 
                        setAttachment={setAttachment} 
                        attachmentType={attachmentType} 
                        setAttachmentType={setAttachmentType} 
                        onAttachmentSelect={(selectedAttachment, type) => {
                            setAttachment(selectedAttachment); 
                            setAttachmentType(type); 
                        }} 
                    />
                </div>
            </div>
        </Drawer>
    );
};

export default ActivityComment;
