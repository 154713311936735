import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Avatar,
  Tooltip,
  Badge,
  Popover,
  Button,
  Divider,
} from "antd";
import { GoSmiley } from "react-icons/go";
import { UserOutlined } from "@ant-design/icons";
import MainLogo from "../Images/Logo icon.png";
import LearnHub from "../Images/LearnHub.jpg";
import StarBoard from "../Images/StarBoard.jpg";
import DevCore from "../Images/DevCore.jpg";
import Finance from "../Images/Finance.jpg";
import Helpdesk from "../Images/HelpDesk2.jpg";
import TalentPulse from "../Images/TalentPulse.jpg";
import OnBoardHub from "../Images/OnBoardHub.jpg";
import AdSuite from "../Images/AdSuite.jpg";
import DemoDesk from "../Images/DemoDesk.jpg";
import Excellence from "../Images/Excellence.jpg";
import SalesHub from "../Images/SalesHub.jpg";
import Settings from "../Images/Setting.jpg";
import AccountCare from "../Images/AccountCare.jpg";
import ProductPro from "../Images/ProductPro.jpg";
import AnswerPro from "../Images/AnswerPro.jpg";
import Help from "../Images/Help.jpg";
import Engineering from "../Images/engineering.png";
import ClickToCall from "../Common Component/ClickToCall/clickToCall";
import Phone from "../Images/Phone.png"
import axios from "axios";

const Dashboard = () => {
  const navig = useNavigate();
  const location = useLocation();
  const [isClicked, setIsClicked] = useState(false);
  const [integration, setIntegration] = useState(false);
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(location.pathname);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    setSelectedTab(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const fetchIntegrations = async () => {
      const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
      if (userInfo) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_CONVERSATION}/integration/${userInfo.uniquePartnerId}/get-all-integration`
          );
          const servetelIntegration = response.data.find(
            (integration) =>
              integration.integrationId === "Servetel001" &&
              integration.integrationStatus === "active"
          );
          if (servetelIntegration) {
            setIntegration(true);
          }
        } catch (error) {
          console.error("Error fetching integrations:", error);
        }
      }
    };

    fetchIntegrations();
  }, []);

  const handlePopoverChange = () => {
    setPopoverOpen(!isPopoverOpen);
  };

  const handleSignOut = () => {
    localStorage.clear();
    navig("/login");
  };

  const handleNavigation = (e, path) => {
    e.preventDefault(); // Prevent default anchor behavior
    setIsClicked(true);
    navig(path); // Use react-router-dom's navigate to avoid full reload
  };

  const isActive = (path) => {
    // Exact match or prefix match
    return selectedTab === path || selectedTab.startsWith(path + "/");
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
      const userID = userInfo.email;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/filter/${userInfo?.uniquePartnerId}?query=${userID}`
        );
        if (response.data && response.data.length > 0) {
          setUserData(response.data[0]);
        } else {
          console.log("Not enough users in the response.");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchUserData();
  }, []);

  const hasResource = (resourceName) => {
    return userData?.resources?.some(
      (resource) => resource.name === resourceName && resource.enabled === true
    );
  };

  const handleOpenChange = () => {
    setOpen(!open);
  };

  return (
    <>
      <Row className="dexkor-main-page">
        <Col span={1} className="dexkor-main-side-nav-bar">
          {/* Top Icons Container */}
          <div className="dexkor-side-nav-bar-app-icons-top">
            {/* Main Logo */}
            <div className="dexkor-side-bar-main-icon-container">
              <img
                className="dexkor-side-nav-bar-main-icon"
                src={MainLogo}
                alt="Main Logo"
              />
            </div>

            {hasResource("SalesHub") && (
              <a
                href="/sales-hub"
                onClick={(e) =>
                  handleNavigation(e, "/sales-hub")
                }
                className={`dexkor-side-nav-bar-image-container ${
                  isActive("/sales-hub") ? "dexkor-main-nav" : ""
                }`}
              >
                <Tooltip placement="topLeft" title="SalesHub">
                  <img src={SalesHub} alt="SalesHub" className="hover-image" />
                </Tooltip>
              </a>
            )}

            {hasResource("HelpDesk") && (
              <a
                href="/help-desk"
                onClick={(e) => handleNavigation(e, "/help-desk")}
                className={`dexkor-side-nav-bar-image-container ${
                  isActive("/help-desk") ? "dexkor-main-nav" : ""
                }`}
              >
                <Tooltip placement="topLeft" title="HelpDesk">
                  <img src={Helpdesk} alt="HelpDesk" className="hover-image" />
                </Tooltip>
              </a>
            )}

            {hasResource("DevCore") && ( <a
              href="/dev-core/assigned-conversation"
              onClick={(e) => handleNavigation(e, "/dev-core/assigned-conversation")}
              className={`dexkor-side-nav-bar-image-container ${
                isActive("/dev-core/assigned-conversation") ? "dexkor-main-nav" : ""
              }`}
            >
              <Tooltip placement="topLeft" title="DevCore">
                <img
                  src={DevCore}
                  alt="DevCore"
                  className="hover-image"
                />
              </Tooltip>
            </a>
            )}

            {hasResource("AccountCare") && (
              <a
                href="/account-care"
                onClick={(e) => handleNavigation(e, "/account-care")}
                className={`dexkor-side-nav-bar-image-container ${
                  isActive("/account-care") ? "dexkor-main-nav" : ""
                }`}
              >
                <Tooltip placement="topLeft" title="Account Care">
                  <img
                    src={AccountCare}
                    alt="AccountCare"
                    className="hover-image"
                  />
                </Tooltip>
              </a>
            )}

            {/* Conditional Rendering of Top Icons */}
            {hasResource("AdSuite") && (
              <a
                href="/adsuite"
                onClick={(e) => handleNavigation(e, "/adsuite")}
                className={`dexkor-side-nav-bar-image-container ${
                  isActive("/adsuite") ? "dexkor-main-nav" : ""
                }`}
              >
                <Tooltip placement="topLeft" title="AdSuite">
                  <img src={AdSuite} alt="AdSuite" className="hover-image" />
                </Tooltip>
              </a>
            )}
            
            {hasResource("DemoDesk") && (
              <a
                href="/demo-desk"
                onClick={(e) => handleNavigation(e, "/demo-desk")}
                className={`dexkor-side-nav-bar-image-container ${
                  isActive("/demo-desk") ? "dexkor-main-nav" : ""
                }`}
              >
                <Tooltip placement="topLeft" title="DemoDesk">
                  <img src={DemoDesk} alt="DemoDesk" className="hover-image" />
                </Tooltip>
              </a>
            )}
            {hasResource("OnBoardHub") && (
              <a
                href="/onboard-hub"
                onClick={(e) => handleNavigation(e, "/onboard-hub")}
                className={`dexkor-side-nav-bar-image-container ${
                  isActive("/onboard-hub") ? "dexkor-main-nav" : ""
                }`}
              >
                <Tooltip placement="topLeft" title="OnBoardHub">
                  <img
                    src={OnBoardHub}
                    alt="OnBoardHub"
                    className="hover-image"
                  />
                </Tooltip>
              </a>
            )}
            
            
            {hasResource("ProductPro") && (
              <a
                href="/product-pro"
                onClick={(e) => handleNavigation(e, "/product-pro")}
                className={`dexkor-side-nav-bar-image-container ${
                  isActive("/product-pro") ? "dexkor-main-nav" : ""
                }`}
              >
                <Tooltip placement="topLeft" title="Product Pro">
                  <img
                    src={ProductPro}
                    alt="ProductPro"
                    className="hover-image"
                  />
                </Tooltip>
              </a>
            )}
            {/* {hasResource("DevCore") && (
              <a
                href="/devcore"
                onClick={(e) => handleNavigation(e, "/devcore")}
                className={`dexkor-side-nav-bar-image-container ${
                  isActive("/devcore") ? "dexkor-main-nav" : ""
                }`}
              >
                <Tooltip placement="topLeft" title="DevCore">
                  <img src={DevCore} alt="DevCore" className="hover-image" />
                </Tooltip>
              </a>
            )} */}
            {hasResource("Excellence") && (
              <a
                href="/excellence"
                onClick={(e) => handleNavigation(e, "/excellence")}
                className={`dexkor-side-nav-bar-image-container ${
                  isActive("/excellence") ? "dexkor-main-nav" : ""
                }`}
              >
                <Tooltip placement="topLeft" title="Excellence">
                  <img
                    src={Excellence}
                    alt="Excellence"
                    className="hover-image"
                  />
                </Tooltip>
              </a>
            )}
            {hasResource("AnswerPro") && (
              <a
                href="/answer-pro"
                onClick={(e) => handleNavigation(e, "/answer-pro")}
                className={`dexkor-side-nav-bar-image-container ${
                  isActive("/answer-pro") ? "dexkor-main-nav" : ""
                }`}
              >
                <Tooltip placement="topLeft" title="Answer Pro">
                  <img
                    src={AnswerPro}
                    alt="AnswerPro"
                    className="hover-image"
                  />
                </Tooltip>
              </a>
            )}
            {hasResource("LearnHub") && (
              <a
                href="/learn-hub"
                onClick={(e) => handleNavigation(e, "/learn-hub")}
                className={`dexkor-side-nav-bar-image-container ${
                  isActive("/learn-hub") ? "dexkor-main-nav" : ""
                }`}
              >
                <Tooltip placement="topLeft" title="LearnHub">
                  <img src={LearnHub} alt="LearnHub" className="hover-image" />
                </Tooltip>
              </a>
            )}
            {hasResource("StarBoard") && (
              <a
                href="/star-board"
                onClick={(e) => handleNavigation(e, "/star-board")}
                className={`dexkor-side-nav-bar-image-container ${
                  isActive("/star-board") ? "dexkor-main-nav" : ""
                }`}
              >
                <Tooltip placement="topLeft" title="StarBoard">
                  <img
                    src={StarBoard}
                    alt="StarBoard"
                    className="hover-image"
                  />
                </Tooltip>
              </a>
            )}
            {hasResource("TalentPulse") && (
              <a
                href="/talent-pulse"
                onClick={(e) => handleNavigation(e, "/talent-pulse")}
                className={`dexkor-side-nav-bar-image-container ${
                  isActive("/talent-pulse") ? "dexkor-main-nav" : ""
                }`}
              >
                <Tooltip placement="topLeft" title="Talent Pulse">
                  <img
                    src={TalentPulse}
                    alt="TalentPulse"
                    className="hover-image"
                  />
                </Tooltip>
              </a>
            )}
          </div>

          {/* Bottom Icons Container */}
          <div className="dexkor-side-nav-bar-app-icons-bottom">
            {integration && (
             <div onClick={handlePopoverChange} style={{cursor: "pointer", paddingBottom: '5px'}}>
              <Tooltip placement="topLeft" title="Click To Call">
                <img src={Phone} alt="Click TO Call" className="hover-image" />
              </Tooltip>
              <ClickToCall isPopoverOpen={isPopoverOpen} setPopoverOpen={setPopoverOpen} />
</div> 
            )}
            <a
              href="/setting"
              onClick={(e) => handleNavigation(e, "/setting")}
              className={`dexkor-side-nav-bar-image-container ${
                isActive("/setting") ? "dexkor-main-nav" : ""
              }`}
            >
              <Tooltip placement="topLeft" title="Set-up">
                <img src={Settings} alt="Settings" className="hover-image" />
              </Tooltip>
            </a>
            <a
              href="/finance"
              onClick={(e) => handleNavigation(e, "/finance")}
              className={`dexkor-side-nav-bar-image-container ${
                isActive("/finance") ? "dexkor-main-nav" : ""
              }`}
            >
              <Tooltip placement="topLeft" title="Finance">
                <img src={Finance} alt="Finance" className="hover-image" />
              </Tooltip>
            </a>
            <a
              href="/help"
              onClick={(e) => handleNavigation(e, "/help")}
              className={`dexkor-side-nav-bar-image-container ${
                isActive("/help") ? "dexkor-main-nav" : ""
              }`}
            >
              <Tooltip placement="topLeft" title="Help">
                <img src={Help} alt="Help" className="hover-image" />
              </Tooltip>
            </a>
            <div className="cursor-pointer dexkor-side-nav-bar-image-container">
              <Popover
                placement="rightTop"
                title={
                  <div className="flex">
                    <div>
                      <Avatar
                        shape="square"
                        style={{ width: "50px", height: "50px" }}
                      >
                        {userData?.image}
                      </Avatar>
                    </div>
                    <div className="ml-2">
                      <div className="text-lg">{userData?.name}</div>
                      <div>
                        {userData?.available === true ? (
                          <div className="items-center gap-5">
                            <span>
                              <Badge status="success" />
                            </span>{" "}
                            <span>Active</span>{" "}
                          </div>
                        ) : (
                          "Away"
                        )}
                      </div>
                    </div>
                  </div>
                }
                content={
                  <div className="text-lg">
                    <div className="px-2 py-2">
                      {" "}
                      <Button style={{ width: "95%", marginTop: "3px" }}>
                        {" "}
                        <GoSmiley /> Update your Status
                      </Button>
                    </div>
                    <div className="dexkor-main-page-profile-popup">
                      {" "}
                      Set yourself as{" "}
                      <span style={{ fontWeight: "bold" }}>Away</span>
                    </div>
                    <Divider style={{ margin: "10px 0" }} />
                    <div className="dexkor-main-page-profile-popup">
                      {" "}
                      Profile
                    </div>
                    <Divider style={{ margin: "10px 0" }} />
                    <div
                      className="dexkor-main-page-profile-popup"
                      onClick={handleSignOut}
                    >
                      Sign Out
                    </div>
                  </div>
                }
                open={open}
                trigger="click"
                onOpenChange={handleOpenChange}
                overlayClassName="custom-popover-bg"
                overlayStyle={{ width: "300px" }}
              >
                <Badge
                  dot
                  status="success"
                  style={{ width: "10px", height: "10px" }}
                >
                  <Avatar shape="square">
                    {userData?.image || userData?.name?.[0]}
                  </Avatar>
                </Badge>
              </Popover>
            </div>
          </div>
        </Col>
        <Col span={23} className="dexkor-main-screen">
          <Outlet />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
