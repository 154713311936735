import React, { useState, useRef } from "react";
import axios from "axios";
import "./UpdateSubscription.css";
import { saveAs } from "file-saver";
import { Button } from "antd";
import Papa from "papaparse";
import useNotification from "../../../components/snackbars/SnackBar";
import Spinner from "../../../components/Full Page Spinner/fullpagespinner";

const UpdateSubscription = () => {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [file, setFile] = useState(null);
  const [conf, setConf] = useNotification();
  const [importModal, setImportModal] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const selected = event.target.files[0];
    setFile(selected);
    setSelectedFile(selected);

    // Reset the file input field so the user can select the same file again
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setFile(null);

    // Reset the file input field so the user can select the same file again
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setFile(droppedFile);
    setSelectedFile(droppedFile);

    // Reset the file input field
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
        setConf({ msg: "Please upload a CSV file", variant: "warning" });
        return;
    }

    setLoading(true);

    Papa.parse(file, {
        header: true,
        complete: async (results) => {
            const records = results.data;
            const successRecords = [];
            const errorRecords = [];

            const processRecord = async (record) => {
                const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
                const CompanyId = record.customerId;

                // Initialize subscriptionDetails
                const subscriptionDetails = {};
                // Check for mandatory customerCode
                if (!record.customerCode) {
                    errorRecords.push({ ...record, error: "customerCode is mandatory." });
                    return; // Skip processing this record
                }
                subscriptionDetails.customerCode = record.customerCode;

                // Add other fields to subscriptionDetails only if they have values
                if (record.endDate) {
                    subscriptionDetails.subscriptionEndDate = record.endDate;
                }
                if (record.lastRenewalDate) {
                    subscriptionDetails.lastRenewalDate = record.lastRenewalDate;
                }
                if (record.planName) {
                    subscriptionDetails.planName = record.planName;
                }
                if (record.planType) {
                    subscriptionDetails.planType = record.planType;
                }

                // Default subscriptionStatus to "Active" if not provided
                subscriptionDetails.subscriptionStatus = record.status || "Active";
                if (record.usedConsumption) {
                    subscriptionDetails.totalConsumption = record.usedConsumption;
                }
                if (record.totalConsumption) {
                    subscriptionDetails.allowedConsumption = record.totalConsumption;
                }

                try {
                    await axios.put(
                        `${process.env.REACT_APP_CONVERSATION}/company/${record.customerCode}/${userData.uniquePartnerId}/updateSubscription`,
                        subscriptionDetails
                    );
                    // Add to success records
                    successRecords.push(record);
                } catch (apiError) {
                    // Include error details for better debugging
                    record.errorMessage = apiError.response && apiError.response.data
                    ? apiError.response.data.message // Capture error.response.message here
                    : apiError.message || "Unknown Error";
                    // Add the modified record to error records
                    errorRecords.push(record);
                }
            };

            for (const record of records) {
                await processRecord(record);
            }

            // Check if there are any error records
            if (errorRecords.length > 0) {
                setConf({
                    msg: `Bulk update completed with ${errorRecords.length} error(s).`,
                    variant: "error",
                });
                setFile(null);
                setSelectedFile(null);
                const csvData = Papa.unparse(errorRecords, {
                  header: true,
                });
        
                // Create a Blob from the CSV string and trigger a download
                const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
                saveAs(blob, "errored_records.csv");
            } else {
                setConf({
                    msg: "Bulk Subscription Updated Successfully",
                    variant: "success",
                });
                setFile(null);
                setSelectedFile(null);
            }

            setLoading(false);
        },
        error: (parseError) => {
            setConf({ msg: "Error Parsing CSV File", variant: "error" });
            setLoading(false);
        },
    });
};


  return (
    <div>
      {loading ? <Spinner /> : <>
      <div className="dexkor-sales-hub-main-screen-left-nav-bar-header">
        <span className="cursor-pointer">Update Subscription</span>
      </div>
      <div className="px-10 py-10">
        <div className="flex items-center justify-center w-full"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <label
            htmlFor="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag
                and drop
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                Please upload only CSV file
              </p>
            </div>
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              onChange={handleFileChange}
              ref={fileInputRef}
              accept=".csv" // Ensure only CSV files are uploaded
            />
          </label>
        </div>

        <div className="py-5">
          <p>
            <span style={{ fontWeight: "bold" }}>Note:</span> Please make sure
            to use the{" "}
            <span>
              {" "}
              <a
              href="https://drive.google.com/uc?export=download&id=1eJPX_sy6J6eu_jcisn7rtHL1b0prpk-C"
                download="default.csv" // File name when downloaded
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Default
              </a>
            </span>{" "}
            CSV structure
          </p>
          <p>2. Below are the Mandatory Field needs to be included in the CSV file</p>
              <div className="pl-4">
              <p>(a): Customer Code should be Unique and Mandatory</p>
              <p>(b): Subscription Duration </p>
              <p>(c): Last Renewal Date</p>
              <p>(d): Format of all date should be DD-MM-YYYY </p>
              </div>
        </div>

        {/* Conditionally show the uploaded file name */}
        {selectedFile && (
          <div className="mt-4">
            <p>
              <strong>Attached File:</strong> {selectedFile.name}
            </p>
          </div>
        )}
      </div>
      <div className="justify-center flex">
        <Button
          size="large"
          style={{ backgroundColor: "#2A4DAD", color: "#fff" }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          size="large"
          style={{ backgroundColor: "#2A4DAD", color: "#fff" }}
          onClick={handleSubmit}
        >
          Import
        </Button>
      </div>
      </>
}
    </div>
  );
};

export default UpdateSubscription;
