import React, { useState, useEffect } from 'react';
import "./NavBar.css"
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { Menu, Row, Col } from 'antd';
import { useSelector } from "react-redux";

const AccountCare = () => {
  const [selectedKey, setSelectedKey] = useState('0');
  const [closedTicketCount, setClosedTicketCount] = useState("0");
  const [unassignedTicketCount, setUnassignedTicketCount] = useState("0");
  const [myOpenTickets, setMyOpenTickets] = useState("0");
  const [openKeys, setOpenKeys] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const ticketData = useSelector((state) => state.tickets.ticketData);

  const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"))
  const userRoles = userInfo.role

  const items = [
    // { key: '1', label: 'Unassigned Customer', path: '/account-care/unassigned-customer'},
    { key: '2', label: 'Assigned Customer',
      children: [
        { key: '21', label: 'My Customers', path: '/account-care/assigned-customer' },
        // { key: '22', label: 'All Customers' },
        // { key: '23', label: 'Recorded Calls' },
        // { key: '24', label: 'All Calls' }
      ]
    },
    { key: '3', label: 'Task', children: [
      { key: '31', label: 'My Task', path: '/account-care/assigned-task' },
      { key: '32', label: 'All Task', path: '/account-care/all-task', roles: ["Admin", "Super Admin"] },
      { key: '33', label: 'Renewal Pending', path: '/account-care/renewal-pending', roles: ["Admin", "Super Admin"] },
      { key: '34', label: 'Feedback Calling', path: '/account-care/feedback-calling', roles: ["Admin", "Super Admin"] }
    ]},
    // { key: '3', label: 'All Details',
    //   children: [
    //     { key: '31', label: 'Tasks', },
    //     { key: '32', label: 'Call Scheduler' },
    //     { key: '33', label: 'Email' },
    //     { key: '34', label: 'Document Sent' },
    //     { key: '35', label: 'Customer Email' },
    //     { key: '36', label: 'Follow up' },
    //     { key: '37', label: 'WhatsApp' },
    //     { key: '38', label: 'Calling' }
    //   ]
    // },
    // { key: '4', label: 'Closed Chats', number: closedTicketCount },
    // { key: '5', label: 'Unassigned Chats', number: unassignedTicketCount },
    // { key: '6', label: 'Transferred Tickets', number: myOpenTickets },
    // {
    //   key: '7',
    //   label: 'Internal Team Queue',
    //   children: [
    //     { key: '71', label: 'All Pending' },
    //     { key: '72', label: 'SLA Breached' },
    //     { key: '73', label: "Approaching SLA Breach" }
    //   ]
    // },
    // {
    //   key: '8',
    //   label: 'Pending Queue',
    //   children: [
    //     { key: '81', label: 'Pending Support' },
    //     { key: '82', label: 'Pending Product' },
    //     { key: '83', label: "Pending Engineering" },
    //     { key: '84', label: "Pending Finance" },
    //   ]
    // },
    // {
    //   key: '9',
    //   label: 'Custom View'
    // }
  ];

  useEffect(() => {
    if (ticketData) {
      const closedTickets = ticketData.filter(item => item.ticketId?.status === "closed");
      setClosedTicketCount(closedTickets.length);

      const unassignedTicket = ticketData.filter(item => item.userId == null);
      setUnassignedTicketCount(unassignedTicket.length);

      const openTickets = ticketData.filter(item => (item.ticketId.status !== 'closed' && item.userId === JSON.parse(localStorage.getItem("dexkorUserData"))?._id));
      setMyOpenTickets(openTickets.length);
    }
  }, [ticketData]);

  const filterMenuByRole = (menuItems) => {
    return menuItems
      .filter(item => {
        if (!item.roles || item.roles.some(role => userRoles.includes(role))) {
          if (item.children) {
            item.children = filterMenuByRole(item.children); // Recursively filter child items
          }
          return true; // Allow item if role matches or no roles are defined
        }
        return false; // Hide item if role does not match
      });
  };

  const filteredItems = filterMenuByRole(items);

  const getMenuItems = (menuItems) =>
    menuItems.map(item => ({
      key: item.key,
      label: (
        <div
          onClick={(e) => {
            if (item.path && !e.target.classList.contains('ant-menu-submenu-arrow')) {
              navigate(item.path);
            }
          }}
          className='dexkor-sales-hub-menu-item'
        >
          {item.label}
        </div>
      ),
      children: item.children ? getMenuItems(item.children) : null,
    }));

  const handleMenuClick = (e) => {
    const selectedItem = items.flatMap(item => item.children || [item]).find(item => item.key === e.key);
    if (selectedItem && selectedItem.path) {
      navigate(selectedItem.path);
    }
      setSelectedKey(e.key);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const currentItem = items.flatMap(item => item.children || item).find(item => item.path === currentPath);
    if (currentItem) {
      setSelectedKey(currentItem.key);
      const parentKey = getParentKey(currentItem.key, items);
      if (parentKey) {
        setOpenKeys([parentKey]);
      }
    }
  }, [location]);

  const onOpenChange = (keys) => {
    setOpenKeys(keys);

  };
  

  const getParentKey = (childKey, menuItems) => {
    for (let item of menuItems) {
      if (item.children) {
        for (let child of item.children) {
          if (child.key === childKey) {
            return item.key;
          }
        }
      }
    }
    return null;
  };

  return (
    <>
      <Row className='dexkor-account-care-body'>
        <Col span={3}>
          <div className='dexkor-account-care-left-nav-bar-header'>
            <p className='dexkor-account-care-left-nav-bar-header-text'>
              Account Care
            </p>
          </div>

          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
            onOpenChange={onOpenChange}
            className='dexkor-account-care-nav-bar-menu-list'
            items={getMenuItems(items)}
            openKeys={openKeys}
          />
        </Col>
        <Col span={21} className="dexkor-account-care-main-screen">
          <Outlet />
        </Col>
      </Row>
    </>
  );
};

export default AccountCare;
