import React from "react";
import { Route } from "react-router-dom";
import LoginSignup from "../Login";
import Otp from "../otp";
import PrivateRoute from "../../Private Route/PrivateRoute";
import ForgotPassword from "../forgotpassword";

const LoginRoutes = () => {
  return (
    <>
    <Route path="/login" element={<LoginSignup />} />
    <Route path="/recover-password" element={<ForgotPassword />} />
      <Route path="/otp-verify" element={<Otp />} />
    </>
  );
};

export default LoginRoutes;
