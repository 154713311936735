import React, { useEffect, useState } from "react";
import axios from "axios";
import "./MainContent.css";
import Spinner from "../../components/Spinner/spinner";
import io from "socket.io-client";
import logo from "../../Images/Logo icon.png";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Modal,
  Input,
  List,
  Avatar,
  Spin,
  Tabs,
  theme,
  Divider,
  Button,
  Collapse,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";

import CompanyDetails from "../All Pages/Company/CompanyDetails";
import Comment from "../All Pages/Comment/Comment";
import AllTickets from "../All Pages/All Ticket/AllTicket";
import Documents from "../All Pages/Documents/Documents";
import Contacts from "../All Pages/Contact/Contacts";
import Task from "../All Pages/All Task/AllTask";
import CompanyDetailsCopy from "../All Pages/Company copy/CompanyDetails";
import CustomerList from "../Components/Customer List/customerList";
import StatusChange from "../Components/Modal/Status Change/statusChange";
const { TabPane } = Tabs;

const socket = io(`${process.env.REACT_APP_CHAT_SERVER}`);

const AssignedCustomer = ({ item }) => {
  const [activeKey, setActiveKey] = useState(null);
  const [allCompanies, setAllCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusChange, setStatusChange] = useState(false);
  const [ticketData, setTicketData] = useState([]);
  const [statusFlag, setStatusFlag] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeCompanyID, setActiveCompanyID] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [userData, setUserData] = useState([]);
  const [companyTabState, setCompanyTabState] = useState({});
  const [selectedValue, setSelectedValue] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [pocDetails, setPocDetails] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      location: "",
      designation: "",
      userType: "",
    },
  ]);
  const navigate = useNavigate();
  const { Panel } = Collapse;
  const { token } = theme.useToken();
  const { companyID } = useParams();

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("dexkorUserData")));
  }, []);

  const deletePoc = (index) => {
    const updatedPocDetails = pocDetails.filter((_, i) => i !== index);
    setPocDetails(updatedPocDetails);
  };

  const addPoc = () => {
    setPocDetails([
      ...pocDetails,
      {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        location: "",
        designation: "",
        userType: "",
      },
    ]);
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
    const getAllConversation = async () => {
      try {
        setLoading(true);
        const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
        const response = await axios.get(
          `${process.env.REACT_APP_CONVERSATION}/conversations/all/${userData.uniquePartnerId}`
        );
        setLoading(false);
        setTicketData(response.data);
      } catch (error) {
        setLoading(false); // Handle errors properly
        console.error(error); // Log error for debugging
      }
    };

    getAllConversation();
  }, []);

  const onCompanyTabChange = (key) => {
    if (selectedCompany) {
      setCompanyTabState((prevState) => ({
        ...prevState,
        [selectedCompany.companyID]: key, // Save the active tab for the selected company
      }));
    }
  };

  const companyTabitems = [
    {
      key: "1",
      label: "Notes",
      children: <Comment selectedCompany={selectedCompany} />,
    },
    {
      key: "2",
      label: "Tickets",
      children: (
        <AllTickets
          ticketData={ticketData}
          selectedCompany={selectedCompany}
          setTicketData={setTicketData}
        />
      ),
    },

    // {
    //   key: "3",
    //   label: "Tasks",
    //   children: <Task selectedCompany={selectedCompany} allCompanies={allCompanies} />,
    // },
    // {
    //   key: "4",
    //   label: "Documents",
    //   children: <Documents selectedCompany={selectedCompany} />,
    // },
    // {
    //   key: "5",
    //   label: "Contacts",
    //   children: <Contacts selectedCompany={selectedCompany}/>,
    // },
    {
      key: "5",
      label: "Contact",
      children: <CompanyDetails selectedCompany={selectedCompany} />,
    },
    {
      key: "6",
      label: "Customer Details",
      children: <CompanyDetailsCopy selectedCompany={selectedCompany} />,
    },
    // {
    //   key: "",
    //   label: "Invoices",

    //   children: <Invoices selectedCompany={selectedCompany}/>,
    // },
  ];

  const onTabEdit = (targetKey, action) => {
    if (action === "remove") {
      removeTab(targetKey);
    }
  };

  const removeTab = (targetKey) => {
    const newTabs = tabs.filter((tab) => tab.key !== targetKey);
    const company = allCompanies.find(
      (company) => company.companyID === targetKey
    );
    const targetIndex = tabs.findIndex((tab) => tab.key === targetKey);
    if (newTabs.length > 0) {
      let newActiveKey;
      let newActiveCompany;
      if (targetIndex > 0) {
        newActiveKey = newTabs[targetIndex - 1].key;
        newActiveCompany = allCompanies.find(
          (company) => company.companyID === newActiveKey
        );
      } else {
        newActiveKey = newTabs[0].key;
        newActiveCompany = allCompanies.find(
          (company) => company.companyID === newActiveKey
        );
      }
      setActiveKey(newActiveKey);
      if (newActiveCompany) {
        navigate(`/account-care/assigned-customer/${newActiveCompany._id}`);
      }
    } else {
      setActiveKey(null);
      navigate(`/account-care/assigned-customer`);
    }
    setTabs(newTabs);
  };

  const onTabChange = (key) => {
    setActiveKey(key);
    const company = allCompanies.find((tab) => tab.companyID === key);
    if (company) {
      setSelectedCompany(company);
      setActiveCompanyID(key);
      navigate(`/account-care/assigned-customer/${company._id}`);
    }
  };

  const handleStatusChange = (company) => {
    setStatusChange(true);
  };

  const onTicketClick = (company) => {
    setActiveCompanyID(company.companyID);
    const existingTab = tabs.find((tab) => tab.key === company.companyID);

    if (existingTab) {
      setActiveKey(existingTab.key);
    } else {
      const newTab = {
        key: company.companyID,
        title: company.companyID,
      };
      const newTabs = [...tabs, newTab];
      setTabs(newTabs);
      setActiveKey(newTab.key);
    }

    setSelectedCompany(company);
    navigate(`/account-care/assigned-customer/${company._id}`);
  };

  useEffect(() => {
    if (companyID) {
      const company = allCompanies.find((tab) => tab._id === companyID);
      if (company) {
        setActiveKey(company.companyID);
        setSelectedCompany(company);
        setActiveCompanyID(company.companyID);

        // Add the tab if it doesn't exist yet
        const existingTab = tabs.find((tab) => tab.key === company.companyID);
        if (!existingTab) {
          const newTab = {
            key: company.companyID,
            title: company.companyID,
          };
          setTabs((prevTabs) => [...prevTabs, newTab]);
        }
      }
    }
  }, [companyID, allCompanies, tabs]);

  const handleSearchChange = (e) =>
    setSearchQuery(e.target.value.toLowerCase());

  const clearFormDetails = () => {
    setPocDetails([
      {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        location: "",
        designation: "",
        userType: "",
      },
    ]);
  };

  const handleInputPOCChange = (index, field) => (e) => {
    const updatedPocDetails = [...pocDetails];
    updatedPocDetails[index][field] = e.target.value;
    setPocDetails(updatedPocDetails);
  };

  return (
    <>
      <Row className="dexkor-sales-hub-main-screen-body">
        <Col span={5} className="dexkor-sales-hub-main-screen-left-nav-bar">
          <div className="dexkor-sales-hub-main-screen-left-nav-bar-header"></div>

          <div className="dexkor-sales-hub-search-bar">
            <Input
              className="dexkor-sales-hub-search-bar-input"
              placeholder="Search For Customer"
              prefix={<SearchOutlined />}
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>

          <div className="dexkor-sales-hub-company-list-container">
            {userData && (
              <CustomerList
                statusFlag={statusFlag}
                setStatusFlag={setStatusFlag}
                activeCompanyID={activeCompanyID}
                onTicketClick={onTicketClick}
                handleStatusChange={handleStatusChange}
                moduleId={"accountcare001"}
                filterType={"assigned"}
                assigned={userData._id}
                searchQuery={searchQuery}
                setAllCompanies={setAllCompanies}
              />
            )}
          </div>
        </Col>

        <Col span={19} className="dexkor-sales-hub-main-page-right-section">
          <div className="dexkor-sales-hub-main-page-right-section-tab-container">
            <Tabs
              className="dexkor-sales-hub-main-page-right-section-tabs"
              type="editable-card"
              hideAdd
              activeKey={activeKey}
              onChange={onTabChange}
              onEdit={onTabEdit}
              tabBarGutter={0}
            >
              {tabs.map((tab) => (
                <TabPane
                  tab={
                    <div
                      className={`dexkor-sales-hub-main-page-right-section-title-tab ${
                        activeKey === tab.key ? "active" : ""
                      }`}
                    >
                      <span>{tab.title}</span>
                    </div>
                  }
                  key={tab.key}
                  closeIcon={
                    <CloseOutlined
                      className={`dexkor-sales-hub-main-page-right-section-title-tab ${
                        activeKey === tab.key ? "active" : ""
                      }`}
                    />
                  }
                />
              ))}
            </Tabs>
          </div>
          <div className="dexkor-sales-hub-main-page-right-section-company-details">
            {activeKey === null ? null : (
              <span>
                <Tabs
                  className="tabs-with-divider"
                  tabBarGutter={0}
                  defaultActiveKey={
                    companyTabState[selectedCompany.companyID] || "1"
                  }
                  onChange={onCompanyTabChange}
                  style={{
                    fontFamily: "roboto",
                    fontSize: "16px",
                    height: "calc(100% - 56px)",
                  }}
                  items={companyTabitems}
                />
              </span>
            )}
          </div>
        </Col>
      </Row>
      <StatusChange
        moduleId={"accountcare001"}
        selectedCompany={selectedCompany}
        setStatusChangeFlag={setStatusFlag}
        setStatusChange={setStatusChange}
        statusChange={statusChange}
      />
    </>
  );
};

export default AssignedCustomer;
