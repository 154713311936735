import React from 'react';
import logo from "../../Images/Logo icon.png";
import './fullPageSpinner.css'; // We'll style the spinner separately

const Spinner = () => {
    return (
        <div className="spinner-container">
            <div className="spinner-wrapper">
                <img src={logo} alt="Loading..." className="logo-spinner" />
                <div className="circle-spinner"></div>
            </div>
        </div>
    );
};

export default Spinner;
