import React, { useEffect, useState } from 'react'
import "./Contact.css"
import logo from "../../../Images/Logo icon.png"
import axios from 'axios'
import { IoCloudDownloadOutline } from "react-icons/io5";
import { RiDeleteBinLine } from "react-icons/ri";
import { Row, Col, Flex, message, Upload, Button, Card, Space, Table, Tag, Input,  Select, Badge, Progress, Modal } from 'antd'
import { LoadingOutlined, PlusOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { CiLocationOn, CiEdit   } from "react-icons/ci";
import { FaPhoneAlt, FaPlus } from "react-icons/fa";

const { TextArea } = Input;

const Contacts = ({ selectedCompany }) => {
    const [loading, setLoading] = useState(false);
    const [filteredPOCs, setFilteredPOCs] = useState([])
    const [userData, setUserData] = useState([])
    const [newPoc, setNewPoc] = useState({
        firstName: "",
    lastName: "",
    email: "",
    phone: "",
    location: "",
    designation: "",
    userType: "",
      });
    const [contactModal, setContactModal] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [data, setData] = useState([])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewPoc({ ...newPoc, [name]: value });
      };

    useEffect(() => {
        setUserData(JSON.parse(localStorage.getItem("dexkorUserData")));
      }, []);

    useEffect(() => {
        const fetchPOCs = async () => {
            try {
              const response = await axios.get(
                `${process.env.REACT_APP_CONVERSATION}/company/${selectedCompany.companyID}`
              );
              const pocDetails = response.data.pocDetails || {};
              
              // If pocDetails contains the necessary fields, map them correctly
              setFilteredPOCs(pocDetails);
              setNewPoc({
                firstName: pocDetails.firstName || "",
                lastName: pocDetails.lastName || "",
                email: pocDetails.email || "",
                phone: pocDetails.phone || "",
                location: pocDetails.location || "",
                designation: pocDetails.designation || "",
                userType: pocDetails.userType || "",
              });
            } catch (error) {
              console.error("Error fetching documents:", error);
            }
          };
          
          fetchPOCs(); // Call the async function
      }, [selectedCompany?.companyID]);

      const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
      };

      const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
      };

    
  
    const columns = [
        {
          title: 'First Name',
          dataIndex: 'firstName',
          key: 'title',
        },
        {
          title: 'Last Name',
          dataIndex: 'lastName',
          key: 'status',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'status',
          },
          {
            title: 'Location',
            dataIndex: 'location',
            key: 'status',
          },
          {
            title: 'Destination',
            dataIndex: 'destination',
            key: 'status',
          },
          {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'status',
          },
        // {
        //     title: 'Documents',
        //     key: 'status',
        //     render: (text,record) => (
        //         <Progress percent={50} status="active" />
        //     )
        //   },
          {
            title: 'Action',
            key: 'action',
            
            render: (text, record) => (
                <>
              <Button
                type="primary"
                style={{
                  backgroundColor: '#2A4DAD',
                  borderRadius: '15px',
                }}
              >
                Manage
              </Button>
               
             </>
            ),
          }
    ]
  

    const handleOk = () => {
        setContactModal(false);
       
    };
    
    const handleCancel = () => {
        setContactModal(false);
      
    };
 
    const handleAddPOC = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_CONVERSATION}/company/${selectedCompany.companyID}/addPoc`,
            newPoc
          );
          setFilteredPOCs([...filteredPOCs, response.data.pocDetails]);
          setNewPoc({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            location: "",
            designation: "",
            userType: "",
          });
        setContactModal(false)
        } catch (error) {
          console.error("Error adding POC:", error);
        }
      };
    
      
      
    return (
        <div className='dexkor-sales-hub-task-body' >
   <div className='dexkor-sales-hub-document-search-bar'>
    <span> <h2>Search For Contacts</h2> <Input prefix={<SearchOutlined />} className="dexkor-sales-hub-task-search-bar-input" placeholder="Search..." /></span>
    <span style={{padding: '20px'}}>
        <Button type='primary' size='large' onClick={() => setContactModal(true)} style={{backgroundColor: '#2A4DAD'}}>Add Contact</Button>
    </span>
   </div>
   <div className='dexkor-sales-hub-task-table'>
   <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={Array.isArray(filteredPOCs) ? filteredPOCs : []}
          loading={loading}
        />
   </div>
   <Modal
        open={contactModal}
        width={600}
        title="Add Contact"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => handleAddPOC()}>
            Submit
          </Button>,
         
        ]}
      >
       <>

<span style={{ display: 'flex', gap: '20px', alignItems: 'center', paddingTop: '20px' }}>
  <h2>First Name:</h2>
  <Input
    style={{ marginLeft: '45px', marginRight: '17px' }}
    name="firstName" 
    onChange={handleInputChange} 
  />
</span>
<span style={{ display: 'flex', gap: '20px', alignItems: 'center', paddingTop: '20px' }}>
  <h2>Last Name:</h2>
  <Input
    style={{ marginLeft: '45px', marginRight: '17px' }}
    name="lastName" 
    onChange={handleInputChange} 
  />
</span>
<span style={{ display: 'flex', gap: '20px', alignItems: 'center', paddingTop: '20px' }}>
  <h2>Email:</h2>
  <Input
    style={{ marginLeft: '45px', marginRight: '17px' }}
    name="email" 
    onChange={handleInputChange} 
  />
</span>
<span style={{ display: 'flex', gap: '20px', alignItems: 'center', paddingTop: '20px' }}>
  <h2>Phone:</h2>
  <Input
    style={{ marginLeft: '45px', marginRight: '17px' }}
    name="phone" 
    onChange={handleInputChange} 
  />
</span>
<span style={{ display: 'flex', gap: '20px', alignItems: 'center', paddingTop: '20px' }}>
  <h2>Location:</h2>
  <Input
    style={{ marginLeft: '45px', marginRight: '17px' }}
    name="location" 
    onChange={handleInputChange} 
  />
</span>
<span style={{ display: 'flex', gap: '20px', alignItems: 'center', paddingTop: '20px' }}>
  <h2>Designation:</h2>
  <Input
    style={{ marginLeft: '45px', marginRight: '17px' }}
    name="designation" 
    onChange={handleInputChange} 
  />
</span>
<span style={{ display: 'flex', gap: '20px', alignItems: 'center', paddingTop: '20px' }}>
  <h2>User Type:</h2>
  <Input
    style={{ marginLeft: '45px', marginRight: '17px' }}
    name="userType" 
    onChange={handleInputChange} 
  />
</span>
       </>
      </Modal>
</div>
    )
}

export default Contacts;
