import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Spinner from "../../../../components/Spinner/spinner";
import "./Tickets.css";
import { useDispatch } from "react-redux";
import io from "socket.io-client";
import logo from "../../../../Images/Logo icon.png";
import googleLogo from "../../../../Images/google-logo.png";
import { RiChatNewFill, RiAlarmLine, RiFilter3Fill } from "react-icons/ri";
import { PaperClipIcon, MicrophoneIcon } from "@heroicons/react/24/outline";
import {
  Menu,
  Input,
  Avatar,
  List,
  Tabs,
  Row,
  Cascader,
  Col,
  Divider,
  Button,
  Tooltip,
} from "antd";
import { SearchOutlined, SendOutlined, CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles for the editor
import { setTickets } from "../../../../Redux/Slice/userSlice";
import Conversation from "../../../../components/Chat Conversation/Conversation";
import MessageDisplay from "../../../components/MessageDisplay";
import DateTimePicker from "../../../../components/DateTimePicker/DateTimePicker";
import useNotification from "../../../../components/snackbars/SnackBar";
import SLADetails from "../../../components/SLADetails";
import TicketDetails from "../../../components/TicketDetails";
import TicketQueue from "../../../components/TicketQueue";
import TicketForm from "../../../components/TicketForm/TicketForm";
import TicketNavBar from "../../../components/TicketNavBar";

const socket = io(`${process.env.REACT_APP_CHAT_SERVER}`);

const Tickets = () => {
  const [activeKey, setActiveKey] = useState(null); // Track the active tab key
  const [snoozeDateTime, setSnoozeDateTime] = useState(null);
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [conf, setConf] = useNotification();
  const [data, setData] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeRoomID, setActiveRoomID] = useState(null);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [attachmentType, setAttachmentType] = useState("");
  const [transcribing, setTranscribing] = useState(false);
  const recognitionRef = useRef(null);
  const [conversations, setConversations] = useState([]);
  const [myConversations, setMyConversations] = useState([]);
  const [myTicket, setMyTicket] = useState([]);
  const [users, setUsers] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [assignee, setAssignee] = useState(null);
  const [ticketPriority, setTicketPriority] = useState(null);
  const [unassignedTicket, setUnassignedTicket] = useState([]);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [recording, setRecording] = useState(false);
  const [sortFlag, setSortFlag] = useState(false);
  const [userData, setUserData] = useState(null);
  const [open, setOpen] = useState(false);
  const chatContainerRef = useRef(null);
  const [unreadMessages, setUnreadMessages] = useState({});
  const { register } = useForm();

  const [value, setValue] = useState("simple text");
  const dispatch = useDispatch();

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("dexkorUserData"));
    setUserData(data);
  }, []);

  function onTextChange(e) {
    setValue(e.target.value);
  }

  const showModal = () => {
    setOpen(!open);
  };

  const onChange = (value) => {
    console.log(value);
  };

  const InboxFilter = [
    {
      value: "Sort By",
      label: "Sort By",
      children: [
        {
          value: "SLA",
          label: "SLA",
        },
        {
          value: "Modified Date",
          label: "Modified Date",
        },
        {
          value: "Created Date",
          label: "Created Date",
        },
      ],
    },
    {
      value: "Divider",
      label: <Divider style={{ margin: "8px 0" }} />, // Customize divider style if needed
    },
    {
      value: "Ascending Order",
      label: "Ascending Order",
    },
    {
      value: "Descending Order",
      label: "Descending Order",
    },
  ];

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    // Trigger scroll to bottom when messages change
    scrollToBottom();
  }, [selectedConversation?.messages]);

  const handleMenuClick = (e) => {
    console.log("Click:", e);
    // Handle menu item click if needed
  };

  useEffect(() => {
    if (!selectedConversation && myTicket.length > 0) {
      const item = myTicket[0]; // Get the first ticket in the array
      // Check if a tab with the same roomId already exists
      const existingTab = tabs.find((tab) => tab.key === item.roomId);

      // If the tab doesn't exist, create a new one
      if (!existingTab) {
        const newTab = {
          key: item.roomId,
          title: item.ticketId.ticketNumber, // Use the ticket number for the title
          conversation: item.messages, // Use the roomId as the title or adjust as needed
        };

        const newTabs = [...tabs, newTab]; // Add the new tab to the tabs array
        setTabs(newTabs); // Update the state with the new tabs array
        setActiveKey(newTab.key); // Set the newly created tab as active
        setActiveRoomID(newTab.key);
        setSelectedConversation(item);
      } else {
        setActiveKey(item.roomId);
        setSelectedConversation(item);
        setActiveRoomID(item.key);
      }
    }
  }, [myTicket]);

  useEffect(() => {
    if (!sortFlag) return;
    // Sorting myConversations whenever it changes
    let sortedConversations = [...myTicket].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    setMyTicket(sortedConversations);
    sortedConversations = conversations.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    setConversations(sortedConversations);

    setSortFlag(false);
  }, [sortFlag]);

  useEffect(() => {
    // Listen for the 'message' event
    socket.on("message", (message) => {
      const { room } = message;
      // Check if room is undefined or null and log the message if it is
      if (!room) {
        console.error("Received message with undefined room:", message);
        return; // Skip updating if room is undefined
      }
      setUnreadMessages((prev) => {
        // If the user is not currently viewing this room, increment the unread count
        if (activeKey !== room) {
          return {
            ...prev,
            [room]: (prev[room] || 0) + 1,
          };
        }
        return prev;
      });
      setMyTicket((prevConversations) => {
        return prevConversations.map((conversation) => {
          if (conversation.roomId === message.room) {
            // Update the messages in the selected conversation
            return {
              ...conversation,
              messages: [...conversation.messages, message],
            };
          }
          return conversation;
        });
      });

      if (
        selectedConversation &&
        message.room === selectedConversation.roomId
      ) {
        setSelectedConversation((prevSelectedConversation) => ({
          ...prevSelectedConversation,
          messages: [...prevSelectedConversation.messages, message],
        }));
      }
    });

    socket.on("ticketStatusUpdated", (response) => {
      setMyTicket((prevConversations) => {
        return prevConversations.map((conversation) => {
          if (conversation.roomId === response.roomId) {
            // Update the status of the ticketId in the selected conversation
            return {
              ...conversation,
              ticketId: {
                ...conversation.ticketId,
                status: response.status,
              },
            };
          }
          return conversation;
        });
      });

      if (
        selectedConversation &&
        response.roomId === selectedConversation.roomId
      ) {
        setSelectedConversation((prevSelectedConversation) => ({
          ...prevSelectedConversation,
          ticketId: {
            ...prevSelectedConversation.ticketId,
            status: response.status,
          },
        }));
      }
    });

    socket.on("ticketClosed", (response) => {
      setMyTicket((prevConversations) => {
        return prevConversations.map((conversation) => {
          if (conversation.roomId === response.roomId) {
            // Update the status of the ticketId in the selected conversation
            return {
              ...conversation,
              ticketId: {
                ...conversation.ticketId,
                status: response.status,
              },
            };
          }
          return conversation;
        });
      });

      if (
        selectedConversation &&
        response.roomId === selectedConversation.roomId
      ) {
        setSelectedConversation((prevSelectedConversation) => ({
          ...prevSelectedConversation,
          ticketId: {
            ...prevSelectedConversation.ticketId,
            status: response.status,
          },
        }));
      }
    });

    // Clean up the socket listener on unmount
    return () => {
      socket.off("message");
      socket.off("ticketClosed");
    };
  }, [selectedConversation, activeKey]);

  useEffect(() => {
    socket.on("connect", () => {
      socket.emit("joinAgent");
    });

    // Emit all roomIds to join
    const roomIds = myTicket.map((conversation) => conversation.roomId);
    socket.emit("join", roomIds);

    socket.on("newConversation", (newConversation) => {
      console.log("New room available:", newConversation);
      setData((prevConversations) => [
        ...new Set([...prevConversations, newConversation]),
      ]);
      setSortFlag(true);
    });

    return () => {
      socket.off("newRoom");
      // socket.off("roomRemoved");
    };
  }, [selectedConversation]);

  console.log({ selectedConversation });

  const switchConversation = (key) => {
    if (key === "1") {
      setMessageType(null); // Set to null for Customer Interaction
    } else if (key === "2") {
      setMessageType("Internal"); // Set to "Internal" for Internal Communication
    }
  };

  // Function to filter messages
  const filterMessagesForCustomerinteraction = (messages) => {
    return messages?.filter((message) => message?.type !== "Internal");
  };

  // Assuming selectedConversation has a messages array
  const filteredMessagesForCustomerInteraction =
    filterMessagesForCustomerinteraction(selectedConversation?.messages);

  // Function to filter messages
  const filterMessagesForInternal = (messages) => {
    return messages?.filter((message) => message?.type === "Internal");
  };

  // Assuming selectedConversation has a messages array
  const filteredMessagesForInternal = filterMessagesForInternal(
    selectedConversation?.messages
  );

  useEffect(() => {
    if (attachment) {
      sendMessage(); // Automatically send message when attachment is set
    }
  }, [attachment]);
  const sendMessage = () => {
    const inputMessage = message.trim();
    if (inputMessage || (attachment && attachmentType)) {
      // Construct the message object
      const msg = {
        room: selectedConversation.roomId,
        sender: "Agent",
        senderId: userData._id,
        message: inputMessage === "" ? null : inputMessage,
        type: messageType,
        attachment,
        attachmentType,
        source: selectedConversation.ticketId?.source || null,
        user: selectedConversation?.user || null,
        customer: selectedConversation?.customer || null,
        ticket: selectedConversation.ticketId,
      };
      socket.emit("message", msg);
      setMessage("");
      setAttachment(null); // Reset attachment after sending
      setAttachmentType(""); // Reset attachment type after sending
    } else {
      console.warn("No message or attachment to send");
    }
  };

  const handleRoomSelection = (room, myConversation) => {
    if (!room) {
      console.error(
        "Trying to select a room with undefined room ID:",
        myConversation
      );
      return; // Skip the function if room ID is undefined
    }
    setSelectedConversation(myConversation);
    setActiveRoomID(room);
    onTicketClick(myConversation);
    setUnreadMessages((prev) => ({
      ...prev,
      [room]: 0,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAttachment(reader.result);
        setAttachmentType(file.type); // Set attachment type based on the file type
      };
      reader.readAsDataURL(file);
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(audioChunksRef.current, { type: "audio/webm" });
        const reader = new FileReader();
        reader.onloadend = () => {
          setAttachment(reader.result);
          setAttachmentType("audio/webm");
        };
        reader.readAsDataURL(blob);
        audioChunksRef.current = [];
      };

      mediaRecorderRef.current.start();
      setRecording(true);
    } catch (error) {
      console.error("Error starting audio recording:", error);
    }
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  const startTranscription = () => {
    if (!("webkitSpeechRecognition" in window)) {
      console.error("Speech recognition is not supported in this browser.");
      return;
    }

    recognitionRef.current = new window.webkitSpeechRecognition();
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = true;
    recognitionRef.current.lang = "en-US";

    let finalTranscript = "";

    recognitionRef.current.onresult = (event) => {
      let interimTranscript = "";
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          finalTranscript += event.results[i][0].transcript;
        } else {
          interimTranscript += event.results[i][0].transcript;
        }
      }
      setMessage(finalTranscript + interimTranscript);
    };

    recognitionRef.current.start();
    setTranscribing(true);
  };

  const stopTranscription = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setTranscribing(false);
    }
  };

  const renderAttachment = (attachment, attachmentType) => {
    const handlePreview = () => {
      window.open(attachment, "_blank");
    };

    if (attachmentType.includes("image")) {
      return (
        <img
          src={attachment}
          alt="attachment"
          className="w-22 max-w-xs rounded-lg"
        />
      );
    } else if (attachmentType.includes("video")) {
      return (
        <video src={attachment} controls className="w-22 max-w-xs rounded-lg" />
      );
    } else if (attachmentType.includes("audio")) {
      return <audio src={attachment} controls />;
    } else {
      return (
        <div className="flex items-center space-x-2">
          <button
            onClick={handlePreview}
            className="bg-transparent hover:bg-transparent hover:text-black px-3 py-1 rounded"
          >
            Attachment preview
          </button>
        </div>
      );
    }
  };

  useEffect(() => {
    if (snoozeDateTime) {
      const selectedDate = new Date(snoozeDateTime); // Convert snoozeDateTime to a Date object
      const currentDate = new Date(); // Get the current date and time

      // Check if snoozeDateTime is in the future
      if (selectedDate < currentDate) {
        setConf({
          msg: "snooze date and time must be in the future.",
          variant: "error",
        });
        setShowDateTimePicker(true);
      } else {
        snoozeTickets();
      }
    }
  }, [snoozeDateTime]);

  const snoozeTickets = async () => {
    const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
    const userToken = localStorage.getItem("dexkorUserToken");
    const ticketIds = [selectedConversation.ticketId._id];
    try {
      setLoading(true);
      const response = await axios.put(
        `${process.env.REACT_APP_CONVERSATION}/tickets/snooze`,
        {
          snoozeDateTime,
          ticketIds,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            Role: `${userData.role}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        // Remove selectedConversation from myTicket array
        setMyTicket((prevConvos) =>
          prevConvos.filter((convo) => convo._id !== selectedConversation._id)
        );
        onTabEdit(selectedConversation.roomId, "remove");
        setSnoozeDateTime(null);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleassignment = (userData, selectedConversation) => {
    const userId = userData._id;
    const conversationId = selectedConversation._id;
    socket.emit(
      "assignUserToConversation",
      {
        userId,
        conversationId,
      },
      (response) => {
        if (response.success) {
          // Filter out conversations whose userId is equal to userData._id
          const updatedConversations = unassignedTicket.filter(
            (conversation) => conversation._id !== response.conversation._id
          );
          setUnassignedTicket(updatedConversations);
          setMyTicket((prevConversations) => [
            ...prevConversations,
            response.conversation,
          ]);
          setSelectedConversation(null);
          setSortFlag(true);
        } else {
          console.error(
            "Error occured while assigning ticket:",
            response.message
          );
        }
      }
    );
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/all`
      ); // Adjust the API endpoint accordingly
      const allUsers = response.data;
      const filteredUsers = allUsers.filter(
        (user) => user._id !== userData._id
      );
      setUsers(filteredUsers);
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  useEffect(() => {
    if (!assignee) return;
    socket.emit(
      "assignUserToConversation",
      {
        conversationId: selectedConversation._id,
        userId: assignee, // Assuming userData contains the current user ID
      },
      (response) => {
        if (response.success) {
          setMyTicket((prevConversations) => [
            ...prevConversations,
            response.conversation,
          ]);
          const remainingConversations = data.filter(
            (con) => con._id !== response.conversation._id
          );
          setUnassignedTicket(remainingConversations);
          setSortFlag(true);
          // setDropdownVisible(!isDropdownVisible);
          // setSelectedConversation(null);
        } else {
          console.error("Error assigning ticket:", response.message);
        }
      }
    );
  }, [assignee]);

  const onTabChange = (key) => {
    setActiveRoomID(key);
    setActiveKey(key);
    const conversation = data.find((tab) => tab.roomId === key);
    setSelectedConversation(conversation);
  };

  const onTabEdit = (key, action) => {
    if (action === "remove") {
      const newTabs = tabs.filter((tab) => tab.key !== key);
      setTabs(newTabs);
      if (newTabs.length > 0) {
        setActiveKey(newTabs[0].key); // Set the first tab as active if any remain
        setActiveRoomID(newTabs[0].key);
      } else {
        setActiveKey(null); // Clear activeKey if no tabs remain
        setActiveRoomID(null);
        setSelectedConversation(null);
      }
    }
  };

  useEffect(() => {
    const getAllConversation = async () => {
      try {
        setLoading(true);
        const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
        const response = await axios.get(
          `${process.env.REACT_APP_CONVERSATION}/conversations/filter/${userInfo.uniquePartnerId}?source=widget`
        );
        console.log(response.data);
        if (response.data.length === 0) {
          setLoading(false);
        }
        setData(response.data);
        dispatch(setTickets(response.data));
      } catch (error) {
        setLoading(false); // Handle errors properly
        console.error(error); // Log error for debugging
      }
    };

    getAllConversation();
  }, [dispatch, sortFlag]);
  useEffect(() => {
    const filteredMyTickets = () => {
      const filteredConversations = data.filter(
        (conversation) => conversation?.owner?._id === userData?._id
      );
      setMyTicket(filteredConversations);

      const unassignedConversations = data.filter(
        (conversation) => conversation.userId == null
      );
      setUnassignedTicket(unassignedConversations);
      setLoading(false);
    };

    if (data.length > 0) {
      filteredMyTickets();
    }
    console.log(data);
  }, [data]);

  const onTicketClick = (item) => {
    // Use roomId as the unique key for the tab
    const existingTab = tabs.find((tab) => tab.key === item.roomId);

    if (existingTab) {
      setActiveKey(existingTab.key); // Set the active tab to the existing tab
    } else {
      // Create a new tab with roomId as the key
      const newTab = {
        key: item.roomId,
        title: item.ticketId.ticketNumber,
        conversation: item.messages, // Use roomId as the title or adjust as needed
      };
      const newTabs = [...tabs, newTab];
      setTabs(newTabs); // Add the new tab to the tabs array
      setActiveKey(newTab.key); // Set the newly created tab as active
    }
  };

  const height =
    selectedConversation?.ticketId?.source === "email" ? "83vh" : "60vh";

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        <label className="ml-3 cursor-pointer">
          <PaperClipIcon className="h-5 w-5 text-gray-500" />
          <input type="file" onChange={handleFileChange} className="hidden" />
        </label>
      </Menu.Item>
      <Menu.Item key="3">
        <button
          className={`bg-transparent hover:bg-transparent p-2 text-gray-500 ${
            recording ? "hover:text-green-500" : "hover:text-gray-700"
          } ml-1 ${recording ? "text-green-500" : ""}`}
          onClick={recording ? stopRecording : startRecording}
        >
          <MicrophoneIcon
            className={`h-5 w-5 ${
              recording ? "animate-pulse" : "text-gray-500"
            }`}
          />
        </button>
      </Menu.Item>
    </Menu>
  );

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();

      const trimmedMessage = message.replace(/(<([^>]+)>)/gi, "").trim();

      if (!trimmedMessage) {
        return;
      }
      sendMessage();
    }
  };

  useEffect(() => {
    const sendDefaultMessages = async () => {
      if (selectedConversation || selectedConversation?.ticketId.subject) {
        const msg = {
          room: selectedConversation.roomId,
          sender: "Agent",
          senderId: userData._id,
          message: `Subject: ${selectedConversation?.ticketId.subject}`,
          attachment: null,
          attachmentType: null,
        };
        socket.emit("message", msg);
      }

      if (selectedConversation || selectedConversation?.ticketId.description) {
        const msg = {
          room: selectedConversation.roomId,
          sender: "Agent",
          senderId: userData._id,
          message: `Description: ${selectedConversation?.ticketId.description}`,
          attachment: null,
          attachmentType: null,
        };
        socket.emit("message", msg);
      }

      if (selectedConversation || selectedConversation?.ticketId.email) {
        const msg = {
          room: selectedConversation.roomId,
          sender: "Agent",
          senderId: userData._id,
          message: `Email: ${selectedConversation?.ticketId.email}`,
          attachment: null,
          attachmentType: null,
        };
        socket.emit("message", msg);
      }
    };
    if (selectedConversation && selectedConversation?.messages?.length === 0) {
      if (userData) {
        sendDefaultMessages();
      }
    }
  }, [selectedConversation, userData]);

  return (
    <>
      <Row style={{ height: "100vh" }}>
        <Col xs={4} sm={3} md={2} lg={5}>
          <div
            style={{
              height: "56px",
              display: "flex",
              backgroundColor: "#2A4DAD",
              width: "100%",
              color: "white",
              alignItems: "center", // Center content vertically
              fontSize: "20px",
              justifyContent: "space-between", // Space between Inbox and Filter icon
              padding: "0 30px", // Padding to align content
              boxSizing: "border-box", // Ensures padding is included in height/width
            }}
          >
            <span
              style={{
                fontFamily: "roboto",
                fontWeight: "400",
              }}
            >
              Inbox
            </span>
            <span>
              <Cascader
                options={InboxFilter}
                onChange={onChange}
                placeholder="Please select"
              >
                <RiFilter3Fill />
              </Cascader>
            </span>
          </div>

          <div
            style={{
              height: "56px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
            }}
          >
            <Input
              style={{ width: "90%", backgroundColor: "#F6F7FA" }}
              placeholder="Search For Ticket"
              prefix={<SearchOutlined />}
            />
          </div>
          {myTicket.length > 0 && (
            <TicketQueue
              myTickets={myTicket}
              unreadMessages={unreadMessages}
              loading={loading}
              handleRoomSelection={handleRoomSelection}
              activeRoomID={activeRoomID}
            />
          )}
        </Col>
        <Col
          xs={20}
          sm={21}
          md={22}
          lg={19}
          style={{ backgroundColor: "#ffffff" }}
        >
          <Row style={{ height: "100vh" }}>
            <Col xs={4} sm={3} md={2} lg={17}>
              <div
                style={{
                  height: "56px",
                  backgroundColor: "#2A4DAD",
                  width: "100%",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "25px",
                  margin: "0",
                  padding: "0",
                  boxSizing: "border-box", // Ensure box-sizing is applied to the parent div
                }}
              >
                <Tabs
                  tabBarStyle={{
                    height: "100% !important", // Ensure Tabs height is 100% of its parent
                    backgroundColor: "#2A4DAD",
                    color: "white",
                    fontSize: "20px",
                    borderBottom: "none",
                    boxSizing: "border-box",
                    lineHeight: "35px",
                    display: "flex",
                    alignItems: "center", // Ensure tabs are vertically centered
                  }}
                  type="editable-card"
                  hideAdd
                  onChange={onTabChange}
                  activeKey={activeKey}
                  onEdit={onTabEdit}
                  tabBarGutter={0}
                >
                  {tabs.map((tab, index) => (
                    <Tabs.TabPane
                      tab={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between", // Ensure space between title and icon
                            // marginTop: "10px",
                            color: activeKey === tab.key ? "blue" : "white",
                          }}
                        >
                          <span>{tab.title}</span>
                        </div>
                      }
                      key={tab.key}
                      closeIcon={
                        <CloseOutlined
                          style={{
                            color: activeKey === tab.key ? "blue" : "white",
                            // marginTop: '22px'
                          }}
                        />
                      }
                    />
                  ))}
                </Tabs>
              </div>

              {!selectedConversation && !loading && myTicket.length > 0 && (
                <MessageDisplay
                  messageTitle={"Please select a ticket."}
                  messageDescription={
                    "There is no ticket selected. If you have any new requests, feel free to create a ticket or contact support."
                  }
                />
              )}

              {myTicket.length === 0 && !loading && (
                <MessageDisplay
                  messageTitle={"No Tickets."}
                  messageDescription={
                    "There are currently no tickets in your inbox. If you have any new requests, feel free to create a ticket or contact support."
                  }
                />
              )}

              <div
                style={{
                  height: "56px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {selectedConversation && (
                  <div
                    style={{
                      display: "",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%", // Ensure full width for proper spacing
                    }}
                  >
                    {/* Container for select elements */}
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <TicketNavBar
                        selectedConversation={selectedConversation}
                        setSelectedConversation={setSelectedConversation}
                        setMyTickets={setMyTicket}
                        setUnassignedTicket={setUnassignedTicket}
                        onTabEdit={onTabEdit}
                        setTabs={setTabs}
                      />

                      <div style={{ marginLeft: "auto", marginRight: "10px" }}>
                        <RiAlarmLine
                          onClick={() => setShowDateTimePicker(true)}
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "blue",
                            cursor: "pointer",
                          }}
                        />
                        {showDateTimePicker && (
                          <DateTimePicker
                            setDateTime={setSnoozeDateTime}
                            dateTime={snoozeDateTime}
                            setShowDateTimePicker={setShowDateTimePicker}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <div
                  style={{
                    justifyContent: "center",
                    backgroundColor: "#F6F7F9",
                    marginTop: "-15px",
                  }}
                >
                  {selectedConversation && (
                    <div
                      ref={chatContainerRef}
                      style={{
                        width: "95%",
                        height: "60vh",
                        overflow: "auto",
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                        marginTop: "1%",
                        marginBottom: "1%",
                      }}
                    >
                      <Tabs
                        defaultActiveKey="1"
                        style={{ paddingLeft: "20px" }}
                        onChange={switchConversation}
                        items={[
                          {
                            label: "Customer Interaction ",
                            key: "1",
                            children: (
                              <Conversation
                                conversation={{
                                  ...selectedConversation,
                                  messages:
                                    filteredMessagesForCustomerInteraction,
                                }}
                              />
                            ),
                          },
                          {
                            label: "Internal Communication",
                            key: "2",
                            children: (
                              <Conversation
                                conversation={{
                                  ...selectedConversation,
                                  messages: filteredMessagesForInternal,
                                }}
                              />
                            ),
                          },
                        ]}
                      />
                    </div>
                  )}
                </div>

                {selectedConversation &&
                selectedConversation?.ticketId?.source !== "email" ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div>
                        <span>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "100%%",
                                position: "relative",
                              }}
                            >
                              <div>
                                <ReactQuill
                                  value={message}
                                  onChange={setMessage}
                                  required
                                  theme="snow"
                                  className="dexkore-sales-hub-custom-quill1"
                                  modules={{
                                    toolbar: [
                                      { size: [] },
                                      "bold",
                                      "italic",
                                      "underline",
                                      "strike",

                                      { list: "ordered" },
                                      { list: "bullet" },
                                      { indent: "-1" },
                                      { indent: "+1" },

                                      "link",
                                      "video",
                                      "blockquote",
                                      "code-block",
                                      { align: [] },
                                      { color: [] },
                                      { background: [] },
                                      "clean",
                                    ],
                                  }}
                                  formats={[
                                    "header",
                                    "font",
                                    "size",
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strike",
                                    "blockquote",
                                    "list",
                                    "bullet",
                                    "indent",
                                    "link",
                                    "video",
                                    "align",
                                    "color",
                                    "background",
                                    "code-block",
                                  ]}
                                />
                              </div>
                              <div className="dexkor-email-reply-send-button-container flex items-center">
                                <span style={{ width: "100%" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      height: "40px",
                                      padding: "0 10px",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Tooltip title="Attach File">
                                        <label>
                                          <PaperClipIcon
                                            style={{ marginRight: "15px" }}
                                            className="h-5 w-5 text-gray-500"
                                          />
                                          <input
                                            type="file"
                                            onChange={handleFileChange}
                                            className="hidden"
                                          />
                                        </label>
                                      </Tooltip>

                                      <Tooltip title="Microphone">
                                        <div
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginLeft: "15px",
                                          }}
                                        >
                                          <button
                                            className={`bg-transparent hover:bg-transparent p-2 text-gray-500 ${
                                              recording
                                                ? "hover:text-green-500"
                                                : "hover:text-gray-700"
                                            } ml-1 ${
                                              recording ? "text-green-500" : ""
                                            }`}
                                            onClick={
                                              recording
                                                ? stopRecording
                                                : startRecording
                                            }
                                          >
                                            <MicrophoneIcon
                                              className={`h-5 w-5 ${
                                                recording
                                                  ? "animate-pulse"
                                                  : "text-gray-500"
                                              }`}
                                            />
                                          </button>
                                        </div>
                                      </Tooltip>
                                    </div>
                                    <Tooltip title="Send">
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "flex-end",
                                          width: "auto",
                                        }}
                                      >
                                        <Button
                                          onClick={sendMessage}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            backgroundColor: "#2A4DAD",
                                            color: "white",
                                            padding: "8px 16px",
                                            marginLeft: "auto",
                                          }}
                                        >
                                          Send
                                          <SendOutlined
                                            style={{ marginLeft: "8px" }}
                                          />
                                        </Button>
                                      </div>
                                    </Tooltip>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                        </span>
                        <span>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",

                              height: "40px",
                              padding: "0 10px",
                            }}
                          />
                        </span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col xs={4} sm={3} md={2} lg={7}>
              <div
                style={{
                  height: "56px",
                  backgroundColor: "#2A4DAD",
                  width: "100%",
                  color: "white",
                  alignContent: "center",
                  fontSize: "25px",
                }}
              ></div>
              <div
                style={{
                  height: "56px",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "12px",
                }}
              >
                <Button
                  type="primary"
                  style={{ fontFamily: "roboto", fontWeight: "400" }}
                  onClick={showModal}
                >
                  <RiChatNewFill /> Create New Ticket
                </Button>
              </div>
              <div
                style={{
                  overflowY: "auto",
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                  height: "83vh",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    marginLeft: "13px",
                    marginTop: "9px",
                    color: "#2A4DAD",
                    fontFamily: "roboto",
                    fontWeight: "600",
                  }}
                >
                  Unassigned ({unassignedTicket.length})
                </p>
                <div
                  style={{
                    cursor: "pointer",
                    flex: 1,
                    marginLeft: "5px",
                    marginRight: "5px",
                    maxHeight: "50vh",
                    // minHeight: "50vh",
                    overflowY: "auto",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <style>
                    {`
                      div::-webkit-scrollbar {
                        display: none;
                      }
                      div {
                        -ms-overflow-style: none; 
                        scrollbar-width: none; 
                      }
                    `}
                  </style>
                  {!loading ? (
                    <>
                      <List
                        itemLayout="horizontal"
                        size="small"
                        style={
                          {
                            // borderBottom: "2px solid #f0f0f0",
                            // margin: "0px 5px", // Increase the width of the bottom border
                          }
                        }
                        dataSource={unassignedTicket}
                        renderItem={(item) => {
                          const nameInitial = item?.customer?.name
                            ? item?.customer?.name.charAt(0).toUpperCase()
                            : "?";

                          const formattedDate = item?.ticketId?.updatedAt
                            ? moment(item?.ticketId?.updatedAt)
                                .local()
                                .fromNow()
                            : null;

                          return (
                            <List.Item
                              onClick={() => handleassignment(userData, item)}
                            >
                              <List.Item.Meta
                                avatar={
                                  <Avatar
                                    style={{
                                      backgroundColor: "#F7F7F7",
                                      border: "1px solid #2A4DAD",
                                    }}
                                  >
                                    {/* <img src={logo} /> */}
                                    {item?.ticketId?.source === "email" ? (
                                      <img src={googleLogo} />
                                    ) : (
                                      <img src={logo} />
                                    )}
                                  </Avatar>
                                }
                                title={
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      fontFamily: "roboto",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <span>
                                      {item?.ticketId?.ticketNumber ||
                                        "Ticket Number"}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: "gray",
                                        fontFamily: "roboto",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {formattedDate}
                                    </span>
                                  </div>
                                }
                              />
                            </List.Item>
                          );
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "80vh",
                        }}
                      >
                        <Spinner size="large" />
                        <span style={{ marginTop: "10px" }}>Loading ...</span>
                      </div>
                    </>
                  )}
                </div>

                {selectedConversation && (
                  <div>
                    <Divider />
                    {selectedConversation && (
                      <TicketDetails
                        selectedConversation={selectedConversation}
                        setSelectedConversation={setSelectedConversation}
                        setMyTickets={setMyTicket}
                        onTabEdit={onTabEdit}
                      />
                    )}
                    <Divider />
                    {selectedConversation && (
                      <SLADetails selectedConversation={selectedConversation} />
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      {open && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-full max-w-md p-4 rounded-lg shadow-lg">
            <TicketForm
              setShowForm={showModal}
              setUnassignedTicket={setUnassignedTicket}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Tickets;
