import React, { useEffect, useState } from "react";
import axios from "axios";
import "./TicketExplorerViewForm.css";
import IssueLoaderButton from "./IssueLoaderButton";
import { IoTrashOutline } from "react-icons/io5";
import io from "socket.io-client";
import { useDispatch } from "react-redux";
import { addTicketExplorerView } from "../../../../../Redux/actions/ticketExplorerViewActions";

const socket = io(`${process.env.REACT_APP_CHAT_SERVER}`);

const TicketExplorerViewForm = ({ setShowForm, filters, itemsPerPage }) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    isActive: true,
    userId: null, // Initialize with null, updated when userData is set
    filters: [],
    itemsPerPage,
  });
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const dispatch = useDispatch();

  // Fetch user data from localStorage
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("dexkorUserData"));
    if (data) setUserData(data);
  }, []);

  // Update formData when userData or filters change
  useEffect(() => {
    if (userData) {
      setFormData((prevData) => ({
        ...prevData,
        userId: userData._id,
      }));
    }
    if (filters) {
      const activeFilters = filters.filter((filter) => filter.isActive); // Only include active filters
      setFormData((prevData) => ({
        ...prevData,
        filters: activeFilters,
      }));
    }
  }, [userData, filters]);

  console.log({ formData });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const userToken = localStorage.getItem("dexkorUserToken");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONVERSATION}/ticket-explorer/create`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            Role: `${userData.role}`,
            "Content-Type": "application/json",
          },
        }
      );

      setShowForm();
      // Dispatch the add action
      dispatch(addTicketExplorerView(response.data));
      setLoading(false);
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      setLoading(false);
    }
  };

  return (
    <div className="p-4 h-full mt-0 dexkor-scrollable-form">
      <h2 className="dexkor-issue-header">Create New Ticket Explorer View</h2>
      <form className="space-y-5 mt-10" onSubmit={handleSubmit}>
        <div className="dexkor-form-grid">
          {/* Full Name */}
          <div className="mb-4 dexkor-form-full-width">
            <label
              for="name"
              class="block mb-1 text-sm font-medium text-gray-900"
            >
              Name
            </label>
            <input
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name of the view"
              className="dexkor-form-input"
              required
            />
          </div>

          {/* Description (Full-width) */}
          <div className="mb-4 dexkor-form-full-width">
            <label
              for="name"
              class="block mb-1 text-sm font-medium text-gray-900"
            >
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Write description."
              className="dexkor-form-input-textarea"
              rows={2}
              required
            />
          </div>
        </div>

        <div className="flex justify-end space-x-6">
          {loading ? (
            <IssueLoaderButton />
          ) : (
            <>
              <button
                onClick={() => setShowForm()}
                className="dexkor-issue-cancel-button flex items-center justify-center"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="dexkor-issue-submit-button flex items-center justify-center"
              >
                Submit
              </button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default TicketExplorerViewForm;
