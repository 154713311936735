import React, { useEffect, useRef } from "react";
import logo from "../../../../../Images/Logo icon.png";
import googleLogo from "../../../../../Images/google-logo.png";
import TicketDetails from "../TicketDetails"; 

import "./TicketViewer.css";
import { Tabs, Col, Divider } from "antd";
import "react-quill/dist/quill.snow.css"; // Import the styles for the editor
import Conversation from "../../../../../components/Chat Conversation/Conversation";
import SLADetails from "../../../../components/SLADetails";
import EmailChat from "../../../../components/EmailChat/EmailChat";

function TicketViewer({ toggleDrawer, isDrawerOpen, setIsDrawerOpen, convo }) {
  console.log({ convo });

  // Close the drawer when clicking outside of it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      const drawerElement = document.getElementById("drawer-form");
      if (
        isDrawerOpen &&
        drawerElement &&
        !drawerElement.contains(event.target)
      ) {
        setIsDrawerOpen(false);
      }
    };
    if (isDrawerOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [isDrawerOpen, toggleDrawer]);

  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    // Trigger scroll to bottom when messages change
    scrollToBottom();
  }, [convo?.messages]);

  // Function to filter messages
  const filterMessagesForCustomerinteraction = (messages) => {
    return messages?.filter((message) => message?.type !== "Internal");
  };

  const filteredMessagesForCustomerInteraction =
    filterMessagesForCustomerinteraction(convo?.messages);

  return (
    <>
     {/* Backdrop */}
     <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-500 z-50
          ${isDrawerOpen ? "opacity-100" : "opacity-0"}`}
        onClick={() => setIsDrawerOpen(false)}
      ></div>

      {/* Drawer */}
      <div
        className={`fixed top-0 right-0 h-full w-1/2 bg-gray-100 p-4 overflow-y-auto z-50
          ${isDrawerOpen ? "animate-slideIn" : "animate-slideOut"}`}
      >

        <div className="flex space-x-2 items-center pt-4 pb-4 ">
          <img
            src={`${convo.ticketId.source === "widget" ? logo : googleLogo}`}
            alt="iMac Front Image"
            className="w-auto h-8 mr-3"
          />
          {convo.ticketId.ticketNumber || "---"}
        </div>
        <div style={{ height: "100vh" }}>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                label: "Chat",
                key: "1",
                children: (
                  <Col>
                    <div
                      style={{
                        justifyContent: "center",
                        backgroundColor: "#F6F7F9",
                      }}
                    >
                      {convo && convo.ticketId.source === "widget" && (
                        <div
                          ref={chatContainerRef}
                          style={{
                            width: "95%",
                            height: "85vh",
                            overflow: "auto",
                            marginTop: "1%",
                            marginBottom: "1%",
                          }}
                        >
                          {filteredMessagesForCustomerInteraction.length ===
                          0 ? (
                            <section className="bg-white">
                              <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                                <div className="mx-auto max-w-screen-sm text-center">
                                  <h1 className="mb-4 text-3xl tracking-tight font-extrabold lg:text-5xl text-blue-600">
                                    No Chat Here.
                                  </h1>
                                  <p className="mb-4 text-xl text-gray-400 tracking-tight md:text-xl">
                                    It seems that no chat has taken place in
                                    this ticket yet. Please check back later
                                    or try again after some time.
                                  </p>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <Conversation
                              conversation={{
                                ...convo,
                                messages:
                                  filteredMessagesForCustomerInteraction,
                              }}
                            />
                          )}
                        </div>
                      )}

                      {convo && convo.ticketId.source === "email" && (
                        <>
                          {filteredMessagesForCustomerInteraction.length ===
                          0 ? (
                            <section className="bg-white">
                              <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                                <div className="mx-auto max-w-screen-sm text-center">
                                  <h1 className="mb-4 text-3xl tracking-tight font-extrabold lg:text-5xl text-blue-600">
                                    No Chat Here.
                                  </h1>
                                  <p className="mb-4 text-xl text-gray-400 tracking-tight md:text-xl">
                                    It seems that no chat has taken place in
                                    this ticket yet. Please check back later
                                    or try again after some time.
                                  </p>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <EmailChat
                              showReplyButton={true}
                              selectedConversation={{
                                ...convo,
                                messages:
                                  filteredMessagesForCustomerInteraction,
                              }}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </Col>
                ),
              },
              {
                label: "Details",
                key: "2",
                children: (
                  <div
                    style={{
                      overflowY: "auto",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                    }}
                  >
                    {convo && (
                      <div>
                        {convo && (
                          <TicketDetails selectedConversation={convo} />
                        )}

                        <Divider />
                        {convo && <SLADetails selectedConversation={convo} />}
                      </div>
                    )}
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  );
}

export default TicketViewer;
