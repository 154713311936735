import React, { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import "./CustomQuill.css";
import { GoChevronDown } from "react-icons/go";
import { LuReply } from "react-icons/lu";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import useNotification from "../../../../components/snackbars/SnackBar";

const ReplyForm = ({
  selectedConversation,
  setSelectedConversation,
  setMyTicket,
  scrollToBottom,
  handleFileChange,
  setMessage,
  message,
  sendMessage,
}) => {
  const [cc, setCc] = useState([]);
  const [inputCc, setInputCc] = useState("");
  const [bcc, setBcc] = useState([]);
  const [inputBcc, setInputBcc] = useState("");
  const [subject, setSubject] = useState("");
  const [errors, setErrors] = useState({});
  const [showSettings, setShowSettings] = useState(null);
  const [showReplyInputForm, setShowReplyInputForm] = useState(null);
  const [conf, setConf] = useNotification();
  const [updated, setUpdated] = useState(false);

  const toggleDetails = () => {
    if (!showSettings || selectedConversation._id !== showSettings) {
      setShowSettings(selectedConversation._id);
    } else {
      setShowSettings(null);
    }
  };
  useEffect(() => {
    if (updated) {
      sendMessage();
      setUpdated(false);
    }
  }, [updated]);
  useEffect(() => {
    if (selectedConversation) {
      setCc(selectedConversation.ticketId.cc);
      setBcc(selectedConversation.ticketId.bcc);
      setSubject(selectedConversation.ticketId.name);
    }
  }, [selectedConversation]);

  // Scroll to the bottom when the reply input form is shown
  useEffect(() => {
    if (showReplyInputForm === selectedConversation._id) {
      scrollToBottom();
    }
  }, [showReplyInputForm]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!message || message === "<p><br></p>") {
      setConf({ msg: "Message is required", variant: "warning" });
    }

    e.preventDefault();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_CONVERSATION}/tickets/${selectedConversation?.ticketId?._id}/cc-bcc`,
        { cc, bcc, subject },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the headers
            Role: "user", // Add the Role header
            "Content-Type": "application/json", // Optional: set content type
          },
        }
      );
      // Update the `selectedConversation` with the new ticket information
      setSelectedConversation((prevState) => ({
        ...prevState,
        ticketId: {
          ...prevState.ticketId,
          ...response.data, // Update the ticketId field with new data while keeping other properties unchanged
        },
      }));

      // Update `setMyTicket` array with the new ticketId object
      setMyTicket((prevConversations) =>
        prevConversations.map((conversation) =>
          conversation._id === selectedConversation._id
            ? {
                ...conversation,
                ticketId: {
                  ...conversation.ticketId,
                  ...response.data, // Assuming `updatedTicket` contains the updated fields for ticketId
                },
              }
            : conversation
        )
      );

      setUpdated(true);
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
    }
  };

  const token = localStorage.getItem("dexkorUserToken");

  const handleSetCc = () => {
    if (validateEmail(inputCc)) {
      const cc = { name: "", address: inputCc };
      setCc((prevCc) => [...prevCc, cc]);
      setInputCc("");
      setErrors({ ...errors, cc: null });
    } else {
      setErrors({ ...errors, cc: "Invalid CC email address." });
    }
  };
  const handleSetBcc = () => {
    if (validateEmail(inputBcc)) {
      const Bcc = { name: "", address: inputBcc };
      setBcc((prevCc) => [...prevCc, Bcc]);
      setInputBcc("");
      setErrors({ ...errors, bcc: null });
    } else {
      setErrors({ ...errors, bcc: "Invalid BCC email address." });
    }
  };
  const removeCc = (CcToRemove) => {
    setCc((prevCc) => prevCc.filter((Cc) => Cc.address !== CcToRemove.address));
  };

  const removeBcc = (BccToRemove) => {
    setBcc((prevBcc) =>
      prevBcc.filter((Bcc) => Bcc.address !== BccToRemove.address)
    );
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className={`p-4 bg-white rounded-lg dexkor-email-reply-settings-drawer ${
          showReplyInputForm === selectedConversation._id ? "open" : ""
        }`}
      >
        <div
          className={`dexkor-email-reply-settings-drawer ${
            showSettings === selectedConversation._id ? "open" : ""
          }`}
        >
          <div className="mb-0">
            <label
              htmlFor="subject"
              className="block text-gray-700 font-bold mb-1"
            >
              Subject
            </label>
            <input
              type="text"
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className={`w-full p-2 border ${
                errors.subject ? "border-red-500" : "border-gray-300"
              } rounded`}
            />
            {errors.subject && (
              <p className="text-red-500 text-xs mt-1">{errors.subject}</p>
            )}
          </div>

          <div className="mb-8 p-1">
            <div className="relative">
              <input
                type="email"
                value={inputCc}
                onChange={(e) => setInputCc(e.target.value)}
                className="px-4 py-2 w-auto text-sm text-gray-900 bg-gray-50 rounded-e-none border-gray-300 rounded-s-lg focus:ring-0 focus:border-gray-300 focus:outline-none"
                placeholder="Example@dexkor.com"
              />

              <button
                type="button"
                onClick={() => handleSetCc()}
                className="dexkor-add-ccOrBcc-button absolute px-4 py-2 rounded-s-none  rounded-e-lg text-sm ml-0"
              >
                Add Cc
              </button>
              {errors.cc && (
                <p className="text-red-500 text-xs mt-1">{errors.cc}</p>
              )}
            </div>

            {cc.length > 0 && (
              <div className="space-y-1">
                <label
                  htmlFor="cc"
                  className="text-gray-700 font-bold mb-1 mr-2"
                >
                  Cc:
                </label>

                {cc.map((Cc, index) => (
                  <span
                    id="badge-dismiss-dark"
                    className="inline-flex items-center px-2 py-1 me-2 text-sm font-medium text-gray-800 bg-gray-100 rounded-xl"
                    key={index}
                  >
                    {Cc.address}
                    <button
                      type="button"
                      className="inline-flex items-center p-1 ms-2 text-sm text-gray-400 bg-transparent rounded-sm hover:bg-gray-200 hover:text-gray-900 "
                      data-dismiss-target="#badge-dismiss-dark"
                      aria-label="Remove"
                      onClick={() => removeCc(Cc)}
                    >
                      <svg
                        className="w-2 h-2"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                      </svg>
                      <span className="sr-only">Remove badge</span>
                    </button>
                  </span>
                ))}
              </div>
            )}
          </div>

          <div className="p-1">
            <div className="relative">
              <input
                type="email"
                value={inputBcc}
                onChange={(e) => setInputBcc(e.target.value)}
                className="px-4 py-2 w-auto text-sm text-gray-900 bg-gray-50 rounded-e-none border-gray-300 rounded-s-lg focus:ring-0 focus:border-gray-300 focus:outline-none"
                placeholder="Example@dexkor.com"
              />
              <button
                type="button"
                onClick={() => handleSetBcc()}
                className="dexkor-add-ccOrBcc-button absolute px-4 py-2 rounded-s-none  rounded-e-lg text-sm ml-0"
              >
                Add Bcc
              </button>
              {errors.bcc && (
                <p className="text-red-500 text-xs mt-1">{errors.bcc}</p>
              )}
            </div>

            {bcc.length > 0 && (
              <div className="space-y-1">
                <label
                  htmlFor="cc"
                  className="text-gray-700 font-bold mb-1 mr-2"
                >
                  Bcc:
                </label>

                {bcc.map((Bcc, index) => (
                  <span
                    id="badge-dismiss-dark"
                    key={index}
                    className="inline-flex items-center px-2 py-1 me-2 text-sm font-medium text-gray-800 bg-gray-100 rounded-xl"
                  >
                    {Bcc.address}
                    <button
                      type="button"
                      className="inline-flex items-center p-1 ms-2 text-sm text-gray-400 bg-transparent rounded-sm hover:bg-gray-200 hover:text-gray-900 "
                      data-dismiss-target="#badge-dismiss-dark"
                      aria-label="Remove"
                      onClick={() => removeBcc(Bcc)}
                    >
                      <svg
                        className="w-2 h-2"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                      </svg>
                      <span className="sr-only">Remove badge</span>
                    </button>
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>

        <p
          className="text-gray-500 mt-1 mb-1 cursor-pointer flex justify-end"
          style={{ userSelect: "none" }} // Prevents text selection
        >
          <span onClick={toggleDetails} className="flex items-center">
            Setting{" "}
            <GoChevronDown
              className={`ml-1 h-4 w-4 dexkor-chevron-transition ${
                showSettings === selectedConversation._id
                  ? ""
                  : "dexkor-chevron-rotate-180"
              }`}
            />
          </span>
        </p>

        <div className="mb-0">
          <ReactQuill
            value={message}
            onChange={setMessage}
            required
            theme="snow"
            className="custom-quill"
            modules={{
              toolbar: [
                { size: [] },
                "bold",
                "italic",
                "underline",
                "strike",

                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },

                "link",
                "video",
                "blockquote",
                "code-block",
                { align: [] },
                { color: [] },
                { background: [] },
                "clean",
              ],
            }}
            formats={[
              "header",
              "font",
              "size",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "list",
              "bullet",
              "indent",
              "link",
              "video",
              "align",
              "color",
              "background",
              "code-block",
            ]}
          />
        </div>

        <div className="dexkor-email-reply-send-button-container flex items-center">
          <button
            type="submit"
            className="dexkor-email-reply-send-button px-2 py-1 mb-2 mt-2 mr-2 text-xs"
          >
            Send
          </button>
          <label className="cursor-pointer">
            <PaperClipIcon
              style={{ marginRight: "15px" }}
              className="h-5 w-5 text-gray-500"
            />
            <input type="file" onChange={handleFileChange} className="hidden" />
          </label>
        </div>
      </form>
      {showReplyInputForm !== selectedConversation._id && (
        <div className="flex justify-end mb-4">
          <button
            className="dexkor-email-reply-button flex justify-center"
            onClick={() => {
              setShowReplyInputForm(selectedConversation._id);
            }}
          >
            <LuReply className="h-5 w-5 mr-1" /> Reply
          </button>
        </div>
      )}
    </div>
  );
};

export default ReplyForm;
