import React from "react";
import { Route } from "react-router-dom";
import AnswerPro from "../AnswerPro";
import PrivateRoute from "../../Private Route/PrivateRoute";

const AnswerProRoute = () => {
  return (
    <>
  <Route path="/answer-pro" element={<PrivateRoute><AnswerPro /></PrivateRoute>}> </Route>
    </>
  );
};

export default AnswerProRoute;
