import React, { useEffect, useState } from "react";
import "./customerList.css";
import { List, Avatar } from "antd";
import logo from "../../../Images/Logo icon.png";
import Spinner from "../../../components/Spinner/spinner";
import axios from "axios";

const CustomerList = ({
  statusFlag,
  setStatusFlag,
  activeCompanyID,
  onTicketClick,
  handleStatusChange,
  moduleId,
  assigned,
  filterType,
  searchQuery,
  setAllCompanies
}) => {
  const [loading, setLoading] = useState(false);
  const [filteredCompanies, setFilteredCompanies] = useState([]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
    const userToken = localStorage.getItem("dexkorUserToken");
  
    const getAllCompany = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_CONVERSATION}/company/all/${userData.uniquePartnerId}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            }
          }
        );
  
        let companies = response.data;   
  
        if (filterType === "assigned" && assigned) {
          companies = companies.filter(
            (company) => company.customerAssignedTo === assigned
          );
        } else if (filterType === "unassigned") {
          companies = companies.filter(
            (company) => !company.customerAssignedTo
          );
        }
  
        // Apply the search filter here
        if (searchQuery) {
          companies = companies.filter((company) =>
            company.companyName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            company.customerCode?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            company.companyID?.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }
        setAllCompanies(companies)
        setFilteredCompanies(companies);
      } catch (error) {
        console.error("Error fetching company data:", error);
      } finally {
        setLoading(false);
        if (filterType === "assigned") {
          setStatusFlag(false);
        }
      }
    };
  
    // Implementing debouncing
    const debounceTimeout = setTimeout(() => {
      getAllCompany();
    }, 700);
  
    return () => {
      clearTimeout(debounceTimeout); 
    };
  }, [statusFlag, setStatusFlag, filterType, assigned, searchQuery]);  

  return (
    <div>
      {!loading ? (
        <List
          itemLayout="horizontal"
          dataSource={filteredCompanies}
          renderItem={(company) => (
            <List.Item
              key={company.companyID} // Ensure unique key prop
              onClick={() => onTicketClick(company)} // Handle ticket click
              className={
                company.companyID === activeCompanyID
                  ? "dexkor-sales-hub-company-list"
                  : ""
              }
            >
              <List.Item.Meta
                style={{ paddingLeft: "10px" }}
                avatar={
                  <Avatar className="dexkor-sales-hub-company-list-avatar">
                    <img src={logo} alt="Company Logo" />
                  </Avatar>
                }
                title={
                  <div className="dexkor-sales-hub-company-list-title">
                    <span className="dexkor-sales-hub-company-list-company-id">
                      {company.companyName}
                    </span>
                    {/* Handle showing the status based on filterType */}
                    {filterType === "unassigned" ? null : company.status && // Do not show any status if filterType is "unassigned"
                      company.status.length > 0 &&
                      company.status.some(
                        (item) => item.moduleId === moduleId
                      ) ? (
                      company.status.map(
                        (item, index) =>
                          
                          item.moduleId === moduleId && (
                            <span
                              key={index}
                              onClick={() => handleStatusChange(company)} // Call local handler
                              className="dexkor-customer-list"
                            >
                              {item.statusAlias}
                            </span>
                          )
                      )
                    ) : (
                      // Show "Unassigned" only for assigned and all views
                      <span className="dexkor-customer-list">Unassigned</span>
                    )}
                  </div>
                }
                description={
                  <div className="dexkor-sales-hub-company-list-description">
                    {company?.customerCode || "customerCode"}
                  </div>
                }
              />
            </List.Item>
          )}
        />
      ) : (
        <div className="dexkor-sales-hub-company-list-spinner">
          <Spinner />
          <span>Loading ...</span>
        </div>
      )}
    </div>
  );
};

export default CustomerList;
