import React, { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import ReactQuill from "react-quill";
import moment from "moment";
import logo from "../../../Images/Logo icon.png";
import "./Comment.css";
import {
  Row,
  Tabs,
  Col,
  Flex,
  message,
  Upload,
  Button,
  Tooltip,
  Card,
  Modal,
  Form,
  Input,
  Avatar,
} from "antd";
import { LoadingOutlined, PlusOutlined, SendOutlined } from "@ant-design/icons";
import {
  PaperClipIcon,
  PaperAirplaneIcon,
  MicrophoneIcon,
} from "@heroicons/react/24/outline";
import { CiLocationOn, CiEdit } from "react-icons/ci";
import { FaPhoneAlt, FaPlus } from "react-icons/fa";
import Spinner from "../../../components/Spinner/spinner";
import Sales from "./AllCommentOption/Sales/Sales";
const { TextArea } = Input;

const Comment = ({ selectedCompany }) => {
  const [loading, setLoading] = useState(false);
  const [commentFlag, setCommentFlag] = useState(false)
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [recording, setRecording] = useState(false);
  const [transcribing, setTranscribing] = useState(false);
  const [error, setError] = useState("");
  const [allComments, setAllComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [attachmentType, setAttachmentType] = useState("");
  const [allDocuments, setAllDocuments] = useState([]);
  const [userData, setUserData] = useState([]);
  const editorRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const recognitionRef = useRef(null);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
    setUserData(userInfo);
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAttachment(reader.result);
        setAttachmentType(file.type); // Set attachment type based on the file type
      };
      reader.readAsDataURL(file);
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(audioChunksRef.current, { type: "audio/webm" });
        const reader = new FileReader();
        reader.onloadend = () => {
          setAttachment(reader.result);
          setAttachmentType("audio/webm");
        };
        reader.readAsDataURL(blob);
        audioChunksRef.current = [];
      };

      mediaRecorderRef.current.start();
      setRecording(true);
    } catch (error) {
      console.error("Error starting audio recording:", error);
    }
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  const startTranscription = () => {
    if (!("webkitSpeechRecognition" in window)) {
      console.error("Speech recognition is not supported in this browser.");
      return;
    }

    recognitionRef.current = new window.webkitSpeechRecognition();
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = true;
    recognitionRef.current.lang = "en-US";

    let finalTranscript = "";

    recognitionRef.current.onresult = (event) => {
      let interimTranscript = "";
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          finalTranscript += event.results[i][0].transcript;
        } else {
          interimTranscript += event.results[i][0].transcript;
        }
      }
      setNewComment(finalTranscript + interimTranscript);
    };

    recognitionRef.current.start();
    setTranscribing(true);
  };

  const stopTranscription = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setTranscribing(false);
    }
  };

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchComments = async () => {
      setLoading(true);
      
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CONVERSATION}/comments/${selectedCompany.companyID}`
        );
        const fetchedComments = response.data.comments || [];
        const sortedComments = fetchedComments.sort(
          (a, b) => new Date(a.time) - new Date(b.time)
        );
        setAllComments(sortedComments);
      } catch (error) {
        console.error("Error fetching comments:", error);
      } finally {
        setLoading(false);
        // Reset comment flag if needed
        setCommentFlag(false);
      }
    };
  
    // Only fetch comments if selectedCompany is defined
    if (selectedCompany) {
      fetchComments();
    }
  }, [selectedCompany, commentFlag]); // Ensure commentFlag is included
  

  const handleCommentSubmit = async () => {
    if (!userData) {
      setError("User not logged in.");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONVERSATION}/comments/${selectedCompany.companyID}`,
        {
          userId: userData.name,
          comment: newComment,
          time: new Date().toLocaleString(),
          department: userData.resources,
        }
      );
      setAllComments([response.data, ...allComments]);
      setNewComment("");
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const options = [
    "bold",
    "italic",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "|",
    "align",
    "|",
    "hr",
    "|",
    "table",
    "link",
  ];

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "",
      defaultActionOnPaste: "insert_as_html",
      defaultLineHeight: 1.5,
      enter: "div",
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      statusbar: false,
      sizeLG: 900,
      sizeMD: 700,
      sizeSM: 400,
      toolbarAdaptive: false,
    }),
    []
  );

  return (
    <>
      <div style={{ lineHeight: "5px", marginTop: "-15px" }}>
        <Tabs
          defaultActiveKey="1"
          tabBarGutter={0}
          items={[
            {
              label: "Sales  ",
              key: "1",
              children: (
                <div style={{ marginTop: "-16px" }}>
                  <Sales allComments={allComments} selectedCompany={selectedCompany}  setCommentFlag={setCommentFlag} />{" "}
                </div>
              ),
            },
            {
              label: "Pre-Sales",
              key: "2",
              // children: 'Tab 2',
              // disabled: true,
            },
            {
              label: "Customer Success",
              key: "3",
              // children: 'Tab 2',
              // disabled: true,
            },
            {
              label: "OnBoarding",
              key: "4",
              // children: 'Tab 2',
              // disabled: true,
            },
          ]}
        />
      </div>
      {/* <Row className="dexkor-sales-hub-comment-body">
        {loading ? (
          <div className="dexkor-sales-hub-comment-body-spinner">
            <Spinner />
          </div>
        ) : (
          <Col className="dexkor-sales-hub-comment-left-column">
            <div className="dexkor-sales-hub-comment-section">
              {allComments.length === 0 ? (
                <p>Please start to add comments</p>
              ) : (
                allComments.map((comment) => (
                  <div key={comment.id}>
                    <Card className="dexkor-sales-hub-comment-card">
                      <span>
                        <Avatar />
                        <p
                          style={{
                            fontWeight: "bold",
                            marginRight: "3px",
                            marginLeft: "10px",
                          }}
                        >
                          {comment.userId}
                        </p>
                        <p>added a comment on</p>
                        <p style={{ marginLeft: "3px" }}>
                          {moment(comment.time).format("DD MMM YYYY @ h:mm A")}
                        </p>
                      </span>
                      <div className="deskor-sales-hub-comment-card-description">
                        <p 
                         dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(comment.comment),
                        }}
                        />
                    
                      </div>
                    </Card>
                  </div>
                ))
              )}
            </div>
            <div
                            style={{
                              width: "94%",
                              position: "relative",
                              marginLeft: "20px",
                              marginBottom: '20px'
                            }}
                          >
                            <div className="pb-0">
                              <ReactQuill
                                value={newComment}
                                onChange={(e) => setNewComment(e)}
                                required
                                theme="snow"
                                className="custom-quill"
                                modules={{
                                  toolbar: [
                                    { size: [] },
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strike",

                                    { list: "ordered" },
                                    { list: "bullet" },
                                    { indent: "-1" },
                                    { indent: "+1" },

                                    "link",
                                    "video",
                                    "blockquote",
                                    "code-block",
                                    { align: [] },
                                    { color: [] },
                                    { background: [] },
                                    "clean",
                                  ],
                                }}
                                formats={[
                                  "header",
                                  "font",
                                  "size",
                                  "bold",
                                  "italic",
                                  "underline",
                                  "strike",
                                  "blockquote",
                                  "list",
                                  "bullet",
                                  "indent",
                                  "link",
                                  "video",
                                  "align",
                                  "color",
                                  "background",
                                  "code-block",
                                ]}
                              />
                            </div>
                            <div className="dexkor-email-reply-send-button-container flex items-center">
                              <span style={{ width: "100%" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    height: "40px",
                                    padding: "0 10px",
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Tooltip title="Attach File">
                                      <label>
                                        <PaperClipIcon
                                          style={{ marginRight: "15px" }}
                                          className="h-5 w-5 text-gray-500"
                                        />
                                        <input
                                          type="file"
                                          onChange={handleFileChange}
                                          className="hidden"
                                        />
                                      </label>
                                    </Tooltip>

                                    <Tooltip title="Microphone">
                                      <div
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginLeft: "15px",
                                        }}
                                      >
                                        <button
                                          className={`bg-transparent hover:bg-transparent p-2 text-gray-500 ${
                                            recording
                                              ? "hover:text-green-500"
                                              : "hover:text-gray-700"
                                          } ml-1 ${
                                            recording ? "text-green-500" : ""
                                          }`}
                                          onClick={
                                            recording
                                              ? stopRecording
                                              : startRecording
                                          }
                                        >
                                          <MicrophoneIcon
                                            className={`h-5 w-5 ${
                                              recording
                                                ? "animate-pulse"
                                                : "text-gray-500"
                                            }`}
                                          />
                                        </button>
                                      </div>
                                    </Tooltip>
                                  </div>
                                  <Tooltip title="Send">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end", 
                                        width: "auto", 
                                      }}
                                    >
                                      <Button
                                        onClick={handleCommentSubmit}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          backgroundColor: "#2A4DAD",
                                          color: "white",
                                          padding: "8px 16px", 
                                          marginLeft: "auto",
                                        }}
                                      >
                                        Send
                                        <SendOutlined
                                          style={{ marginLeft: "8px" }}
                                        />
                                      </Button>
                                    </div>
                                  </Tooltip>
                                </div>
                              </span>
                            </div>
                            </div> */}
      {/* <div className='dexkor-sales-hub-company-list-text-area'>
     <TextArea
       showCount
       onChange={(e) => setNewComment(e.target.value)}
       value={newComment}
       placeholder="Add your comment"
       style={{
         height: 90,
         resize: 'none',
       }}
     />
     <Button onClick={handleCommentSubmit} style={{ height: '47px' }} type='primary'>
       Comment
     </Button>
   </div> */}
      {/* </Col>
        )} */}
      {/* <Col className='dexkor-sales-hub-comment-right-column'>
    <span className='dexkor-sales-hub-comment-right-column-company-name-span'>
    <p className='dexkor-sales-hub-comment-right-column-company-name'>Company Details</p>
    {isEdit === false ? <p><CiEdit style={{fontSize: '20px', cursor: 'pointer'}} onClick={() => setIsEdit(true)}/></p> : null}
    </span>
    <div>
    <div style={{display: 'flex', gap: '10px'}}>
      <span style={{ marginTop: '5px'}}>
     <h3>Company ID: </h3>
       <Input value={selectedCompany.companyID}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
      <span style={{ marginTop: '5px'}}>
     <h3>Company Name: </h3>
       <Input value={selectedCompany.companyName}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
    </div>
    <div style={{display: 'flex', gap: '10px'}}>
      <span style={{ marginTop: '5px'}}>
     <h3>Company Email: </h3>
       <Input value={selectedCompany.companyID}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
      <span style={{ marginTop: '5px'}}>
     <h3>Company Phone: </h3>
       <Input value={selectedCompany.companyName}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
    </div>
    </div>
    <span className='dexkor-sales-hub-comment-right-column-company-name-span'>
    <p className='dexkor-sales-hub-comment-right-column-company-name'>Shipping Details</p>
    </span>
    <div>
    <div style={{display: 'flex', gap: '10px'}}>
      <span style={{ marginTop: '5px'}}>
     <h3>Address Line 1: </h3>
       <Input value={selectedCompany.addressLine1}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
      <span style={{ marginTop: '5px'}}>
     <h3>Address Line 2: </h3>
       <Input value={selectedCompany.addressLine2}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
    </div>
    <div style={{display: 'flex', gap: '10px'}}>
      <span style={{ marginTop: '5px'}}>
     <h3>City: </h3>
       <Input value={selectedCompany.city}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
      <span style={{ marginTop: '5px'}}>
     <h3>State: </h3>
       <Input value={selectedCompany.state}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
    </div>
    <div style={{display: 'flex', gap: '10px'}}>
      <span style={{ marginTop: '5px'}}>
     <h3>Country: </h3>
       <Input value={selectedCompany.country}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
      <span style={{ marginTop: '5px'}}>
     <h3>Pin Code: </h3>
       <Input value={selectedCompany.pincode}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
    </div>
    <span className='dexkor-sales-hub-comment-right-column-company-name-span'>
    <p className='dexkor-sales-hub-comment-right-column-company-name'>Shipping Details</p>
    </span>
    <div>
    <div style={{display: 'flex', gap: '10px'}}>
      <span style={{ marginTop: '5px'}}>
     <h3>Address Line 1: </h3>
       <Input value={selectedCompany.addressLine1}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
      <span style={{ marginTop: '5px'}}>
     <h3>Address Line 2: </h3>
       <Input value={selectedCompany.addressLine2}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
    </div>
    <div style={{display: 'flex', gap: '10px'}}>
      <span style={{ marginTop: '5px'}}>
     <h3>City: </h3>
       <Input value={selectedCompany.city}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
      <span style={{ marginTop: '5px'}}>
     <h3>State: </h3>
       <Input value={selectedCompany.state}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
    </div>
    <div style={{display: 'flex', gap: '10px'}}>
      <span style={{ marginTop: '5px'}}>
     <h3>Country: </h3>
       <Input value={selectedCompany.country}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
      <span style={{ marginTop: '5px'}}>
     <h3>Pin Code: </h3>
       <Input value={selectedCompany.pincode}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
    </div>
    </div>
   
    </div>
    { isEdit === true ?
    <div style={{display: 'flex', gap: '10px', justifyContent: 'center', marginTop: '20px'}}>
     <Button type='primary' danger onClick={() => setIsEdit(false)}>Cancel</Button>
     <Button type='primary'>Update</Button>
    </div> : null
}
    </Col> */}
      {/* // </Row> */}
      {/* <Modal
        open={open}
        title="Add New Comment"
        onOk={handleOk}
        width={1000}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
            Submit
          </Button>,
        ]}
      >
       <>
       <Form style={{margin: '20px'}}>
       <Form.Item label="Subject">
        <Input placeholder="Enter the Subject" />
      </Form.Item>
      <Form.Item label="Description">
      <TextArea
        // value={value}
        // onChange={(e) => setValue(e.target.value)}
        placeholder="Enter Description"
        autoSize={{
          minRows: 4,
          maxRows: 7,
        }}
      />
      </Form.Item>
       </Form>
       </>
      </Modal> */}
    </>
  );
};

export default Comment;
