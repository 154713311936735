import React from "react";
import { Route } from "react-router-dom";
import Setting from "../Setting";
import GeneralSetup from "../All Component/General Setup/GeneralSetup";
import ImportExport from "../All Component/Import Export Setup/ImportExport";
import ImportCustomer from "../Import Component/Import Customer/ImportCustomer";
import ImportUser from "../Import Component/Import User/ImportUser";
import UserSetup from "../All Component/User Setup/UserSetup";
import AllUsers from "../All Component/All User/AllUsers";
import RolesManagement from "../All Component/Roles Management/Roles";
import AllOption from "../All Component/All Option/AllOption";
import PrivateRoute from "../../Private Route/PrivateRoute";
import ImportSubscription from "../Import Component/Import Subscription/ImportSubscription"
import UpdateSubscription from "../Import Component/Update Subscription/UpdateSubscription";
import ImportTask from "../Import Component/Import Task/importTask";
import UpdateCustomer from "../Import Component/Update Customer/UpdateCustomer";
import IntegrationSetup from "../All Component/Integration/IntegrationSetup";
import Servetel from "../Integration Component/Servetel/servetel";
import Status from "../General Setup/Status Setup/status";

const SettingRoute = () => {
  return (
    <>
      <Route
        path="/setting"
        element={
          <PrivateRoute>
            <Setting />
          </PrivateRoute>
        }
      >
        <Route
          path="general-setup"
          element={
            <PrivateRoute>
              <GeneralSetup />
            </PrivateRoute>
          }
        >
          <Route path="status-setup" element={<PrivateRoute><Status /></PrivateRoute>} />
          <Route path="status-setup/:tabName" element={<PrivateRoute><Status /></PrivateRoute>} />
          </Route>
        <Route
          path="import-export-setup"
          element={
            <PrivateRoute>
              <ImportExport />
            </PrivateRoute>
          }
        >
          <Route
            path="import-customer"
            element={
              <PrivateRoute>
                <ImportCustomer />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="import-user"
            element={
              <PrivateRoute>
                <ImportUser />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="update-subscription"
            element={
              <PrivateRoute>
                <UpdateSubscription />
              </PrivateRoute>
            }
          ></Route>
           <Route
            path="import-task"
            element={
              <PrivateRoute>
                <ImportTask />
              </PrivateRoute>
            }
          ></Route>
           <Route
            path="import-subscription"
            element={
              <PrivateRoute>
                <ImportSubscription />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="update-customer"
            element={
              <PrivateRoute>
                <UpdateCustomer />
              </PrivateRoute>
            }
          ></Route>
        </Route>
        <Route
          path="user-setup"
          element={
            <PrivateRoute>
              <UserSetup />
            </PrivateRoute>
          }
        >
          <Route
            path="users"
            element={
              <PrivateRoute>
                <AllUsers />
              </PrivateRoute>
            }
          />
          
          <Route
            path="roles"
            element={
              <PrivateRoute>
                <RolesManagement />
              </PrivateRoute>
            }
          />
        </Route>
        {/* <Route
          path=""
          element={
            <PrivateRoute>
              <AllOption />
            </PrivateRoute>
          }
        ></Route> */}
        <Route
          path="all-users"
          element={
            <PrivateRoute>
              <AllUsers />
            </PrivateRoute>
          }
        />
        <Route path="integration" element={<PrivateRoute><IntegrationSetup /></PrivateRoute>}>
          <Route path="servetel" element={<PrivateRoute><Servetel /></PrivateRoute>} />
        </Route>
      </Route>
    </>
  );
};

export default SettingRoute;
