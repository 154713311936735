import React from "react";
import { List, Collapse, theme } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

function TicketDetails({ selectedConversation }) {
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 20,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  const data = [selectedConversation];

  return (
    <div>
      <Collapse
        bordered={false}
        defaultActiveKey={["1"]}
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{
          backgroundColor: "white",
        }}
        items={[
          {
            key: "1",
            label: (
              <div
                style={{
                  fontWeight: "600",
                  fontFamily: "roboto",
                }}
              >
                {" "}
                Ticket Details{" "}
              </div>
            ),
            children: (
              <List
                size="small"
                dataSource={data}
                renderItem={(item) => (
                  <List.Item key={item.roomId}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Subject: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId.subject || "---"}
                      </span>
                    </div>{" "}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Description: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId.description || "---"}
                      </span>
                    </div>{" "}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Status: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId.status || "---"}
                      </span>
                    </div>{" "}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Ticket Type: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId.type || "---"}
                      </span>
                    </div>{" "}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Priority: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId.priority || "---"}
                      </span>
                    </div>{" "}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Category: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId?.category || "---"}
                      </span>
                    </div>{" "}
                    <br />
                    {item.owner && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontFamily: "roboto",
                            fontWeight: "400",
                          }}
                        >
                          <span style={{ color: "#A0A7AF" }}>owner: </span>

                          <span style={{ color: "#2A4DAD" }}>
                            {item?.owner?.name}
                          </span>
                        </div>{" "}
                        <br />
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Assigned To: </span>

                      <span style={{ color: "#2A4DAD" }}>
                        {item?.assignee?.name}
                      </span>
                    </div>{" "}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Tags: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId.tags || "---"}
                      </span>
                    </div>
                  </List.Item>
                )}
              />
            ),
            style: panelStyle,
          },
        ]}
      />
      <Collapse
        bordered={false}
        defaultActiveKey={["1"]}
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{
          backgroundColor: "white",
        }}
        items={[
          {
            key: "1",
            label: (
              <div
                style={{
                  fontWeight: "600",
                  fontFamily: "roboto",
                }}
              >
                {" "}
                Customer Details{" "}
              </div>
            ),
            children: (
              <List
                size="small"
                dataSource={data}
                renderItem={(item) => (
                  <List.Item key={item.roomId}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Name: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId.name || "---"}
                      </span>
                    </div>{" "}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Email: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId.email || "---"}
                      </span>
                    </div>{" "}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>phone: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId.number || "---"}
                      </span>
                    </div>
                  </List.Item>
                )}
              />
            ),
            style: panelStyle,
          },
        ]}
      />
    </div>
  );
}

export default TicketDetails;
