import React, { useState } from 'react'
import logo from "../../Images/Logo icon.png"
import { Row, Col, Flex, message, Upload, Button, Card  } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { CiLocationOn, CiEdit   } from "react-icons/ci";
import { FaPhoneAlt, FaPlus } from "react-icons/fa";


const CompanyDetails = ({ selectedCompany }) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState()

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
                width: '120px', // Increased width
                height: '120px', // Increased height
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            type="button"
        >
            {loading ? <LoadingOutlined style={{ fontSize: '24px', color: 'black' }} /> : <PlusOutlined style={{ fontSize: '24px', color: 'black' }} />}
            <div
                style={{
                    marginTop: 8,
                    color: 'black'
                }}
            >
                Upload
            </div>
        </button>
    );

    return (
        <div style={{backgroundColor: '#EFF3F5', width: '100%', height: '86vh', marginTop: '-15px'}}>
            <div>
            <Row>
    <Col span={24}>
        <div style={{ border: "2px solid", borderRadius: '5px', padding: '10px', display: 'flex', alignItems: 'center', margin: "30px", backgroundColor: '#fff' }}>
            <Col span={8}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                        style={{ width: '120px', height: '240px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        {!imageUrl ? (
                            <img
                                src={imageUrl || logo}
                                alt="avatar"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload>
                </div>
            </Col>

            <Col span={16}>
                <h1 style={{ fontSize: '3em', fontWeight: 'bold', margin: '0', fontFamily: 'roboto', marginTop: '10px' }}>
                    {selectedCompany.companyName}
                </h1>
                <h3 style={{ fontSize: '1em', fontWeight: 'bold', margin: '0', fontFamily: 'roboto', marginTop: '20px', color: "#979797" }}>
                    {selectedCompany.companyID}
                </h3>
                <h3 style={{ fontSize: '1em', fontWeight: 'bold', margin: '0', fontFamily: 'roboto', display: 'flex', alignItems: 'center', marginTop: '20px', color: "#979797" }}>
                    <CiLocationOn style={{ marginRight: '5px' }} /> {selectedCompany.addressLine1} {selectedCompany.addressLine2} {selectedCompany.city} {selectedCompany.state} {selectedCompany.country} {selectedCompany.pincode}
                </h3>
                <h3 style={{ fontSize: '1em', fontWeight: 'bold', margin: '0', fontFamily: 'roboto', display: 'flex', alignItems: 'center', marginTop: '20px', color: "#979797" }}>
                    <FaPhoneAlt style={{ marginRight: '5px' }} /> {selectedCompany.pincode}
                </h3>
            </Col>
        </div>
    </Col>
</Row>

            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', margin: '0px 30px', alignItems: 'center'}}>
            <h1 style={{ fontSize: '2em', fontWeight: 'bold', margin: '0', fontFamily: 'roboto', marginTop: '10px', textDecoration: 'underline dashed' }}>
                    Addresses
                </h1>  
                <span><Button size='large'>  <FaPlus style={{ color: 'black' }} />Add Address</Button></span>   
                 
            </div>
            <div style={{display: 'flex', flexGrow: '1', gap: '20px', margin: '20px 30px'}}>
            <Card title={<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}><span>Billing Address</span> <span><CiEdit /></span></div>} bordered={false} style={{ width: 400}}>
                <span>
            {selectedCompany.addressLine1} {selectedCompany.addressLine2} {selectedCompany.city} {selectedCompany.state} {selectedCompany.country} {selectedCompany.pincode}
            </span>
  </Card>
  <Card title={<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}><span>Shipping Address</span> <span><CiEdit /></span></div>} bordered={false} style={{ width: 400 }}>
            {selectedCompany.addressLine1} {selectedCompany.addressLine2} {selectedCompany.city} {selectedCompany.state} {selectedCompany.country} {selectedCompany.pincode}
  </Card>
  <Card title={<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}><span>Corresponding Address</span> <span><CiEdit /></span></div>} bordered={false} style={{ width: 400 }}>
            {selectedCompany.addressLine1} {selectedCompany.addressLine2} {selectedCompany.city} {selectedCompany.state} {selectedCompany.country} {selectedCompany.pincode}
  </Card>
  
            </div>
        </div>
    )
}

export default CompanyDetails;
