import React from "react";
import { Route } from "react-router-dom";
import LearnHub from "../LearnHub";
import PrivateRoute from "../../Private Route/PrivateRoute";

const LearnHubRoutes = () => {
  return (
    <>
      <Route path="/learn-hub" element={<PrivateRoute><LearnHub /></PrivateRoute>}> 
      </Route>

    </>
  );
};

export default LearnHubRoutes;
