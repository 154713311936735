import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Form, Select, Button, message } from 'antd';
import axios from 'axios';
import useNotification from '../../../../components/snackbars/SnackBar';

const { Option } = Select;

const StatusChange = ({ moduleId, statusChange, setStatusChange, selectedCompany, setStatusChangeFlag }) => {
  const [allStatus, setAllStatus] = useState([]); // List of all statuses from API
  const [selectedStatusAlias, setSelectedStatusAlias] = useState(null); // The selected statusAlias
  const [conf, setConf] = useNotification();

  // Fetch available statuses for the module
  useEffect(() => {
    const getAllStatus = async () => {
      const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));

      if (userInfo) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_CONVERSATION}/status/${moduleId}/${userInfo.uniquePartnerId}/get-status`);
          setAllStatus(response.data.data); // Set the list of all available statuses
        } catch (error) {
          console.error("Error fetching statuses:", error);
        }
      }
    };

    getAllStatus();
  }, [moduleId, selectedCompany]);

  // Set the current status for the specified moduleId
 // Set the current status for the specified moduleId
useEffect(() => {
  const currentStatus = selectedCompany?.status?.find((item) => item.moduleId === moduleId);
  
  if (currentStatus) {
    setSelectedStatusAlias(currentStatus.statusAlias); // Set the current statusAlias
  } else {
    setSelectedStatusAlias(null); // If no status is found, reset to null
  }
}, [selectedCompany, moduleId]); // Trigger effect on change of selectedCompany or moduleId


  // Handle status change from dropdown
  const handleStatusChange = (value) => {
    console.log("Selected Status Alias:", value); // Log selected status
    setSelectedStatusAlias(value); // Set the selected statusAlias
  };

  // Handle status update via API
  const handleStatusUpdate = async () => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
    const selectedStatus = allStatus.find((status) => status.statusName === selectedStatusAlias);

    if (userInfo && selectedCompany && selectedStatus) {
        try {
            // Create a copy of the current status array
            let updatedStatusArray = [...selectedCompany.status]; // Make a copy of the current status array

            // Check if the status for the module already exists
            const moduleStatusIndex = updatedStatusArray.findIndex((item) => item.moduleId === moduleId);

            if (moduleStatusIndex !== -1) {
                // If moduleId exists, update the corresponding status object
                updatedStatusArray[moduleStatusIndex] = {
                    ...updatedStatusArray[moduleStatusIndex],
                    statusAlias: selectedStatus.statusAlias,
                    statusName: selectedStatus.statusName,
                    statusType: selectedStatus.statusType,
                };
            } else {
                // If moduleId doesn't exist, create a new status object and push to the array
                updatedStatusArray.push({
                    moduleId: moduleId,
                    statusAlias: selectedStatus.statusAlias,
                    statusName: selectedStatus.statusName,
                    statusType: selectedStatus.statusType,
                });
            }

            // Send the updated status array in the payload
            const response = await axios.put(`${process.env.REACT_APP_CONVERSATION}/company/update/${selectedCompany.customerCode}/${userInfo.uniquePartnerId}`, {
                status: updatedStatusArray, // Send the updated status array
            });

            console.log("API Response:", response.data); // Log API response
            setConf({msg: "Status Updated Successfully", variant: "success"});
            setStatusChangeFlag(true);
            setStatusChange(false); // Close the modal after successful update
        } catch (error) {
            console.error("Error updating status:", error);
            setConf({msg: "Failed to update Status", variant: "error"});
        }
    } else {
        console.error("User Info or Selected Company or Selected Status is null"); // Log if any required information is missing
    }
};


  // Handle modal close (cancel) and reset the selected status back to the original
  const handleModalCancel = () => {
    const originalStatus = selectedCompany?.status?.find((item) => item.moduleId === moduleId);
    if (originalStatus) {
      setSelectedStatusAlias(originalStatus.statusAlias); // Reset to original statusAlias
    }
    setStatusChange(false); // Close the modal
  };

  return (
    <Modal
      title="Change Status"
      visible={statusChange}
      onOk={handleStatusUpdate} // Handle the status update on "OK"
      onCancel={handleModalCancel} // Reset the status on cancel and close the modal
      footer={null} // Remove default footer to add custom buttons
    >
      <Form layout="vertical">
        <div className='pt-10 pb-5'>
          <Row className='flex items-center'>
            <Col span={8}>
              <Form.Item><p>Status</p></Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item>
                <Select
                  placeholder="Select Status"
                  onChange={handleStatusChange} // Update selected status
                  value={selectedStatusAlias || null} // Show the current statusAlias for "saleshub001"
                >
                  {allStatus.map((status) => (
                    <Option key={status.statusId} value={status.statusName}>
                      {status.statusName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="flex items-center justify-center">
          <Button onClick={handleModalCancel}>Cancel</Button>
          <Button
            type="primary"
            onClick={handleStatusUpdate} // Handle status update on button click
            disabled={!selectedStatusAlias} // Disable if no status is selected
          >
            Change Status
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default StatusChange;
