import React from "react";
import { Route } from "react-router-dom";
import Excellence from "../Excellence";
import PrivateRoute from "../../Private Route/PrivateRoute";

const ExcellenceRoutes = () => {
  return (
    <>
     <Route path="/excellence" element={<PrivateRoute><Excellence /></PrivateRoute>}> 
     </Route>
    </>
  );
};

export default ExcellenceRoutes;
