import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./Main Panel/Dashboard";
import PrivateRoute from "./Private Route/PrivateRoute";
import DexkorPrivateRoute from "./Private Route/DexKorPrivateRoute";
import { SnackbarProvider } from "notistack";
import PartnerRegistrationForm from "./Partner/PartnerRegistrationForm";
import AdSuiteRoutes from "./AdSuite/Route/AdSuiteRoute";
import SalesHubRoutes from "./SalesHub1/Routes/SalesHubRoutes";
import HelpDeskRoutes from "./HelpDesk/Route/HelpDeskRoutes";
import AccountCareRoutes from "./AccountCare/Route/AccountCareRoute";
import AnswerProRoutes from "./Answer Pro/Route/AnswerProRoutes";
import DemoDeskRoutes from "./Demo Desk/Route/DemoDeskRoutes";
import ExcellenceRoutes from "./Excellence/Route/ExcellenceRoutes";
import FinanceRoutes from "./Finance/Route/FinanceRoutes";
import LearnHubRoutes from "./LearnHub/Route/LearnHubRoutes";
import LoginRoutes from "./Login.js/Route/LoginRoutes";
import OnBoardHubRoutes from "./OnboardHub/Route/OnBoardHubRoutes";
import ProductProRoutes from "./ProductPro/Route/ProductProRoutes";
import SettingRoutes from "./Setting/Route/SettingRoute";
import StarBoardRoutes from "./StarBoard/Route/StarBoardRoutes";
import TalentPulseRoutes from "./TalentPulse/Route/TalentPulseRoutes";
import UserInvite from "./Setting/All Component/UserInvitationSetup/UserInvite";
import EngineeringRoutes from "./DevCore/Route/EngineeringRoutes";
import ResetPassword from "./Login.js/ForgotPassword Page/resetPassword";
import { useDispatch } from "react-redux";
import { fetchTicketExplorerViews } from "./Redux/actions/ticketExplorerViewActions";
//
function App() {

  const dispatch = useDispatch();
  useEffect(() => {
    const userToken = localStorage.getItem("dexkorUserToken");
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));

    if (userToken && userInfo) {
      dispatch(
        fetchTicketExplorerViews(userInfo._id, userInfo.role, userToken)
      );
    }
  }, []);
  return (
    <>
      <SnackbarProvider
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <BrowserRouter>
          <Routes>
            {LoginRoutes()}
            <Route path="/user-setup/:token" element={<UserInvite />} />
            <Route
              path="/password-recover/:token"
              element={<ResetPassword />}
            />
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            >
              {SalesHubRoutes()}
              {AdSuiteRoutes()}
              {EngineeringRoutes()}
              {AccountCareRoutes()}
              {AnswerProRoutes()}
              {DemoDeskRoutes()}
              {ExcellenceRoutes()}
              {FinanceRoutes()}
              {LearnHubRoutes()}
              {HelpDeskRoutes()}
              {OnBoardHubRoutes()}
              {ProductProRoutes()}
              {SettingRoutes()}
              {StarBoardRoutes()}
              {TalentPulseRoutes()}
            </Route>
            <Route
              path="/partner-registration"
              element={
                <DexkorPrivateRoute>
                  <PartnerRegistrationForm />
                </DexkorPrivateRoute>
              }
            ></Route>
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </>
  );
}

export default App;
