import React, { useEffect, useState } from 'react'
import "./Documents.css"
import logo from "../../../Images/Logo icon.png"
import axios from 'axios'
import { IoCloudDownloadOutline } from "react-icons/io5";
import { RiDeleteBinLine } from "react-icons/ri";
import { Row, Col, Flex, message, Upload, Button, Card, Space, Table, Tag, Input,  Select, Badge, Progress, Modal } from 'antd'
import { LoadingOutlined, PlusOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { CiLocationOn, CiEdit   } from "react-icons/ci";
import { FaPhoneAlt, FaPlus } from "react-icons/fa";

const { TextArea } = Input;

const Document = ({ selectedCompany }) => {
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState([])
    const [fileList, setFileList] = useState([]);
    const [documents, setDocuments] = useState([])
    const [newDocument, setNewDocument] = useState({
        name: "",
    description: "",
    file: null,
      });
    const [taskModal, setTaskModal] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [data, setData] = useState([])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewDocument(prevState => ({
          ...prevState,
          [name]: value,
        }));
      };

      const handleFileChange = ({ fileList: newFileList }) => {
        // Update the file list state
        setFileList(newFileList);
    
        // Check if there is at least one file in the list
        if (newFileList.length > 0) {
          setNewDocument(prev => ({
            ...prev,
            file: newFileList[0].originFileObj, // Extract the file object
          }));
        }
      };

    useEffect(() => {
        setUserData(JSON.parse(localStorage.getItem("dexkorUserData")));
      }, []);

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
              const response = await axios.get(
                `${process.env.REACT_APP_CONVERSATION}/company/${selectedCompany.companyID}/documents`
              );
              setDocuments(response.data);
            } catch (error) {
              console.error("Error fetching documents:", error);
            }
          };
          fetchDocuments(); // Call the async function
      }, [selectedCompany.companyID]);

      const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
      };

      const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
      };

    
  
    const columns = [
        {
          title: 'Name',
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: 'Email ID',
          dataIndex: 'status',
          key: 'status',
        },
        {
            title: 'Documents',
            key: 'status',
            render: (text,record) => (
                <Progress percent={50} status="active" />
            )
          },
          {
            title: 'Action',
            key: 'action',
            
            render: (text, record) => (
                <>
              <Button
                type="primary"
                style={{
                  backgroundColor: '#2A4DAD',
                  borderRadius: '15px',
                }}
              >
                Manage
              </Button>
               <Button
               type="primary"
               style={{
                 backgroundColor: '#2A4DAD',
                 borderRadius: '15px',
               }}
             >
               Manage
             </Button>
             </>
            ),
          }
    ]
    const resetForm = () => {
        setFileList([]); // Clear file list
        setNewDocument({ name: '', description: '', file: null }); // Reset document state
    };

    const handleOk = () => {
        setTaskModal(false);
        resetForm();
    };
    
    const handleCancel = () => {
        setTaskModal(false);
        resetForm();
    };
 
      const handleUpload = async () => {
        if (!newDocument.file) {
            message.error('Please upload a file before submitting');
            return;
        }
    
        const formData = new FormData();
        formData.append('file', newDocument.file);
        formData.append('name', newDocument.name);
        formData.append('description', newDocument.description);
        formData.append('uploadedBy', userData.email);
        formData.append('companyID', selectedCompany.companyID);
    
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_CONVERSATION}/company/${selectedCompany.companyID}/upload`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Required for file upload
                    },
                }
            );
    
            setDocuments([...documents, response.data]);
            setNewDocument({ name: '', description: '', file: null });
            setFileList([]); // Clear the file list after successful upload
            setTaskModal(false);
            message.success('Document uploaded successfully');
        } catch (error) {
            console.error('Error uploading document:', error);
            message.error('Failed to upload document');
        }
    };  
      
      
    return (
        <div className='dexkor-sales-hub-task-body' >
   <div className='dexkor-sales-hub-document-search-bar'>
    <span> <h2>Search For Documents</h2> <Input prefix={<SearchOutlined />} className="dexkor-sales-hub-task-search-bar-input" placeholder="Search..." /></span>
    <span style={{padding: '20px'}}>
        <Button type='primary' size='large' onClick={() => setTaskModal(true)} style={{backgroundColor: '#2A4DAD'}}>Add Document</Button>
    </span>
   </div>
   <div className='dexkor-sales-hub-task-table'>
   <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={documents}
          loading={loading}
        />
   </div>
   <Modal
        open={taskModal}
        width={600}
        title="Add Task"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => handleUpload()}>
            Submit
          </Button>,
         
        ]}
      >
       <>

<span style={{ display: 'flex', gap: '20px', alignItems: 'center', paddingTop: '20px' }}>
  <h2>Name:</h2>
  <Input
    style={{ marginLeft: '45px', marginRight: '17px' }}
    name="name" 
    onChange={handleInputChange} 
  />
</span>

<span style={{ display: 'flex', gap: '20px', alignItems: 'center', paddingTop: '20px' }}>
  <h2>Description:</h2>
  <TextArea
    style={{
      height: 120,
      marginRight: '17px',
      resize: 'none',
    }}
    name="description" // Important: Name must match the state property
    onChange={handleInputChange} // Use generic input handler
  />
</span>
<span style={{ display: 'flex', gap: '20px', alignItems: 'center', paddingTop: '20px' }}>
    <h2>File Upload: </h2>
    <Upload
    fileList={fileList}
    beforeUpload={(file) => {
        console.log('File in beforeUpload:', file); // Log the file object
        setNewDocument(prev => ({
            ...prev,
            file: file, // Set the file object
        }));
        return false; // Prevent automatic upload
    }}
    onChange={handleFileChange}
    listType="picture"
>
    <Button type="primary">
       <IoCloudDownloadOutline />
    </Button>
    <Button type="primary">
       <RiDeleteBinLine />
    </Button>
</Upload>

</span>
       </>
      </Modal>
</div>
    )
}

export default Document;
