import React, { useState } from "react";
import axios from "axios";
import logo from "../Images/Logo icon.png";
import SignInLoader from "./SignInLoader";
import useNotification from "../components/snackbars/SnackBar";

const ForgotPassword = () => {
  const [email, setEmail] = useState('');  // Initialize email as an empty string
  const [loading, setLoading] = useState(false);
  const [conf, setConf] = useNotification();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/auth/recover-password`,
        {
          accessUrl: window.location.origin,  // Ensure this URL is correct
          email: email,
        }
      );
      setLoading(false);
      setConf({
        msg: "Password Reset Link Sent Successfully",
        variant: "success",
      });
    } catch (error) {
      setConf({ msg: "User not found with this Email", variant: "error" });
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img alt="Dexkor" src={logo} className="mx-auto h-44 w-auto" />
        <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Recover Your Password
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label
              htmlFor="email"
              className="flex block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>
            <div className="relative mt-2">
              <input
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="dexkor@example.com"
                type="email"
                required
                autoComplete="email"
                aria-label="Email address for password recovery"
                className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            {loading ? (
              <SignInLoader />
            ) : (
              <button
                type="submit"
                aria-label="Submit to recover password"
                className="dexkor-signin-button flex ml-0 w-full justify-center mb-3 rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm"
              >
                Recover Password
              </button>
            )}
          </div>
          <div>
            <p>
              Know Your Password?{" "}
              <a
                href="/login"
                className="text-l font-medium text-indigo-600 hover:text-indigo-500"
              >
                Sign In
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
