import React, { useState, useEffect, useRef } from "react";
import "./CompanyDetails.css";
import axios from "axios";
import logo from "../../../Images/Logo icon.png";
import {
  Row,
  Col,
  message,
  Upload,
  Button,
  Popover,
  Card,
  Select,
  Form,
  Table,
  Input,
  Modal,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { TbEdit } from "react-icons/tb";
import { SlLocationPin } from "react-icons/sl";
import { MdDeleteForever, MdOutlineMail  } from "react-icons/md";
import { FaRegSquarePlus } from "react-icons/fa6";
import { FiPhone } from "react-icons/fi";
import useNotification from "../../../components/snackbars/SnackBar";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

const CompanyDetails = ({ selectedCompany }) => {
  const [loading, setLoading] = useState(false);
  const [contactUpdateFlag, setContactUpdateFlag] = useState(false);
  const [selectedContact, setSelectedContact] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [addressDeleteModal, setAddressDeleteModal] = useState(false);
  const [addressDeleteFlag, setAddressDeleteFlag] = useState(false);
  const [addressUpdateFlag, setAddressUpdateFlag] = useState(false);
  const [filteredPOCs, setFilteredPOCs] = useState([]);
  const [addressFlag, setAddressFlag] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [allAddresses, setAllAddresses] = useState([]);
  const [contactModal, setContactModal] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [conf, setConf] = useNotification();
  const [form] = Form.useForm();

  useEffect(() => {
    if (contactModal && contactUpdateFlag && selectedContact) {
      form.setFieldsValue(selectedContact); // Populate form with selected contact details
    } else {
      form.resetFields(); // Reset fields if not updating
    }
  }, [contactModal, contactUpdateFlag, selectedContact, form]);

  useEffect(() => {
    if (addressUpdateFlag && selectedAddress) {
     form.setFieldsValue(selectedAddress)
    } else {
    form.resetFields();
    }
  }, [addressUpdateFlag, selectedAddress]);

  const handleOk = () => {
    setContactModal(false);
    setAddressModal(false);
    setContactUpdateFlag(false);
    setAddressUpdateFlag(false);
    setAddressDeleteModal(false);
  };

  const handleCancel = () => {
    setContactModal(false);
    setAddressModal(false);
    setContactUpdateFlag(false);
    setAddressUpdateFlag(false);
    setAddressDeleteModal(false);
  };

  const handleDeleteAddress = async (address) => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));

    if (userInfo) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_CONVERSATION}/company/${address.addressID}/${selectedCompany.customerCode}/${userInfo.uniquePartnerId}/delete-address`
        );
        setAddressDeleteModal(false);
        setConf({ msg: "Address Deleted Successfully", variant: "success" });
        setAddressFlag(true);
      } catch (error) {
        setAddressDeleteModal(false);
        setConf({ msg: "Failed to Delete Address", variant: "error" });
        setAddressFlag(true);
      }
    }
  };

  const handleDeletePOC = async (record) => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));

    if (userInfo) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_CONVERSATION}/company/${record.pocID}/${selectedCompany.customerCode}/${userInfo.uniquePartnerId}/delete-poc`
        );
        setAddressDeleteModal(false);
        setConf({ msg: "Contact Deleted Successfully", variant: "success" });
        setAddressFlag(true);
      } catch (error) {
        setAddressDeleteModal(false);
        setConf({ msg: "Failed to Delete Contact", variant: "error" });
        setAddressFlag(true);
      }
    }
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
    },

    // {
    //     title: 'Documents',
    //     key: 'status',
    //     render: (text,record) => (
    //         <Progress percent={50} status="active" />
    //     )
    //   },
    {
      title: "Action",
      key: "action",

      render: (text, record) => (
        <div className="flex gap-5">
          <div>
            <TbEdit
              className="w-5 h-5 cursor-pointer"
              onClick={() => {
                setContactModal(true);
                setContactUpdateFlag(true);
                setSelectedContact(record);
              }}
            />
          </div>
          <div>
            <span>
              <MdDeleteForever
                className="w-5 h-5 cursor-pointer"
                onClick={() => {
                  setAddressDeleteModal(true);
                  setAddressDeleteFlag(false);
                  setSelectedContact(record);
                }}
              />
            </span>
          </div>

          <div>
            <FiPhone
              onClick={() => handleCallClick(record)}
              className="w-5 h-5 cursor-pointer"
            />
          </div>
          {/* <div>
            <MdOutlineEmail className="w-5 h-5 cursor-pointer" />
          </div> */}
        </div>
      ),
    },
  ];

  const handleUpdatePOC = async (values) => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
    if (userInfo) {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_CONVERSATION}/company/${selectedContact.pocID}/${selectedCompany.customerCode}/${userInfo.uniquePartnerId}/update-poc`,
          values
        );

        if (response.status === 200) {
          setConf({ msg: "Contact Updated Successfully", variant: "success" });
          setContactModal(false);
          setAddressFlag(true);
        } else {
          setConf({ msg: "Failed to Update Contact", variant: "error" });
        }
      } catch (error) {
        console.error("Error updating contact:", error);
        setConf({ msg: "Failed to Update Contact", variant: "error" });
      } finally {
        setContactModal(false);
        form.resetFields();
      }
    }
  };

  const handleAddContactClick = () => {
    form.resetFields();
    setContactUpdateFlag(false); // Ensure update mode is off
    setSelectedContact(null); // Clear selected contact data
    setContactModal(true); // Open the modal
  };

  const handleAddAddressClick = () => {
    form.resetFields();
    setAddressUpdateFlag(false); // Ensure update mode is off
    setSelectedAddress(null); // Clear selected contact data
    setAddressModal(true); // Open the modal
  };

  const handleUpdateAddress = async (value) => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
    if (userInfo) {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_CONVERSATION}/company/${selectedAddress.addressID}/${selectedCompany.customerCode}/${userInfo.uniquePartnerId}/update-address`,
          value
        );

        setConf({ msg: "Address Updated Successfully", variant: "success" });
        setAddressModal(false);
        setAddressFlag(true);
      } catch (error) {
        setContactModal(false);
        setConf({ msg: "Failed to Update Address", variant: "error" });
        setAddressFlag(true);
      }
    }
  };

  const handleAddAddress = async (value) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONVERSATION}/company/${selectedCompany.companyID}/addAddress`,
        value
      );
      if (response.status === 200) {
        setAddressModal(false);
        setAddressFlag(true);
      }
    } catch (error) {
      console.error("Error adding Address:", error);
    }
  };
  const handleAddPOC = async (values) => {    
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONVERSATION}/company/${selectedCompany.companyID}/addPoc`,
        values // Use the values from the form
      );

      if (response.status === 200) {
        // Successfully added
        setFilteredPOCs((prevPOCs) => [...prevPOCs, response.data.pocDetails]); // Update state with new POC
        message.success("Contact added successfully!"); // Show success message

        form.resetFields(); // Reset form fields
        setContactModal(false); // Close the modal
        setAddressFlag(true); // Trigger any additional UI updates if necessary
      } else {
        message.error("Failed to add contact: " + response.data.message);
      }
    } catch (error) {
      console.error("Error adding POC:", error);
      message.error("An error occurred while adding the contact.");
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CONVERSATION}/company/${selectedCompany.companyID}`
        );
        const pocDetails = response.data.pocDetails || {};
        const addressDetails = response.data.companyAddress || {};
        setAllAddresses(addressDetails); // Update state with the new address list
        setFilteredPOCs(pocDetails);
        setAddressFlag(false);
        // setContactUpdateFlag(false)// Reset the flag after fetching
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    fetchDetails(); // Call the async function
  }, [selectedCompany?.companyID, addressFlag]);

  const handleInputChange = (e) => {
    if (e.target) {
      // For inputs (text fields)
      const { name, value } = e.target;
      form.setFieldsValue({ [name]: value });
    } else if (typeof e === "object" && e.name && e.value) {
      // For Select components
      const { name, value } = e;
      form.setFieldsValue({ [name]: value });
    }
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
   form.setFieldValue({ [name] : value})
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const handleCallClick = async (record) => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));

    if (userInfo) {
      try {
        // Show "Connecting..." before making the API call
        setConf({ msg: "Connecting...", variant: "success" });

        // Make the API call
        const response = await axios.post(
          `${process.env.REACT_APP_CONVERSATION}/servetel/${userInfo.uniquePartnerId}/click-to-call`,
          {
            agentNumber: userInfo.phone,
            destinationNumber: record.phone,
            callerId: "+918068051700",
            callTimeOut: 30,
          }
        );
        if (response.status !== 200) {
          setConf({ msg: response.data.message, variant: "error" });
        } else {
          setConf({ msg: "Call initiated successfully!", variant: "success" });
        }
      } catch (error) {
        console.log(error);
        setConf({
          msg: "Failed to initiate the call. Please try again.",
          variant: "error",
        });
      }
    }
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
        width: "120px", // Increased width
        height: "120px", // Increased height
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
      type="button"
    >
      {loading ? (
        <LoadingOutlined style={{ fontSize: "24px", color: "black" }} />
      ) : (
        <PlusOutlined style={{ fontSize: "24px", color: "black" }} />
      )}
      <div
        style={{
          marginTop: 8,
          color: "black",
        }}
      >
        Upload
      </div>
    </button>
  );

  return (
    <div className="dexkor-sales-hub-company-details-body">
      <div className="dexkor-sales-hub-company-details-box">
        <div>
          <div
            style={{
              color: "#18191C",
              fontSize: "24px",
              fontWeight: "800",
              lineHeight: "30px",
            }}
          >
            {selectedCompany.companyName}
          </div>
          <div
            style={{
              color: "#979797",
              fontSize: "16px",
              fontWeight: "700",
              display: "flex",
              gap: "5px",
            }}
          >
            <p>Customer Code: </p>
            {selectedCompany.customerCode || "NA"}
          </div>
          <div className="flex items-center gap-3">
            <span
              style={{
                color: "#2A4DAD",
                height: "19px",
                width: "16px",
                flexShrink: "0",
              }}
            >
              <SlLocationPin />
            </span>
            <span
              style={{ color: "#979797", fontSize: "16px", fontWeight: "500" }}
            >
              {selectedCompany.companyAddress[0]?.addressLine1}{" "}
              {selectedCompany.companyAddress[0]?.addressLine2}
              {selectedCompany.companyAddress[0]?.city}{" "}
              {selectedCompany.companyAddress[0]?.state}
              {selectedCompany.companyAddress[0]?.country}{" "}
              {selectedCompany.companyAddress[0]?.pincode}
            </span>
          </div>
          <div className="flex items-center gap-3">
            <span
              style={{
                color: "#2A4DAD",
                height: "19px",
                width: "16px",
                flexShrink: "0",
              }}
            >
              <FiPhone />
            </span>
            <span
              style={{ color: "#979797", fontSize: "16px", fontWeight: "500" }}
            >
              {selectedCompany.companyPhone}
            </span>
          </div>
          <div className="flex items-center gap-3">
            <span
              style={{
                color: "#2A4DAD",
                height: "19px",
                width: "16px",
                flexShrink: "0",
              }}
            >
              <MdOutlineMail />
            </span>
            <span
              style={{ color: "#979797", fontSize: "16px", fontWeight: "500" }}
            >
              {selectedCompany.companyEmail}
            </span>
          </div>
        </div>
        {/* <div>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
            beforeUpload={beforeUpload}
            onChange={handleChange}
            style={{
              width: "120px",
              height: "240px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!imageUrl ? (
              <img
                src={imageUrl || logo}
                alt="avatar"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </div> */}
      </div>
      <div className="flex justify-between align-center mx-5 my-5">
        <div className="text-3xl font-semibold" style={{ color: "#2A4DAD" }}>
          Addresses
        </div>
        <div
          onClick={handleAddAddressClick}
          className="flex align-center"
        >
          {" "}
          <Button
            size="large"
            type="primary"
            style={{ backgroundColor: "#2A4DAD" }}
          >
            {" "}
            <FaRegSquarePlus
              style={{ color: "white", width: "20px", height: "20px" }}
            />{" "}
            <p className="text-base font-semibold">Add Address</p>
          </Button>{" "}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexGrow: "1",
          gap: "20px",
          margin: "20px 30px",
        }}
      >
        <div className="address-container">
          {allAddresses.length > 0 ? (
            allAddresses.map((address, index) => (
              <Card
                key={index}
                title={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          color: "#18191C",
                          fontSize: "18px",
                          fontWeight: "400",
                        }}
                      >
                        {address.addressType || "Address"}
                      </span>
                    </div>
                    <div className="flex gap-3">
                      <span style={{ color: "#2A4DAD", cursor: "pointer" }}>
                        <TbEdit
                          style={{ width: "20px", height: "20px" }}
                          onClick={() => {
                            setAddressModal(true);
                            setAddressUpdateFlag(true);
                            setSelectedAddress(address);
                          }}
                        />
                      </span>
                      <span style={{ color: "#2A4DAD", cursor: "pointer" }}>
                        <MdDeleteForever
                          style={{ width: "20px", height: "20px" }}
                          onClick={() => {
                            setAddressDeleteModal(true);
                            setAddressDeleteFlag(true);
                            setSelectedAddress(address);
                          }}
                        />
                      </span>
                    </div>
                  </div>
                }
                bordered={false}
                style={{ width: 400, marginBottom: "20px" }}
              >
                <span>
                  {address.addressLine1} {address.addressLine2} {address.city}{" "}
                  {address.state} {address.country} {address.pincode}
                </span>
              </Card>
            ))
          ) : (
            <div
              style={{
                width: "60vw",
                height: "10vh",
                backgroundColor: "#fff",
                borderRadius: "10px",
              }}
            >
              {" "}
              <h2
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyItems: "center",
                }}
              >
                No Address
              </h2>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-between align-center mx-5 my-5">
        <div className="text-3xl font-semibold" style={{ color: "#2A4DAD" }}>
          Contact
        </div>
        <div
           onClick={handleAddContactClick}
          className="flex align-center"
        >
          {" "}
          <Button
            size="large"
            type="primary"
            style={{ backgroundColor: "#2A4DAD" }}
          >
            {" "}
            <FaRegSquarePlus
              style={{ color: "white", width: "20px", height: "20px" }}
            />{" "}
            <p className="text-base font-semibold">Add Contact</p>
          </Button>{" "}
        </div>
      </div>
      <div className="dexkor-sales-hub-task-table">
        <Table
          columns={columns}
          dataSource={Array.isArray(filteredPOCs) ? filteredPOCs : []}
          loading={loading}
        />
      </div>
      <Modal
        open={contactModal}
        width={600}
        title={contactUpdateFlag ? "Update Contact" : "Add Contact"}
        onCancel={handleCancel}
        footer={null} // We will handle buttons inside the form
      >
        <Form
          form={form}
          // initialValues={newPoc}
          onFinish={contactUpdateFlag ? handleUpdatePOC : handleAddPOC}
          onFinishFailed={(errorInfo) => {
            console.log("Validation failed:", errorInfo);
          }}
          layout="vertical"
        >
          <Row style={{ alignItems: "center", marginBottom: "10px" }}>
            <Col span={8}>
              <Form.Item>
                <p>First Name:</p>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="firstName"
                rules={[
                  { required: true, message: "Please enter first name!" },
                ]}
              >
                <Input
                  placeholder="Please Enter First Name"
                  name="firstName"
                  onChange={(e) => handleInputChange(e)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ alignItems: "center", marginBottom: "10px" }}>
            <Col span={8}>
              <Form.Item>
                <p>Last Name:</p>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="lastName"
                rules={[{ required: true, message: "Please enter last name!" }]}
              >
                <Input
                  placeholder="Please Enter Last Name"
                  name="lastName"
                  onChange={(e) => handleInputChange(e)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ alignItems: "center", marginBottom: "10px" }}>
            <Col span={8}>
              <Form.Item>
                <p>Email:</p>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please enter valid email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input
                  placeholder="Please Enter Valid Email"
                  name="email"
                  disabled={contactUpdateFlag}
                  onChange={(e) => handleInputChange(e)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ alignItems: "center", marginBottom: "10px" }}>
            <Col span={8}>
              <Form.Item>
                <p>Phone:</p>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="phone"
                rules={[
                  { required: true, message: "Please enter phone number!" },
                ]}
              >
                <Input
                  placeholder="Please Enter Phone Number"
                  name="phone"
                  onChange={(e) => handleInputChange(e)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ alignItems: "center", marginBottom: "10px" }}>
            <Col span={8}>
              <Form.Item>
                <p>Location:</p>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="location"
                rules={[{ required: true, message: "Please enter location!" }]}
              >
                <Input
                  placeholder="Please Enter Your Location"
                  name="location"
                  onChange={(e) => handleInputChange(e)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ alignItems: "center", marginBottom: "10px" }}>
            <Col span={8}>
              <Form.Item>
                <p>Designation:</p>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="designation"
                rules={[
                  { required: true, message: "Please enter designation!" },
                ]}
              >
                <Input
                  placeholder="Please Enter Your Designation"
                  name="designation"
                  onChange={(e) => handleInputChange(e)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ alignItems: "center", marginBottom: "10px" }}>
            <Col span={8}>
              <Form.Item>
                <p>User Type:</p>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="userType"
                rules={[
                  { required: true, message: "Please select the user type!" },
                ]}
              >
                <Select
                  allowClear
                  placeholder="Please Select the Type"
                  name="userType"
                  style={{ width: "100%", height: "45px" }}
                  onChange={
                    (value) => handleInputChange({ name: "userType", value }) // Pass name and value directly
                  }
                  options={[
                    { value: "CXO", label: "CXO" },
                    { value: "Owner", label: "Owner" },
                    { value: "DecisionMaker", label: "Decision Maker" },
                    { value: "Influencer", label: "Influencer" },
                    { value: "Champion", label: "Champion" },
                    { value: "User", label: "User" },
                    { value: "POC", label: "Point Of Contact" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <div className="flex justify-end mt-5">
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              style={{ marginLeft: "10px" }}
            >
              {contactUpdateFlag ? "Update" : "Submit"}
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        open={addressModal}
        width={600}
        title={ addressUpdateFlag ? "Update Address" : "Add Address" }
        onCancel={handleCancel}
        footer={null}
      >
        <>
        <Form
          form={form}
          // initialValues={newPoc}
          onFinish={addressUpdateFlag ? handleUpdateAddress : handleAddAddress}
          onFinishFailed={(errorInfo) => {
            console.log("Validation failed:", errorInfo);
          }}
          layout="vertical"
        >
         <Row style={{ alignItems: "center", marginBottom: "10px" }}>
            <Col span={8}>
              <Form.Item>
                <p>Address Type:</p>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="addressType"
                rules={[
                  { required: true, message: "Please enter Address Type" },
                ]}
              >
                <Input
                  placeholder="Please Enter Address Type"
                  name="addressType"
                  onChange={(e) => handleAddressChange(e)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ alignItems: "center", marginBottom: "10px" }}>
            <Col span={8}>
              <Form.Item>
                <p>Address Line 1:</p>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="addressLine1"
                rules={[
                  { required: true, message: "Please enter address line 1!" },
                ]}
              >
                <Input
                  placeholder="Please Enter Address Line 1"
                  name="addressLine1"
                  onChange={(e) => handleAddressChange(e)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ alignItems: "center", marginBottom: "10px" }}>
            <Col span={8}>
              <Form.Item>
                <p>Address Line 2:</p>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="addressLine2"
                rules={[
                  { required: true, message: "Please enter address line 2!" },
                ]}
              >
                <Input
                  placeholder="Please Enter Address Line 2"
                  name="firstName"
                  onChange={(e) => handleAddressChange(e)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ alignItems: "center", marginBottom: "10px" }}>
            <Col span={8}>
              <Form.Item>
                <p>City:</p>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="city"
                rules={[
                  { required: true, message: "Please enter city name!" },
                ]}
              >
                <Input
                  placeholder="Please Enter City Name"
                  name="city"
                  onChange={(e) => handleAddressChange(e)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ alignItems: "center", marginBottom: "10px" }}>
            <Col span={8}>
              <Form.Item>
                <p>State:</p>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="state"
                rules={[
                  { required: true, message: "Please enter state name!" },
                ]}
              >
                <Input
                  placeholder="Please Enter State Name"
                  name="state"
                  onChange={(e) => handleAddressChange(e)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ alignItems: "center", marginBottom: "10px" }}>
            <Col span={8}>
              <Form.Item>
                <p>Country:</p>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="country"
                rules={[
                  { required: true, message: "Please enter country name!" },
                ]}
              >
                <Input
                  placeholder="Please Enter First Name"
                  name="country"
                  onChange={(e) => handleAddressChange(e)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ alignItems: "center", marginBottom: "10px" }}>
            <Col span={8}>
              <Form.Item>
                <p>Pin Code:</p>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="pinCode"
                rules={[
                  { required: true, message: "Please enter pin code!" },
                ]}
              >
                <Input
                  placeholder="Please Enter Pin Code"
                  name="pinCode"
                  onChange={(e) => handleAddressChange(e)}
                />
              </Form.Item>
            </Col>
          </Row>

          <div className="flex justify-end mt-5">
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              style={{ marginLeft: "10px" }}
            >
              {contactUpdateFlag ? "Update" : "Submit"}
            </Button>
          </div>
          </Form>
        </>
      </Modal>
      <Modal
        open={addressDeleteModal}
        title={
          addressDeleteFlag ? (
            <div style={{ fontFamily: "roboto" }}>Delete Address</div>
          ) : (
            <div style={{ fontFamily: "roboto" }}>Delete Contact</div>
          )
        }
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {addressDeleteFlag ? (
          <div>
            <p
              className="flex justify-center items-center text-lg"
              style={{ fontFamily: "roboto" }}
            >
              Are you sure you want to delete the address?
            </p>
            <div className="flex items-center justify-center pt-5">
              <Button>Cancel</Button>
              <Button onClick={() => handleDeleteAddress(selectedAddress)}>
                Delete
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <p  className="flex justify-center items-center text-lg"
              style={{ fontFamily: "roboto" }}>Are you sure you want to delete the Contact?</p>
            <div className="flex items-center justify-center pt-5">
              <Button>Cancel</Button>
              <Button onClick={() => handleDeletePOC(selectedContact)}>
                Delete
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CompanyDetails;
