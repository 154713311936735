import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Default storage (localStorage)
import { combineReducers } from 'redux'; // Import combineReducers
import userReducer from "../Slice/userSlice";
import ticketReducer from "../Slice/userSlice"; // Import the correct ticket slice
import starboardBeerDonationPostsReducer from "../Slice/starboardBeerDonationPostsSlice";
import ticketExplorerReducer from "../reducers/ticketExplorerReducer";

// Configuration for redux-persist
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "tickets", "starboardBeerDonationPosts"], // Specify which reducers to persist
};

// Combine reducers
const rootReducer = combineReducers({
  user: userReducer,
  tickets: ticketReducer,
  starboardBeerDonationPosts: starboardBeerDonationPostsReducer,
  ticketExplorer: ticketExplorerReducer
});

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

// Create a persistor object
export const persistor = persistStore(store);
export default store;