import React, { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import storeToken from "./storeToken";
import axios from "axios";
import useNotification from "../components/snackbars/SnackBar";

function Otp() {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [conf, setConf] = useNotification();
  const navigate = useNavigate();
  const inputRefs = useRef([]);

  const location = useLocation();
  const { email } = location.state || {};
  const { uniquePartnerId } = location.state || {};

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Focus next input
    if (index < otp.length - 1 && element.value) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      setOtp([...otp.map((d, idx) => (idx === index ? "" : d))]);

      // Focus previous input
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text");
    if (pasteData.length === otp.length && !isNaN(pasteData)) {
      const newOtp = pasteData.split("");
      setOtp(newOtp);

      // Focus last input field
      inputRefs.current[otp.length - 1].focus();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const otpCode = otp.join("");

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/otp/verify/user`,
        { email, otp: otpCode, uniquePartnerId: uniquePartnerId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      storeToken(res.data.token);
      navigate("/login");
      setConf({ msg: "Registration Successfully", variant: "success" });
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-12">
      <div className="relative bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl">
        <div className="mx-auto flex w-full max-w-md flex-col space-y-16">
          <div className="flex flex-col items-center justify-center text-center space-y-2">
            <div className="font-semibold text-3xl">
              <p>Email Verification</p>
            </div>
            <div className="flex flex-row text-sm font-medium text-gray-400">
              <p>We have sent a code to your email {email}</p>
            </div>
          </div>

          <div>
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col space-y-16">
                <div className="flex flex-row items-center justify-between mx-auto w-full max-w-xs">
                  {otp.map((data, index) => (
                    <div key={index} className="w-12 h-12">
                      <input
                        className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                        type="text"
                        name="otp"
                        maxLength="1"
                        value={data}
                        ref={(el) => (inputRefs.current[index] = el)}
                        onChange={(e) => handleChange(e.target, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onFocus={(e) => e.target.select()}
                        onPaste={handlePaste}
                      />
                    </div>
                  ))}
                </div>

                <div className="flex flex-col space-y-5">
                  <div>
                    <button className="flex flex-row items-center justify-center text-center w-full border rounded-xl outline-none py-5 bg-blue-700 border-none text-white text-sm shadow-sm">
                      Verify Account
                    </button>
                  </div>

                  <div className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                    <p>Didn't receive code?</p>{" "}
                    <a
                      className="flex flex-row items-center text-blue-600"
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Resend
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Otp;
