import React, { useState, useEffect } from "react";
import logo from "../../../Images/Logo icon.png";
import "./AllTicket.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import io from "socket.io-client";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import DOMPurify from "dompurify";
import moment from "moment";
import { Row, Col, Button, Card, Input, Tabs, Modal, Form, Segmented } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { CiFilter } from "react-icons/ci";
import { BiMessageSquareEdit } from "react-icons/bi";
import Conversation from "../../../components/Chat Conversation/Conversation";

const socket = io(`${process.env.REACT_APP_CHAT_SERVER}`);

const AllTickets = ({ ticketData, selectedCompany, setTicketData }) => {
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [allCustomer, setAllCustomer] = useState([]);
  const [activeTicketID, setActiveTicketID] = useState(null)
  const [segmentValue, setSegmentValue] = useState('Open Tickets');
  const [openNewTicketModal, setOpenNewTicketModal] = useState(false)
  const {register, handleSubmit, formState} = useForm()


  const handleSearchChange = (e) =>
    setSearchQuery(e.target.value.toLowerCase());

  const handleSegmentChange = (value) => {
    setSegmentValue(value); // Set the segmented value when it changes
  };
 
  const showNewTicketModal = () => {
    setOpenNewTicketModal(true)
  }

  const handleNewTicketCreate = async (data) => {

    const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
    const userToken = localStorage.getItem("dexkorUserToken")
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONVERSATION}/tickets/create`,
        {
          uniquePartnerId: userData.uniquePartnerId,
          name: data.name,
          description: data.description,
          type: data.type,
          priority: data.priority,
          // tags: "Open"
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`, 
            Role: `${userData.role}`, 
            "Content-Type": "application/json"
          },
        }
      );

      const roomId = `Room-${new Date().getTime()}`;
    const customerId = selectedCompany._id
    const uniquePartnerId = selectedCompany.uniquePartnerId
    socket.emit("createRoom", uniquePartnerId, roomId, response.data._id, customerId, (response) => {
      if (response.success) {
        setTicketData((prevconversation) => [
          ...prevconversation,
          response.conversation]);
      }
    });
      
      setOpenNewTicketModal(false);
    } catch (error) {
      console.log(error);
      setOpenNewTicketModal(false);
    }
  };

  const handleCancel = () => {
    setOpenNewTicketModal(false);
  };

  const onFinishFailed = (errorInfo) => {
    setOpenNewTicketModal(false);
  };


  const filteredTickets = ticketData.filter((ticket) => {    
    const searchQueryLower = searchQuery.trim().toLowerCase();
    const matchesSearch =
      ticket.ticketId.ticketNumber && ticket.customerId === selectedCompany._id &&
      ticket.ticketId.ticketNumber.toLowerCase().includes(searchQueryLower);

    // Filter tickets based on the segment value (open or closed)
    const matchesSegment =
      segmentValue === 'Open Tickets'
        ? ticket.ticketId.status.toLowerCase() !== 'closed'
        : ticket.ticketId.status.toLowerCase() === 'closed';

    return matchesSearch && matchesSegment;
  });

  const renderAttachment = (attachment, attachmentType) => {
    const handlePreview = () => {
      window.open(attachment, "_blank");
    };

    if (attachmentType.includes("image")) {
      return (
        <img
          src={attachment}
          alt="attachment"
          className="w-22 max-w-xs rounded-lg"
        />
      );
    } else if (attachmentType.includes("video")) {
      return (
        <video src={attachment} controls className="w-22 max-w-xs rounded-lg" />
      );
    } else if (attachmentType.includes("audio")) {
      return <audio src={attachment} controls />;
    } else {
      return (
        <button
          onClick={handlePreview}
          className="bg-transparent hover:bg-transparent hover:text-black px-3 py-1 rounded"
        >
          Attachment preview
        </button>
      );
    }
  };

  return (
    <div>
    <Row className="dexkor-sales-hub-all-ticket-body">
      <Col span={12}>
        <div className="dexkor-sales-hub-all-ticket-list">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <Input
              style={{
                width: "90%",
                backgroundColor: "#F6F7FA",
                height: "45px",
              }}
              placeholder="Search Tickets"
              prefix={<SearchOutlined style={{ color: "#5F5F5F" }} />}
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <span style={{ display: "flex" }}>
              <div className="pl-2 custom-segmented" >
            <Segmented options={['Open Tickets', 'Closed Tickets']} value={segmentValue}
                    onChange={handleSegmentChange} />
            </div>
              <Button
                style={{
                  backgroundColor: "#2A4DAD",
                  color: "white",
                  height: "45px",
                }}
                onClick={showNewTicketModal}
              >
                <BiMessageSquareEdit
                  style={{ color: "white", fontSize: "26px" }}
                />{" "}
                New Ticket
              </Button>
            </span>
          </div>

          <div className="dexkor-sales-hub-all-ticket-view"
  
          >
            {filteredTickets.length > 0 ? (
              filteredTickets.map((ticket) => (
                <Card
                key={ticket.ticketId.ticketNumber}
                style={{
                  width: "90%",
                  margin: "10px 0",
                  cursor: "pointer",
                  backgroundColor: selectedTicket?.ticketId.ticketNumber === ticket.ticketId.ticketNumber ? '#e6f7ff' : 'white', // Highlight color
                  border: selectedTicket?.ticketId.ticketNumber === ticket.ticketId.ticketNumber ? '1px solid #1890ff' : '1px solid transparent', // Border color
                }}
                onClick={() => setSelectedTicket(ticket)}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                    }}
                  >
                    <span style={{ color: "#202020", fontSize: "20px" }}>
                      {ticket.ticketId.ticketNumber} - {ticket.ticketId.name}
                    </span>
                    <span>
                      {moment(ticket.createdAt).format("DD-MM-YYYY hh:mm A")}
                    </span>
                  </div>
                  <div
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "normal",
                      marginTop: "10px",
                    }}
                  >
                    <p
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        WebkitLineClamp: 4,
                        textOverflow: "ellipsis",
                        lineHeight: "1.5em",
                        maxHeight: "6em",
                        whiteSpace: "normal",
                      }}
                    >
                      {ticket.ticketId.description}
                    </p>
                  </div>
                  <p>{ticket.ticketId.status}</p>
                </Card>
              ))
            ) : (
              <div className="flex items-center justify-center text-3xl">No tickets available</div>
            )}
          </div>
        </div>
      </Col>
      <Col span={12}>
        <Tabs
          defaultActiveKey="1"
          style={{ paddingLeft: "10px", marginTop: "-20px" }}
          tabBarGutter={0}
          items={[
            {
              label: "Customer Interaction ",
              key: "1",
              children:  <div className="dexkor-sales-hub-all-ticket">
              <Conversation conversation={selectedTicket} />
            </div>,
            },
            {
              label: "Internal Communication",
              key: "2",
              // children: 'Tab 2',
              // disabled: true,
            },
          ]}
        />
      </Col>
    </Row>
    <Modal
    open={openNewTicketModal}
    title="Create New Ticket"
    footer={null}
    onCancel={handleCancel}
  >
    <Form
name="basic"
labelCol={{
  span: 8,
}}
wrapperCol={{
  span: 16,
}}
style={{
  maxWidth: 600,
}}
initialValues={{
  remember: true,
}}
onFinish={handleNewTicketCreate}
onFinishFailed={onFinishFailed}
autoComplete="off"
>
<Form.Item
  label="Name"
  name="name"
  {...register("name")}
>
  <Input />
</Form.Item>

<Form.Item
  label="Description"
  name="description"
>
  <Input {...register("description")} />
</Form.Item>

<Form.Item
  label="Type"
  name="type"
>
  <Input {...register("type")} />
</Form.Item>

<Form.Item
  label="Priority"
  name="priority"
>
  <Input {...register("priority")} />
</Form.Item>

<Form.Item
  wrapperCol={{
    offset: 8,
    span: 16,
  }}
>
   <Button type="primary" htmlType="submit" onClick={handleCancel}>
    Cancel
  </Button>
  <Button type="primary" htmlType="submit">
    Submit
  </Button>
</Form.Item>
</Form>
  </Modal>
  </div>
  );
};

export default AllTickets;
