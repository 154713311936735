import React from "react";
import { Route } from "react-router-dom";
import DemoDesk from "../DemoDesk"
import PrivateRoute from "../../Private Route/PrivateRoute";

const DemoDeskRoutes = () => {
  return (
    <>
    <Route path="/demo-desk" element={<PrivateRoute><DemoDesk /></PrivateRoute>}> 
    </Route>
    </>
  );
};

export default DemoDeskRoutes;
