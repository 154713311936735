import { createSlice } from '@reduxjs/toolkit';

const ticketSlice = createSlice({
  name: 'tickets',
  initialState: {
    ticketData: [],
  },
  reducers: {
    setTickets: (state, action) => {
      state.ticketData = action.payload;
    },
    clearTickets: (state) => {
      state.ticketData = [];
    },
  },
});

export const { setTickets, clearTickets } = ticketSlice.actions;
export default ticketSlice.reducer;
