import axios from "axios";
import React, { useEffect, useState } from "react";
import "./CheerNowPopUp.css";
import { RxCross2 } from "react-icons/rx";
import { IoChevronBack } from "react-icons/io5";
import useNotification from "../../components/snackbars/SnackBar";
import { useDispatch } from "react-redux";
import { updateStarboardBeerDonationPosts } from "../../Redux/Slice/starboardBeerDonationPostsSlice";

function CheerNowPopUp({
  isModalOpen,
  toggleModal,
  beerFunds,
  setBeerFunds,
  userData,
  setLoading,
}) {
  const [conf, setConf] = useNotification();
  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [inputFiedlsFlag, setInputFieldsFlag] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedCoreValues, setSelectedCoreValues] = useState([]);
  const [selectedBeersPerPerson, setSelectedBeersPerPerson] = useState(10);
  const [totalBeerAmount, setTotalBeerAmount] = useState(0);
  const [beersPerPerson, setBeersPerPerson] = useState([
    { amount: 10 },
    { amount: 20 },
    { amount: 30 },
    { amount: 40 },
    { amount: 50 },
  ]);
  const [coreValues, setCoreValues] = useState([
    { name: "Collabration", color: "text-blue-800", background: "bg-blue-200" },
    { name: "Integrity", color: "text-gray-800", background: "bg-gray-200" },
    { name: "Innovation", color: "text-red-800", background: "bg-red-200" },
    {
      name: "Customer Focus",
      color: "text-green-800",
      background: "bg-green-200",
    },
    {
      name: "Excellence",
      color: "text-yellow-800",
      background: "bg-yellow-200",
    },
    {
      name: "Accountability",
      color: "text-indigo-800",
      background: "bg-indigo-200",
    },
    { name: "Respect", color: "text-purple-800", background: "bg-purple-200" },
    {
      name: "Sustainability",
      color: "text-pink-800",
      background: "bg-pink-200",
    },
  ]);

  useEffect(() => {
    setTotalBeerAmount(selectedUsers.length * selectedBeersPerPerson);
  }, [selectedUsers, selectedBeersPerPerson]);

  const handleSelectCoreValue = (coreValue) => {
    setSelectedCoreValues((prevSelectedCoreValues) => {
      // Check if the core value is already selected
      const isSelected = prevSelectedCoreValues.some(
        (selectedCoreValue) => selectedCoreValue.name === coreValue.name
      );

      if (isSelected) {
        // If the core value is selected, remove it
        return prevSelectedCoreValues.filter(
          (selectedCoreValue) => selectedCoreValue.name !== coreValue.name
        );
      } else {
        // If the core value is not selected, add it
        return [...prevSelectedCoreValues, coreValue];
      }
    });
  };

  const handleCheckboxChange = (user) => {
    setSelectedUsers((prevUsers) => {
      if (prevUsers.some((selectedUser) => selectedUser === user)) {
        // If user is already selected, remove them
        return prevUsers.filter((selectedUser) => selectedUser !== user);
      } else {
        // If user is not selected, add them
        return [...prevUsers, user];
      }
    });
    if (selectedUsers.length === 0) {
      setInputFieldsFlag(false);
      setSelectedCoreValues([]);
      setMessage("");
    }
  };

  useEffect(() => {
    // Define an async function to fetch all users
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/all/${userData.uniquePartnerId}`
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    // Call the fetch function
    if (isModalOpen) {
      fetchUsers();
    }
  }, [isModalOpen]); // Empty dependency array ensures this runs once when the component mounts

  useEffect(() => {
    // Define an async function to filter all users
    const searchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/filter/${userData.uniquePartnerId}`,
          {
            params: { query: searchQuery }, // Pass the query as a query parameter
          }
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    // Call the fetch function
    if (searchQuery) {
      searchUsers();
    }
  }, [searchQuery]); // Empty dependency array ensures this runs once when the component mounts

  const handleSubmit = async () => {
    if (
      message === "" ||
      !selectedBeersPerPerson ||
      selectedCoreValues.length === 0
    ) {
      setConf({
        msg: "Please fill all the required fields!",
        variant: "warning",
        style: {
          backgroundColor: "red",
        },
      });
      return;
    }

    if (totalBeerAmount > beerFunds.beerDonationQuota) {
      setConf({
        msg: "Your total beers are exceeding your beer quota!",
        variant: "warning",
      });
      return;
    }

    const beersLeft = beerFunds.beerDonationQuota - totalBeerAmount;

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_CONVERSATION}/starboard/beer-fund/${userData._id}`,
        {
          beerDonationQuota: beersLeft,
        }
      );
      if (response.data.success) {
        setBeerFunds(response.data.data);
      }
    } catch (error) {
      console.error(`Error fetching beer fund: ${error}`);
      setConf({
        msg: "An error occurred.",
        variant: "error",
      });
    }

    try {
      setLoading(true);
      toggleModal();
      const response = await axios.post(
        `${process.env.REACT_APP_CONVERSATION}/starboard/beerDonationPost/create`,
        {
          donorId: userData._id,
          recipientIds: selectedUsers,
          beerAmount: selectedBeersPerPerson,
          message: message,
          coreValues: selectedCoreValues,
          uniquePartnerId: userData.uniquePartnerId,
        }
      );
      if (response.data.response.success) {
        setConf({
          msg: "Beer donation post created.",
          variant: "success",
        });
        dispatch(updateStarboardBeerDonationPosts(response.data.response.data));
        setMessage("");
        setSelectedCoreValues([]);
        setSelectedUsers([]);
        setSelectedBeersPerPerson(10);
        setTotalBeerAmount(0);
        setInputFieldsFlag(false);
        setSearchQuery("");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error creating BeerDonationPost:", error);
      setConf({
        msg: "An error occurred.",
      });
      setLoading(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen bg-black bg-opacity-40"
          onClick={toggleModal}
        >
          <div
            className="relative p-2 w-1/2 max-w-md max-h-fit bg-white rounded-lg shadow"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          >
            <div className="flex items-center justify-between p-4 h-10 border-b rounded-t">
              <div className="inline-flex items-center h-6">
                {inputFiedlsFlag && (
                  <p
                    onClick={() => setInputFieldsFlag(false)}
                    className="bg-transparent hover:bg-transparent text-black p-0 mr-2 cursor-pointer hover:text-blue-600"
                  >
                    <IoChevronBack className="h-6 w-6" />
                  </p>
                )}
                <h3 className="text-xl font-semibold text-gray-900">
                  Spark Now
                </h3>
              </div>
            </div>
            <div className="p-4 md:p-5 space-y-3">
              <div>
                <p className="inline-flex items-center text-sm font-medium text-center text-black  rounded-lg">
                  Selected People
                  <span className="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                    {selectedUsers.length}
                  </span>
                </p>
                <div className="flex flex-wrap mt-3 ml-1">
                  {selectedUsers.length > 0 ? (
                    selectedUsers.map((seletedUser) => (
                      <div className="mr-4 ">
                        <div className="relative inline-flex items-center justify-center rounded-lg">
                          <img
                            className="w-8 h-8 rounded-full object-cover mr-1 shadow"
                            src="https://images.unsplash.com/photo-1542156822-6924d1a71ace?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
                            alt="avatar"
                          />
                          <div
                            onClick={() => handleCheckboxChange(seletedUser)}
                            className="absolute inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-1 -end-1"
                          >
                            <RxCross2 />
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>You have not selected any people</p>
                  )}
                </div>
              </div>
              {inputFiedlsFlag && selectedUsers.length > 0 ? (
                <div>
                  <label
                    for="message"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Message*
                  </label>
                  <textarea
                    id="message"
                    rows="2"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="block p-1 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 :bg-gray-700"
                    placeholder="Write your thoughts here..."
                  ></textarea>
                  <div className="mt-4">
                    <p className="inline-flex items-center text-sm font-medium text-center text-black  rounded-lg">
                      Select core values*
                      <span className="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                        {selectedCoreValues.length}
                      </span>
                    </p>
                    <div className="flex flex-wrap py-1 gap-y-2 mt-1">
                      {coreValues.length > 0 &&
                        coreValues.map((coreValue, index) => (
                          <span
                            key={index}
                            onClick={() => handleSelectCoreValue(coreValue)}
                            className={`${
                              selectedCoreValues.includes(coreValue)
                                ? "bg-black text-white"
                                : coreValue.background + " " + coreValue.color
                            }  text-xs font-medium me-2 px-2.5 py-0.5 rounded cursor-pointer`}
                          >
                            {coreValue.name}
                          </span>
                        ))}
                    </div>
                  </div>
                  <div className="mt-4">
                    <p className="inline-flex items-center text-sm font-medium text-center text-black  rounded-lg">
                      Star per person*
                    </p>
                    <div className="flex  py-1 gap-y-2 mt-1 items-center">
                      {beersPerPerson.length > 0 &&
                        beersPerPerson.map((beerPerPerson, index) => (
                          <p
                            key={index}
                            className={`${
                              selectedBeersPerPerson === beerPerPerson.amount &&
                              "bg-black text-white"
                            } border border-gray-500 px-2 rounded-full mr-2 cursor-pointer`}
                            // className="border border-gray-500 px-4 py-2 rounded-full mr-2 cursor-pointer"
                            onClick={() =>
                              setSelectedBeersPerPerson(beerPerPerson.amount)
                            }
                          >
                            {beerPerPerson.amount}
                          </p>
                        ))}
                      <p className="ml-auto bg-blue-800 text-white px-4 py-2 rounded-lg text-xs">
                        <div>
                          Your star quota:{" "}
                          <span className="font-bold">
                            {beerFunds.beerDonationQuota}
                          </span>
                        </div>
                        Your total star amount:{" "}
                        <span className="font-bold">{totalBeerAmount}</span>
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <p className="text-sm font-medium text-black  rounded-lg">
                    Select People
                  </p>
                  <div className="relative w-full text-gray-600 mt-1">
                    <input
                      className="border-2 border-gray-300 w-4/5 bg-white h-8 rounded-lg text-sm focus:outline-none"
                      type="search"
                      name="search"
                      value={searchQuery}
                      placeholder="Search"
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                  <div className="p-1 pl-3 mt-1 p-1 pl-3 mt-1 h-48 overflow-hidden overflow-y-auto">
                    <ul className="mt-2 max-w-md divide-y divide-gray-300">
                      {users.length > 0 &&
                        users.map((user, index) => (
                          <li key={index} className="pt-2 pb-2 sm:pb-4">
                            <div className="flex items-center space-x-4 rtl:space-x-reverse">
                              <div className="flex-shrink-0 pt-0 items-center justify-center">
                                <input
                                  id={`checkbox-${user._id}`}
                                  type="checkbox"
                                  checked={selectedUsers.some(
                                    (selectedUser) => selectedUser === user._id
                                  )}
                                  className="text-blue-600 bg-gray-100 border-gray-300 rounded"
                                  onChange={() =>
                                    handleCheckboxChange(user._id)
                                  }
                                />
                              </div>
                              <div className="flex-shrink-0">
                                <img
                                  className="w-6 h-6 rounded-full"
                                  src="https://pbs.twimg.com/media/BcINeMVCIAABeWd.jpg:large"
                                  alt="Neil image"
                                />
                              </div>
                              <div className="flex-1 min-w-0">
                                <p className="text-sm font-medium text-gray-900 truncate">
                                  {user.name}
                                </p>
                                <p className="text-sm text-gray-500 truncate">
                                  {user.email}
                                </p>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>

            <div className="flex items-center justify-between p-4 md:p-5 border-t border-gray-200 rounded-b">
              <button
                type="button"
                className="dexkor-spark-now-next-button"
                onClick={toggleModal}
              >
                Cancel
              </button>
              {inputFiedlsFlag
                ? selectedUsers.length > 0 && (
                    <button
                      onClick={handleSubmit}
                      className="dexkor-spark-now-next-button"
                    >
                      Submit
                    </button>
                  )
                : selectedUsers.length > 0 && (
                    <button
                      onClick={() => setInputFieldsFlag(true)}
                      className="dexkor-spark-now-next-button"
                    >
                      Next
                    </button>
                  )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CheerNowPopUp;
