import React, { useState, useEffect } from "react";
import "./Index.css";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { Menu, Row, Col } from "antd";
import { useSelector } from "react-redux";

const Index = () => {
  const [selectedKey, setSelectedKey] = useState("0");
  const [openKeys, setOpenKeys] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  // Get the Ticket Explorer views from the Redux store
  const ticketExplorerViews = useSelector(
    (state) => state.ticketExplorer.views
  );

  // Dynamically create "Ticket Explorer" children based on ticketExplorerViews
  const ticketExplorerChildren = ticketExplorerViews.map((view) => ({
    key: `ticketExplorer-${view._id}`, // Unique key for each view
    label: view.name, // Assuming each view has a `name` field
    path: `/help-desk/ticket-explorer/${view.name
      .replace(/\s+/g, "-")
      .toLowerCase()}`, // Assuming each view has a unique `_id` to use in the path
  }));

  const items = [
    {
      key: "1",
      label: "Inbox",
      children: [
        {
          key: "12",
          label: "Tickets",
          path: "/help-desk/inbox/tickets",
        },
        { key: "13", label: "Emails", path: "/help-desk/inbox/emails" },
      ],
    },
    {
      key: "2",
      label: "Assigned",
      children: [
        { key: "17", label: "Tickets", path: "/help-desk/assigned/tickets" },
        { key: "25", label: "Emails", path: "/help-desk/assigned/emails" },
      ],
    },
    {
      key: "3",
      label: "Snoozed",
      children: [
        { key: "15", label: "Tickets", path: "/help-desk/snoozed/tickets" },
        { key: "16", label: "Emails", path: "/help-desk/snoozed/emails" },
      ],
    },
    {
      key: "4",
      label: "My Team Queue",
      children: [
        {
          key: "18",
          label: "All Pending",
          path: "/help-desk/my-team-queue/all-pendings",
        },
        {
          key: "19",
          label: "SLA Breached",
          path: "/help-desk/my-team-queue/sla-breached",
        },
        {
          key: "20",
          label: "Approaching SLA Breach",
          path: "/help-desk/my-team-queue/approaching-sla-breach",
        },
      ],
    },
    {
      key: "5",
      label: "Ticket Explorer",
      children: [...ticketExplorerChildren],
    },
  ];

  const getMenuItems = (menuItems) =>
    menuItems.map((item) => ({
      key: item.key,
      label: (
        <div
          onClick={(e) => {
            if (
              item.path &&
              !e.target.classList.contains("ant-menu-submenu-arrow")
            ) {
              navigate(item.path);
            }
          }}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          {item.label}
          {item.number !== undefined && (
            <span style={{ marginLeft: "auto" }}>{item.number}</span>
          )}
        </div>
      ),
      children: item.children ? getMenuItems(item.children) : null,
    }));

  const handleMenuClick = (e) => {
    const selectedItem = items
      .flatMap((item) => item.children || [item])
      .find((item) => item.key === e.key);
    if (selectedItem && selectedItem.path) {
      navigate(selectedItem.path);
    }
    setSelectedKey(e.key);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const currentItem = items
      .flatMap((item) => item.children || item)
      .find((item) => item.path === currentPath);
    if (currentItem) {
      setSelectedKey(currentItem.key);
      const parentKey = getParentKey(currentItem.key, items);
      if (parentKey) {
        setOpenKeys([parentKey]);
      }
    }
  }, [location]);

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const getParentKey = (childKey, menuItems) => {
    for (let item of menuItems) {
      if (item.children) {
        for (let child of item.children) {
          if (child.key === childKey) {
            return item.key;
          }
        }
      }
    }
    return null;
  };

  return (
    <>
      <Row style={{ height: "100vh" }}>
        <Col xs={24} sm={6} md={5} lg={4} xl={3}>
          <div
            style={{ width: "100%", padding: "0px", boxSizing: "border-box" }}
          >
            <div style={{ height: "40px", borderBottom: "2px solid #f0f0f0" }}>
              <p
                style={{
                  fontSize: "20px",
                  marginLeft: "16px",
                  marginTop: "16px",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                }}
              >
                Help Desk
              </p>
            </div>

            <Menu
              mode="inline"
              selectedKeys={[selectedKey]}
              onClick={handleMenuClick}
              onOpenChange={onOpenChange}
              style={{
                height: "calc(100vh - 118px)",
                overflowY: "auto",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: "600",
              }}
              className="dexkor-help-desk-left-bar"
              items={getMenuItems(items)}
              openKeys={openKeys}
            />
          </div>
        </Col>
        <Col
          xs={24}
          sm={18}
          md={19}
          lg={20}
          xl={21}
          style={{ backgroundColor: "#ffffff" }}
        >
          <Outlet />
        </Col>
      </Row>
    </>
  );
};

export default Index;
