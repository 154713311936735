import React, { useState } from "react";
import "./PartnerRegistrationForm.css";
import { RiDeleteBin2Fill } from "react-icons/ri";
import PartnerOtp from "./PartnerOtp";
import FinalPage from "./FinalPage";
import axios from "axios";
import useNotification from "../components/snackbars/SnackBar";
import SubmitLoader from "./SubmitLoader";

const PartnerRegistrationForm = ({ onSubmit }) => {
  const [renderComponents, setRenderComponents] = useState("form");
  const [conf, setConf] = useNotification();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    partnerName: "",
    partnerUrl: "",
    email: "",
    phone: "",
    addresses: [
      {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
      },
    ],
    pointsOfContact: [{ name: "", email: "", phone: "", position: "" }],
  });

  const [currentStep, setCurrentStep] = useState(1);

  // Handle basic input change for partner info (not addresses/POC)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle dynamic change for fields like addresses and POCs
  const handleDynamicChange = (index, field, subField, value) => {
    const updatedField = formData[field].map((item, i) =>
      i === index ? { ...item, [subField]: value } : item
    );
    setFormData({
      ...formData,
      [field]: updatedField,
    });
  };

  // Add more address or POC
  const handleAddField = (field) => {
    const newField =
      field === "addresses"
        ? {
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            postalCode: "",
            country: "",
          }
        : { name: "", email: "", phone: "", position: "" };

    setFormData({
      ...formData,
      [field]: [...formData[field], newField],
    });
  };

  // Remove specific address or POC
  const handleRemoveField = (index, field) => {
    const updatedField = formData[field].filter((_, i) => i !== index);
    setFormData({
      ...formData,
      [field]: updatedField,
    });
  };

  // Helper function to generate a random password
  const generateRandomPassword = (length = 12) => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+[]{}|;:,.<>?";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      formData.partnerName === "" ||
      formData.email === "" ||
      formData.phone === "" ||
      formData.partnerUrl === ""
    ) {
      setConf({ msg: "Fill all required fields!", variant: "warning" });
      return;
    }

    const randomPassword = generateRandomPassword(); // Generate a random password
    setFormData((prevFormData) => ({
      ...prevFormData,
      randomPassword,
    }));
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/auth/signup`,
        {
          name: "Admin",
          email: formData.email,
          phone: formData.phone,
          password: randomPassword, // Use the generated password
          role: "Admin",
        }
      );
      // Check the response status code
      if (response.status === 200) {
        console.log("Otp sent to email ", response.data);
        setConf({ msg: "Otp sent", variant: "success" });
        setRenderComponents("otp");
      } else {
        console.log("Sign up failed with status code:", response.status);
        setConf({ msg: "Sign up failed.", variant: "error" });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error creating the admin user for partner:", error);
      if (error.response.status === 400) {
        setConf({ msg: "Email is already registered", variant: "error" });
      }
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            {/* Basic Partner Info */}
            <div>
              <label
                htmlFor="partnerName"
                className="block text-sm font-medium text-gray-700"
              >
                Partner Name
              </label>
              <input
                type="text"
                id="partnerName"
                name="partnerName"
                value={formData.partnerName}
                onChange={handleInputChange}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              />
            </div>

            <div>
              <label
                htmlFor="partnerUrl"
                className="block text-sm font-medium text-gray-700"
              >
                Website URL
              </label>
              <input
                type="url"
                id="partnerUrl"
                name="partnerUrl"
                value={formData.partnerUrl}
                onChange={handleInputChange}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              />
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Phone
              </label>
              <input
                type="number"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            {/* Dynamic Addresses */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Addresses
              </label>
              {formData.addresses.map((address, index) => (
                <div key={index} className="space-y-2 mb-4">
                  <div className="flex space-x-2">
                    <input
                      type="text"
                      placeholder="Address Line 1"
                      value={address.addressLine1}
                      onChange={(e) =>
                        handleDynamicChange(
                          index,
                          "addresses",
                          "addressLine1",
                          e.target.value
                        )
                      }
                      className="w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                    <input
                      type="text"
                      placeholder="Address Line 2"
                      value={address.addressLine2}
                      onChange={(e) =>
                        handleDynamicChange(
                          index,
                          "addresses",
                          "addressLine2",
                          e.target.value
                        )
                      }
                      className="w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>
                  <div className="flex space-x-2">
                    <input
                      type="text"
                      placeholder="City"
                      value={address.city}
                      onChange={(e) =>
                        handleDynamicChange(
                          index,
                          "addresses",
                          "city",
                          e.target.value
                        )
                      }
                      className="w-1/3 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                    <input
                      type="text"
                      placeholder="State"
                      value={address.state}
                      onChange={(e) =>
                        handleDynamicChange(
                          index,
                          "addresses",
                          "state",
                          e.target.value
                        )
                      }
                      className="w-1/3 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                    <input
                      type="number"
                      placeholder="Postal Code"
                      value={address.postalCode}
                      onChange={(e) =>
                        handleDynamicChange(
                          index,
                          "addresses",
                          "postalCode",
                          e.target.value
                        )
                      }
                      className="w-1/3 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>
                  <input
                    type="text"
                    placeholder="Country"
                    value={address.country}
                    onChange={(e) =>
                      handleDynamicChange(
                        index,
                        "addresses",
                        "country",
                        e.target.value
                      )
                    }
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveField(index, "addresses")}
                    className="dexkor-partner-button text-red-500"
                  >
                    <RiDeleteBin2Fill />
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => handleAddField("addresses")}
                className="dexkor-partner-button"
              >
                + Add Address
              </button>
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            {/* Dynamic Points of Contact */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Points of Contact
              </label>
              {formData.pointsOfContact.map((poc, index) => (
                <div key={index} className="space-y-2 mb-4">
                  <div className="flex space-x-2">
                    <input
                      type="text"
                      placeholder="Name"
                      value={poc.name}
                      onChange={(e) =>
                        handleDynamicChange(
                          index,
                          "pointsOfContact",
                          "name",
                          e.target.value
                        )
                      }
                      className="w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                    <input
                      type="email"
                      placeholder="Email"
                      value={poc.email}
                      onChange={(e) =>
                        handleDynamicChange(
                          index,
                          "pointsOfContact",
                          "email",
                          e.target.value
                        )
                      }
                      className="w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>
                  <div className="flex space-x-2">
                    <input
                      type="number"
                      placeholder="Phone"
                      value={poc.phone}
                      onChange={(e) =>
                        handleDynamicChange(
                          index,
                          "pointsOfContact",
                          "phone",
                          e.target.value
                        )
                      }
                      className="w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                    <input
                      type="text"
                      placeholder="Position"
                      value={poc.position}
                      onChange={(e) =>
                        handleDynamicChange(
                          index,
                          "pointsOfContact",
                          "position",
                          e.target.value
                        )
                      }
                      className="w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => handleRemoveField(index, "pointsOfContact")}
                    className="dexkor-partner-button"
                  >
                    <RiDeleteBin2Fill />
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => handleAddField("pointsOfContact")}
                className="dexkor-partner-button"
              >
                + Add Contact
              </button>
            </div>
          </div>
        );
      default:
        return <div>Unknown Step</div>;
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      {renderComponents === "form" && (
        <form
          onSubmit={handleSubmit}
          className="w-full max-w-lg p-6 bg-white rounded-lg border border-gray-300 shadow-lg "
        >
          {/* Title */}
          <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">
            Register a New Partner
          </h2>
          <div className="p-4 bg-gray-50">
            <div className="flex justify-between items-center mb-4 mr-auto">
              {currentStep > 1 && (
                <button
                  type="button"
                  onClick={() =>
                    setCurrentStep((prev) => Math.max(prev - 1, 1))
                  }
                  disabled={currentStep === 1}
                  className="dexkor-partner-button"
                >
                  Previous
                </button>
              )}
              <span className="font-semibold">Step {currentStep} of 3</span>
              {currentStep < 3 && (
                <button
                  type="button"
                  onClick={() =>
                    setCurrentStep((prev) => Math.min(prev + 1, 3))
                  }
                  disabled={currentStep === 3}
                  className="dexkor-partner-button"
                >
                  Next
                </button>
              )}
            </div>
            {renderStep()}
            <div className="flex justify-end mt-4">
              {currentStep === 3 &&
                (loading ? (
                  <SubmitLoader />
                ) : (
                  <button type="submit" className="dexkor-partner-button">
                    Submit
                  </button>
                ))}
            </div>
          </div>
        </form>
      )}
      {renderComponents === "otp" && (
        <PartnerOtp
          setRenderComponents={setRenderComponents}
          formData={formData}
          resend={handleSubmit}
        />
      )}
      {renderComponents === "finalPage" && <FinalPage email={formData.email} />}
    </div>
  );
};

export default PartnerRegistrationForm;
