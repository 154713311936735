import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import logo from "../../../Images/Logo icon.png";
import googleLogo from "../../../Images/google-logo.png";
import TicketViewer from "./components/TicketViewer/TicketViewer";
import { RxCross1 } from "react-icons/rx";
import TicketExplorerViewForm from "./components/Form/TicketExplorerViewForm";

function TicketExplorer({ viewData }) {
  const [loading, setLoading] = useState(true);
  const [conversations, setConversations] = useState([]);
  const [selectedConvo, setSelectedConvo] = useState(null);
  const [filterReady, setFilterReady] = useState(false); // New state to track filter readiness
  const [keyword, setKeyword] = useState("");
  const [open, setOpen] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [showSaveButton, setShowSaveButton] = useState(null); // New state to control save button visibility
  const [showFilters, setShowFilters] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(13); // Define how many items per page

  const showModal = () => {
    setOpen(!open);
  };

  const [filters, setFilters] = useState({});
  // Helper function to initialize showFilters based on viewData.filters
  const initializeFilters = (defaultFilters, viewFilters) => {
    return defaultFilters.map((filter) => {
      // Check if a matching filter (ignoring case) exists in viewFilters
      const match = viewFilters.find(
        (viewFilter) =>
          viewFilter.name.toLowerCase() === filter.name.toLowerCase()
      );

      // If a match is found, set `isActive` to true and copy the value
      return match ? { ...filter, isActive: true, value: match.value } : filter;
    });
  };

  // Helper function to compare initial viewData.filters with current filters
  const areFiltersDifferent = (initialFilters, currentFilters) => {
    // Create a map of initialFilters with case-insensitive keys (lowercase names)
    const initialFilterValues = initialFilters.reduce((acc, filter) => {
      // Use toLowerCase for both name and value to ensure case-insensitive comparison
      acc[filter.name.toLowerCase()] = filter.value
        ? filter.value.toLowerCase()
        : null; // Store null if value is null
      return acc;
    }, {});

    // Create a map of currentFilters with case-insensitive keys (lowercase names)
    const currentFilterValues = Object.keys(currentFilters).reduce(
      (acc, key) => {
        // Normalize key to lowercase for case-insensitive comparison
        // If the key is 'slaBreaches', treat it as 'sla'
        const normalizedKey = key === "slaBreached" ? "sla" : key.toLowerCase();
        // Normalize value to lowercase and store null if value is null
        acc[normalizedKey] = currentFilters[key]
          ? currentFilters[key].toLowerCase()
          : null;
        return acc;
      },
      {}
    );

    // Compare both maps to check for any differences
    for (const key in initialFilterValues) {
      if (initialFilterValues[key] !== currentFilterValues[key]) {
        return true; // Found a difference
      }
    }

    // Also check if there are extra filters in currentFilters that don't exist in initialFilters
    if (filterCount !== viewData.filters.length) return true;

    return false; // No differences found
  };

  // Effect to set visibility of the save button based on filter changes
  useEffect(() => {
    const isDifferent = areFiltersDifferent(viewData.filters || [], filters);
    setShowSaveButton(isDifferent);
  }, [filters, viewData.filters, filterCount]);

  // Inside the component
  const defaultFilters = [
    { name: "Status", isActive: false, value: null },
    { name: "Source", isActive: false, value: null },
    { name: "Type", isActive: false, value: null },
    { name: "Category", isActive: false, value: null },
    { name: "Priority", isActive: false, value: null },
    { name: "SLA", isActive: false, value: null },
  ];

  useEffect(() => {
    setShowFilters(() => {
      const viewFilters = viewData.filters || []; // Ensure viewData.filters exists

      // Use the helper function to initialize showFilters
      return initializeFilters(defaultFilters, viewFilters);
    });
    setFilterCount(viewData.filters.length);
    setItemsPerPage(viewData.itemsPerPage)
  }, [viewData]);

  // Transform viewData.filters to set initial filters state, setting null values to ""
  const setInitialFilters = () => {
    if (viewData?.filters) {
      const transformedFilters = viewData.filters.reduce((acc, filter) => {
        const key = filter.name.toLowerCase(); // Convert name to lowercase
        acc[key] = filter.value !== null ? filter.value : ""; // Set value to empty string if null
        return acc;
      }, {});

      setFilters(transformedFilters);
      setFilterReady(true); // Mark filters as ready
    }
  };
  useEffect(() => {
    setInitialFilters();
  }, [viewData]);

  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [totalPages, setTotalPages] = useState(
    Math.ceil(conversations.length / itemsPerPage)
  );
  const [totalCount, setTotalCount] = useState(conversations.length);
  // Pagination logic
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentConversations = conversations.slice(firstIndex, lastIndex);

  useEffect(() => {
    const getAllConversation = async () => {
      try {
        setLoading(true);
        const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));

        let response;

        if (keyword === "") {
          response = await axios.post(
            `${process.env.REACT_APP_CONVERSATION}/conversations/search/${userInfo.uniquePartnerId}?page=${currentPage}&limit=${itemsPerPage}`,
            { filters }
          );
        } else {
          response = await axios.post(
            `${process.env.REACT_APP_CONVERSATION}/conversations/search/${userInfo.uniquePartnerId}?keyword=${keyword}&page=${currentPage}&limit=${itemsPerPage}`,
            { filters }
          );
        }

        setLoading(false);
        // Sorting tickets in descending order based on createdAt
        const data = response.data.conversations;
        setConversations(data); // Set the filtered conversations
        setTotalPages(Math.ceil(response.data.totalCount / itemsPerPage));
        setTotalCount(response.data.totalCount);
      } catch (error) {
        setLoading(false); // Handle errors properly
        if (error.response && error.response.status === 404) {
          setConversations([]);
          setTotalPages(0);
          setTotalCount(0);
        }
        console.error(error); // Log error for debugging
      }
    };

    if (filterReady) {
      getAllConversation();
    }
  }, [keyword, currentPage, filters, itemsPerPage, filterReady]);

  // Define an array of background colors
  const backgroundColors = [
    "bg-red-300",
    "bg-green-300",
    "bg-blue-300",
    "bg-yellow-300",
    "bg-purple-300",
    "bg-pink-300",
    "bg-indigo-300",
    "bg-orange-300",
    "bg-teal-300",
    "bg-cyan-300",
    "bg-lime-300",
    "bg-amber-300",
    "bg-emerald-300",
    "bg-fuchsia-300",
    "bg-rose-300",
    "bg-sky-300",
    "bg-violet-300",
    "bg-gray-300",
    "bg-stone-300",
    "bg-amber-400",
    "bg-cyan-400",
    "bg-blue-400",
    "bg-indigo-400",
    "bg-orange-400",
    "bg-teal-400",
    "bg-pink-400",
    "bg-lime-400",
    "bg-purple-400",
    "bg-rose-400",
    "bg-sky-400",
    "bg-green-400",
    "bg-red-400",
    "bg-violet-400",
  ];

  const typeOptions = {
    Accounting: [
      {
        value: "Data Value MisMatch/Missing",
        label: "Data Value MisMatch/Missing",
      },
      { value: "Unmapped SKU Not Shown", label: "Unmapped SKU Not Shown" },
      { value: "Offline Unmapped", label: "Offline Unmapped" },
      { value: "Tax Rate Set up", label: "Tax Rate Set up" },
      {
        value: "Tally Sale/Returns Value mismatch",
        label: "Tally Sale/Returns Value mismatch",
      },
      { value: "GSTR1 Error", label: "GSTR1 Error" },
    ],
    Reconciliation: [
      { value: "Data misMatch/missing", label: "Data misMatch/missing" },
      { value: "P & L Related", label: "P & L Related" },
      { value: "Claims Related", label: "Claims Related" },
      { value: "Reports Related", label: "Reports Related" },
      { value: "Unable to Scan Returns", label: "Unable to Scan Returns" },
    ],
  };
  // Hash function to generate a consistent index based on the owner's name
  const getColorForOwner = (name) => {
    if (!name) return backgroundColors[0]; // default color if no name
    const hash = [...name].reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return backgroundColors[hash % backgroundColors.length];
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFilters = (name, value) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };

      if (value === null || value === "") {
        // If value is null or an empty string, remove the filter
        const { [name]: removed, ...remainingFilters } = newFilters;
        return remainingFilters; // Return the filters without the removed one
      }

      // Otherwise, update the filter state
      newFilters[name] = value;
      return newFilters;
    });

    setShowFilters((prevFilters) =>
      prevFilters.map((f) => {
        // Normalize the name to handle case-insensitive comparison
        const normalizedName = f.name.toLowerCase();
        const normalizedInputName =
          name === "slaBreached" ? "sla" : name.toLowerCase();

        // Update the filter if names match (considering case insensitivity)
        return normalizedName === normalizedInputName
          ? { ...f, value: value }
          : f;
      })
    );
  };

  console.log({showSaveButton})

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const [rotate, setRotate] = useState(false); // State to track rotation

  const handleClick = () => {
    setRotate(!rotate); // Toggle rotation on every click
    // setFilters({});
    setInitialFilters();
    setShowFilters(() => {
      const viewFilters = viewData.filters || []; // Ensure viewData.filters exists

      // Use the helper function to initialize showFilters
      return initializeFilters(defaultFilters, viewFilters);
    });
    setFilterCount(viewData.filters.length);
    setKeyword("");
  };

  const handleItemsPerpage = (value) => {
    if (value === "" || value === null) {
      setItemsPerPage(13);
    } else setItemsPerPage(value);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    // Add event listener only if the dropdown is open
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  // Determine which type options to show based on the selected category
  const getFilteredTypeOptions = () => {
    if (!filters.category) {
      // Show all types if no category is selected
      return [...typeOptions.Accounting, ...typeOptions.Reconciliation];
    }
    return typeOptions[filters.category] || [];
  };

  return (
    <section className="bg-gray-50 py-1">
      <div className="px-1 mx-auto max-w-screen-2xl">
        <div className="relative overflow-hidden bg-white shadow-md sm:rounded-lg">
          <div className="flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
            <div className="flex items-center flex-1 space-x-4">
              <h5>
                <span className="text-gray-500">{viewData.name} : </span>
                <span>{totalCount}</span>
              </h5>
            </div>
            {isDrawerOpen && (
              <TicketViewer
                isDrawerOpen={isDrawerOpen}
                toggleDrawer={toggleDrawer}
                setIsDrawerOpen={setIsDrawerOpen}
                convo={selectedConvo}
              />
            )}
            <div className="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
              {/* {showSaveButton && ( */}
                <button
                  type="button"
                  className={`flex items-center justify-center px-2 py-1 text-xs font-medium rounded-md  text-white ${showSaveButton ? "bg-blue-600 hover:bg-blue-700" : "cursor-not-allowed text-white bg-blue-400 hover:bg-blue-400"}`}
                  onClick={showModal}
                  disabled={!showSaveButton}
                >
                  Save view
                </button>
              {/* )} */}
              {/* <button
                type="button"
                className="flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:ring-4 focus:ring-gray-200"
              >
                <svg
                  className="w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  fill="none"
                  viewbox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
                Update stocks 1/250
              </button> */}
              <div className="mr-6 w-36 h-8 ml-auto">
                <label for="underline_select" class="sr-only">
                  Choose tickets per page
                </label>
                <select
                  id="underline_select"
                  class="block px-0 w-full text-xs h-8 text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                  onChange={(e) => handleItemsPerpage(e.target.value || null)} // Handle select change
                  value={itemsPerPage || 13}
                >
                  <option value={13} selected>
                    13 Tickets per page
                  </option>
                  <option value={5}>5 Tickets per page</option>
                  <option value={10}>10 Tickets per page</option>
                  <option value={15}>15 Tickets per page</option>
                  <option value={20}>20 Tickets per page</option>
                  <option value={25}>25 Tickets per page</option>
                  <option value={30}>30 Tickets per page</option>
                  <option value={35}>35 Tickets per page</option>
                  <option value={40}>40 Tickets per page</option>
                </select>
              </div>
            </div>
          </div>
          <div className="flex w-full">
            <div className="flex space-x-2" style={{ width: "70%" }}>
              <div className="w-36 ml-4 mb-2">
                <div className="flex items-center">
                  <div className="w-full">
                    <input
                      type="text"
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                      id="simple-search"
                      className="bg-gray-50 z-0 border h-8 border-gray-300 text-gray-900 rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10"
                      placeholder="Search ticket here"
                      style={{ fontSize: "13px" }}
                      required=""
                    />
                  </div>
                </div>
              </div>

              {showFilters?.map((filter) => {
                if (filter.name === "Status" && filter.isActive) {
                  return (
                    <div className="flex w-48" key={filter.name}>
                      <p class="relative inline-flex p-0 text-center">
                        <p
                          id="states-button"
                          data-dropdown-toggle="dropdown-states"
                          className=" h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                          type="button"
                        >
                          Status{" "}
                        </p>
                        <label for="states" className="sr-only">
                          Choose state
                        </label>
                        <select
                          id="states"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs h-8 w-full rounded-e-lg border-s-gray-100 border-s-2 focus:ring-blue-500 focus:border-blue-500 block"
                          onChange={(e) =>
                            handleFilters("status", e.target.value || null)
                          } // Handle select change
                          value={filters.status || filter.value || ""}
                        >
                          <option value="" selected>
                            select state
                          </option>
                          <option value="Open">Open</option>
                          <option value="Assigned">Assigned</option>
                          <option value="UnAssigned">UnAssigned</option>
                          <option value="Work In Progress">
                            WorkInProgress
                          </option>
                          <option value="Pending To Tech">
                            Pending To Tech
                          </option>
                          <option value="Resolved">Resolved</option>
                          <option value="Awaiting Customer Response">
                            Awaiting Customer Response
                          </option>
                        </select>
                        <div
                          onClick={() => {
                            setShowFilters((prevFilters) =>
                              prevFilters.map((f) =>
                                f.name === filter.name
                                  ? { ...f, isActive: !f.isActive }
                                  : f
                              )
                            );
                            setFilterCount(filterCount - 1);
                            handleFilters("status", null);
                          }}
                          class="absolute cursor-pointer inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-gray-400 p-0.5 border-2 border-white rounded-full -top-1.5 -end-1.5"
                        >
                          <RxCross1 />
                        </div>
                      </p>
                    </div>
                  );
                }
                if (filter.name === "Source" && filter.isActive) {
                  return (
                    <div className="flex w-48" key={filter.name}>
                      <p class="relative inline-flex p-0 text-center">
                        <p
                          id="source-button"
                          data-dropdown-toggle="dropdown-states"
                          className="flex-shrink-0 h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                          type="button"
                        >
                          Source{" "}
                        </p>
                        <label for="Type" className="sr-only">
                          Choose Source
                        </label>
                        <select
                          id="type"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs h-8 w-full rounded-e-lg border-s-gray-100 border-s-2 focus:ring-blue-500 focus:border-blue-500 block"
                          onChange={(e) =>
                            handleFilters("source", e.target.value || null)
                          } // Handle select change
                          value={filters.source || filter.value || ""}
                        >
                          <option value="">Select source</option>
                          <option value="widget">Widget</option>
                          <option value="email">Email</option>
                          <option value="servetel">Servetel</option>
                          <option value="portal">Portal</option>
                        </select>
                        <div
                          onClick={() => {
                            setShowFilters((prevFilters) =>
                              prevFilters.map((f) =>
                                f.name === filter.name
                                  ? { ...f, isActive: !f.isActive }
                                  : f
                              )
                            );
                            setFilterCount(filterCount - 1);
                            handleFilters("source", null);
                          }}
                          class="absolute cursor-pointer inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-gray-400 p-0.5 border-2 border-white rounded-full -top-1.5 -end-1.5"
                        >
                          <RxCross1 />
                        </div>
                      </p>
                    </div>
                  );
                }
                if (filter.name === "Category" && filter.isActive) {
                  return (
                    <div className="flex w-48" key={filter.name}>
                      <p class="relative inline-flex p-0 text-center">
                        <p
                          id="category-button"
                          data-dropdown-toggle="dropdown-category"
                          className="flex-shrink-0 h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                          type="button"
                        >
                          Category{" "}
                        </p>
                        <label for="category" className="sr-only">
                          Choose Category
                        </label>
                        <select
                          id="Category"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs h-8 w-full rounded-e-lg border-s-gray-100 border-s-2 focus:ring-blue-500 focus:border-blue-500 block"
                          onChange={(e) =>
                            handleFilters("category", e.target.value || null)
                          } // Handle select change
                          value={filters.category || filter.value || ""}
                        >
                          <option value="">Select category</option>
                          <option value="Accounting">Accounting</option>
                          <option value="Reconciliation">Reconciliation</option>
                        </select>
                        <div
                          onClick={() => {
                            setShowFilters((prevFilters) =>
                              prevFilters.map((f) =>
                                f.name === filter.name
                                  ? { ...f, isActive: !f.isActive }
                                  : f
                              )
                            );
                            setFilterCount(filterCount - 1);
                            handleFilters("category", null);
                          }}
                          class="absolute cursor-pointer inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-gray-400 p-0.5 border-2 border-white rounded-full -top-1.5 -end-1.5"
                        >
                          <RxCross1 />
                        </div>
                      </p>
                    </div>
                  );
                }
                if (filter.name === "Type" && filter.isActive) {
                  return (
                    <div className="flex w-48" key={filter.name}>
                      <p class="relative inline-flex p-0 text-center">
                        <p
                          id="type-button"
                          data-dropdown-toggle="dropdown-type"
                          className="flex-shrink-0 h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                          type="button"
                        >
                          Type{" "}
                        </p>
                        <label for="Type" className="sr-only">
                          Choose Type
                        </label>
                        <select
                          id="Type"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs h-8 w-full rounded-e-lg border-s-gray-100 border-s-2 focus:ring-blue-500 focus:border-blue-500 block"
                          onChange={(e) =>
                            handleFilters("type", e.target.value || null)
                          } // Handle select change
                          value={filters.type || filter.value || ""}
                        >
                          <option value="" selected>
                            Select type
                          </option>
                          {getFilteredTypeOptions().map((type, index) => (
                            <option key={index} value={type.value}>
                              {type.label}
                            </option>
                          ))}
                        </select>
                        <div
                          onClick={() => {
                            setShowFilters((prevFilters) =>
                              prevFilters.map((f) =>
                                f.name === filter.name
                                  ? { ...f, isActive: !f.isActive }
                                  : f
                              )
                            );
                            setFilterCount(filterCount - 1);
                            handleFilters("type", null);
                          }}
                          class="absolute cursor-pointer inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-gray-400 p-0.5 border-2 border-white rounded-full -top-1.5 -end-1.5"
                        >
                          <RxCross1 />
                        </div>
                      </p>
                    </div>
                  );
                }
                if (filter.name === "Priority" && filter.isActive) {
                  return (
                    <div className="flex w-48" key={filter.name}>
                      <p class="relative inline-flex p-0 text-center">
                        <p
                          id="states-button"
                          data-dropdown-toggle="dropdown-states"
                          className="flex-shrink-0  h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                          type="button"
                        >
                          Priority{" "}
                        </p>
                        <label for="states" className="sr-only">
                          Choose Priority
                        </label>
                        <select
                          id="states"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs h-8 w-full rounded-e-lg border-s-gray-100 border-s-2 focus:ring-blue-500 focus:border-blue-500 block"
                          onChange={(e) =>
                            handleFilters("priority", e.target.value || null)
                          } // Handle select change
                          value={filters.priority || filter.value || ""}
                        >
                          <option value="" selected>
                            select priority
                          </option>
                          <option value="Low">Low</option>
                          <option value="Medium">Medium</option>
                          <option value="High">High</option>
                          <option value="Critical">Critical</option>
                        </select>
                        <div
                          onClick={() => {
                            setShowFilters((prevFilters) =>
                              prevFilters.map((f) =>
                                f.name === filter.name
                                  ? { ...f, isActive: !f.isActive }
                                  : f
                              )
                            );
                            setFilterCount(filterCount - 1);
                            handleFilters("priority", null);
                          }}
                          class="absolute cursor-pointer inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-gray-400 p-0.5 border-2 border-white rounded-full -top-1.5 -end-1.5"
                        >
                          <RxCross1 />
                        </div>
                      </p>
                    </div>
                  );
                }
                if (filter.name === "SLA" && filter.isActive) {
                  return (
                    <div className="flex w-48" key={filter.name}>
                      <p class="relative inline-flex p-0 text-center">
                        <p
                          id="states-button"
                          data-dropdown-toggle="dropdown-states"
                          className="flex-shrink-0  h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                          type="button"
                        >
                          SLA{" "}
                        </p>
                        <label for="states" className="sr-only">
                          Choose SLA
                        </label>
                        <select
                          id="states"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs h-8 w-full rounded-e-lg border-s-gray-100 border-s-2 focus:ring-blue-500 focus:border-blue-500 block"
                          onChange={(e) =>
                            handleFilters("slaBreached", e.target.value || null)
                          } // Handle select change
                          value={filters.slaBreached || filter.value || ""}
                        >
                          <option value="" selected>
                            select SLA
                          </option>
                          <option value={true}>Breached</option>
                          <option value={false}>Not Breached</option>
                        </select>
                        <div
                          onClick={() => {
                            setShowFilters((prevFilters) =>
                              prevFilters.map((f) =>
                                f.name === filter.name
                                  ? { ...f, isActive: !f.isActive }
                                  : f
                              )
                            );
                            setFilterCount(filterCount - 1);
                            handleFilters("slaBreached", null);
                          }}
                          class="absolute cursor-pointer inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-gray-400 p-0.5 border-2 border-white rounded-full -top-1.5 -end-1.5"
                        >
                          <RxCross1 />
                        </div>
                      </p>
                    </div>
                  );
                }
                return null;
              })}

              {filterCount !== showFilters.length && (
                <div className="flex" ref={dropdownRef}>
                  {/* Button to toggle dropdown */}
                  <button
                    id="dropdownBgHoverButton"
                    onClick={toggleDropdown}
                    className="flex-shrink-0 h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-xl hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                    type="button"
                  >
                    +
                  </button>

                  {/* Dropdown menu, visible only if isDropdownOpen is true */}
                  {isDropdownOpen && (
                    <div
                      id="dropdownBgHover"
                      className="absolute z-10 w-48 mt-2 bg-white rounded-lg shadow"
                    >
                      <ul
                        className="p-3 space-y-1 text-sm text-gray-700"
                        aria-labelledby="dropdownBgHoverButton"
                      >
                        {showFilters.map((filter) => {
                          if (!filter.isActive) {
                            return (
                              <li>
                                <div
                                  onClick={() => {
                                    setShowFilters((prevFilters) =>
                                      prevFilters.map((f) =>
                                        f.name === filter.name
                                          ? { ...f, isActive: !f.isActive }
                                          : f
                                      )
                                    );
                                    setFilterCount(filterCount + 1);
                                  }}
                                  className="flex items-center p-2 rounded hover:bg-gray-100"
                                >
                                  <label
                                    htmlFor="checkbox-item-5"
                                    className="w-full ms-2 text-xs font-medium text-gray-900 rounded"
                                  >
                                    {filter.name}
                                  </label>
                                </div>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              )}

              <div
                className="flex items-center mb-2 cursor-pointer"
                onClick={handleClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className={`size-5 transition-transform duration-500 ease-in-out ${
                    rotate ? "rotate-[-180deg]" : "rotate-0"
                  }`} // Rotate counterclockwise on click
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
              </div>
            </div>
          </div>

          {conversations.length > 0 ? (
            <>
              <div className="overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500 ">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="p-4">
                        <div className="flex items-center">
                          <input
                            id="checkbox-all"
                            type="checkbox"
                            className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 focus:ring-2 "
                          />
                          <label for="checkbox-all" className="sr-only">
                            checkbox
                          </label>
                        </div>
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Product
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Subject
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Status
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Owner
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Assignee
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Last Update
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading &&
                      conversations.length > 0 &&
                      conversations.map((convo, index) => {
                        return (
                          <tr
                            onClick={() => {
                              toggleDrawer();
                              setSelectedConvo(convo);
                            }}
                            className="border-b hover:bg-gray-100 cursor-pointer"
                            key={index}
                          >
                            <td className="w-4 px-4 py-3">
                              <div className="flex items-center">
                                <input
                                  id="checkbox-table-search-1"
                                  type="checkbox"
                                  onclick="event.stopPropagation()"
                                  className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 focus:ring-2"
                                />
                                <label
                                  for="checkbox-table-search-1"
                                  className="sr-only"
                                >
                                  checkbox
                                </label>
                              </div>
                            </td>
                            <th
                              scope="row"
                              className="flex items-center px-4 py-2 font-medium text-gray-900 whitespace-nowrap"
                            >
                              <img
                                src={`${
                                  convo.ticketId.source === "widget"
                                    ? logo
                                    : googleLogo
                                }`}
                                alt="iMac Front Image"
                                className="w-auto h-8 mr-3"
                              />
                              {convo.ticketId.ticketNumber || "---"}
                            </th>
                            <td className="px-4 py-2">
                              <span className="bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded">
                                {convo.ticketId.subject || "---"}
                              </span>
                            </td>
                            <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap">
                              {convo.ticketId.status || "---"}
                            </td>
                            <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap">
                              <div className="flex items-center">
                                {convo.owner?.imageUrl ? (
                                  <img
                                    src={convo.owner.imageUrl}
                                    alt={`${convo.owner.name}'s avatar`}
                                    className="w-auto h-6 mr-6 rounded-full"
                                  />
                                ) : (
                                  <div
                                    className={`w-6 h-6 mr-3 flex items-center justify-center rounded-full text-white font-semibold text-xs ${getColorForOwner(
                                      convo.owner?.name
                                    )}`}
                                  >
                                    {convo.owner?.name
                                      ? convo.owner.name
                                          .slice(0, 2)
                                          .toUpperCase()
                                      : "--"}
                                  </div>
                                )}
                                {convo.owner?.name || "---"}
                              </div>
                            </td>
                            <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap">
                              <div className="flex items-center">
                                {convo.assignee?.imageUrl ? (
                                  <img
                                    src={convo.assignee.imageUrl}
                                    alt={`${convo.assignee.name}'s avatar`}
                                    className="w-auto h-6 mr-6 rounded-full"
                                  />
                                ) : (
                                  <div
                                    className={`w-6 h-6 mr-3 flex items-center justify-center rounded-full text-white font-semibold text-xs ${getColorForOwner(
                                      convo.assignee?.name
                                    )}`}
                                  >
                                    {convo.assignee?.name
                                      ? convo.assignee.name
                                          .slice(0, 2)
                                          .toUpperCase()
                                      : "--"}
                                  </div>
                                )}
                                {convo.assignee?.name || "---"}
                              </div>
                            </td>
                            <td className="px-4 py-2">
                              {convo.ticketId.updatedAt
                                ? new Date(
                                    convo.ticketId.updatedAt
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  }) +
                                  " " +
                                  new Date(
                                    convo.ticketId.updatedAt
                                  ).toLocaleTimeString("en-US", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })
                                : "---"}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {loading && (
                <div className="animate-pulse w-full p-4">
                  {Array.from({ length: itemsPerPage }).map((_, index) => (
                    <div
                      key={index}
                      className={`h-4 ${
                        index % 3 === 0
                          ? "bg-gray-200"
                          : index % 3 === 1
                          ? "bg-gray-300"
                          : "bg-gray-300"
                      } mb-6 rounded w-full`}
                    ></div>
                  ))}
                </div>
              )}
              <nav
                className="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0"
                aria-label="Table navigation"
              >
                <span className="text-sm font-normal text-gray-500">
                  Showing{" "}
                  <span className="font-semibold text-gray-900">
                    {firstIndex + 1}-{Math.min(lastIndex, totalCount)}
                  </span>{" "}
                  of{" "}
                  <span className="font-semibold text-gray-900">
                    {totalCount}
                  </span>
                </span>
                <ul className="inline-flex items-stretch -space-x-px">
                  <li className="cursor-pointer">
                    <p
                      onClick={() => handlePrevPage()}
                      className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    >
                      <span className="sr-only">Previous</span>
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewbox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </p>
                  </li>

                  {/* Page numbers */}
                  {[...Array(totalPages)].map((_, index) => (
                    <li className="cursor-pointer" key={index}>
                      <p
                        onClick={() => handlePageChange(index + 1)}
                        className={`py-2 px-3 text-sm font-medium ${
                          currentPage === index + 1
                            ? "bg-blue-600 text-white"
                            : "text-gray-500 bg-white hover:bg-gray-100"
                        } border border-gray-300`}
                      >
                        {index + 1}
                      </p>
                    </li>
                  ))}
                  <li className="cursor-pointer">
                    <p
                      onClick={() => handleNextPage()}
                      className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    >
                      <span className="sr-only">Next</span>
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewbox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </p>
                  </li>
                </ul>
              </nav>
            </>
          ) : (
            <>
              {!loading && (
                <section className="bg-white">
                  <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center">
                      <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-blue-600">
                        Opps!
                      </h1>
                      <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">
                        No Tickets Found
                      </p>
                      <p className="mb-4 text-lg font-light text-gray-500">
                        Sorry, we couldn't find any tickets matching your search
                        terms or filters. Try adjusting your filters or search
                        terms.
                      </p>
                      <p
                        onClick={handleClick}
                        className="inline-flex text-blue-500 bg-primary-600 cursor-pointer font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4"
                      >
                        clear filters
                      </p>
                    </div>
                  </div>
                </section>
              )}

              {loading && (
                <div className="animate-pulse w-full p-4">
                  {Array.from({ length: itemsPerPage }).map((_, index) => (
                    <div
                      key={index}
                      className={`h-4 ${
                        index % 3 === 0
                          ? "bg-gray-200"
                          : index % 3 === 1
                          ? "bg-gray-300"
                          : "bg-gray-300"
                      } mb-6 rounded w-full`}
                    ></div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {open && (
        <div className="fixed inset-0 flex items-center justify-center bg-black z-50 bg-opacity-50">
          <div className="bg-white w-full max-w-md p-4 rounded-lg shadow-lg">
            <TicketExplorerViewForm
              setShowForm={showModal}
              filters={showFilters}
              itemsPerPage={itemsPerPage}
            />
          </div>
        </div>
      )}
    </section>
  );
}

export default TicketExplorer;
