import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import useNotification from "../components/snackbars/SnackBar";
import "./PartnerRegistrationForm.css";
import OtpLoader from "./OtpLoader";

function PartnerOtp({
  setRenderComponents,
  formData,
  resend,
}) {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [email, setEmail] = useState("");
  const [conf, setConf] = useNotification();
  const [adminDetails, setAdminDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(30);
  const inputRefs = useRef([]);

  useEffect(() => {
    setEmail(formData.email);
  }, []);

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [counter]);

  const handleChange = (event, index) => {
    const { value } = event.target;

    // Ensure value is a digit and within the input length
    if (/^\d$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace") {
      if (otp[index] === "") {
        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
      } else {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };

  const token = localStorage.getItem("dexkorUserToken");
  const submitPartner = async () => {
    setLoading(true);
    try {
      // Ensure that adminDetails is available
      if (!adminDetails || !adminDetails._id) {
        setConf({
          type: "error",
          message: "Admin details are not available. Please try again.",
        });
        return;
      }

      // Add the userId (adminDetails._id) to formData
      const updatedFormData = {
        ...formData,
        userId: adminDetails._id, // Add userId to the form data
      };
      const res = await axios.post(
        `${process.env.REACT_APP_CONVERSATION}/partners/partner`,
        updatedFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the headers
            Role: "user", // Add the Role header
            "Content-Type": "application/json", // Optional: set content type
          },
        }
      );
      if (res.status === 201) {
        console.log(res.data);
        setRenderComponents("finalPage");
      } else {
        setConf({
          msg: res.data.msg,
          variant: "error",
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
      setConf({
        msg: "An error occurred. Please try again later.",
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (adminDetails) {
      submitPartner();
    }
  }, [adminDetails]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const otpCode = otp.join("");
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/otp/verify/user`,
        { email, otp: otpCode },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 200) {
        console.log(res.data);
        setAdminDetails(res.data.userData);
        setLoading(false);
      } else {
        setConf({
          msg: res.data.msg,
          variant: "error",
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
      if (error.response.status === 400) {
        setConf({
          msg: "Wrong otp!",
          variant: "error",
        });
      }
    }
  };

  const handleResend = async (e) => {
    setCounter(30);
    resend(e);
  };

  return (
    <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-12">
      <div className="relative bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl">
        <div className="mx-auto flex w-full max-w-md flex-col space-y-16">
          <div className="flex flex-col items-center justify-center text-center space-y-2">
            <div className="font-semibold text-3xl">
              <p>Email Verification</p>
            </div>
            <div className="flex flex-row text-sm font-medium text-gray-400">
              <p>We have sent a code to {formData.email}</p>
            </div>
          </div>

          <div>
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col space-y-16">
                <div className="flex flex-row items-center justify-between mx-auto w-full max-w-xs">
                  {otp.map((data, index) => (
                    <div key={index} className="w-8 h-8">
                      <input
                        className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                        type="text"
                        name="otp"
                        maxLength="1"
                        ref={(el) => (inputRefs.current[index] = el)}
                        value={data}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onFocus={(e) => e.target.select()}
                      />
                    </div>
                  ))}
                </div>

                <div className="flex flex-col space-y-5">
                  <div>
                    {loading ? (
                      <OtpLoader />
                    ) : (
                      <button className="dexkor-verify-otp-button flex flex-row items-center justify-center text-center w-full rounded-xl py-3 border-none text-white text-sm">
                        Verify Account
                      </button>
                    )}
                  </div>

                  <div className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                    <p>Didn't receive code?</p>{" "}
                    <p
                      className="text-blue-500 cursor-pointer"
                      onClick={counter === 0 ? (e) => handleResend(e) : null}
                      style={{
                        pointerEvents: counter === 0 ? "auto" : "none",
                        opacity: counter === 0 ? 1 : 0.5,
                      }}
                    >
                      {counter > 0
                        ? `Resend OTP in ${counter}s`
                        : "Resend OTP"}
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerOtp;
