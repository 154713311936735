import React, { useEffect, useState } from "react";
import axios from "axios";
import { Breadcrumb } from "antd";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import { Divider, List } from "antd";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AllPendingView = () => {
  const [personName, setPersonName] = useState([]);
  const [conversationType, setConversationType] = useState([]);
  const [dataNumber, setDataNumber] = useState("0");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getAllConversation = async () => {
      try {
        setLoading(true);
        const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
        const token = localStorage.getItem("dexkorUserToken");
        const response = await axios.get(
          `${process.env.REACT_APP_CONVERSATION}/conversations/all/${userInfo.uniquePartnerId}`,
          {
            headers: {
              Authorization:
                `Bearer ${token}`,
            },
          }
        );
        setLoading(false);
        setData(response.data);
        const Number = response.data;
        setDataNumber(Number.length);
      } catch (error) {
        setLoading(false); // Handle errors properly
        console.error(error); // Log error for debugging
      }
    };

    getAllConversation();
  }, []);

  const breadcrumbItems = [
    {
      path: "/help-desk/all-conversation",
      breadcrumbName: "Home",
    },
    {
      path: "/help-desk/internal-queue/all-pending-conversation",
      breadcrumbName: "All Pending Conversation View",
    },
  ];

  const onBreadcrumbClick = (path) => {
    navigate(path);
  };

  const handlePersonChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const handleConversationChange = (event) => {
    const {
      target: { value },
    } = event;
    setConversationType(typeof value === "string" ? value.split(",") : value);
  };

  const names = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];

  const conversationName = ["Chat", "Ticket", "Email", "Custom"];

  return (
    <div style={{ fontFamily: "Roboto, sans-serif" }}>
      <div style={{ marginTop: "20px", marginLeft: "20px" }}>
        <div style={{ display: "flex", gap: "5px" }}>
          <Breadcrumb style={{ cursor: "pointer" }}>
            {breadcrumbItems.map((item, index) => (
              <Breadcrumb.Item
                key={index}
                onClick={() => onBreadcrumbClick(item.path)}
                style={{ cursor: "pointer" }}
              >
                {item.breadcrumbName}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
          <span style={{ fontFamily: "roboto" }}>({dataNumber})</span>
        </div>
      </div>
      <div
        style={{
          marginTop: "30px",
          marginLeft: "30px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", gap: "20px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>Select Assignee</span>
            <FormControl sx={{ m: 1, width: 200 }} size="small">
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                style={{ fontFamily: "Roboto, sans-serif" }}
                value={personName}
                onChange={handlePersonChange}
                renderValue={(selected) => selected.join(",")}
                MenuProps={MenuProps}
              >
                {names.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={personName.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>Conversation Type</span>
            <FormControl sx={{ m: 1, width: 200 }} size="small">
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                style={{ fontFamily: "Roboto, sans-serif" }}
                value={conversationType}
                onChange={handleConversationChange}
                renderValue={(selected) => selected.join(",")}
                MenuProps={MenuProps}
              >
                {conversationName.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={conversationType.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div style={{ fontFamily: "roboto", display: "flex", gap: "5px" }}>
          <Button variant="contained" color="error">
            Clear
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: "20px" }}
          >
            Filter
          </Button>{" "}
          {/* Filter button added here */}
        </div>
      </div>
      <div style={{ margin: "20px" }}>
        <List
          size="small"
          dataSource={data}
          header={
            <div style={{ fontFamily: "roboto", paddingLeft: "15px" }}>
              {" "}
              <Checkbox /> Items
            </div>
          }
          renderItem={(item) => (
            <div>
              <List.Item
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontFamily: "roboto",
                }}
              >
                <Checkbox />
                <div style={{ flex: 1, padding: "0 10px" }}>
                  {item.ticketId.ticketNumber}
                </div>
                <div style={{ flex: 2, padding: "0 10px" }}>
                  {item.ticketId.description}
                </div>
                <div style={{ flex: 3, padding: "0 10px" }}>
                  {item.customer.name}
                </div>
                <div style={{ flex: 3, padding: "0 10px" }}>
                  {item.ticketId.status}
                </div>
                <div style={{ flex: 3, padding: "0 10px" }}>
                  {item.ticketId.name}
                </div>
              </List.Item>
              <Divider style={{ margin: 0 }} />{" "}
              {/* Remove default margin to fit between items */}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default AllPendingView;
