import React, { useEffect, useState, useMemo, useRef } from 'react'
import axios from 'axios'
import moment from 'moment'
import logo from "../../../Images/Logo icon.png"
import "./Comment.css"
import { Row, Col, Flex, message, Upload, Button, Card, Modal, Form, Input, Avatar  } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { CiLocationOn, CiEdit   } from "react-icons/ci";
import { FaPhoneAlt, FaPlus } from "react-icons/fa";
import Spinner from '../../../components/Spinner/spinner'
const { TextArea } = Input;


const Comment = ({ selectedCompany }) => {
  
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [error, setError] = useState("");
    const [allComments, setAllComments] = useState([])
    const [newComment, setNewComment] = useState("");
    const [allDocuments, setAllDocuments] = useState([])
    const [userData, setUserData] = useState([])
    const editorRef = useRef(null);

    useEffect(() => {
      const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
      setUserData(userInfo);
    }, []);

    const showModal = () => {
      setOpen(true);
    };
    const handleOk = () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setOpen(false);
      }, 3000);
    };
    const handleCancel = () => {
      setOpen(false);
    };

    useEffect(() => {
      const fetchComments = async () => {
        setLoading(true)
        
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_CONVERSATION}/comments/${selectedCompany.companyID}`
          );
          const fetchedComments = response.data.comments || [];
          const sortedComments = fetchedComments.sort(
            (a, b) => new Date(b.time) - new Date(a.time)
          );
          setAllComments(sortedComments);
          setLoading(false)
        } catch (error) {
          console.error("Error fetching comments:", error);
          setLoading(false)
        }
      };
      fetchComments();
    }, [selectedCompany])

    const handleCommentSubmit = async () => {
      if (!userData) {
        setError("User not logged in.");
        return;
      }
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_CONVERSATION}/comments/${selectedCompany.companyID}`,
          {
            userId: userData.name,
            comment: newComment,
            time: new Date().toLocaleString(),
          }
        );
        setAllComments([response.data, ...allComments]);
        setNewComment("");
      } catch (error) {
        console.error("Error adding comment:", error);
      }
    };
   

    const options = [ 'bold', 'italic', '|', 'ul', 'ol', '|', 'font', 'fontsize', '|','align', '|', 'hr',  '|', 'table', 'link', ];

    const config = useMemo(
      () => ({
      readonly: false,
      placeholder: '',
      defaultActionOnPaste: 'insert_as_html',
      defaultLineHeight: 1.5,
      enter: 'div',
     // options that we defined in above step.
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      statusbar: false,
      sizeLG: 900,
      sizeMD: 700,
      sizeSM: 400,
      toolbarAdaptive: false,
      }),
      [],
     );
     
     console.log({selectedCompany});
     
    return (<>
        
<Row className='dexkor-sales-hub-comment-body'>
  {loading ?  <div className="dexkor-sales-hub-comment-body-spinner">
    <Spinner />
  </div> : 
   <Col className='dexkor-sales-hub-comment-left-column'>
   <div className='dexkor-sales-hub-comment-section'>
     {allComments.length === 0 ? (
       <p>Please start to add comments</p>
     ) : (
       allComments.map((comment) => (
         <div key={comment.id}> 
           <Card className="dexkor-sales-hub-comment-card">
             <span>
               <Avatar /> 
               <p style={{ fontWeight: 'bold', marginRight: '3px', marginLeft: '10px' }}>
                 {comment.userId}
               </p>  
               <p>added a comment on</p> 
               <p style={{ marginLeft: '3px' }}>
                 {moment(comment.time).format('DD MMM YYYY @ h:mm A')}
               </p>
             </span>
             <div className='deskor-sales-hub-comment-card-description'>
               <p>
                 {comment.comment}
                 {comment?.attachment}
               </p>
             </div>
           </Card>
         </div>
       ))
     )}
   </div>
   <div className='dexkor-sales-hub-company-list-text-area'>
     <TextArea
       showCount
       onChange={(e) => setNewComment(e.target.value)}
       value={newComment}
       placeholder="Add your comment"
       style={{
         height: 90,
         resize: 'none',
       }}
     />
     <Button onClick={handleCommentSubmit} style={{ height: '47px' }} type='primary'>
       Comment
     </Button>
   </div>
 </Col>
 
    }
    {/* <Col className='dexkor-sales-hub-comment-right-column'>
    <span className='dexkor-sales-hub-comment-right-column-company-name-span'>
    <p className='dexkor-sales-hub-comment-right-column-company-name'>Company Details</p>
    {isEdit === false ? <p><CiEdit style={{fontSize: '20px', cursor: 'pointer'}} onClick={() => setIsEdit(true)}/></p> : null}
    </span>
    <div>
    <div style={{display: 'flex', gap: '10px'}}>
      <span style={{ marginTop: '5px'}}>
     <h3>Company ID: </h3>
       <Input value={selectedCompany.companyID}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
      <span style={{ marginTop: '5px'}}>
     <h3>Company Name: </h3>
       <Input value={selectedCompany.companyName}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
    </div>
    <div style={{display: 'flex', gap: '10px'}}>
      <span style={{ marginTop: '5px'}}>
     <h3>Company Email: </h3>
       <Input value={selectedCompany.companyID}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
      <span style={{ marginTop: '5px'}}>
     <h3>Company Phone: </h3>
       <Input value={selectedCompany.companyName}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
    </div>
    </div>
    <span className='dexkor-sales-hub-comment-right-column-company-name-span'>
    <p className='dexkor-sales-hub-comment-right-column-company-name'>Shipping Details</p>
    </span>
    <div>
    <div style={{display: 'flex', gap: '10px'}}>
      <span style={{ marginTop: '5px'}}>
     <h3>Address Line 1: </h3>
       <Input value={selectedCompany.addressLine1}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
      <span style={{ marginTop: '5px'}}>
     <h3>Address Line 2: </h3>
       <Input value={selectedCompany.addressLine2}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
    </div>
    <div style={{display: 'flex', gap: '10px'}}>
      <span style={{ marginTop: '5px'}}>
     <h3>City: </h3>
       <Input value={selectedCompany.city}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
      <span style={{ marginTop: '5px'}}>
     <h3>State: </h3>
       <Input value={selectedCompany.state}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
    </div>
    <div style={{display: 'flex', gap: '10px'}}>
      <span style={{ marginTop: '5px'}}>
     <h3>Country: </h3>
       <Input value={selectedCompany.country}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
      <span style={{ marginTop: '5px'}}>
     <h3>Pin Code: </h3>
       <Input value={selectedCompany.pincode}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
    </div>
    <span className='dexkor-sales-hub-comment-right-column-company-name-span'>
    <p className='dexkor-sales-hub-comment-right-column-company-name'>Shipping Details</p>
    </span>
    <div>
    <div style={{display: 'flex', gap: '10px'}}>
      <span style={{ marginTop: '5px'}}>
     <h3>Address Line 1: </h3>
       <Input value={selectedCompany.addressLine1}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
      <span style={{ marginTop: '5px'}}>
     <h3>Address Line 2: </h3>
       <Input value={selectedCompany.addressLine2}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
    </div>
    <div style={{display: 'flex', gap: '10px'}}>
      <span style={{ marginTop: '5px'}}>
     <h3>City: </h3>
       <Input value={selectedCompany.city}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
      <span style={{ marginTop: '5px'}}>
     <h3>State: </h3>
       <Input value={selectedCompany.state}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
    </div>
    <div style={{display: 'flex', gap: '10px'}}>
      <span style={{ marginTop: '5px'}}>
     <h3>Country: </h3>
       <Input value={selectedCompany.country}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
      <span style={{ marginTop: '5px'}}>
     <h3>Pin Code: </h3>
       <Input value={selectedCompany.pincode}  disabled={!isEdit} style={{ marginTop: '5px'}}/>
      </span>
    </div>
    </div>
   
    </div>
    { isEdit === true ?
    <div style={{display: 'flex', gap: '10px', justifyContent: 'center', marginTop: '20px'}}>
     <Button type='primary' danger onClick={() => setIsEdit(false)}>Cancel</Button>
     <Button type='primary'>Update</Button>
    </div> : null
}
    </Col> */}
</Row>
{/* <Modal
        open={open}
        title="Add New Comment"
        onOk={handleOk}
        width={1000}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
            Submit
          </Button>,
        ]}
      >
       <>
       <Form style={{margin: '20px'}}>
       <Form.Item label="Subject">
        <Input placeholder="Enter the Subject" />
      </Form.Item>
      <Form.Item label="Description">
      <TextArea
        // value={value}
        // onChange={(e) => setValue(e.target.value)}
        placeholder="Enter Description"
        autoSize={{
          minRows: 4,
          maxRows: 7,
        }}
      />
      </Form.Item>
       </Form>
       </>
      </Modal> */}
</>
    )
}

export default Comment;
