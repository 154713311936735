import React from "react";
import Frame from "../Images/Frame.jpg";

const PageNotFound = () => {
  return (
    <div style={{ height: "calc(100vh - 10%)" }}>
      <div
        style={{ paddingTop: "10%", display: "flex", justifyContent: "center" }}
      >
        <img src={Frame} alt="404 Not Found" />
      </div>
      <div>
        <p
          style={{
            fontSize: "23px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            paddingTop: "30px",
            fontFamily: "roboto",
          }}
        >
          404 - Page Not Found
        </p>
      </div>
      <div style={{ textAlign: "center" }}>
        <p
          style={{
            textAlign: "center",
            paddingTop: "10px",
            marginLeft: "auto",
            fontFamily: "roboto",
            marginRight: "auto",
            width: "60%", // Adjust this value to control the width of the paragraph
          }}
        >
          The page you are looking for might have been removed or is temporarily
          unavailable.
        </p>
      </div>
      {/* You can add a button or link to navigate back to the home page if needed */}
    </div>
  );
};

export default PageNotFound;
