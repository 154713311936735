import React, { useState, useEffect, useMemo, useRef } from "react";
import "./sales.css";
import ReactQuill from "react-quill";
import axios from "axios";
import moment from "moment";
import DOMPurify from "dompurify";
import Spinner from "../../../../../components/Spinner/spinner";
import { Col, Row, Button, Tooltip, Avatar, Card } from "antd";
import { LoadingOutlined, PlusOutlined, SendOutlined } from "@ant-design/icons";
import {
  PaperClipIcon,
  PaperAirplaneIcon,
  MicrophoneIcon,
} from "@heroicons/react/24/outline";

const Sales = ({ allComments, selectedCompany, setCommentFlag  }) => {
  const [hasAccess, setHasAccess] = useState(true);
  //   const [allComments, setAllComments] = useState([]);
  const [replyActive, setReplyActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newComment, setNewComment] = useState();
  const [attachment, setAttachment] = useState(null);
  const [attachmentType, setAttachmentType] = useState("");
  const [recording, setRecording] = useState(false);
  const [transcribing, setTranscribing] = useState(false);
  const editorRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const recognitionRef = useRef(null);
  const messagesEndRef = useRef(null); 

  useEffect(() => {
    scrollToBottom();
  }, [allComments]);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));

    const GetUser = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/filter/${userInfo.uniquePartnerId}?query=${userInfo.email}`
        );
        if (response.data && Array.isArray(response.data)) {
          // Assuming resources is an array within response.data
          const hasSalesResource = response.data.some(
            (user) =>
              Array.isArray(user.resources) &&
              user.resources.some((resource) => resource === "Sales")
          );

          // Set access based on the presence of "Sales"
          if (hasSalesResource) {
            setHasAccess(true);
          } else {
            console.log("No Sales resource found.");
            // setHasAccess(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    GetUser();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAttachment(reader.result);
        setAttachmentType(file.type); // Set attachment type based on the file type
      };
      reader.readAsDataURL(file);
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(audioChunksRef.current, { type: "audio/webm" });
        const reader = new FileReader();
        reader.onloadend = () => {
          setAttachment(reader.result);
          setAttachmentType("audio/webm");
        };
        reader.readAsDataURL(blob);
        audioChunksRef.current = [];
      };

      mediaRecorderRef.current.start();
      setRecording(true);
    } catch (error) {
      console.error("Error starting audio recording:", error);
    }
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  const startTranscription = () => {
    if (!("webkitSpeechRecognition" in window)) {
      console.error("Speech recognition is not supported in this browser.");
      return;
    }

    recognitionRef.current = new window.webkitSpeechRecognition();
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = true;
    recognitionRef.current.lang = "en-US";

    let finalTranscript = "";

    recognitionRef.current.onresult = (event) => {
      let interimTranscript = "";
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          finalTranscript += event.results[i][0].transcript;
        } else {
          interimTranscript += event.results[i][0].transcript;
        }
      }
      setNewComment(finalTranscript + interimTranscript);
    };

    recognitionRef.current.start();
    setTranscribing(true);
  };

  const stopTranscription = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setTranscribing(false);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.shiftKey) {
        return; 
      }
      event.preventDefault();
      handleCommentSubmit(); 
    }
  };

  const handleCommentSubmit = async () => {
    const userData = JSON.parse(localStorage.getItem("dexkorUserData"));

    if (!userData) {
      console.log("User not logged in.");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONVERSATION}/comments/${selectedCompany.companyID}`,
        {
          userId: userData.name,
          comment: newComment,
          time: new Date().toLocaleString(),
          department: userData.resources,
        }
      );
      //   setAllComments([response.data, ...allComments]);
      setNewComment("");
      setCommentFlag(true)
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="dexkor-sales-hub-conversation-sales">
      <Row>
        <Col span={replyActive ? 12 : 24}>
          <div className="dexkor-sales-hub-conversation-sales-comment-section">
            <div className="flex flex-col dexkor-sales-hub-conversation-sales-comment-section-card">
              {allComments.length === 0 ? (
                <p>Please start to add comments</p>
              ) : (
                allComments.map((comment) => (
                  <div
                    key={comment.id}
                    className="dexkor-sales-hub-comment-card"
                  >
                    {/* <Card className="dexkor-sales-hub-comment-card">
                      <span>
                        <Avatar />
                        <p
                          style={{
                            fontWeight: "bold",
                            marginRight: "3px",
                            marginLeft: "10px",
                          }}
                        >
                          {comment.userId}
                        </p>
                        <p>added a comment on</p>
                        <p style={{ marginLeft: "3px" }}>
                          {moment(comment.time).format("DD MMM YYYY @ h:mm A")}
                        </p>
                      </span>
                      <div
  className="deskor-sales-hub-comment-card-description"
  dangerouslySetInnerHTML={{
    __html: DOMPurify.sanitize(comment.comment),
  }}
/>
                    </Card> */}
                    <div className="flex justify-between px-5 py-2">
                      <div className="flex items-center gap-5">
                        <span>
                          {" "}
                          <Avatar />
                        </span>
                        <span
                          style={{
                            color: "#2A4DAD",
                            fontWeight: "Bold",
                            fontSize: "16px",
                          }}
                        >
                          {comment.userId}
                        </span>
                      </div>
                      <div>
                        {" "}
                        {moment(comment.time).format("DD, MMM, YYYY h:mm A")}
                      </div>
                    </div>
                    <div  ref={messagesEndRef}
                      className="pl-14"
                      style={{ fontSize: "14px" }}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(comment.comment),
                      }}
                    />
                    {/* <div className="pr-20 py-3 flex justify-end">
  <p>Leave a comment</p>
</div> */}
                  </div>
                ))
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {hasAccess ? (
                <div
                  style={{
                    width: "100%%",
                    position: "relative",
                  }}
                >
                  <div>
                    <ReactQuill
                      value={newComment}
                      onChange={(e) => setNewComment(e)}
                      onKeyDown={handleKeyDown}
                      required
                      theme="snow"
                      className="custom-quill"
                      modules={{
                        toolbar: [
                          { size: [] },
                          "bold",
                          "italic",
                          "underline",
                          "strike",

                          { list: "ordered" },
                          { list: "bullet" },
                          { indent: "-1" },
                          { indent: "+1" },

                          "link",
                          "video",
                          "blockquote",
                          "code-block",
                          { align: [] },
                          { color: [] },
                          { background: [] },
                          "clean",
                        ],
                      }}
                      formats={[
                        "header",
                        "font",
                        "size",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "list",
                        "bullet",
                        "indent",
                        "link",
                        "video",
                        "align",
                        "color",
                        "background",
                        "code-block",
                      ]}
                    />
                  </div>
                  <div className="dexkor-email-reply-send-button-container flex items-center">
                    <span style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          height: "40px",
                          padding: "0 10px",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip title="Attach File">
                            <label>
                              <PaperClipIcon
                                style={{ marginRight: "15px" }}
                                className="h-5 w-5 text-gray-500"
                              />
                              <input
                                type="file"
                                onChange={handleFileChange}
                                className="hidden"
                              />
                            </label>
                          </Tooltip>

                          <Tooltip title="Microphone">
                            <div
                              style={{
                                width: "30px",
                                height: "30px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginLeft: "15px",
                              }}
                            >
                              <button
                                className={`bg-transparent hover:bg-transparent p-2 text-gray-500 ${
                                  recording
                                    ? "hover:text-green-500"
                                    : "hover:text-gray-700"
                                } ml-1 ${recording ? "text-green-500" : ""}`}
                                onClick={
                                  recording ? stopRecording : startRecording
                                }
                              >
                                <MicrophoneIcon
                                  className={`h-5 w-5 ${
                                    recording
                                      ? "animate-pulse"
                                      : "text-gray-500"
                                  }`}
                                />
                              </button>
                            </div>
                          </Tooltip>
                        </div>
                        <Tooltip title="Send">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              width: "auto",
                            }}
                          >
                            <Button
                              onClick={handleCommentSubmit}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: "#2A4DAD",
                                color: "white",
                                padding: "8px 16px",
                                marginLeft: "auto",
                              }}
                            >
                              Send
                              <SendOutlined style={{ marginLeft: "8px" }} />
                            </Button>
                          </div>
                        </Tooltip>
                      </div>
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </Col>
        <Col span={replyActive ? 12 : 24}>
          {replyActive === true ? (
            <div style={{ backgroundColor: "#fff" }}>Hello</div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default Sales;
