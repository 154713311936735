import React, { useState, useEffect } from "react";
import { Button, Form, Input, Row, Col } from "antd";
import axios from "axios";
import useNotification from "../../../components/snackbars/SnackBar";
import Spinner from "../../../components/Full Page Spinner/fullpagespinner";

const Servetel = () => {
  const [conf, setConf] = useNotification();
  const [integrationFlag, setIntegrationFlag] = useState(false);
  const [activeIntegration, setActiveIntegration] = useState(false);
  const [data, setData] = useState({ userName: "" });
  const [loading, setLoading] = useState(false);

  // Create a form instance
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchIntegrations = async () => {
      const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));

      if (userInfo) {
        try {
          setLoading(true);
          const response = await axios.get(
            `${process.env.REACT_APP_CONVERSATION}/integration/${userInfo.uniquePartnerId}/get-all-integration`
          );

          const servetelIntegration = response.data.find(
            (integration) =>
              integration.integrationId === "Servetel001" &&
              integration.integrationStatus === "active"
          );

          if (servetelIntegration) {
            setActiveIntegration(true);
            setData(servetelIntegration);
            form.setFieldsValue({ username: servetelIntegration.userName });
          } else {
            console.log("Integration not found");
          }
        } catch (error) {
          console.error("Error fetching integrations:", error);
        } finally {
          setLoading(false);
          setIntegrationFlag(false);
        }
      }
    };

    fetchIntegrations();
  }, [integrationFlag]);

// Utility function to generate dynamic audit log entries
const generateAuditLog = (oldData, newData, userInfo, changeContext) => {
  const auditLogs = [];
  
  // Iterate through the keys of the new data to check for changes
  for (const key in newData) {
    if (newData[key] !== oldData[key]) {
      // Create an audit log entry for every modified field
      auditLogs.push({
        modifiedBy: userInfo.email,
        modifiedAt: new Date(),
        modifiedName: key,
        modifiedValue: `Changed from '${oldData[key] || "N/A"}' to '${newData[key]}'`,
        modifiedStatus: changeContext,
      });
    }
  }
  
  return auditLogs;
};

// onFinish function (example)
const onFinish = async (values) => {
  const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
  
  if (userInfo && values) {
    try {
      const loginResponse = await axios.post(
        `https://api.servetel.in/v1/auth/login`,
        {
          email: values.username,
          password: values.password,
        }
      );

      if (loginResponse.status === 200) {
        try {
          const checkResponse = await axios.get(
            `${process.env.REACT_APP_CONVERSATION}/integration/${userInfo.uniquePartnerId}/Servetel001/get-integration`
          );

          const newIntegrationData = {
            integrationStatus: "active",
            userName: values.username,
            password: values.password,
            accessToken: loginResponse.data.access_token,
          };

          // Ensure auditLogs is initialized as an array
          const currentAuditLogs = Array.isArray(checkResponse.data.auditLogs)
            ? checkResponse.data.auditLogs
            : [];

          if (checkResponse.data && checkResponse.data.integrationId === "Servetel001") {
            if (checkResponse.data.integrationStatus === "active") {
              setConf({ msg: "Integration Already Exists", variant: "warning" });
            } else {
              const auditLogEntries = generateAuditLog(checkResponse.data, newIntegrationData, userInfo, "reactivation");

              const updateResponse = await axios.put(
                `${process.env.REACT_APP_CONVERSATION}/integration/${userInfo.uniquePartnerId}/Servetel001/update-integration`,
                {
                  ...newIntegrationData,
                  auditLogs: [...currentAuditLogs, ...auditLogEntries],
                }
              );
              setConf({ msg: "Integration Updated to Active", variant: "success" });
              setActiveIntegration(true);
            }
          } else {
            await createIntegration(userInfo, values, loginResponse.data.access_token);
          }
        } catch (error) {
          await createIntegration(userInfo, values, loginResponse.data.access_token);
        }
      }
    } catch (error) {
      setConf({ msg: error.response?.data?.message || "Error occurred", variant: "error" });
    }
  }
};


// Helper function to create a new integration with dynamic audit logging
const createIntegration = async (userInfo, values, accessToken) => {
  const newIntegrationData = {
    uniquePartnerId: userInfo.uniquePartnerId,
    integrationId: "Servetel001",
    integrationName: "Servetel",
    integrationStatus: "active",
    userName: values.username,
    password: values.password,
    accessToken: accessToken,
    createdBy: userInfo.email,
  };

  // Generate audit log for integration creation
  const auditLogEntry = {
    modifiedBy: userInfo.email,
    modifiedAt: new Date(),
    modifiedName: "Servetel",
    modifiedValue: "Integration Created",
    modifiedStatus: "active",
  };

  try {
    const integrationCreate = await axios.post(
      `${process.env.REACT_APP_CONVERSATION}/integration/add-integration`,
      {
        ...newIntegrationData,
        auditLogs: [auditLogEntry],  // Include audit log for creation
      }
    );
    setConf({ msg: "Integration Successful", variant: "success" });
    setActiveIntegration(true);
  } catch (error) {
    setConf({ msg: error.response?.data?.message || "Error creating integration", variant: "error" });
  }
};

// Disconnect with dynamic audit logging
// Disconnect with dynamic audit logging
const handleDisconnect = async () => {
  const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));

  if (userInfo) {
    try {
      // Ensure auditLogs is initialized as an array
      const currentAuditLogs = Array.isArray(data.auditLogs) ? data.auditLogs : [];

      // Generate audit log for disconnection
      const auditLogEntry = {
        modifiedBy: userInfo.email,
        modifiedAt: new Date(),
        modifiedName: "Servetel",
        modifiedValue: "Integration Disconnected",
        modifiedStatus: "inactive",
      };

      // Update integration status to inactive with audit logs
      const response = await axios.put(
        `${process.env.REACT_APP_CONVERSATION}/integration/${userInfo.uniquePartnerId}/Servetel001/update-integration`,
        {
          integrationStatus: "inactive",
          auditLogs: [...currentAuditLogs, auditLogEntry],  // Append new audit log
        }
      );
      setActiveIntegration(false);
      setConf({ msg: "Integration disconnected", variant: "warning" });

      // Reset the form after disconnection
      form.resetFields();
    } catch (error) {
      console.log(error);
      setConf({ msg: "Error disconnecting integration", variant: "error" });
    }
  }
};


  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <div className="dexkor-sales-hub-main-screen-left-nav-bar-header">
            <span className="cursor-pointer">Servetel Integration</span>
          </div>
          <div className="flex pl-5 pt-10">
            <div>
              <img
                style={{ width: "200px" }}
                src="https://www.servetel.in/images/servetel-by-acefone.svg"
              />
            </div>
            <div>
              <span
                style={{ font: "roboto", fontSize: "25px", fontWeight: "bold" }}
                className="pl-10"
              >
                Dexkor Servetel Integration
              </span>
            </div>
          </div>
          <div style={{ padding: "20px" }}>
            <Row justify="center" style={{ paddingTop: "5%" }}>
              <Col xs={24} sm={16} md={12} lg={8}>
                <Form
                  form={form} // Bind form instance
                  name="basic"
                  labelCol={{
                    xs: { span: 24 },
                    sm: { span: 24 },
                  }}
                  wrapperCol={{
                    xs: { span: 24 },
                    sm: { span: 24 },
                  }}
                  initialValues={{
                    remember: true,
                    username: activeIntegration ? data.userName : "",
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                >
                  <Form.Item
                    label="Username"
                    name="username"
                    rules={[{ required: true, message: "Please input your username!" }]}
                  >
                    <Input style={{ height: "50px" }} disabled={activeIntegration} />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: "Please input your password!" }]}
                  >
                    <Input.Password style={{ height: "50px" }} disabled={activeIntegration} />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        width: "100%",
                        height: "50px",
                        marginLeft: "0px",
                        backgroundColor: "#2A4DAD",
                      }}
                      disabled={activeIntegration}
                    >
                      <p style={{ fontSize: "20px" }}>Connect</p>
                    </Button>
                  </Form.Item>

                  {activeIntegration && (
                    <Form.Item>
                      <Button
                        type="danger"
                        style={{
                          width: "100%",
                          height: "50px",
                          backgroundColor: "red",
                          color: "#fff",
                          fontSize: "20px",
                          margin: "0px",
                        }}
                        onClick={handleDisconnect}
                      >
                        Disconnect
                      </Button>
                    </Form.Item>
                  )}
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default Servetel;
