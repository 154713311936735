import React from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

function DexKorPrivateRoute({ children }) {
  const token = localStorage.getItem("dexkorUserToken");
  const accessUrl = window.location.origin;

  if (
    accessUrl === "https://internal.dexkor.com" ||
    accessUrl === "http://localhost:3000"
  ) {
  } else {
    return <Navigate to="/login" />;
  }
  if (!token) {
    return <Navigate to="/login" />;
  }

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // convert to seconds

    // Check if the token is expired
    if (decodedToken.exp < currentTime) {
      localStorage.removeItem("dexkorUserData"); // Optionally, clear the invalid token
      localStorage.removeItem("dexkorUserToken"); // Optionally, clear the invalid token
      return <Navigate to="/login" />;
    }
  } catch (error) {
    console.error("Invalid token:", error);
    return <Navigate to="/login" />;
  }

  return children;
}

export default DexKorPrivateRoute;
