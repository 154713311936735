import React from "react";
import { Route, Outlet } from "react-router-dom";
import SalesDesk from "../MainPage/Left Nav Bar/NavBar";
import SalesHubMainpage from "../MainPage/mainpage";
import AssignedSales from "../MainPage/Right Side Main Screen/MainContent";
import PrivateRoute from "../../Private Route/PrivateRoute";
import UnassignedCustomer from "../Unassigned Customer/UnassignedCustomers";
import AllCustomer from "../All Customer/AllCustomer";

const SalesHubRoutes = () => {
  return (
    <>
      {/* Define a route with the "sales-hub" path */}
      <Route path="/sales-hub" element={<PrivateRoute><SalesDesk /></PrivateRoute>}>
        {/* Nested routes inside sales-hub */}
        <Route index element={<PrivateRoute><SalesHubMainpage /></PrivateRoute>} />
        <Route path="assigned-sales" element={<PrivateRoute><AssignedSales /></PrivateRoute>} />
        <Route path="assigned-sales/:companyID" element={<PrivateRoute><AssignedSales /></PrivateRoute>} />
        {/* <Route path="assigned-sales/:companyID/:tab" element={<PrivateRoute><AssignedSales /></PrivateRoute>} /> */}
        <Route path="unassigned-customer" element={<PrivateRoute><UnassignedCustomer /></PrivateRoute>} />
        <Route path="unassigned-customer/:customerID" element={<PrivateRoute><UnassignedCustomer /></PrivateRoute>} />
        <Route path="all-customer" element={<PrivateRoute><AllCustomer /></PrivateRoute>} />
        <Route path="all-customer/:customerID" element={<PrivateRoute><AllCustomer /></PrivateRoute>} />
      </Route>
    </>
  );
};

export default SalesHubRoutes;
