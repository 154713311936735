import React, { useState } from "react";
import { Popover, Flex, Button } from "antd";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'; // Import the styles
import axios from "axios";
import useNotification from "../../components/snackbars/SnackBar";


const ClickToCall = ({ isPopoverOpen, setPopoverOpen }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [conf, setConf] = useNotification();

  // Toggle the popover visibility
  const handleOpenChange = (newOpen) => {
    setPopoverOpen(newOpen); // Control visibility state
  };

  // Prevent the popover from closing on click inside content
  const preventClose = (e) => {
    e.stopPropagation();
  };

  // Handle call function (trigger your API or action here)
  const handleCall = async() => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
  
    if (userInfo) {
      try {
        // Show "Connecting..." before making the API call
        setConf({ msg: "Connecting...", variant: "success" });
  
        // Make the API call
        const response = await axios.post(
          `${process.env.REACT_APP_CONVERSATION}/servetel/${userInfo.uniquePartnerId}/click-to-call`,
          {
            agentNumber: userInfo.phone,
            destinationNumber: phoneNumber,
            callerId: "+918068051700",
            callTimeOut: 30,
          }
        );
        if (response.status !== 200) {
          setConf({ msg: response.data.message, variant: "error" });
        } else {
          setConf({ msg: "Call initiated successfully!", variant: "success" });
        }
  
      } catch (error) {
        console.log(error);
        setConf({ msg: "Failed to initiate the call. Please try again.", variant: "error" });
      }
    }
  };

  return (
    <Flex vertical justify="center" align="center" className="demo">
      <Flex align="center" vertical>
        <Popover
          placement="right"
          title={
            <div style={{ width: "100%", height: "50px", textAlign: "center" }}>
              Click To Call
            </div>
          }
          content={
            <div className="popover-content" onClick={preventClose}>
              <PhoneInput
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={setPhoneNumber}
                international // Allow international formatting
                maxLength={15} // Restrict to max 10 digits
                defaultCountry="IN" // Set your default country
              />
              <Button
                type="primary"
                style={{ width: "100%", marginTop: "10px" }}
                onClick={handleCall}
                disabled={!phoneNumber} // Disable button if no number entered
              >
                Call
              </Button>
            </div>
          }
          trigger="click"
          open={isPopoverOpen} // Control from parent
          onOpenChange={handleOpenChange} // Handle visibility change
        />
      </Flex>
    </Flex>
  );
};

export default ClickToCall;
