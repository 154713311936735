import React from "react";
import { Route } from "react-router-dom";
import StarBoard from "../StarBoard";
import PrivateRoute from "../../Private Route/PrivateRoute";

const StarBoardRoutes = () => {
  return (
    <>
     <Route path="/star-board" element={<PrivateRoute><StarBoard /></PrivateRoute>}> </Route>
    </>
  );
};

export default StarBoardRoutes;
