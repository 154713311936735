import {
  SET_TICKET_EXPLORER_VIEWS,
  UPDATE_TICKET_EXPLORER_VIEW,
  ADD_TICKET_EXPLORER_VIEW,
} from "../actions/ticketExplorerViewActions";

const defaultViews = [
    {
      _id: 123443,
      name: "Unassigned tickets",
      description: "View for unassigned tickets",
      filters: [
        {
          name: "Status",
          value: "UnAssigned", // The status filter with value UnAssigned
        },
      ],
      itemsPerPage: 13,
      isActive: true,
    },
    {
      _id: 123444,
      name: "All tickets",
      description: "View for All tickets",
      filters: [
        {
          name: "Status",
          value: null, // The status filter with value UnAssigned
        },
      ],
      itemsPerPage: 13,
      isActive: null,
    },
  ];
const initialState = {
    views: defaultViews, // Initialize with default views
  };

const ticketExplorerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TICKET_EXPLORER_VIEWS:
      return {
        ...state,
        views: [...defaultViews, ...action.payload], // Combine default views and the new payload
      };

    case UPDATE_TICKET_EXPLORER_VIEW:
      return {
        ...state,
        views: state.views.map((view) =>
          view._id === action.payload._id ? action.payload : view
        ),
      };

    case ADD_TICKET_EXPLORER_VIEW:
      return {
        ...state,
        views: [...state.views, action.payload], // Add the new view to the end of the array
      };

    default:
      return state;
  }
};

export default ticketExplorerReducer;
