import React, { useEffect, useState } from "react";
import "./Task.css";
import logo from "../../../Images/Logo icon.png";
import axios from "axios";
import moment from "moment";
import dayjs from "dayjs";
import {
  Row,
  Col,
  Flex,
  message,
  Upload,
  Button,
  Card,
  Space,
  Table,
  DatePicker,
  TimePicker,
  Tag,
  Input,
  Select,
  Badge,
  Progress,
  Modal,
} from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { CiLocationOn, CiEdit } from "react-icons/ci";
import { FaPhoneAlt, FaPlus } from "react-icons/fa";
import useNotification from "../../../components/snackbars/SnackBar";
import ActivityComment from "../../Components/Drawer/Activity Comment/ActivityComment";

const { Option } = Select;
const { TextArea } = Input;

const FeedbackCalling = ({ selectedCompany, allCompanies }) => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [activities, setActivities] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [taskDrawerOpen, setTaskDrawerOpen] = useState(false)
  const [isCreatingTask, setIsCreatingTask] = useState(false);
  const [error, setError] = useState(null);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [conf, setConf] = useNotification();
  const [successMessage, setSuccessMessage] = useState("");
  const [newActivity, setNewActivity] = useState({
    taskName: "",
    taskType: "",
    taskStatus: "Pending",
    date: "",
    time: "",
  });
  const [taskModal, setTaskModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedTask((prevTask) => ({
      ...prevTask,
      [name]: value, // Update the taskOwner or any other field
    }));
  };

  const handleComments = async(record) => {
    setTaskDrawerOpen(true)
    setSelectedTask(record)
  }

  useEffect(() => {
    const fetchAllUsers = async () => {
      const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/all/${userData.uniquePartnerId}`
        );
        
        const filteredUser = response.data.filter((user) => user.status === "active" || user.status === "Active")
        
        setAllUsers(filteredUser); // Update users state with the latest data
        setUpdateFlag(false); // Reset flag after fetching
      } catch (error) {
        console.error("Error fetching users:", error);
        setUpdateFlag(false);
      }
    };
    fetchAllUsers();
  }, [updateFlag]);

  const handleDateChange = (date, dateString) => {
    setNewActivity((prev) => ({
      ...prev,
      date: dateString, // Storing formatted date
    }));
  };

  const handleTimeChange = (time, timeString) => {
    setNewActivity((prev) => ({
      ...prev,
      time: timeString, // Storing formatted time
    }));
  };

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("dexkorUserData")));
  }, []);

  useEffect(() => {
    const fetchTask = async () => {
      const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CONVERSATION}/task/getTaskByPartnerID/${userInfo.uniquePartnerId}`
        );
        const filteredActivities = response.data.data
          .filter((activity) => activity.taskType === "FeedbackCalling") // Filter by taskType
          .map((activity, index) => ({
            ...activity,
            key: index, // Ensuring each row has a unique key
          }));

        setActivities(filteredActivities);
        setUpdateFlag(false);
      } catch (error) {
        console.error("Error fetching activities:", error);
        setUpdateFlag(false);
      }
    };

    fetchTask(); // Call the async function
  }, [updateFlag]);

  const onFinishFailed = (errorInfo) => {
    setShowUpdateModal(false);
  };

  const handleUpdateTask = async () => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));

    if (userInfo && selectedTask) {
      try {
        const updatedActivity = {
          taskName: newActivity.taskName || selectedTask.taskName,
          taskType: newActivity.taskType || selectedTask.taskType,
          taskStatus: newActivity.taskStatus || selectedTask.taskStatus,
          taskOwner: newActivity.taskOwner || selectedTask.taskOwner,
          date: newActivity.date || selectedTask.date,
          time: newActivity.time || selectedTask.time,
        };
        const response = await axios.put(
          `${process.env.REACT_APP_CONVERSATION}/task/${selectedTask.taskID}/${userInfo.uniquePartnerId}/update-task`,
          updatedActivity
        );
        setShowUpdateModal(false);
        setUpdateFlag(true);
        setConf({ msg: "Task Updated successfully", variant: "success" });
      } catch (error) {
        setShowUpdateModal(false);
        setConf({ msg: "Internal Server Error", variant: "error" });
      }
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };


  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Task ID",
      dataIndex: "taskID",
      key: "taskID",
    },
    {
      title: "Customer Code",
      dataIndex: "customerCode",
      key: "taskID",
    },
    {
      title: "Status",
      dataIndex: "taskStatus",
      key: "status",
    },
    {
      title: "Type",
      dataIndex: "taskType",
      key: "status",
    },
    {
      title: "Owner",
      dataIndex: "taskOwner",
      key: "status",
    },
    {
      title: "Action",
      key: "action",

      render: (text, record) => (
        <>
        <Button
          type="primary"
          style={{
            backgroundColor: "#2A4DAD",
            borderRadius: "15px",
          }}
          onClick={() => {
            setSelectedTask(record); // Set the selected task
            setShowUpdateModal(true); // Show the modal
          }}
        >
          Update
        </Button>
        <Button
        type="primary"
        style={{
          backgroundColor: "#2A4DAD",
          borderRadius: "15px",
        }}
        onClick={() => {
         handleComments(record)
        }}
      >
        Comments
      </Button>
      </>
      ),
    },
  ];

  const handleOk = () => {
    setTaskModal(false);
  };
  const handleCancel = () => {
    setTaskModal(false);
  };

  const handleAddTask = async () => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));

    if (userInfo) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_CONVERSATION}/task/task-create/${userInfo.uniquePartnerId}`,
          {
            uniquePartnerId: userInfo.uniquePartnerId,
            companyID: selectedCompany.companyID,
            taskName: newActivity.taskName, // Ensure taskName exists
            taskType: newActivity.taskType, // Ensure taskType exists
            taskStatus: newActivity.taskStatus,
            date: newActivity.date,
            time: newActivity.time,
            createdBy: userInfo.email,
          }
        );

        setActivities([...activities, response.data]);
        setNewActivity({
          taskName: "",
          taskType: "",
          taskStatus: "Pending",
          date: "",
          time: "",
        });
        setTaskModal(false);
        setSuccessMessage("Task created successfully.");
      } catch (error) {
        console.error("Error adding activity:", error);
        setError("Failed to create task.");
        setTaskModal(false);
      }
    }
  };

  const handleSearchChange = (e) =>
    setSearchQuery(e.target.value.toLowerCase());

  const filteredTask = activities.filter((activity) => {
    const searchQueryLower = searchQuery.toLowerCase();

    return (
      activity.customerCode.toLowerCase().includes(searchQuery) ||
      activity.taskID.toLowerCase().includes(searchQuery) ||
      activity.taskType.toLowerCase().includes(searchQuery)
    );
  });

  return (
    <div className="dexkor-account-care-task-body">
      <div className="dexkor-account-care-main-screen-left-nav-bar-header">
        <div> Tasks</div>
      </div>
      <div className="flex justify-between items-center">
        <div className="dexkor-sales-hub-task-search-bar">
          <span>
            {" "}
            <h2>Search For Task</h2>{" "}
            <Input
              prefix={<SearchOutlined />}
              className="dexkor-sales-hub-task-search-bar-input"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </span>
          {/* <span>
            {" "}
            <h2>Status</h2>
            <Select
              className="dexkor-sales-hub-task-search-bar-input"
              defaultValue="lucy"
              //   onChange={handleChange}
              options={[
                {
                  value: "jack",
                  label: (
                    <span style={{ gap: "5px", display: "flex" }}>
                      <Badge color="green" /> <p>Active</p>
                    </span>
                  ),
                },
                {
                  value: "lucy",
                  label: (
                    <span>
                      <Badge color="green" /> Completed
                    </span>
                  ),
                },
                {
                  value: "Yiminghe",
                  label: (
                    <span>
                      <Badge color="#D03545" /> Scheduled
                    </span>
                  ),
                },
              ]}
            />
          </span>
          <span>
            {" "}
            <h2>Progress</h2>
            <Select
              className="dexkor-sales-hub-task-search-bar-input"
              defaultValue="lucy"
              //   onChange={handleChange}
              options={[
                {
                  value: "jack",
                  label: (
                    <span style={{ gap: "5px", display: "flex" }}>
                      <Badge color="green" /> <p>Active</p>
                    </span>
                  ),
                },
                {
                  value: "lucy",
                  label: (
                    <span>
                      <Badge color="green" /> Completed
                    </span>
                  ),
                },
                {
                  value: "Yiminghe",
                  label: (
                    <span>
                      <Badge color="#D03545" /> Scheduled
                    </span>
                  ),
                },
              ]}
            />
          </span> */}
        </div>
        {/* <span style={{ padding: "20px" }}>
          <Button
            type="primary"
            size="large"
            onClick={() => setTaskModal(true)}
            style={{ backgroundColor: "#2A4DAD" }}
          >
            <FaPlus /> Add New Task
          </Button>
        </span> */}
      </div>
      <div className="dexkor-sales-hub-task-table">
        <Table
          columns={columns}
          dataSource={filteredTask}
          loading={loading}
          pagination={{
            position: ["bottomCenter"], // Center pagination at the bottom
          }}
        />
      </div>
      <Modal
        open={taskModal}
        width={600}
        title="Add Task"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => handleAddTask()}>
            Submit
          </Button>,
        ]}
      >
        <>
          <span style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <h2>Select Date:</h2>
            <DatePicker
              style={{ width: "80%" }}
              onChange={handleDateChange} // Pass date directly to handler
            />
          </span>

          <span
            style={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              paddingTop: "20px",
            }}
          >
            <h2>Select Time:</h2>
            <TimePicker
              style={{ width: "80%" }}
              defaultOpenValue={dayjs("00:00:00", "HH:mm:ss")}
              onChange={(time, timeString) =>
                handleTimeChange(time, timeString)
              } // Correct parameter
            />
          </span>

          <span
            style={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              paddingTop: "20px",
            }}
          >
            <h2>Type:</h2>
            <Input
              style={{ marginLeft: "45px", marginRight: "17px" }}
              name="taskType" // Important: Name must match the state property
              onChange={handleInputChange} // Use generic input handler
            />
          </span>

          <span
            style={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              paddingTop: "20px",
            }}
          >
            <h2>Description:</h2>
            <TextArea
              style={{
                height: 120,
                marginRight: "17px",
                resize: "none",
              }}
              name="description" // Important: Name must match the state property
              onChange={handleInputChange} // Use generic input handler
            />
          </span>
        </>
      </Modal>
      <Modal
        open={showUpdateModal}
        width={600}
        title="Update Task"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => handleUpdateTask()}
          >
            Submit
          </Button>,
        ]}
      >
        <div className="pt-5">
          <Row className="flex items-center mt-5">
            <Col span={8}>
              <p>Select Status:</p>
            </Col>
            <Col span={16}>
              <Select
                defaultValue={
                  selectedTask ? selectedTask.taskStatus : "Pending"
                }
                style={{
                  width: "100%",
                  height: "50px",
                }}
                onChange={(value) => {
                  setNewActivity((prev) => ({
                    ...prev,
                    taskStatus: value, // Update task status on change
                  }));
                }}
                options={[
                  {
                    value: "Pending",
                    label: "Pending",
                  },
                  {
                    value: "InProgress",
                    label: "In Progress",
                  },
                  {
                    value: "Complete",
                    label: "Complete",
                  },
                  {
                    value: "Cancelled",
                    label: "Cancelled",
                  },
                ]}
              />
            </Col>
          </Row>
          <Row className="flex items-center mt-5">
            <Col span={8}>
              <p>Task Name:</p>
            </Col>
            <Col span={16}>
              <Input
                defaultValue={selectedTask ? selectedTask.taskName : ""}
                name="taskName"
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <Row className="flex items-center mt-5">
            <Col span={8}>
              <p>Task Type:</p>
            </Col>
            <Col span={16}>
              <Input
                defaultValue={selectedTask ? selectedTask.taskType : ""}
                name="taskType"
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <Row className="flex items-center mt-5">
            <Col span={8}>
              <p>Task Owner Email:</p>
            </Col>
            <Col span={16}>
              <Select
                value={selectedTask ? selectedTask.taskOwner : ""} // Use value instead of defaultValue
                name="taskOwner"
                onChange={(value) =>
                  handleInputChange({ target: { name: "taskOwner", value } })
                }
                style={{ width: "100%", height: "50px" }}
              >
                {allUsers.map((user) => (
                  <Option key={user.email} value={user.email}>
                    {user.name} ({user.email})
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </div>
      </Modal>
      <ActivityComment activityDrawerOpen={taskDrawerOpen} setActivityDrawerOpen={setTaskDrawerOpen} selectedActivity={selectedTask}/>
    </div>
  );
};

export default FeedbackCalling;
