import axios from "axios";

export const SET_TICKET_EXPLORER_VIEWS = "SET_TICKET_EXPLORER_VIEWS";

export const setTicketExplorerViews = (views) => ({
  type: SET_TICKET_EXPLORER_VIEWS,
  payload: views,
});

// Define new action type
export const UPDATE_TICKET_EXPLORER_VIEW = "UPDATE_TICKET_EXPLORER_VIEW";

// Action creator to update specific view(s)
export const updateTicketExplorerView = (updatedView) => ({
  type: UPDATE_TICKET_EXPLORER_VIEW,
  payload: updatedView,
});

// Define a new action type for adding a view
export const ADD_TICKET_EXPLORER_VIEW = "ADD_TICKET_EXPLORER_VIEW";

// Action creator to add a new view
export const addTicketExplorerView = (newView) => ({
  type: ADD_TICKET_EXPLORER_VIEW,
  payload: newView,
});

export const fetchTicketExplorerViews =
  (userId, userRole, userToken) => async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CONVERSATION}/ticket-explorer/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            Role: userRole,
            "Content-Type": "application/json",
          },
        }
      );

      const views = response.data || [];
      dispatch(setTicketExplorerViews(views));
    } catch (error) {
      console.error("Error fetching ticket explorer views:", error);
    }
  };
