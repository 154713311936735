import React from "react";
import Frame from "../../Images/Frame.jpg";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const MainPage = () => {
  const navig = useNavigate();
  return (
    <div style={{ height: "calc(100vh - 10%)" }}>
      <div
        style={{ marginTop: "10%", display: "flex", justifyContent: "center" }}
      >
        <img src={Frame} />
      </div>
      <div>
        <p
          style={{
            fontSize: "23px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
            fontFamily: "roboto",
          }}
        >
          Introducing Your DexKor Inbox: Customer Communication Simplified
        </p>
      </div>
      <div style={{ textAlign: "center" }}>
        <p
          style={{
            textAlign: "center",
            marginTop: "10px",
            marginLeft: "auto",
            fontFamily: "roboto",
            marginRight: "auto",
            width: "60%", // Adjust this value to control the width of the paragraph
          }}
        >
          You are welcome to the DexKor Inbox! This central nerve centre has
          been created in order to change your customer communication
          experience, and thus enable users to maintain them efficiently. Top
          quality is what we assure at DexKor given that our own products are
          used internally.
        </p>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}
      >
        <Button
          style={{ fontFamily: "roboto" }}
          type="primary"
          size="large"
          onClick={() => navig("/help-desk/inbox")}
        >
          Get Started
        </Button>
      </div>
    </div>
  );
};

export default MainPage;
