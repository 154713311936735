import React, { useState, useEffect } from "react";
import "./UserInvite.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import useNotification from "../../../components/snackbars/SnackBar";
import Spinner from "../../../components/Full Page Spinner/fullpagespinner";

const UserInvite = () => {
  const { token } = useParams();
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState();
  const [conf, setConf] = useNotification();
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isVerificationComplete, setIsVerificationComplete] = useState(false); // New state for verification completion
  const navigate = useNavigate();

  useEffect(() => {
    const VerifyUser = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/verify-token/${token}`
        );
        setUserDetails(response.data.data);
        setIsTokenValid(true);
      } catch (error) {
        console.log(error.response.data);
        setIsTokenValid(false);
      } finally {
        setLoading(false);
        setIsVerificationComplete(true); // Set verification completion to true
      }
    };

    VerifyUser();
  }, [token]);

  const signUp = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      if (userDetails.password === confirmPassword) {
        const res = await axios.post(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/auth/signup`,
          userDetails
        );
        navigate("/otp-verify", { state: { email: userDetails.email, uniquePartnerId: userDetails.uniquePartnerId } });
      } else {
        setConf({
          msg: "Password and confirm password must be the same!",
          variant: "warning",
        });
      }
    } catch (error) {
      setConf({ msg: error.response.data.msg, variant: "warning" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="dexkor-sales-hub-main-screen-left-nav-bar-header mb-5">
            <span className="cursor-pointer">DexKor User Account Setup</span>
          </div>
          {isVerificationComplete ? ( // Check for verification completion
            isTokenValid ? (
              <form
                className="max-w-sm mx-auto p-6 bg-white shadow-md rounded-lg"
                onSubmit={(e) => signUp(e)}
              >
                <h2 className="text-xl font-semibold mb-6 text-center text-gray-900 dark:text-white">
                  Sign Up
                </h2>

                <div className="mb-5">
                  <label
                    htmlFor="text"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="text"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    placeholder="Enter Full Name"
                    required
                    onChange={(e) =>
                      setUserDetails((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                  />
                </div>

                <div className="mb-5">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    disabled
                    value={userDetails.email}
                    id="disabled-input"
                    aria-label="disabled input"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    required
                  />
                </div>

                <div className="mb-5">
                  <label
                    htmlFor="contact"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Contact Number
                  </label>
                  <input
                    type="text"
                    id="contact"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    placeholder="Enter Contact Number"
                    required
                    onChange={(e) =>
                      setUserDetails((prev) => ({
                        ...prev,
                        phone: e.target.value,
                      }))
                    }
                  />
                </div>

                <div className="mb-5">
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    required
                    onChange={(e) =>
                      setUserDetails((prev) => ({
                        ...prev,
                        password: e.target.value,
                      }))
                    }
                  />
                </div>

                <div className="mb-5">
                  <label
                    htmlFor="repeat-password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    id="repeat-password"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    required
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>

                <div className="mb-5">
                  <label
                    htmlFor="role"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Role
                  </label>
                  <input
                    type="text"
                    disabled
                    value={userDetails.role}
                    id="disabled-input"
                    aria-label="disabled input"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    required
                  />
                </div>

                <div className="flex items-start mb-5">
                  <div className="flex items-center h-5">
                    <input
                      id="terms"
                      type="checkbox"
                      required
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                    />
                  </div>
                  <label
                    htmlFor="terms"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    I agree with the{" "}
                    <a
                      href="#"
                      className="text-blue-600 hover:underline dark:text-blue-500"
                    >
                      terms and conditions
                    </a>
                  </label>
                </div>

                <button
                  type="submit"
                  className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Send OTP
                </button>
              </form>
            ) : (
              <div className="flex flex-col items-center justify-center">
                <h3 className="text-lg font-semibold mb-4">Token is Invalid</h3>
                <p className="text-sm text-gray-600">
                  Please check your invitation link or contact support.
                </p>
              </div>
            )
          ) : (
            <div className="flex flex-col items-center justify-center">
              <h3 className="text-lg font-semibold mb-4">Verifying...</h3>
              <Spinner />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UserInvite;
