import React from "react";

function PostsSkeletonLoader() {
  return (
    <div className="relative flex bg-white shadow-lg rounded-lg mx-4 md:mx-auto my-5 max-w-md md:max-w-5xl ">
      <div className="w-full">
        <div className=" rounded overflow-hidden shadow-lg animate-pulse">
          <div className="h-48 bg-gray-300"></div>
          <div className="px-6 py-4">
            <div className="h-6 bg-gray-300 mb-2"></div>
            <div className="h-4 bg-gray-300 w-2/3"></div>
          </div>
          <div className="px-6 pt-4 pb-2">
            <div className="h-4 bg-gray-300 w-1/4 mb-2"></div>
            <div className="h-4 bg-gray-300 w-1/2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostsSkeletonLoader;
