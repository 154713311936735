import React, { useEffect, useState } from "react";
import axios from "axios";
import "./MainContent.css";
import Spinner from "../../../components/Spinner/spinner";
import io from "socket.io-client";
import logo from "../../../Images/Logo icon.png";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Input,
  List,
  Avatar,
  Spin,
  Tabs,
  theme,
  Divider,
  Button,
} from "antd";
import { useNavigate } from "react-router-dom";
import CompanyDetails from "../../All Pages/CompanyDetails";
import Comment from "../../All Pages/Comment/Comment";
import AllTickets from "../../All Pages/All Ticket/AllTicket";
import Documents from "../../All Pages/Documents/Documents";
import Contacts from "../../All Pages/Contact/Contacts";
import Task from "../../All Pages/Task/Task";

const { TabPane } = Tabs;

const socket = io(`${process.env.REACT_APP_CHAT_SERVER}`);

const AssignedSales = ({ item }) => {
  const [activeKey, setActiveKey] = useState(null);
  const [allCompanies, setAllCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ticketData, setTicketData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeCompanyID, setActiveCompanyID] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [userData, setUserData] = useState([]);
  const [companyTabState, setCompanyTabState] = useState({});
  const navigate = useNavigate();

  const { token } = theme.useToken();

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("dexkorUserData")));
  }, []);

  useEffect(() => {
    const getAllCompany = async () => {
const userToken = localStorage.getItem("dexkorUserToken")

if(userToken){
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_CONVERSATION}/company/all`,
          {
            headers: {
              Authorization:
                `Bearer ${userToken}`
            },
          }
        );
        setLoading(false);
        setAllCompanies(response.data);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
    };

    getAllCompany();
  }, []);

  useEffect(() => {
    const getAllConversation = async () => {
      try {
        setLoading(true);
        const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
        const response = await axios.get(
          `${process.env.REACT_APP_CONVERSATION}/conversations/all/${userInfo.uniquePartnerId}`
        );
        setLoading(false);
        setTicketData(response.data);
      } catch (error) {
        setLoading(false); // Handle errors properly
        console.error(error); // Log error for debugging
      }
    };

    getAllConversation();
  }, []);

  const onCompanyTabChange = (key) => {
    if (selectedCompany) {
      setCompanyTabState((prevState) => ({
        ...prevState,
        [selectedCompany.companyID]: key, // Save the active tab for the selected company
      }));
    }
  };

  const companyTabitems = [
    {
      key: "1",
      label: "Conversations",
      children: <Comment selectedCompany={selectedCompany}/>,
    },
    {
      key: "2",
      label: "Tickets",
      children: <AllTickets ticketData={ticketData} />,
    },

    {
      key: "3",
      label: "Tasks",
      children: <Task selectedCompany={selectedCompany}/>,
    },
    {
      key: "4",
      label: "Documents",
      children: <Documents selectedCompany={selectedCompany} />,
    },
    {
      key: "5",
      label: "Contacts",
      children: <Contacts selectedCompany={selectedCompany}/>,
    },
    {
      key: "6",
      label: "Company Details",
      children: <CompanyDetails selectedCompany={selectedCompany}/>,
    },
  ];

  const onTabEdit = (key, action) => {
    if (action === "remove") {
      const newTabs = tabs.filter((tab) => tab.key !== key);
      setTabs(newTabs);
      if (newTabs.length > 0) {
        setActiveKey(newTabs[0].key);
      } else {
        setActiveKey(null);
      }
    }
  };

  const onTabChange = (key) => {
    setActiveKey(key);
    const company = allCompanies.find((tab) => tab.companyID === key);
    setSelectedCompany(company);
    setActiveCompanyID(key)
  };

  const onTicketClick = (company) => {
    setActiveCompanyID(company.companyID);
    const existingTab = tabs.find((tab) => tab.key === company.companyID);
    if (existingTab) {
      setActiveKey(existingTab.key);
    } else {
      const newTab = {
        key: company.companyID,
        title: company.companyID,
      };
      const newTabs = [...tabs, newTab];
      setTabs((prevTabs) => [...prevTabs, newTab]);
      setActiveKey(newTab.key);
    }
    const lastActiveTab = companyTabState[company.companyID] || "1";
    setActiveKey(company.companyID);
    setSelectedCompany(company);
  };

  const handleSearchChange = (e) =>
    setSearchQuery(e.target.value.toLowerCase());

  const filteredCompanies = allCompanies.filter((company) => {
    const searchQueryLower = searchQuery.toLowerCase();

    return (
      company.companyName.toLowerCase().includes(searchQuery) ||
      company.hqCity.toLowerCase().includes(searchQuery) ||
      company.companyID.toLowerCase().includes(searchQuery) ||
      company.dmDetails.toLowerCase().includes(searchQuery)
    );
  });
  return (
    <>
      <Row className="dexkor-sales-hub-main-screen-body">
        <Col span={5} className="dexkor-sales-hub-main-screen-left-nav-bar">
          <div className="dexkor-sales-hub-main-screen-left-nav-bar-header">
            <span>Hub</span>
          </div>

          <div className="dexkor-sales-hub-search-bar">
            <Input
              className="dexkor-sales-hub-search-bar-input"
              placeholder="Search For Companies"
              prefix={<SearchOutlined />}
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>

          <div className="dexkor-sales-hub-company-list-container">
            {!loading ? (
              <List
                itemLayout="horizontal"
                dataSource={filteredCompanies}
                renderItem={(company) => (
                  <List.Item
                    onClick={() => onTicketClick(company)}
                 
                      className={company.companyID === activeCompanyID ? "dexkor-sales-hub-company-list" : ""}
                
                  >
                    <List.Item.Meta
                    style={{paddingLeft: '10px'}}
                      avatar={
                        <Avatar className="dexkor-sales-hub-company-list-avatar">
                          <img src={logo} alt="Company Logo" />
                        </Avatar>
                      }
                      title={
                        <div className="dexkor-sales-hub-company-list-title">
                          <span className="dexkor-sales-hub-company-list-company-id">
                            {company?.companyID || "Company ID"}
                          </span>
                          <span className="dexkor-sales-hub-list-company-status">{company.status}</span>
                        </div>
                      }
                      description={
                        <div className="dexkor-sales-hub-company-list-description">
                          {company.companyName}
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            ) : (
              <div className="dexkor-sales-hub-company-list-spinner">
                <Spinner />
                <span>
                  Loading ...
                </span>
              </div>
            )}
          </div>
        </Col>

        <Col span={19} className="dexkor-sales-hub-main-page-right-section">
          <div className="dexkor-sales-hub-main-page-right-section-tab-container">
            <Tabs className="dexkor-sales-hub-main-page-right-section-tabs"
              type="editable-card"
              hideAdd
              activeKey={activeKey}
              onChange={onTabChange}
              onEdit={onTabEdit}
              tabBarGutter={0}
            >
              {tabs.map((tab) => (
                <TabPane
                  tab={
                    <div className={`dexkor-sales-hub-main-page-right-section-title-tab ${activeKey === tab.key ? 'active' : ''}`}>
                      <span>{tab.title}</span>
                    </div>
                  }
                  key={tab.key}
                  closeIcon={
                    <CloseOutlined className={`dexkor-sales-hub-main-page-right-section-title-tab ${activeKey === tab.key ? 'active' : ''}`}
                    />
                  }
                />
              ))}
            </Tabs>
          </div>
          <div className="dexkor-sales-hub-main-page-right-section-company-details"
          >
            {activeKey === null ? null : (
              <span>
                <Tabs
                  className="tabs-with-divider"
                  tabBarGutter={0}
                  defaultActiveKey={
                    companyTabState[selectedCompany.companyID] || "1"
                  }
                  onChange={onCompanyTabChange}
                  style={{
                    fontFamily: "roboto",
                    fontSize: "16px",
                    height: "calc(100% - 56px)",
                  }}
                  items={companyTabitems}
                />
              </span>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AssignedSales;
