import React from "react";
import { Route } from "react-router-dom";
import AdSuite from "../AdSuite";
import PrivateRoute from "../../Private Route/PrivateRoute";

const AdSuiteRoute = () => {
  return (
    <>
      <Route path="/adsuite" element={<PrivateRoute><AdSuite /></PrivateRoute>}> 
      </Route>

    </>
  );
};

export default AdSuiteRoute;
